import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { GroupUserStatusEnum } from 'src/app/config/constants/enums';
import { capitlizeFirstLetter, objToParams } from 'src/app/config/helper';
import { baseUrl } from 'src/app/config/types/base-url';
import CreatePost from '../shared/components/CreatePost';
import GroupAboutInfoCard from '../shared/components/GroupAboutInfo/GroupAboutInfoCard';
import GroupMiniInfoCard from './components/GroupMiniInfoCard';
import Loader from '../shared/components/Loader';
import Post from '../shared/components/Post';
import ProfilePageHeader from '../shared/components/ProfilePageHeader';
import GroupDetailHeader from './components/GroupDetailHeader/GroupDetailHeader';
import GroupsList from './components/GroupsList';
import { useGetGroupQuery, useGetGroupsQuery } from './services/groupsApi';
import { useEffect, useState } from 'react';
import { useGetPersonalInformationQuery } from '../profile/services/profileApi';

const GroupDetail = () => {
  const { id } = useParams();

  const { user } = useSelector((state: any) => state.auth);

  const { data, isLoading }: any = useGetGroupQuery({ groupId: id });
  const { data: publicGroups, isLoading: loadingPublicGroups } =
    useGetGroupsQuery({
      params: objToParams({ only_public: true, limit: 5, page: 1 }),
      requested: 0,
    });
  const { data: requestedGroups, isLoading: loadingRequestedGroups } =
    useGetGroupsQuery(
      {
        params: objToParams({ limit: 5, page: 1 }),
        userId: user?.uid,
        requested: 1,
      },
      { skip: loadingPublicGroups }
    );
  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );
  const { data: personalInformation = {} } = getPersonalInformation;
  const { full_name, username } = personalInformation || {};

  const {
    background_image,
    group_member_count,
    group_type,
    name,
    profile_image,
    user_id,
    id: groupId,
    group_user_status,
    sport_category,
    sport_industry,
    sport_name,
  } = data?.data || {};

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMediaQuery = () => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      setIsMobile(mediaQuery.matches);
    };

    checkMediaQuery();
    window.addEventListener('resize', checkMediaQuery);

    return () => {
      window.removeEventListener('resize', checkMediaQuery);
    };
  }, []);

  const RenderPosts = () => {
    return (
      <Col xs={12} sm={12} md={12} lg={8} xl={7}>
        {group_user_status == GroupUserStatusEnum.Joined ||
        group_user_status == GroupUserStatusEnum.Owner ||
        group_type == 'public' ? (
          <>
            {(group_user_status == GroupUserStatusEnum.Joined ||
              group_user_status == GroupUserStatusEnum.Owner) && (
              <CreatePost
                groupId={groupId}
                placeholder={`What's your game plan, ${full_name || username}`}
              />
            )}
            <Post groupId={groupId} />
          </>
        ) : (
          <p style={{ color: 'gray', marginTop: '20px' }}>
            Join group to see posts
          </p>
        )}
      </Col>
    );
  };

  return (
    <AuthGuard>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div id="content-page" className="content-page">
            <Row>
              <Col md={12} xl={10}>
                <ProfilePageHeader
                  image={baseUrl + '/' + background_image}
                  title="Groups"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={10}>
                <GroupDetailHeader
                  id={groupId}
                  name={name}
                  visibility={capitlizeFirstLetter(group_type)}
                  members={group_member_count}
                  image={baseUrl + '/' + profile_image}
                  ownerId={user_id}
                  groupUserStatus={group_user_status}
                />
              </Col>
            </Row>
            <Row>
              {!isMobile && RenderPosts()}
              <Col sm={12} md={12} lg={4} xl={3}>
                <GroupMiniInfoCard />
                <GroupAboutInfoCard
                  groupType={group_type}
                  sportCategory={sport_category?.title}
                  sportIndustry={sport_industry?.title}
                  sportName={sport_name?.title}
                />
                {loadingPublicGroups ? (
                  <Loader />
                ) : (
                  <GroupsList
                    seeAllLink={`/groups`}
                    title="Public Groups"
                    groups={publicGroups?.data?.data}
                  />
                )}
                {loadingRequestedGroups ? (
                  <Loader />
                ) : (
                  <GroupsList
                    seeAllLink={`/groups/${user?.uid}/${1}`}
                    title="Requested Groups"
                    groups={
                      requestedGroups?.data?.length > 0
                        ? requestedGroups?.data
                        : requestedGroups?.data?.data
                    }
                  />
                )}
              </Col>
              {isMobile && RenderPosts()}
            </Row>
          </div>
        )}
      </>
    </AuthGuard>
  );
};

export default GroupDetail;
