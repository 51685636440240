import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import marketPlaceImg from '../../assets/images/marketplace.png';
import AddNewItemForm from '../shared/components/Forms/AddNewItemForm';
import MarketPlaceHeader from '../shared/components/MarketPlaceHeader/MarketPlaceHeader';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import { useGetPersonalInformationQuery } from '../profile/services/profileApi';
import { Link, useNavigate } from 'react-router-dom';
import {
  setBusinessDetails,
  setScrollToBottom,
} from '../shared/store/sharedSlice';
import { useDispatch } from 'react-redux';

const AddItem = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const checkBuinessDetailAvailability = () => {
    if (
      personalInformation?.company_name ||
      personalInformation?.website ||
      personalInformation?.sports_industry ||
      personalInformation?.business_size ||
      personalInformation?.description ||
      personalInformation?.tag_line ||
      personalInformation?.verify_authorized_representative
    )
      return true;
    else return false;
  };

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Market Place" image={marketPlaceImg} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <MarketPlaceHeader activeLink="/add-item" />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              {checkBuinessDetailAvailability() ? (
                <AddNewItemForm />
              ) : (
                <p>
                  Please add{' '}
                  <span
                    onClick={() => {
                      dispatch(setBusinessDetails(true));
                      dispatch(setScrollToBottom(true));
                      history('/profile/edit-personal-information');
                    }}
                  >
                    <Link to="">business details</Link>{' '}
                  </span>
                  to add items.
                </p>
              )}
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default AddItem;
