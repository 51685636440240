import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetCategoriesQuery,
  useGetEmploymentTypesQuery,
  useGetIndustriesQuery,
  useLazyGetNamesQuery,
  useLazyGetRolesQuery,
  useLazyGetSportTypesQuery,
} from '../../services/sharedApi';
import { useForm } from 'react-hook-form';
import { useAddWorkExperienceMutation } from 'src/app/screens/profile/services/profileApi';
import { toast } from 'react-hot-toast';
import { setProfileCompletion } from 'src/app/screens/auth/store/authSlice';
import { getFilePreviewURL } from 'src/app/config/helper';

const AddWorkplaceExperienceForm = () => {
  const defaultValues = {
    job_title: '',
    employment_type_id: '',
    niche_industry_id: '',
    company: '',
    sports_category_id: '',
    location: '',
    latitude: '',
    longitude: '',
    currently_working: '',
    start_date: '',
    end_date: '',
    description: '',
    link: '',
    media: {},
    sport_name_id: '',
    sport_type_id: '',
  };

  const history = useNavigate();

  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);

  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border, textInput },
  } = theme;

  const [industryNiche, setIndustryNiche] = useState('');
  const [roles, setRoles] = useState([]);
  const [category, setCategory] = useState('');
  const [names, setNames] = useState([]);
  const [name, setName] = useState('');
  const [sportTypes, setSportTypes] = useState([]);
  const [sportType, setSportType] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchTitle = watch('job_title');
  const watchCompany = watch('company');
  const watchMedia = watch('media');
  const watchCurrentlyWorking = watch('currently_working');

  const { data: allIndustries } = useGetIndustriesQuery({});

  const [getRoles] = useLazyGetRolesQuery();

  const { data: allCategories } = useGetCategoriesQuery({});

  const { data: allEmploymentTypes } = useGetEmploymentTypesQuery({});

  const [getNames] = useLazyGetNamesQuery();

  const [getSportTypes] = useLazyGetSportTypesQuery();

  const [addWorkExperience, { isLoading }] = useAddWorkExperienceMutation();

  const emptyInputFields = () => {
    reset({
      ...defaultValues,
    });

    setIndustryNiche('');
    setRoles([]);
    setCategory('');
    setNames([]);
    setName('');
    setSportTypes([]);
    setSportType('');
    setEmploymentType('');
  };

  const handleAddWorkExperience = handleSubmit(async (data: any) => {
    if (
      watchTitle != '' &&
      watchCompany != '' &&
      Object?.keys(watchMedia)?.length != 0
    ) {
      const currentlyWorking: any = data?.currently_working == true ? 1 : 0;

      const modifiedMedia =
        typeof data?.media == 'string' ? data?.media : data?.media[0];

      const formData = new FormData();

      formData.append('job_title', data?.job_title);
      formData.append('employment_type_id', data?.employment_type_id);
      formData.append('niche_industry_id', data?.niche_industry_id);
      formData.append('company', data?.company);
      formData.append('sports_category_id', data?.sports_category_id);
      formData.append('location', data?.location);
      formData.append('latitude', data?.latitude);
      formData.append('longitude', data?.longitude);
      formData.append('currently_working', currentlyWorking);
      formData.append('start_date', data?.start_date);
      formData.append(
        'end_date',
        data?.currently_working ? '' : data?.end_date
      );
      formData.append('description', data?.description);
      formData.append('link', data?.link);
      formData.append('media', modifiedMedia);
      formData.append('sport_name_id', data?.sport_name_id);
      formData.append('sport_type_id', data?.sport_type_id);

      const response = await addWorkExperience(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        if (user?.profile_completion?.workExperience == 0) {
          dispatch(
            setProfileCompletion({
              ...user,
              profile_completion: {
                ...user?.profile_completion,
                workExperience: 1,
              },
            })
          );
          user?.profile_completion?.teamsInfo == 0
            ? history('/profile/profile-add-teams')
            : history('/');
        }
        emptyInputFields();
      }
    } else {
      toast.error(
        'Please add atleast title, company name, and image to complete the step'
      );
    }
  });

  useEffect(() => {
    watchMedia && setSelectedMedia(watchMedia[0]);
  }, [watchMedia]);

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Work Experience</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Title/Role</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title/Role"
                    {...register('job_title')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    {...register('company')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Employment Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Employment Type"
                    value={employmentType}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="mb-3">
                <div
                  style={{
                    background: background.tag,
                    padding: '16px',
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allEmploymentTypes?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return (
                      <p
                        key={uid}
                        style={{
                          border: `1px dashed ${textInput.border}`,
                          padding: '2px 8px',
                          display: 'inline-block',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          background:
                            employmentType === title
                              ? background.blue
                              : 'transparent',
                          color:
                            employmentType === title
                              ? background.light
                              : background.dark,
                        }}
                        onClick={() => {
                          setEmploymentType(title);
                          setValue('employment_type_id', uid);
                        }}
                      >
                        {title}
                      </p>
                    );
                  })}
                </div>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Niche/Industry Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Niche/Industry Name"
                    value={industryNiche}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="mb-3">
                <div
                  style={{
                    background: background.tag,
                    padding: '16px',
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allIndustries?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return (
                      <p
                        key={uid}
                        style={{
                          border: `1px dashed ${textInput.border}`,
                          padding: '2px 8px',
                          display: 'inline-block',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          background:
                            industryNiche === title
                              ? background.blue
                              : 'transparent',
                          color:
                            industryNiche === title
                              ? background.light
                              : background.dark,
                        }}
                        onClick={async () => {
                          setIndustryNiche(title);
                          const roles: any = await getRoles(uid);
                          setRoles(roles?.data?.data);
                          setValue('niche_industry_id', uid);
                        }}
                      >
                        {title}
                      </p>
                    );
                  })}
                </div>
              </Col>
              <Col md="12" className="mt-2">
                <Form.Group className="form-group">
                  <Form.Label>Sports Categories</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sports Categories"
                    value={category}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="mb-3">
                <div
                  style={{
                    background: background.tag,
                    padding: '16px',
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allCategories?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return (
                      <p
                        key={uid}
                        style={{
                          border: `1px dashed ${textInput.border}`,
                          padding: '2px 8px',
                          display: 'inline-block',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          background:
                            category === title
                              ? background.blue
                              : 'transparent',
                          color:
                            category === title
                              ? background.light
                              : background.dark,
                        }}
                        onClick={async () => {
                          setCategory(title);
                          const names = await getNames(uid);
                          setNames(names?.data?.data);
                          setValue('sports_category_id', uid);
                        }}
                      >
                        {title}
                      </p>
                    );
                  })}
                </div>
              </Col>

              <Col md="12" className="mt-2">
                <Form.Group className="form-group">
                  <Form.Label>Sport Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sport Name"
                    value={name}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="mb-3">
                {names?.length > 0 && (
                  <div
                    style={{
                      background: background.tag,
                      padding: '16px',
                      display: 'flex',
                      gap: '8px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {names?.map?.((data: any) => {
                      const { uid, title } = data;
                      return (
                        <p
                          key={uid}
                          style={{
                            border: `1px dashed ${textInput.border}`,
                            padding: '2px 8px',
                            display: 'inline-block',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            background:
                              name === title ? background.blue : 'transparent',
                            color:
                              name === title
                                ? background.light
                                : background.dark,
                          }}
                          onClick={async () => {
                            setName(title);
                            setValue('sport_name_id', uid);
                            const sportTypes = await getSportTypes(uid);
                            setSportTypes(sportTypes?.data?.data);
                          }}
                        >
                          {title}
                        </p>
                      );
                    })}
                  </div>
                )}
              </Col>

              {sportTypes?.length > 0 && (
                <>
                  <Col md="12" className="mt-2">
                    <Form.Group className="form-group">
                      <Form.Label>Sport Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sport Name"
                        value={sportType}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12" className="mb-3">
                    {sportTypes?.length > 0 && (
                      <div
                        style={{
                          background: background.tag,
                          padding: '16px',
                          display: 'flex',
                          gap: '8px',
                          flexWrap: 'wrap',
                        }}
                      >
                        {sportTypes?.map?.((data: any) => {
                          const { uid, title } = data;
                          return (
                            <p
                              key={uid}
                              style={{
                                border: `1px dashed ${textInput.border}`,
                                padding: '2px 8px',
                                display: 'inline-block',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                background:
                                  sportType === title
                                    ? background.blue
                                    : 'transparent',
                                color:
                                  sportType === title
                                    ? background.light
                                    : background.dark,
                              }}
                              onClick={async () => {
                                setSportType(title);
                                setValue('sport_type_id', uid);
                              }}
                            >
                              {title}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </Col>
                </>
              )}
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location"
                    {...register('location')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Check
                  type="checkbox"
                  label="I am currently working in this role."
                  {...register('currently_working')}
                />
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    {...register('start_date')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>End Date (Or Expected)</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={watchCurrentlyWorking}
                    placeholder="End Date (Or Expected)"
                    {...register('end_date')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    {...register('description')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Add Link</Form.Label>
                  <Form.Control type="text" {...register('link')} />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Add Media</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    {...register('media')}
                  />
                </Form.Group>
                <p>
                  <b>Media </b>Add media like images, documents, sites or
                  presentations (eg.
                  .pdf,.ppt/.pptx,.doc/.docx,.jpg/.jpeg/.png,.gif)
                </p>
              </Col>
              <Col md="12">
                {typeof watchMedia !== 'string' && selectedMedia && (
                  <img
                    src={watchMedia && getFilePreviewURL(watchMedia[0])}
                    alt="Profile Image"
                    style={{
                      width: '100px',
                    }}
                  />
                )}
              </Col>
            </Row>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
              onClick={handleAddWorkExperience}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddWorkplaceExperienceForm;
