import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AuthGuard from 'src/app/config/auth/AuthGuard'
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader'
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar'
import WorkplaceDetailCard from '../shared/components/WorkplaceDetailCard/WorlplaceDetailCard'

const WorkplaceDetail = () => {

    const history = useNavigate()

    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    <Row>
                        <Col md={10}>
                            <ProfileHeader
                                loggedInUserProfile={true}
                                myProfile={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <ProfileSidebar activeLink='work-experience' />
                        </Col>
                        <Col md={8}>
                            <WorkplaceDetailCard />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default WorkplaceDetail