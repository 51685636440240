import cls from 'classnames';
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { slideOne, slideThree, slideTwo } from 'src/app/config/constants/texts';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import cycling from '../../../assets/images/cycling.png';

const PasswordConfirmation = () => {
  const history = useNavigate();

  return (
    <AuthGuard>
      <>
        <section className={cls('sign-in-page')}>
          <div
            id="container-inside"
            style={{
              border: '0px solid red',
            }}
          >
            <div
              id="circle-small"
              style={{
                background: '#5B5B5C',
              }}
            />
            <div
              id="circle-medium"
              style={{
                background: '#444444',
              }}
            />
            <div
              id="circle-large"
              style={{
                background: '#2D2D2D',
              }}
            />
            <div
              id="circle-xlarge"
              style={{
                background: '#1F1E1F',
              }}
            />
            <div
              id="circle-xxlarge"
              style={{
                background: '#151515',
              }}
            />
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              <Col
                md="6"
                className="text-center "
                style={{
                  border: '0px solid red',
                }}
              >
                <div
                  className="sign-in-detail text-white"
                  style={{
                    border: '0px solid red',
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Link className="sign-in-logo" to="#">
                      <Logo height="73px" width="" />
                    </Link>
                    <h4 className="mb-3">Your Sports Network</h4>
                    <div
                      className="sign-slider"
                      style={{
                        border: '0px solid red',
                      }}
                    >
                      <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                          delay: 2000,
                          disableOnInteraction: false,
                        }}
                        className="list-inline m-0 p-0 "
                      >
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">Find new friends</h4>
                          <p>{slideOne}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">
                            Connect with the world
                          </h4>
                          <p>{slideTwo}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">Create new events</h4>
                          <p>{slideThree}</p>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
                <div
                  className="sign-in-from"
                  style={{
                    border: '0px solid red',
                    marginTop: '20%',
                  }}
                >
                  <h1 className="mb-0">Password Reset</h1>
                  <p>Your password has been successfully reset.</p>
                  <p>Click below to Sign In magically.</p>
                  <Form className="mt-4">
                    <div
                      className="sign-info"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Link
                        to="/auth/sign-in"
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '10px',
                          color: 'red',
                        }}
                      >
                        <span className="material-symbols-outlined">
                          arrow_back
                        </span>
                        Back to Sign In
                      </Link>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </AuthGuard>
  );
};

export default PasswordConfirmation;
