import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { objToParams } from 'src/app/config/helper';
import useDebounce from 'src/app/hooks/useDebounce';
import marketPlaceImg from '../../assets/images/marketplace.png';
import Loader from '../shared/components/Loader/Loader';
import MarketPlaceItems from '../shared/components/MarketPlaceItems/MarketPlaceItems';
import NoData from '../shared/components/NoData/NoData';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import Advertisement from '../shared/components/advertisement/Advertisement';
import { useGetMarketPlaceItemsQuery } from './services/marketPlaceApi';

const MarketPlace = () => {
  const [marketPlaceType, setMarketPlaceType] = useState('items');
  const { search, handleSearch } = useDebounce();
  const [userLocation, setUserLocation] = useState<any>(null);

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { data: getMarketPlaceItems, isLoading } = useGetMarketPlaceItemsQuery(
    {
      params: objToParams({
        type: marketPlaceType,
        search: search,
        latitude: userLocation?.latitude,
        longitude: userLocation?.longitude,
      }),
    },
    {
      skip: !marketPlaceType && userLocation,
    }
  );
  const { data: marketPlaceItems = [] } = getMarketPlaceItems || {};

  const data = marketPlaceItems;

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Market Place" image={marketPlaceImg} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <div className="d-flex gap-3 align-items-center justify-content-end flex-wrap my-3">
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    width: '200px',
                  }}
                  className='custom-input-group'
                  value={marketPlaceType}
                  onChange={(e) => setMarketPlaceType(e.target.value)}
                >
                  <option value="items">Items</option>
                  <option value="company">Company</option>
                  <option value="radius">Within radius 2KM</option>
                </Form.Select>
                <div className="iq-search-bar device-search position-relative">
                  <InputGroup
                    style={{
                      width: '200px',
                    }}
                    className='custom-input-group'
                  >
                    <Form.Control
                      placeholder="Search"
                      style={{
                        borderRight: 0,
                      }}
                      onChange={handleSearch}
                    />
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{
                        backgroundColor: 'transparent',
                      }}
                    >
                      <span className="material-symbols-outlined">search</span>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <Button
                  variant="primary"
                  type="button"
                  className="float-end"
                  style={{
                    background: button.background,
                    borderColor: border.dark,
                    paddingRight: '32px',
                    paddingLeft: '32px',
                  }}
                  onClick={() => history('/add-item')}
                >
                  Sell An Item
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              {!isLoading ? (
                data?.length > 0 ? (
                  <MarketPlaceItems data={data} />
                ) : (
                  <NoData
                    name={
                      marketPlaceType == 'radius' ? 'items' : marketPlaceType
                    }
                  />
                )
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
        </div>

        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default MarketPlace;
