import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Hide from 'src/app/assets/icon/hide/hide';
import Show from 'src/app/assets/icon/show/show';
import { camelToSentence } from 'src/app/config/helper';

type TextInputType = {
  name: string;
  placeholder?: string;
  register: any;
  onChange: any;
};

function TextInput({ name, placeholder, register, onChange }: TextInputType) {
  const isPassword = name.includes('password');

  const { mode } = useSelector((state: any) => state.styles);

  const [show, setShow] = useState(false);

  return (
    <Form.Group
      className="form-group"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
      }}
    >
      <Form.Control
        type={show ? 'text' : name}
        placeholder={placeholder || camelToSentence(name)}
        {...register}
        {...(onChange && {
          onChange,
        })}
        style={
          isPassword
            ? {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                borderRightWidth: '0px',
              }
            : {}
        }
      />
      {isPassword && (
        <div
          onClick={() => setShow((prev) => !prev)}
          style={{
            border: mode == 'dark' ? `1px solid #2a2a2a` : `1px solid #CED4DA`,
            borderLeftWidth: 0,
            height: '39px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {show ? <Hide /> : <Show />}
        </div>
      )}
    </Form.Group>
  );
}

export default TextInput;
