import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { followersData } from 'src/app/mock/followers';
import { photosData } from 'src/app/mock/photos';
import { teamsData } from 'src/app/mock/teams';
import FollowersCard from '../shared/components/FollowersCard';
import PhotoCard from '../shared/components/PhotoCard';
import Post from '../shared/components/Post';
import ProfileAboutCard from '../shared/components/ProfileAboutCard';
import ProfileHeader from '../shared/components/ProfileHeader';
import TeamCard from '../shared/components/TeamCard';
import Advertisement from '../shared/components/advertisement/Advertisement';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const UserProfile = () => {
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMediaQuery = () => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      setIsMobile(mediaQuery.matches);
    };

    checkMediaQuery();
    window.addEventListener('resize', checkMediaQuery);

    return () => {
      window.removeEventListener('resize', checkMediaQuery);
    };
  }, []);

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            {!isMobile && (
              <Col xs={12} sm={12} md={12} lg={7}>
                <Post profile />
              </Col>
            )}
            <Col sm={12} md={12} lg={3}>
              <ProfileAboutCard />
              <PhotoCard
                photos={photosData}
                loggedInUserProfile={false}
                redirectTo={`/user-profile/photos/${id}`}
                style={{
                  height: '70px',
                  objectFit: 'cover',
                }}
              />
              <FollowersCard followers={followersData} />
              <TeamCard
                teams={teamsData}
                loggedInUserProfile={false}
                profile
                redirectTo="/team-details"
                style={{
                  height: '70px',
                  objectFit: 'cover',
                }}
              />
            </Col>
            {isMobile && (
              <Col xs={12} sm={12} md={12} lg={7}>
                <Post profile />
              </Col>
            )}
          </Row>
        </div>
        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default UserProfile;
