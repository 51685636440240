import user01 from "../assets/images/03.jpg"
import user02 from "../assets/images/04.jpg"
import user03 from "../assets/images/05.jpg"
import user04 from "../assets/images/02.jpg"

export const userprofileData = [
    {
        image: user01,
        name: 'Alice',
        description: 'This is lorem text'
    },
    {
        image: user02,
        name: 'Juliya',
        description: 'This is lorem text'
    },
    {
        image: user03,
        name: 'William Smith',
        description: 'This is lorem text'
    },
    {
        image: user04,
        name: 'Jordon',
        description: 'This is lorem text'
    },
]