import cls from 'classnames';
import moment from 'moment';
import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { Card, Dropdown, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationsEnum } from 'src/app/config/constants/enums/notifications.enum';
import NoData from '../../NoData/NoData';
import CustomToggle from '../../dropdowns';
import {
  useAllNotificationsQuery,
  useReadAllNotificationMutation,
  useReadNotificationMutation,
} from '../services/notificationAPI';
import {
  setChatType,
  setOpenChat,
  setReceiverId,
} from '../../../store/sharedSlice';

const NotificationPopup = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.auth);

  const [userNotifications, setUserNotifications] = useState<any>([]);

  const {
    data: getAllNotifications,
    refetch: refetchNotification,
    isLoading,
  } = useAllNotificationsQuery({});
  const { data: allNotifications } = getAllNotifications || {};
  const [readNotification, { isLoading: readingNotification }] =
    useReadNotificationMutation();
  const [readAllNotification, { isLoading: readingAllNotification }] =
    useReadAllNotificationMutation();

  const handleOpenChat = (id: any) => {
    dispatch(setOpenChat(true));
    dispatch(setReceiverId(id));
    dispatch(setChatType('general'));
  };

  const handleReadNotification = async (
    id: any,
    isAllNotification: boolean,
    data: any
  ) => {
    const response = isAllNotification
      ? readAllNotification({})
      : readNotification(id);

    if (
      data?.notificationable_type?.split('\\')?.[2] == NotificationsEnum.Group
    ) {
      if (data?.heading.includes('Accept')) {
        history(`/groups/group-detail/${data?.notificationable_id}`);
      } else if (data?.heading.includes('Post')) {
        history(`/groups/group-detail/${data?.notificationable_id}`);
      } else {
        history(`/groups/group-requests/${data?.notificationable_id}`);
      }
    }

    if (
      data?.notificationable_type?.split('\\')?.[2] ==
      NotificationsEnum.Follower
    ) {
      if (data?.heading.includes('accept')) {
        history(`/profile`);
      } else if (data?.heading.includes('connection request')) {
        history(`/profile/pending-requests`);
      } else if (data?.heading.includes('follow request')) {
        history(`/profile/follow-requests`);
      }
    }

    if (
      data?.notificationable_type?.split('\\')?.[2] == NotificationsEnum.Chat
    ) {
      // history('/chat');
      handleOpenChat(data?.sender_id);
    }

    if (
      data?.notificationable_type?.split('\\')?.[2] == NotificationsEnum.Job
    ) {
      history(`/jobs/job-details/${data?.notificationable_id}`);
    }
  };

  useEffect(() => {
    if (allNotifications) {
      var pusher = new Pusher('14d3035a4ea699478de1', {
        cluster: 'ap2',
      });
      const channel = pusher.subscribe('notification');
      channel.bind('notificationSent', (data: any) => {
        data?.message?.recipientUsers?.length == 1
          ? data?.message?.data?.details?.receiver_id == user?.uid &&
            setUserNotifications((prevUserNotification: any) => [
              ...prevUserNotification,
              {
                ...data?.message?.data?.details,
                sender_profile: data?.message?.data?.sender?.profile_image,
              },
            ])
          : data?.message?.recipientUsers?.some(
              (data: any) => data == user?.uid
            ) &&
            setUserNotifications((prevUserNotification: any) => [
              ...prevUserNotification,
              {
                ...data?.message?.data?.details,
                sender_profile: data?.message?.data?.sender?.profile_image,
              },
            ]);
      });
    }
  }, [allNotifications]);

  useEffect(() => {
    setUserNotifications(allNotifications);
  }, [allNotifications]);

  return (
    <>
      <Dropdown
        as="li"
        align={{ lg: 'start' }}
        className="nav-item"
        data-toggle="tooltip"
        data-placement="top"
        title="Notifications"
        onClick={() => refetchNotification()}
      >
        <Dropdown.Toggle
          href="#"
          as={CustomToggle}
          variant={cls(
            'search-toggle',
            'd-flex',
            'align-items-center',
            'position-relative'
          )}
        >
          {userNotifications?.filter(
            (notification: any) =>
              notification.is_read == 0 &&
              (notification?.receiver_id == user?.uid ||
                notification?.receiver_id?.some(
                  (data: any) => data == user?.uid
                ))
          )?.length > 0 && (
            <div
              style={{
                borderRadius: '40px',
                position: 'absolute',
                background: 'red',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                top: '15px',
                fontSize: '12px',
              }}
              className="custom-notification-count"
            >
              {
                new Set(
                  userNotifications
                    ?.filter(
                      (notification: any) =>
                        notification.is_read == 0 &&
                        (notification?.receiver_id == user?.uid ||
                          notification?.receiver_id?.some(
                            (data: any) => data == user?.uid
                          ))
                    )
                    .map((notification: any) => notification.id)
                ).size
              }
            </div>
          )}
          <span
            className="material-symbols-outlined top-nav-notification-icon"
            style={{
              fontSize: '35px',
              color: '#CED4DA',
            }}
          >
            notifications
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="sub-drop"
          style={{
            boxShadow: '0 15px 30px -5px rgba(0,0,0,.15)',
          }}
        >
          <Card
            className="shadow-none m-0 custom-notification-popup"
            style={{
              height: '350px',
              overflow: 'auto',
            }}
          >
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h5 className="mb-0 text-dark ">All Notifications</h5>
              </div>
              <div
                className="text-primary"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleReadNotification(0, true, null)}
              >
                Mark all as read
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {userNotifications?.length > 0 ? (
                userNotifications?.map?.((data: any) => {
                  const {
                    created_at,
                    message,
                    id,
                    sender_profile,
                    heading,
                    is_read,
                    notificationable_type,
                  } = data;
                  return (
                    <div
                      className="iq-sub-card"
                      key={id}
                      onClick={() => handleReadNotification(id, false, data)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="">
                          <Image
                            className="avatar-40 rounded"
                            src={sender_profile}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="ms-3 w-100">
                          <div className="d-flex justify-content-between align-items-start gap-2">
                            <h6
                              className="mb-0 "
                              style={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {heading}
                            </h6>
                            <small className="float-right font-size-12">
                              {moment.utc(created_at).local().fromNow()}
                            </small>
                          </div>
                          <p
                            className="mb-0 mr-1"
                            style={{
                              width: '190px',
                            }}
                          >
                            {message}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoData name="notifications" />
              )}
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NotificationPopup;
