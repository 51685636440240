import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { getFilePreviewURL } from 'src/app/config/helper';
import { useAddCommentMutation } from 'src/app/screens/feed/services/feedApi';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import Avatar from '../Avatar/Avatar';
import Loader from '../Loader';
import cls from 'classnames';

type PostCommentProps = {
  postId?: any;
};

const PostCommentField = ({ postId }: PostCommentProps) => {
  const defaultValues = {
    post_id: '',
    comment: '',
    image: '',
  };

  const [addComment, { isLoading }] = useAddCommentMutation();

  const fileInputRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [emoji, setEmoji] = useState(false);

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { handleSubmit, setValue, watch }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchComment = watch('comment');
  const watchImage = watch('image');

  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];
    setValue('image', file);
  };

  const handleCameraIconClick = () => {
    fileInputRef.current.click();
  };

  const emptyFields = () => {
    setValue('comment', '');
    setValue('image', '');
  };

  const addEmoji = (e: any) => {
    let sym = e.unified.split('-');
    let codesArray: any = [];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);

    const cursor = inputRef.current.selectionStart;

    const newValue =
      watchComment.slice(0, cursor) + emoji + watchComment.slice(cursor);

    setValue('comment', newValue);
  };

  const handleAddComment = handleSubmit(async (data: any) => {
    if (!isLoading) {
      const formData = new FormData();

      formData.append('post_id', postId);
      formData.append('comment', data?.comment);
      formData.append('image', data?.image);

      const response = await addComment(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.errors?.email);

      if (respData) {
        emptyFields();
      }
    }
  });

  return (
    <>
      <div className="d-flex gap-2 align-items-center mt-3">
        <div className="user-img">
          {personalInformation?.profile_image && (
            <Avatar
              pfp={personalInformation?.profile_image}
              size="35"
              name={personalInformation?.username}
            />
          )}
        </div>
        <form
          className={cls(
            'comment-text',
            'mega-textarea-container',
            'd-flex',
            'align-items-start',
            'w-100'
          )}
          onSubmit={handleAddComment}
        >
          <div
            style={{
              border: '1px solid grey',
              borderRadius: '8px',
              padding: '8px',
              width: '100%',
            }}
          >
            <Form.Group className="form-group mb-0">
              <Form.Control
                className="form-control rounded mega-textarea"
                placeholder="Enter Your Comment"
                ref={inputRef}
                name="comment"
                value={watchComment}
                style={{
                  border: 'none',
                  outline: 'none',
                  padding: 0,
                  resize: 'none',
                }}
                onChange={(e) => setValue('comment', e.target.value)}
              />
            </Form.Group>
            {watchImage && (
              <img
                src={getFilePreviewURL(watchImage)}
                alt="Image"
                style={{
                  width: '100px',
                }}
              />
            )}
          </div>
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <OutsideClickHandler
            onOutsideClick={() => {
              setEmoji(false);
            }}
          >
            <div
              className="comment-attagement d-flex"
              style={{
                padding: '8px 0',
              }}
            >
              <Link
                to="#"
                className="material-symbols-outlined  me-1"
                onClick={() => setEmoji(!emoji)}
              >
                sentiment_satisfied
              </Link>
              {emoji && (
                <div
                  style={{
                    zIndex: 999999,
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <Picker data={data} onEmojiSelect={addEmoji} perLine={6} />
                </div>
              )}
              <Link
                to="#"
                className="material-symbols-outlined  me-1"
                onClick={handleCameraIconClick}
              >
                attach_file
              </Link>
              {(watchComment?.length > 0 || watchImage) && (
                <>
                  {isLoading ? (
                    <Loader
                      size="sm"
                      sx={{
                        marginBottom: '0px',
                        marginTop: '5px',
                        marginRight: '10px',
                        marginLeft: '0.7px',
                      }}
                    />
                  ) : (
                    <Link
                      to="#"
                      className="material-symbols-outlined  me-1"
                      onClick={handleAddComment}
                    >
                      send
                    </Link>
                  )}
                </>
              )}
            </div>
          </OutsideClickHandler>
        </form>
      </div>
    </>
  );
};

export default PostCommentField;
