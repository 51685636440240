import { api } from 'src/app/services';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/login`,
          method: 'POST',
          body,
        };
      },
    }),
    googleLogin: builder.mutation({
      query: (body) => {
        return {
          url: `/api/login/google`,
          method: 'POST',
          body,
        };
      },
    }),
    create: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/created`,
          method: 'POST',
          body,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/forgot-password`,
          method: 'POST',
          body,
        };
      },
    }),
    updatePassword: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/update-password`,
          method: 'POST',
          body,
        };
      },
    }),
    updateAccountSettings: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/update-profile`,
          method: 'POST',
          body,
        };
      },
    }),
    changeStatus: builder.mutation({
      query: () => {
        return {
          url: `/api/user/status-changed`,
          method: 'GET',
        };
      },
    }),
    staticPages: builder.query({
      query: () => {
        return {
          url: `/api/user/pages`,
          method: 'GET',
        };
      },
    }),
    logout: builder.query({
      query: () => {
        return {
          url: `/api/user/logout`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGoogleLoginMutation,
  useCreateMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useUpdateAccountSettingsMutation,
  useChangeStatusMutation,
  useStaticPagesQuery,
  useLazyLogoutQuery,
} = authApi;
