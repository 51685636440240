import { Card } from 'react-bootstrap';
import { GroupTypeEnum } from 'src/app/config/constants';
import { capitlizeFirstLetter } from 'src/app/config/helper';

type GroupAboutInfoCard = {
  groupType: GroupTypeEnum | string;
  sportCategory: string;
  sportIndustry: string;
  sportName: string;
};

const GroupAboutInfoCard = ({
  groupType,
  sportCategory,
  sportIndustry,
  sportName,
}: GroupAboutInfoCard) => {
  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">About</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex gap-1 align-items-start mb-1">
            <span className="material-symbols-outlined">
              check_indeterminate_small
            </span>
            <div>
              <span>Sport Category</span>
              <p>{sportCategory}</p>
            </div>
          </div>
          <div className="d-flex gap-1 align-items-start mb-1">
            <span className="material-symbols-outlined">
              check_indeterminate_small
            </span>
            <div>
              <span>Sport Industry</span>
              <p>{sportIndustry}</p>
            </div>
          </div>
          <div className="d-flex gap-1 align-items-start mb-1">
            <span className="material-symbols-outlined">
              check_indeterminate_small
            </span>
            <div>
              <span>Sport Name</span>
              <p>{sportName}</p>
            </div>
          </div>
          {capitlizeFirstLetter(groupType) == GroupTypeEnum.Private ? (
            <div className="d-flex gap-1 align-items-start mb-1">
              <span className="material-symbols-outlined">lock</span>
              <div>
                <span>Private</span>
                <p>Only members can see posts in the group.</p>
              </div>
            </div>
          ) : (
            <div className="d-flex gap-1 align-items-start  mb-1">
              <span className="material-symbols-outlined">visibility</span>
              <div>
                <span>Public</span>
                <p>
                  Anyone or off sportconn can see the posts in the group. Only
                  group members can add comments and create posts.
                </p>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default GroupAboutInfoCard;
