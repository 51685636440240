import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Image, Row, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import user5 from '../../../assets/images/user/05.jpg';
import user1 from '../../../assets/images/user/1.jpg';
import Card from '../../../components/Card';
import './chat.css';
import UserSidebarDetails from './components/UserSidebarDetails';
import cls from 'classnames';
import {
  useAllChatQuery,
  useDeleteChatMutation,
  useGetUserChatMutation,
  useSendMessageMutation,
} from './services/chatApi';
import NoData from '../shared/components/NoData/NoData';
import { toast } from 'react-hot-toast';
import Pusher from 'pusher-js';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useGetPersonalInformationQuery } from '../profile/services/profileApi';
import { setOpenChat } from '../shared/store/sharedSlice';
import { handleImageUpload } from 'src/app/config/helper';
import PreviewImages from '../shared/components/PreviewImages/PreviewImages';
import { baseUrl } from 'src/app/config/types/base-url';
import LinkifyText from '../shared/components/LinkifyText/LinkifyText';

const Chat = () => {
  const defaultValues = {
    message: '',
    sender_id: '',
    receiver_id: '',
    attachments: [],
  };

  const dispatch = useDispatch();

  const { theme, mode } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { chat },
  } = theme;

  const [show2, setShow2] = useState('');
  const [activeUser, setActiveUser] = useState(null);
  const [selectedUserChat, setSelectedUserChat] = useState<any>(null);
  const [uploadedImages, setUploadedImages] = useState<any>([]);
  const [searchInput, setSearchInput] = useState('');
  const fileInputRef = useRef<any>(null);
  const filePdfRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchAttachments = watch('attachments');

  const { data: getAllChat = {}, isLoading } = useAllChatQuery({});
  const { data: allChat } = getAllChat || {};

  const [getUserChat, { isLoading: gettingUserChat }] =
    useGetUserChatMutation();
  const [sendMessage, { isLoading: sendingMessage }] = useSendMessageMutation();
  const [deleteChat, { isLoading: deletingChat }] = useDeleteChatMutation();
  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const ChatSidebar = () => {
    document.getElementsByClassName('scroller')[0].classList.add('show');
  };
  const ChatSidebarClose = () => {
    document.getElementsByClassName('scroller')[0].classList.remove('show');
  };

  const handleGetUserChat = async (channelId: any) => {
    const response = await getUserChat(channelId);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      setSelectedUserChat(respData?.data);
    }
  };

  const handleFileSelection = (reference: any) => {
    reference == 'fileInputRef'
      ? fileInputRef.current.click()
      : filePdfRef.current.click();
  };

  const removeImage = (indexToRemove: number) => {
    const updatedPreviewUrls = [...uploadedImages];
    updatedPreviewUrls.splice(indexToRemove, 1);
    let fileArray = Array.from(watchAttachments);
    const removedAttachment: any = fileArray[indexToRemove];
    const filterAttachments: any = fileArray?.filter(
      (data: any, index) => index !== indexToRemove
    );
    setValue('attachments', filterAttachments);
    setUploadedImages(updatedPreviewUrls);
  };

  const handleDeleteChat = async () => {
    const userId =
      selectedUserChat?.receiver_id != user?.uid
        ? selectedUserChat?.receiver_user?.id
        : selectedUserChat?.sender_user?.id;
    const response = await deleteChat(userId);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      setSelectedUserChat((prevSelectedUserChat: any) => ({
        ...prevSelectedUserChat,
        chat: [],
      }));
    }
  };

  const handleSendMessage = handleSubmit((data) => {
    if (data?.message != '' || data?.attachments?.length > 0) {
      const formData = new FormData();
      formData.append('message', data?.message);
      formData.append('sender_id', user?.uid);
      formData.append(
        'receiver_id',
        user?.uid == selectedUserChat?.receiver_id
          ? selectedUserChat?.sender_id
          : selectedUserChat?.receiver_id
      );
      formData.append('channel_id', selectedUserChat?.id);
      data?.attachments?.map((data: any) =>
        formData?.append('attachments[]', data)
      );
      sendMessage(formData);
      reset({ ...defaultValues });
      setUploadedImages([]);
    } else {
      toast.error('Please write a message');
    }
  });

  const renderDate = (date: any, index: any) => {
    const currentDate = moment(date).format('MMMM Do YYYY');
    const nextDate =
      index < selectedUserChat?.chat?.length - 1
        ? moment(
            selectedUserChat?.chat?.slice()?.reverse()?.[index + 1][
              'created_at'
            ]
          ).format('MMMM Do YYYY')
        : '';

    const showDate = currentDate != nextDate;

    return (
      showDate && (
        <div key={`date-${date}`} className="p-4 text-center">
          {currentDate} - {moment(date).format('dddd')}
        </div>
      )
    );
  };

  const renderImages = (attachments: any, alignContent: any) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 4,
          justifyContent: alignContent == 'start' ? 'flex-start' : 'flex-end',
          flexWrap: 'wrap',
          margin: '0 8px 16px 8px',
        }}
      >
        {attachments?.map((data: any, index: any) =>
          data?.fileExtension != 'pdf' ? (
            <Image
              key={index}
              src={baseUrl + '/' + data?.path}
              alt={`Preview ${index}`}
              style={{
                width: '60px',
                height: '60px',
              }}
            />
          ) : (
            <Link to={baseUrl + '/' + data?.path} target="_blank">
              {data?.filename}
            </Link>
          )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (selectedUserChat?.channel_name) {
      var pusher = new Pusher('14d3035a4ea699478de1', {
        cluster: 'ap2',
      });
      const channelName = selectedUserChat?.channel_name;
      const channel = pusher.subscribe(channelName);
      channel.bind('MessageSent', (data: any) => {
        setSelectedUserChat((prevSelectedUserChat: any) => ({
          ...prevSelectedUserChat,
          chat: [...prevSelectedUserChat?.chat, data?.message?.data?.message],
        }));
      });
    }
  }, [selectedUserChat?.channel_name]);

  useEffect(() => {
    dispatch(setOpenChat(false));
  }, []);

  return (
    <AuthGuard>
      <>
        <Tab.Container id="left-tabs-example" defaultActiveKey="start">
          <Card>
            <Card.Body className="chat-page p-0">
              <div className="chat-data-block">
                <Row>
                  <Col
                    lg="3"
                    className="chat-data-left scroller"
                    style={{
                      backgroundColor: mode == 'dark' ? '#191919' : '#F8F9FA',
                    }}
                  >
                    <div className="chat-search pt-3 ps-3 pe-3">
                      <UserSidebarDetails />
                      <div className="chat-searchbar mt-4">
                        <Form.Group className="form-group chat-search-data m-0">
                          <input
                            type="text"
                            className="form-control round"
                            id="chat-search"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          <i className="material-symbols-outlined">search</i>
                        </Form.Group>
                      </div>
                    </div>
                    <div
                      className={cls(
                        'chat-sidebar-channel',
                        'scroller',
                        'left-user-chat-list',
                        'mt-2',
                        'p-3',
                        'ps-3'
                      )}
                      style={{
                        backgroundColor: mode == 'dark' ? '#191919' : '#F8F9FA',
                        height: 'calc(100vh - 140px - 85px - 30px)',
                      }}
                    >
                      {allChat?.length > 0 ? (
                        allChat
                          ?.filter((item: any) => {
                            if (!searchInput) {
                              return true;
                            } else {
                              return (
                                item?.receiver_user?.name
                                  ?.toLowerCase()
                                  ?.includes(searchInput) ||
                                item?.sender_user?.name
                                  ?.toLowerCase()
                                  ?.includes(searchInput) ||
                                item?.receiver_user?.username
                                  ?.toLowerCase()
                                  ?.includes(searchInput) ||
                                item?.sender_user?.username
                                  ?.toLowerCase()
                                  ?.includes(searchInput)
                              );
                            }
                          })
                          ?.reverse()
                          ?.map((data: any) => {
                            const {
                              id,
                              chat,
                              receiver_user,
                              sender_user,
                              receiver_id,
                              status,
                            } = data;
                            return (
                              <div
                                className="d-flex align-items-center mt-2"
                                style={{
                                  backgroundColor:
                                    activeUser == id && mode == 'dark'
                                      ? '#0F0F0F'
                                      : activeUser != id && mode == 'dark'
                                      ? '#191919'
                                      : activeUser == id && mode != 'dark'
                                      ? 'white'
                                      : '#F8F9FA',
                                  padding: '8px',
                                  cursor: 'pointer',
                                  boxShadow:
                                    activeUser == id
                                      ? '0 4px 4px rgba(0,0,0,0.15)'
                                      : 'none',
                                }}
                                onClick={() => {
                                  handleGetUserChat(id);
                                  setActiveUser(id);
                                }}
                              >
                                <div className="avatar me-2">
                                  <img
                                    loading="lazy"
                                    src={
                                      receiver_id != user?.uid
                                        ? receiver_user?.personal_information
                                            ?.imagepath
                                        : sender_user?.personal_information
                                            ?.imagepath
                                    }
                                    alt="chatuserimage"
                                    className="avatar-50"
                                  />
                                  <span className="avatar-status">
                                    <i className="material-symbols-outlined text-success  md-14 filled">
                                      circle
                                    </i>
                                  </span>
                                </div>
                                <div className="chat-sidebar-name">
                                  <h6 className="mb-0">
                                    {receiver_id != user?.uid
                                      ? receiver_user?.name ||
                                        receiver_user?.username
                                      : sender_user?.name ||
                                        sender_user?.username}
                                  </h6>
                                  <span>
                                    {chat?.[
                                      chat?.length - 1
                                    ]?.message?.substring(0, 30)}
                                  </span>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <NoData name="chat" />
                      )}
                    </div>
                  </Col>
                  {selectedUserChat !== null ? (
                    <Col lg={9} className=" chat-data p-0 chat-data-right">
                      <Tab.Content>
                        <Tab.Pane
                          eventKey="start"
                          className="tab-pane fade show"
                          id="default-block"
                          role="tabpanel"
                        >
                          <div className="chat-head">
                            <header
                              className={cls(
                                'd-flex',
                                'justify-content-between',
                                'align-items-center',
                                'bg-white',
                                'pt-3',
                                'ps-3',
                                'pe-3',
                                'pb-3'
                              )}
                              style={{
                                overflow: 'hidden',
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="avatar chat-user-profile m-0 me-3">
                                  <img
                                    loading="lazy"
                                    src={
                                      selectedUserChat?.receiver_user
                                        ?.personal_information?.imagepath ||
                                      user5
                                    }
                                    alt="avatar"
                                    className="avatar-50 "
                                    onClick={() => setShow2('true')}
                                  />
                                </div>
                                <h5 className="mb-0">
                                  {selectedUserChat?.receiver_user?.name}
                                </h5>
                              </div>
                              <span
                                className="material-symbols-outlined"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={handleDeleteChat}
                              >
                                delete
                              </span>
                              <div
                                className={`chat-user-detail-popup scroller ${
                                  show2 === 'true' ? 'show' : ''
                                }`}
                              >
                                <div className="user-profile">
                                  <Button
                                    type="submit"
                                    onClick={ChatSidebarClose}
                                    variant=" close-popup p-3"
                                  >
                                    <i
                                      className="material-symbols-outlined md-18"
                                      onClick={() => setShow2('false')}
                                    >
                                      close
                                    </i>
                                  </Button>
                                  <div className="user mb-4  text-center">
                                    <Link className="avatar m-0" to="">
                                      <img
                                        loading="lazy"
                                        src={
                                          selectedUserChat?.receiver_user
                                            ?.personal_information?.imagepath
                                        }
                                        alt="avatar"
                                        className="avatar-80"
                                      />
                                    </Link>
                                    <div className="user-name mt-4">
                                      <h4>
                                        {selectedUserChat?.receiver_user
                                          ?.name ||
                                          selectedUserChat?.receiver_user
                                            ?.username}
                                      </h4>
                                    </div>
                                    <div className="user-desc">
                                      <p>
                                        {
                                          selectedUserChat?.receiver_user
                                            ?.personal_information?.address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="chatuser-detail text-left mt-4">
                                    <Row>
                                      <Col md="6" className="col-6 title">
                                        Tel:
                                      </Col>
                                      <Col md="6" className="col-6 text-right">
                                        {
                                          selectedUserChat?.receiver_user
                                            ?.personal_information
                                            ?.contact_number
                                        }
                                      </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                      <Col md="6" className="col-6 title">
                                        Date Of Birth:
                                      </Col>
                                      <Col md="6" className="col-6 text-right">
                                        {
                                          selectedUserChat?.receiver_user
                                            ?.personal_information
                                            ?.date_of_birth
                                        }
                                      </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                      <Col md="6" className="col-6 title">
                                        Gender:
                                      </Col>
                                      <Col md="6" className="col-6 text-right">
                                        {
                                          selectedUserChat?.receiver_user
                                            ?.personal_information?.gender
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </header>
                          </div>
                          <div
                            className="chat-content scrollers invert-scroll"
                            style={{
                              backgroundColor:
                                mode == 'dark' ? '#191919' : '#F8F9FA',
                              height: 'calc(100vh - 85px - 81px - 130px)',
                            }}
                          >
                            {selectedUserChat?.chat
                              ?.slice()
                              ?.reverse()
                              ?.map?.((data: any, index: any) => {
                                const {
                                  channel_id,
                                  file,
                                  message,
                                  receiver_id,
                                  sender_id,
                                  attachments,
                                  created_at,
                                } = data;
                                return sender_id == user?.uid ? (
                                  <>
                                    <div className="chat d-flex other-user mb-4">
                                      <div className="chat-user">
                                        <Link className="avatar m-0" to="">
                                          <img
                                            loading="lazy"
                                            src={
                                              personalInformation?.profile_image ||
                                              user1
                                            }
                                            alt="avatar"
                                            className="avatar-35 "
                                          />
                                        </Link>
                                        <span className="chat-time mt-1">
                                          {moment(created_at)?.format(
                                            'hh:mm A'
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="chat-detail"
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 2,
                                        }}
                                      >
                                        {message?.length > 0 && (
                                          <div
                                            className="chat-message"
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '400px',
                                              background: chat.sender,
                                              color: '#F5F5F5',
                                              boxShadow:
                                                '0 4px 4px rgba(0,0,0,0.15)',
                                            }}
                                          >
                                            {message && (
                                              <LinkifyText text={message} />
                                            )}
                                          </div>
                                        )}
                                        {renderImages(attachments, 'end')}
                                      </div>
                                    </div>
                                    {renderDate(created_at, index)}
                                  </>
                                ) : (
                                  <>
                                    <div className="chat chat-left">
                                      <div className="chat-user">
                                        <Link className="avatar m-0" to="">
                                          <img
                                            loading="lazy"
                                            src={
                                              selectedUserChat?.receiver_id ==
                                              user?.uid
                                                ? selectedUserChat?.sender_user
                                                    ?.personal_information
                                                    ?.imagepath
                                                : selectedUserChat
                                                    ?.receiver_user
                                                    ?.personal_information
                                                    ?.imagepath
                                            }
                                            alt="avatar"
                                            className="avatar-35 "
                                          />
                                        </Link>
                                        <span className="chat-time mt-1">
                                          {moment(created_at)?.format(
                                            'hh:mm A'
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="chat-detail"
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 2,
                                        }}
                                      >
                                        {message?.length > 0 && (
                                          <div
                                            className="chat-message"
                                            style={{
                                              maxWidth: '400px',
                                              boxShadow:
                                                '0 4px 4px rgba(0,0,0,0.15)',
                                              background: chat.receiver,
                                              color: '#0F0F0F',
                                            }}
                                          >
                                            {message && (
                                              <LinkifyText text={message} />
                                            )}
                                          </div>
                                        )}
                                        {renderImages(attachments, 'start')}
                                      </div>
                                    </div>
                                    {renderDate(created_at, index)}
                                  </>
                                );
                              })}
                          </div>
                          <div className="chat-footer p-3 bg-white">
                            <Form
                              className="d-flex align-items-center"
                              action="#"
                            >
                              <div className="chat-attagement d-flex">
                                <span
                                  className="material-symbols-outlined"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleFileSelection('fileInputRef')
                                  }
                                >
                                  image
                                </span>
                                <input
                                  type="file"
                                  id="imageUpload"
                                  ref={fileInputRef}
                                  accept="image/*"
                                  multiple
                                  style={{ display: 'none' }}
                                  onChange={(e) =>
                                    handleImageUpload(
                                      e,
                                      setValue,
                                      'attachments',
                                      setUploadedImages,
                                      watchAttachments
                                    )
                                  }
                                />
                                <span
                                  className="material-symbols-outlined"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleFileSelection('filePdfRef')
                                  }
                                >
                                  link
                                </span>
                                <input
                                  type="file"
                                  id="imageUpload"
                                  ref={filePdfRef}
                                  accept="application/pdf"
                                  multiple
                                  style={{ display: 'none' }}
                                  onChange={(e) =>
                                    handleImageUpload(
                                      e,
                                      setValue,
                                      'attachments',
                                      setUploadedImages,
                                      watchAttachments
                                    )
                                  }
                                />
                              </div>
                              <Form.Control
                                type="text"
                                className="me-3"
                                placeholder="Type your message"
                                style={{
                                  outline: 'none',
                                  border: 'none',
                                }}
                                {...register('message')}
                              />
                              <Button
                                type="submit"
                                variant="primary d-flex align-items-center"
                                onClick={handleSendMessage}
                              >
                                <i
                                  className="far fa-paper-plane"
                                  aria-hidden="true"
                                ></i>
                                <span className="d-lg-block ms-1">Send</span>
                              </Button>
                            </Form>
                            <div className="pt-4">
                              <PreviewImages
                                images={uploadedImages}
                                removeImage={removeImage}
                              />
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  ) : (
                    <Col
                      lg={9}
                      className="p-0 chat-data-right"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 85px)',
                      }}
                    >
                      <h1>No chat is active yet</h1>
                    </Col>
                  )}
                </Row>
              </div>
            </Card.Body>
          </Card>
          {/* </Col>
          </Row> */}
        </Tab.Container>
      </>
    </AuthGuard>
  );
};
export default Chat;
