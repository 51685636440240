import React from 'react';
import {
    ReactComponent as ShowSvg
} from './show.svg'

function Show() {

    return <ShowSvg />
}

export default Show