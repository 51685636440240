import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileHeader from '../shared/components/ProfileHeader';
import PhotoCard from '../shared/components/PhotoCard';
import { photosData } from 'src/app/mock/photos';
import AuthGuard from 'src/app/config/auth/AuthGuard';

const UserPhotos = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <PhotoCard photos={photosData} loggedInUserProfile={false} />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default UserPhotos;
