import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { objToFormData } from 'src/app/config/helper';
import { baseUrl } from 'src/app/config/types/base-url';
import useModalDialog from 'src/app/hooks/useModalDialog';
import {
  useCreateMarketPlaceItemMutation,
  useGetMarketPlaceItemDetailsQuery,
  useUpdateMarketPlaceItemMutation,
} from 'src/app/screens/market-place/services/marketPlaceApi';
import {
  useGetCurrenciesQuery,
  useGetIndustriesQuery,
  useGetItemCategoriesQuery,
  useGetSportNamesQuery,
} from '../../services/sharedApi';
import JobPostedModal from '../Modals/JobPostedModal';
import addNewItemFormSchema from './schema/addNewItemForm.schema';

interface IAddNewItemForm {
  edit?: boolean;
}

const AddNewItemForm = ({ edit = false }: IAddNewItemForm) => {
  const defaultValues = {
    user_id: 0,
    title: '',
    price: '',
    sports_industry_id: '',
    currency_id: '',
    sports_name_id: '',
    item_category_id: '',
    item_condition: '',
    location: '',
    latitude: '',
    longitude: '',
    description: '',
    attachments: {},
    delete_attachment: [],
  };

  const { itemId } = useParams();

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { backgrund, button, border },
  } = theme;

  const locationRef = useRef<any>(null);
  const [suggestions, setSuggestions] = useState([]);
  const [previewUrls, setPreviewUrls] = useState<any>([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const { isOpen, open, close } = useModalDialog();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: addNewItemFormSchema,
  });

  const watchAttachments = watch('attachments');
  const watchCurrency = watch('currency_id');

  const { data: allIndustries } = useGetIndustriesQuery({});
  const { data: allSportNames } = useGetSportNamesQuery({});
  const { data: allItemCategories } = useGetItemCategoriesQuery({});
  const { data: allCurrencies } = useGetCurrenciesQuery({});

  const industryList = allIndustries?.data?.map?.(({ uid, title }: any) => ({
    id: uid,
    name: title,
  }));
  const sportNameList = allSportNames?.data?.map?.(({ uid, title }: any) => ({
    id: uid,
    name: title,
  }));
  const currencyList = allCurrencies?.data?.map?.(
    ({ id, currency, country }: any) => ({
      id: id,
      name: country,
    })
  );
  const itemCategoriesList = allItemCategories?.data?.map?.(
    ({ uid, title }: any) => ({
      id: uid,
      name: title,
    })
  );
  const { data: getMarketPlaceItemDetails } = useGetMarketPlaceItemDetailsQuery(
    itemId,
    {
      skip: !itemId,
    }
  );

  const { data: marketPlaceItemDetails } = getMarketPlaceItemDetails || {};

  const [createMarketPlaceItem, { isLoading }] =
    useCreateMarketPlaceItemMutation();
  const [updateMarketPlaceItem, { isLoading: updatingMarketPlaceItem }] =
    useUpdateMarketPlaceItemMutation();

  const removeImage = (indexToRemove: number, url: string) => {
    const updatedPreviewUrls = [...previewUrls];
    updatedPreviewUrls.splice(indexToRemove, 1);
    let fileArray = Array.from(watchAttachments);
    const removedAttachment: any = fileArray[indexToRemove];
    setValue('delete_attachment', [removedAttachment?.id]);
    const filterAttachments = fileArray?.filter(
      (data: any, index) => index !== indexToRemove
    );
    setValue('attachments', filterAttachments);
    setPreviewUrls(updatedPreviewUrls);
    const removedImages = marketPlaceItemDetails?.attachments
      ?.filter(
        (obj: any) =>
          !filterAttachments?.some((item: any) => item?.id === obj?.id)
      )
      ?.map?.((obj: any) => obj?.id);
    setValue('delete_attachment', removedImages);
  };

  const handleFileChange = (e: any) => {
    const selectedFiles = e.target.files;
    setValue('attachments', selectedFiles);

    const files = Array.from(e.target.files);
    const urls: any = files.map((file: any) => {
      if (file.type.startsWith('image/')) {
        return URL.createObjectURL(file);
      } else if (file.type.startsWith('video/')) {
        return `${baseUrl}/assets/img/default_logo.png`;
      }
      return null;
    });

    setPreviewUrls((prevUrls: any) => [...prevUrls, ...urls]);
  };

  const handleAddMarketPlaceItem = handleSubmit(async (data: any) => {
    if (Object?.entries(watchAttachments)?.length != 0) {
      const formData = objToFormData({
        ...data,
        item_id: itemId ? itemId : null,
        user_id: user?.uid,
        attachments: Array.from(data?.attachments),
      });

      const response = !itemId
        ? await createMarketPlaceItem(formData)
        : await updateMarketPlaceItem(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        history('/market-place');
      }
    } else {
      toast.error('Add at least one image for item');
    }
  });

  useEffect(() => {
    const symbol =
      watchCurrency !== '' &&
      allCurrencies?.data?.find((data: any) => watchCurrency == data?.id)
        ?.symbol;
    setCurrencySymbol(symbol);
  }, [watchCurrency]);

  useEffect(() => {
    const initAutocomplete = () => {
      const google = window.google;
      const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
      };
      const autocomplete: any = new google.maps.places.Autocomplete(
        locationRef?.current,
        options
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        setValue('latitude', place?.geometry?.location?.lat());
        setValue('longitude', place?.geometry?.location?.lng());
        setValue('location', place?.name);
      });

      autocomplete.addListener('place_changed', () => {
        setSuggestions([]);
      });

      autocomplete.addListener('input', () => {
        const input = autocomplete.getPlace();
        const predictions = autocomplete?.getPlacePredictions();
        setSuggestions(predictions);
      });
    };

    if (
      window?.google &&
      window?.google?.maps &&
      window?.google?.maps?.places
    ) {
      initAutocomplete();
    } else {
      console.error('Google Maps API not loaded yet');
    }
  }, []);

  useEffect(() => {
    if (marketPlaceItemDetails) {
      setValue(
        'sports_industry_id',
        marketPlaceItemDetails?.sports_industry?.id
      );
      setValue('currency_id', marketPlaceItemDetails?.currency?.id);
      setValue('sports_name_id', marketPlaceItemDetails?.sport_name?.id);
      setValue('item_category_id', marketPlaceItemDetails?.item_category?.id);
      setValue('attachments', marketPlaceItemDetails?.attachments);
      locationRef.current.value = marketPlaceItemDetails?.location;

      const existingImageUrls = marketPlaceItemDetails?.attachments?.map(
        (attachment: any) => {
          return attachment?.fileExtension != 'mp4'
            ? baseUrl + '/storage/' + attachment.path
            : `${baseUrl}/assets/img/default_logo.png`;
        }
      );
      setPreviewUrls(existingImageUrls);

      reset({
        ...marketPlaceItemDetails,
      });
    }
  }, [marketPlaceItemDetails]);

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{!edit ? 'Add Item' : 'Edit Item'}</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    {...register('title')}
                  />
                  <p className="text-danger">{errors?.title?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Currency</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('currency_id')}
                  >
                    {currencyList &&
                      [
                        { name: 'Select an option', id: 0, country: '' },
                        ...currencyList,
                      ]?.map?.((data: any) => {
                        const { id, name } = data;
                        return (
                          <option value={id}>
                            {name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <p className="text-danger">{errors?.currency_id?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Label>Price</Form.Label>
                <InputGroup className="">
                  <InputGroup.Text>{currencySymbol || '$'}</InputGroup.Text>
                  <Form.Control aria-label="Price" {...register('price')} />
                </InputGroup>
                <p className="text-danger">{errors?.price?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Sports Industry</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('sports_industry_id')}
                  >
                    {industryList &&
                      [
                        { name: 'Select an option', id: 0 },
                        ...industryList,
                      ]?.map?.((data: any) => {
                        const { id, name } = data;
                        return <option value={id}>{name}</option>;
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Sports Name</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('sports_name_id')}
                  >
                    {sportNameList &&
                      [
                        { name: 'Select an option', id: 0 },
                        ...sportNameList,
                      ]?.map?.((data: any) => {
                        const { id, name } = data;
                        return <option value={id}>{name}</option>;
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Item Category</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('item_category_id')}
                  >
                    {itemCategoriesList &&
                      [
                        { name: 'Select an option', id: 0 },
                        ...itemCategoriesList,
                      ]?.map?.((data: any) => {
                        const { id, name } = data;
                        return <option value={id}>{name}</option>;
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Label>Condition</Form.Label>
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                  }}
                >
                  <Form.Check
                    type="radio"
                    label="New"
                    name="condition"
                    value="new"
                    {...register('item_condition')}
                  />
                  <Form.Check
                    type="radio"
                    label="Second hand"
                    name="condition"
                    value="second hand"
                    {...register('item_condition')}
                  />
                </div>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    placeholder="Location"
                    ref={locationRef}
                  />
                  <p className="text-danger">{errors?.location?.message}</p>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    {...register('description')}
                  />
                  <p className="text-danger">{errors?.description?.message}</p>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Media</Form.Label>
                  <Form.Control
                    multiple
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                  />
                  <p className="text-danger">{errors?.attachments?.message}</p>
                </Form.Group>
              </Col>
              <Col md="12">
                <div
                  style={{
                    display: 'flex',
                    gap: 4,
                    flexWrap: 'wrap',
                    marginBottom: '16px',
                  }}
                >
                  {previewUrls.map((url: any, index: any) => (
                    <div
                      key={index}
                      style={{
                        position: 'relative',
                        marginRight: '8px',
                        marginBottom: '8px',
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(-1)}
                    >
                      <Image
                        src={url}
                        alt={`Preview ${index}`}
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-10px',
                          width: '28px',
                          height: '28px',
                          borderRadius: '40px',
                          backgroundColor: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span
                          style={{
                            color: 'white',
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          onClick={() => removeImage(index, url)}
                        >
                          &#10006;
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Button
              disabled={isLoading || updatingMarketPlaceItem}
              variant="primary"
              type="button"
              className="float-end"
              onClick={handleAddMarketPlaceItem}
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
            >
              {!edit ? 'Add' : 'Update'}
            </Button>
          </div>
        </Card.Body>
      </Card>

      <JobPostedModal open={isOpen} close={close} />
    </>
  );
};

export default AddNewItemForm;
