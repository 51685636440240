import React from "react";
import SignIn from "../../screens/auth/sign-in";
import SignUp from "../../screens/auth/sign-up";
import ForgotPassword from "../../screens/auth/forgot-password";
import SetNewPassword from "../../screens/auth/set-new-password";
import PasswordConfirmation from "../../screens/auth/password-confirmation";
import Subscription from "../../screens/auth/subscription";
import TermsAndConditions from "src/app/screens/auth/terms-and-conditions";
import PrivacyPolicy from "src/app/screens/auth/privacy-policy";

export const SimpleRouter = [
  {
    path: "auth/sign-in",
    element: <SignIn />,
  },
  {
    path: "auth/sign-up",
    element: <SignUp />,
  },
  {
    path: "auth/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "auth/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "auth/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "auth/set-new-password/:token",
    element: <SetNewPassword />,
  },
  {
    path: "auth/password-confirmation",
    element: <PasswordConfirmation />,
  },
  {
    path: "auth/subscription",
    element: <Subscription />,
  },
];
