import { api } from 'src/app/services';

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalInformation: builder.query({
      query: () => {
        return {
          url: `/api/user/profile/personal/information/get`,
          method: 'GET',
        };
      },
      providesTags: ['ProfilePersonalInformation'],
    }),
    updatePersonalInformation: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/personal/information/update`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfilePersonalInformation'],
    }),
    getEducation: builder.query({
      query: () => {
        return {
          url: `/api/user/profile/education/information/get`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileEducation'],
    }),
    getEducationDetail: builder.query({
      query: (id) => {
        return {
          url: `/api/user/profile/education/information/get-id?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileEducation'],
    }),
    addEducation: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/education/information/add`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileEducation'],
    }),
    editEducation: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/education/information/edit`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileEducation'],
    }),
    getWorkExperience: builder.query({
      query: () => {
        return {
          url: `/api/user/profile/work/experience/get`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileWorkExperience'],
    }),
    addWorkExperience: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/work/experience/add`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileWorkExperience'],
    }),
    getWorkExperienceDetail: builder.query({
      query: (id) => {
        return {
          url: `/api/user/profile/work/experience/get-id?id=${id}`,
          method: 'GET',
        };
      },
    }),
    editWorkExperience: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/work/experience/edit`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileWorkExperience'],
    }),
    addTeam: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/teams/add`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileTeam'],
    }),
    getTeams: builder.query({
      query: () => {
        return {
          url: `/api/user/profile/teams/get`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileTeam'],
    }),
    getUserTeams: builder.query({
      query: (id) => {
        return {
          url: `/api/user/profile/teams/get/user-id?user_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileTeam'],
    }),
    getTeamDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/user/profile/teams/get-id?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['ProfileTeam'],
    }),
    getUserPhotos: builder.query({
      query: (id) => {
        return {
          url: `/api/user/post/photo?user_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Posts'],
    }),
    updateTeam: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/teams/update`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfileTeam'],
    }),
    addSupport: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/support/add`,
          method: 'POST',
          body,
        };
      },
    }),
    getUserPosts: builder.query({
      query: (id) => {
        return {
          url: `/api/user/post/get/my-timeline?user_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Posts'],
    }),
    getUserDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/user/profile/personal/information/user-details?user_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['UserDetails'],
    }),
    sendRequest: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/connection-following/send`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserDetails', 'ShouldFollow', 'Posts'],
    }),
    cancelRequest: builder.mutation({
      query: (data) => {
        return {
          url: `/api/user/post/unfollow/desconnect?type=${data?.type}&followee_id=${data?.followee_id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserDetails', 'Posts', 'Network'],
    }),
    getUserNetwork: builder.query({
      query: (data) => {
        return {
          url: `/api/user/post/follower-list?type=${data?.type}&user_id=${data?.user_id}`,
          method: 'GET',
        };
      },
      providesTags: ['Network'],
    }),
    getUserNetworkList: builder.query({
      query: (type) => {
        return {
          url: `/api/user/post/follower-request?type=${type}`,
          method: 'GET',
        };
      },
      providesTags: ['Network'],
    }),
    acceptOrCancelRequest: builder.mutation({
      query: (data) => {
        return {
          url: `/api/user/post/request/accept-cancel?type=${data?.type}&action=${data?.action}&id=${data?.id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Network', 'ProfilePersonalInformation'],
    }),
    updateBgImage: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/personal/information/update-background`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ProfilePersonalInformation', 'UserDetails'],
    }),
  }),
});

export const {
  useUpdatePersonalInformationMutation,
  useGetPersonalInformationQuery,
  useGetEducationQuery,
  useGetEducationDetailQuery,
  useAddEducationMutation,
  useEditEducationMutation,
  useGetWorkExperienceQuery,
  useAddWorkExperienceMutation,
  useGetWorkExperienceDetailQuery,
  useEditWorkExperienceMutation,
  useAddTeamMutation,
  useGetTeamsQuery,
  useGetUserTeamsQuery,
  useGetTeamDetailsQuery,
  useGetUserPhotosQuery,
  useUpdateTeamMutation,
  useAddSupportMutation,
  useGetUserPostsQuery,
  useGetUserDetailsQuery,
  useSendRequestMutation,
  useCancelRequestMutation,
  useGetUserNetworkQuery,
  useGetUserNetworkListQuery,
  useAcceptOrCancelRequestMutation,
  useUpdateBgImageMutation,
} = profileApi;
