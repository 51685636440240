import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileHeader from '../shared/components/ProfileHeader';
import TeamCard from '../shared/components/TeamCard';
import { teamsData } from 'src/app/mock/teams';
import AuthGuard from 'src/app/config/auth/AuthGuard';

const ProfileTeams = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={true} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <TeamCard
                teams={teamsData}
                loggedInUserProfile={false}
                profile
                redirectTo="/team-details"
              />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default ProfileTeams;
