import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import profileBg from '../../../../assets/images/profile-bg.jpg';

type BackgroundImageType = {
  image: string;
};

function BackgroundImage({ image }: BackgroundImageType) {
  return (
    <div
      style={{
        position: 'relative',
        height: '100px',
        backgroundImage: `url("${profileBg}")`,
      }}
    >
      <Image
        style={{
          height: '100px',
          objectFit: 'cover',
          zIndex: 999,
          textIndent: '-10000px',
        }}
        src={image}
        className="img-fluid w-100"
        alt=""
        onError={(i: any) => (i.target.src = '')}
      />
    </div>
  );
}

export default BackgroundImage;
