import { Card } from 'react-bootstrap';
import FollowUserCard from '../FollowUserCard';

interface IFollowSuggestion {
  users: {
    image: string;
    name: string;
    username: string;
  }[];
}

const FollowSuggestion = ({ users }: IFollowSuggestion) => {
  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              You should follow
              <span
                style={{
                  fontSize: '12px',
                }}
              >
                {" "}(with highest connections)
              </span>
            </h4>
          </div>
        </Card.Header>
        <Card.Body>
          <ul className="list-inline m-0 p-0">
            <FollowUserCard />
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

export default FollowSuggestion;
