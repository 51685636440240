import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { useGetUserNetworkQuery } from 'src/app/screens/profile/services/profileApi';
import { useSelector } from 'react-redux';

interface IProfileContainer {
  title: string;
  linkName?: string;
  link?: string;
  data: any;
  type: string;
}

const ProfileContainer = ({
  title,
  linkName,
  link = '#',
  data,
  type,
}: IProfileContainer) => {
  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{title}</h4>
          </div>
          {linkName && <Link to={link}>{linkName}</Link>}
        </div>
        <Card.Body>
          <Row>
            <UserProfileCard btnFor={title.toLowerCase()} type={type} />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileContainer;
