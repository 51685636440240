import { baseUrl } from '../types/base-url';

const camelToSentence = (str: string) => {
  const words = str.split(/(?=[A-Z])/);

  const sentence = words
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.charAt(0).toLowerCase() + word.slice(1);
      }
    })
    .join(' ');

  return sentence;
};
const skillsInArray = (data: any) => {
  return data?.map((skill: any) => skill.title);
};
const objToFormData = (obj: any) => {
  const formData = new FormData();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];

      if (typeof element == 'object' && element?.length > 0) {
        element.map((item: any) => {
          formData.append(`${key}[]`, item);
        });
      } else formData.append(key, element);
    }
  }

  return formData;
};
const getFilePreviewURL = (file: any) => {
  if (file) return URL.createObjectURL(file);
};
const convertNullToEmptyString = (obj: any) => {
  const result: any = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key] !== 'null' && obj[key] !== null ? obj[key] : '';
    }
  }
  return result;
};
const objToParams = (obj: any) => {
  return Object.keys(obj)
    .map((key) => {
      if (obj[key] == undefined) return null;

      if (typeof obj[key] == 'object' && obj[key]?.length > 0)
        return obj[key]
          .map((nestedKey: any) => {
            return `${key + '[]'}=${encodeURIComponent(nestedKey)}`;
          })
          .join('&');
      else return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .filter((item) => item)
    .join('&');
};
const getId = () => {
  return Math.random().toString(16).slice(2);
};
const capitlizeFirstLetter = (string: string) => {
  if (typeof string != 'string') return '';

  let words = string.split(' ');
  let capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  let result = capitalizedWords.join(' ');

  return result;
};
const getSrc = (url: any) => {
  if (typeof url == 'object') return getFilePreviewURL(url);
  if (url?.includes?.('storage')) return baseUrl + '/' + url;
  return url;
};
const handleImageUpload = (
  e: any,
  setValue: any,
  name: any,
  setUploadedImages: any,
  watchAttachments: any
) => {
  setValue(name, [
    ...Array.from(watchAttachments),
    ...Array.from(e.target.files),
  ]);

  const files = Array.from(e.target.files);
  const urls: any = files.map((file: any) => {
    if (file.type.startsWith('image/')) {
      return URL.createObjectURL(file);
    } else if (file.type.startsWith('video/')) {
      return `${baseUrl}/assets/img/default_logo.png`;
    } else if (file.type === 'application/pdf') {
      return `${baseUrl}/assets/img/file-1.png`;
    }
    return null;
  });

  setUploadedImages((prevUrls: any) => [...prevUrls, ...urls]);
};
const paramsToObj = (params: string) => {
  const arrayParams = params.split('&');

  let objParams: any = {};
  arrayParams.map((item) => {
    const [key, value] = item.split('=');
    objParams[key] = value;
  });

  return objParams;
};

const truncateText = (text: any, limit: any) =>
  text?.length > limit ? `${text?.slice(0, limit)}...` : text;

export {
  getId,
  getSrc,
  paramsToObj,
  objToParams,
  skillsInArray,
  objToFormData,
  truncateText,
  camelToSentence,
  getFilePreviewURL,
  handleImageUpload,
  capitlizeFirstLetter,
  convertNullToEmptyString,
};
