import moment from 'moment';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import Avatar from '../Avatar/Avatar';
import LinkifyText from '../LinkifyText/LinkifyText';

interface IChatProps {
  sender: boolean;
  image: string;
  data?: any;
  attachments?: any;
}

const ChatMessage = ({ sender, image, data, attachments = [] }: IChatProps) => {
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border, textInput, chat },
  } = theme;

  const renderImages = (attachments: any, alignContent: any) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 4,
          justifyContent: alignContent == 'start' ? 'flex-start' : 'flex-end',
          flexWrap: 'wrap',
          marginBottom: '4px',
          marginTop: '2px',
        }}
      >
        {attachments?.map((data: any, index: any) =>
          data?.fileExtension != 'pdf' ? (
            <Image
              key={index}
              src={baseUrl + '/' + data?.path}
              alt={`Preview ${index}`}
              style={{
                width: '60px',
                height: '60px',
              }}
            />
          ) : (
            <Link to={baseUrl + '/' + data?.path} target="_blank">
              {data?.filename}
            </Link>
          )
        )}
      </div>
    );
  };

  return (
    <>
      {!sender ? (
        <div
          className="chat-messages d-flex justify-content-between align-items-start p-2"
          style={{
            borderBottom: `1px solid ${textInput.background}`,
          }}
        >
          <div className="d-flex gap-3 align-items-start">
            <Avatar pfp={data?.image} size="40" name={data?.name} />
            <div>
              {data?.message && (
                <p
                  className="mb-0"
                  style={{
                    width: '200px',
                    textAlign: 'left',
                    background: chat.receiver,
                    borderRadius: '4px',
                    padding: '4px',
                    color: '#0F0F0F',
                    lineHeight: '16px',
                  }}
                >
                  {data?.message && <LinkifyText text={data?.message} />}
                </p>
              )}
              {renderImages(attachments, 'start')}
            </div>
          </div>
          <p> {moment(data?.created_at)?.format('hh:mm A')}</p>
        </div>
      ) : (
        <div
          className="chat-messages d-flex justify-content-between align-items-start p-2"
          style={{
            borderBottom: `1px solid ${textInput.background}`,
          }}
        >
          <p> {moment(data?.created_at)?.format('hh:mm A')}</p>
          <div className="d-flex gap-3 align-items-start">
            <div
              style={{
                textAlign: 'right',
              }}
            >
              {data?.message && (
                <p
                  className="mb-0"
                  style={{
                    maxWidth: '200px',
                    textAlign: 'left',
                    background: chat.sender,
                    borderRadius: '4px',
                    padding: '4px',
                    marginBottom: '2px',
                    color: '#F5F5F5',
                    lineHeight: '16px',
                  }}
                >
                  {data?.message && <LinkifyText text={data?.message} />}
                </p>
              )}
              {renderImages(attachments, 'end')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessage;
