import { useGoogleLogin } from '@react-oauth/google';
import cls from 'classnames';
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { slideOne, slideThree, slideTwo } from 'src/app/config/constants/texts';
import { googleUserInfoUrl } from 'src/app/config/types/base-url';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import cycling from '../../../assets/images/cycling.png';
import gmail from '../../assets/images/gmail.png';
import TextInput from '../shared/components/TextInput';
import signupSchema from './schema/signup.schema';
import { useCreateMutation, useGoogleLoginMutation } from './services/authApi';

const SignUp = () => {
  const defaultValues = {
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    privacy_policy: false,
  };

  const history = useNavigate();

  const { theme } = useSelector((state) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [create, { isLoading }] = useCreateMutation();
  const [googleLogin, { isLoading: loggingInUser }] = useGoogleLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: signupSchema,
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await fetch(googleUserInfoUrl, {
        headers: {
          Authorization: `Bearer ${tokenResponse?.access_token}`,
        },
      }).then((response) => response.json());
      if (userInfo) {
        const data = {
          google_id: userInfo?.id,
          email: userInfo?.email,
          name: userInfo?.name,
        };
        const response = await googleLogin(data);

        const { error, data: respData } = response || {};

        if (error)
          toast.error(
            error?.data?.errors?.AccountBlock || error?.data?.message
          );

        if (respData) {
          history('/');
        }
      }
    },
  });

  const handleRegister = handleSubmit(async (data) => {
    if (data?.privacy_policy) {
      const response = await create(data);

      const { error, data: respData } = response || {};

      if (error) toast.error(error?.data?.errors?.email);

      if (respData) {
        history('/');
      }
    }
  });

  return (
    <AuthGuard>
      <>
        <section className={cls('sign-in-page')}>
          <div
            id="container-inside"
            style={{
              border: '0px solid red',
            }}
          >
            <div
              id="circle-small"
              style={{
                background: '#5B5B5C',
              }}
            />
            <div
              id="circle-medium"
              style={{
                background: '#444444',
              }}
            />
            <div
              id="circle-large"
              style={{
                background: '#2D2D2D',
              }}
            />
            <div
              id="circle-xlarge"
              style={{
                background: '#1F1E1F',
              }}
            />
            <div
              id="circle-xxlarge"
              style={{
                background: '#151515',
              }}
            />
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              <Col
                md="6"
                className="text-center "
                style={{
                  border: '0px solid red',
                }}
              >
                <div
                  className="sign-in-detail text-white"
                  style={{
                    border: '0px solid red',
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Link className="sign-in-logo" to="#">
                      <Logo height="73px" width="" />
                    </Link>
                    <h4 className="mb-3">Your Sports Network</h4>
                    <div
                      className="sign-slider"
                      style={{
                        border: '0px solid red',
                      }}
                    >
                      <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                          delay: 2000,
                          disableOnInteraction: false,
                        }}
                        className="list-inline m-0 p-0 "
                      >
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">Find new friends</h4>
                          <p>{slideOne}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">
                            Connect with the world
                          </h4>
                          <p>{slideTwo}</p>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Image
                            src={cycling}
                            className="img-fluid mb-4"
                            alt="logo"
                          />
                          <h4 className="mb-1 text-white">Create new events</h4>
                          <p>{slideThree}</p>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md="6"
                className="bg-white pt-5 pt-5 pb-lg-0 pb-5"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="sign-in-from"
                  style={{
                    border: '0px solid red',
                    width: '100%'
                  }}
                >
                  <h1 className="mb-0">Sign Up</h1>
                  <p>Sign Up for free and gain access to our platform.</p>
                  <Form className="mt-4" onSubmit={handleRegister}>
                    <TextInput
                      name="text"
                      placeholder="Username"
                      register={register('username')}
                    />
                    <p className="text-danger">{errors?.username?.message}</p>
                    <TextInput
                      name="text"
                      placeholder="Email"
                      register={register('email')}
                    />
                    <p className="text-danger">{errors?.email?.message}</p>
                    <TextInput
                      name="password"
                      placeholder="New Password"
                      register={register('password')}
                    />
                    <p className="text-danger">{errors?.password?.message}</p>
                    <TextInput
                      name="password"
                      placeholder="Confirm Password"
                      register={register('password_confirmation')}
                    />
                    <p className="text-danger">
                      {errors?.password_confirmation?.message}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        {...register('privacy_policy')}
                      />
                      <p className="mb-0">
                        I agreed to{' '}
                        <Link to="/auth/privacy-policy">Privacy Policy.</Link>
                      </p>
                    </div>
                    <p className="text-danger">
                      {errors?.privacy_policy?.message}
                    </p>
                    <div className="d-inline-block w-100">
                      <Button
                        variant="primary"
                        disabled={isLoading}
                        type="submit"
                        className="float-end"
                        style={{
                          background: button.background,
                          borderColor: border.dark,
                        }}
                      >
                        Sign Up
                      </Button>
                    </div>
                    <div
                      className="sign-info"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: '8px',
                        borderTop: `1px solid ${border.divider}`,
                      }}
                    >
                      <span
                        className={cls(
                          'line-height-2',
                          'd-inline-block',
                          'dark-color'
                        )}
                        style={{
                          marginTop: '3px',
                        }}
                      >
                        Already have an account?
                        <Link
                          to="/auth/sign-in"
                          style={{
                            marginLeft: 10,
                            cursor: 'pointer',
                          }}
                        >
                          Sign In
                        </Link>
                      </span>
                      <Image
                        src={gmail}
                        className="img-fluid mb-4"
                        alt="logo"
                        style={{
                          height: '22px',
                          maxWidth: '22px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleGoogleLogin()}
                      />
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </AuthGuard>
  );
};

export default SignUp;
