import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useModalDialog from 'src/app/hooks/useModalDialog';
import AdvertisementAudienceModal from '../Modals/AdvertisementAudienceModal';
import postAdvertisementFormSchema from './schema/postAdvertisementForm.schema';

const PostAdvertisementForm = () => {
  const defaultValues = {
    post: '',
    external_link: '',
    attachments: {},
  };

  const history = useNavigate();
  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border, textInput },
  } = theme;

  const { isOpen, open, close } = useModalDialog();

  const [data, setData] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: postAdvertisementFormSchema,
  });

  const handlePostAdvertisementData = handleSubmit(async (data: any) => {
    setData({ ...data, attachments: Array.from(data?.attachments) });

    open();
  });

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Post an Advertisement</h4>
          </div>
        </div>
        <Card.Body>
          <Row>
            <Col md="3">
              <h5>Title</h5>
            </Col>
            <Col md="9">
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  placeholder="Title"
                  {...register('post')}
                />
                <p className="text-danger">{errors?.post?.message}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <h5>External Link</h5>
            </Col>
            <Col md="9">
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  placeholder="External Link"
                  {...register('external_link')}
                />
                <p className="text-danger">{errors?.external_link?.message}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <h5>Media</h5>
            </Col>
            <Col md="9">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" {...register('attachments')} />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
            onClick={handlePostAdvertisementData}
          >
            Post
          </Button>
        </Card.Body>
      </Card>

      <AdvertisementAudienceModal open={isOpen} close={close} data={data} />
    </>
  );
};

export default PostAdvertisementForm;
