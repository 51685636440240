import React from 'react'
import { Image, Modal } from 'react-bootstrap'
import successImage from "../../../../assets/images/application-submitted.png"

interface IJobPostedModal {
    open: boolean,
    close: () => void,
}

const JobPostedModal = ({
    open,
    close,
}: IJobPostedModal) => {
    return (
        <>
            <Modal centered show={open} onHide={close}>
                <Modal.Header
                    closeButton
                    style={{
                        borderBottom: 0
                    }}
                >
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-items-center flex-column mb-5'>
                        <Image
                            src={successImage}
                            alt="Application Submitted"
                            className='img-fluid'
                        />
                        <h5 className='mt-3'>Job Posted</h5>
                        <h5>Successfully</h5>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default JobPostedModal