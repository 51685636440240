import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGetUsersQuery } from '../../services/sharedApi';
import Select, { StylesConfig } from 'react-select';
import {
  useAddTeamMutation,
  useGetTeamDetailsQuery,
  useUpdateTeamMutation,
} from 'src/app/screens/profile/services/profileApi';
import { toast } from 'react-hot-toast';
import { setProfileCompletion } from 'src/app/screens/auth/store/authSlice';

const getIds = (data: any) => {
  return data?.map?.((data: any) => data?.value);
};

const AddTeamForm = () => {
  const defaultValues = {
    information: '',
    name: '',
    location: '',
    latitude: '',
    longitude: '',
    year_founded: '',
    team_color_mascot: '',
    team_history_evolution: '',
    significant_moments: '',
    list_current_team_members: '',
    player_profiles: [],
    head_and_assistant_coaches: '',
    coaching_staff_profiles: [],
    championships_won: '',
    playoff_appearances: '',
    notable_victories_matches: '',
    current_season: '',
    matches_current_record: '',
    standings_league: '',
    information_teams_fan_base_and_support: '',
    stadium_arena_details: '',
    home_venue_current_record: '',
    notable_features: '',
    links_official_social_media_profiles: '',
    press_releases_news: '',
    attachments: [],
  };

  const history = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const { id } = useParams();

  const { theme, mode } = useSelector((state: any) => state.styles);

  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border },
  } = theme;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchTeamName = watch('name');
  const watchLocation = watch('location');
  const watchPlayerProfiles = watch('player_profiles');
  const watchCoachingStaffProfiles = watch('coaching_staff_profiles');
  const watchAttachments = watch('attachments');

  const colourStyles: StylesConfig = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: mode == 'dark' ? '#0F0F0F' : 'white',
    }),
  };

  const { data: allUsers = {} } = useGetUsersQuery({});

  const { data } = allUsers;

  const users = data?.map?.((data: any) => ({
    value: data?.id,
    label: data?.email,
  }));

  const { data: getTeamDetails = {} } = useGetTeamDetailsQuery(id, {
    skip: id ? false : true,
  });

  const { data: teamDetails = {} } = getTeamDetails;

  const [addTeam, { isLoading }] = useAddTeamMutation();

  const [updateTeam, { isLoading: updatingTeam }] = useUpdateTeamMutation();

  const emptyFields = () => {
    reset({
      ...defaultValues,
    });
  };

  const populateFields = () => {
    const membersProfile = teamDetails?.members?.map((data: any) => ({
      value: data?.id,
      label: data?.email,
    }));

    const membersCoachingStaffProfiles = teamDetails?.coaching_staff?.map(
      (data: any) => ({
        value: data?.id,
        label: data?.email,
      })
    );

    setValue('information', teamDetails?.information);
    setValue('name', teamDetails?.name);
    setValue('location', teamDetails?.location);
    setValue('latitude', teamDetails?.latitude);
    setValue('longitude', teamDetails?.longitude);
    setValue('year_founded', teamDetails?.year_founded);
    setValue('team_color_mascot', teamDetails?.team_color_mascot);
    setValue('team_history_evolution', teamDetails?.team_history_evolution);
    setValue('significant_moments', teamDetails?.significant_moments);
    setValue(
      'list_current_team_members',
      teamDetails?.list_current_team_members
    );
    setValue('player_profiles', membersProfile);
    setValue(
      'head_and_assistant_coaches',
      teamDetails?.head_and_assistant_coaches
    );
    setValue('coaching_staff_profiles', membersCoachingStaffProfiles);
    setValue('championships_won', teamDetails?.championships_won);
    setValue('playoff_appearances', teamDetails?.playoff_appearances);
    setValue(
      'notable_victories_matches',
      teamDetails?.notable_victories_matches
    );
    setValue('current_season', teamDetails?.current_season);
    setValue('matches_current_record', teamDetails?.matches_current_record);
    setValue('standings_league', teamDetails?.standings_league);
    setValue(
      'information_teams_fan_base_and_support',
      teamDetails?.information_teams_fan_base_and_support
    );
    setValue('stadium_arena_details', teamDetails?.stadium_arena_details);
    setValue(
      'home_venue_current_record',
      teamDetails?.home_venue_current_record
    );
    setValue('notable_features', teamDetails?.notable_features);
    setValue(
      'links_official_social_media_profiles',
      teamDetails?.links_official_social_media_profiles
    );
    setValue('press_releases_news', teamDetails?.press_releases_news);
    setValue('attachments', teamDetails?.attachments);
  };

  const handleAddTeam = handleSubmit(async (data: any) => {
    if (
      watchTeamName != '' &&
      watchLocation != '' &&
      watchAttachments?.length != 0
    ) {
      const formData = new FormData();

      id && formData.append('id', id);

      formData.append('information', data?.information);
      formData.append('name', data?.name);
      formData.append('location', data?.location);
      formData.append('latitude', data?.latitude);
      formData.append('longitude', data?.longitude);
      formData.append('year_founded', data?.year_founded);
      formData.append('team_color_mascot', data?.team_color_mascot);
      formData.append('team_history_evolution', data?.team_history_evolution);
      formData.append('significant_moments', data?.significant_moments);
      formData.append(
        'list_current_team_members',
        data?.list_current_team_members
      );
      data?.player_profiles?.forEach((data: any) =>
        formData.append('player_profiles[]', data?.value)
      );
      formData.append(
        'head_and_assistant_coaches',
        data?.head_and_assistant_coaches
      );
      data?.coaching_staff_profiles?.forEach((data: any) =>
        formData.append('coaching_staff_profiles[]', data?.value)
      );
      formData.append('championships_won', data?.championships_won);
      formData.append('playoff_appearances', data?.playoff_appearances);
      formData.append(
        'notable_victories_matches',
        data?.notable_victories_matches
      );
      formData.append('current_season', data?.current_season);
      formData.append('matches_current_record', data?.matches_current_record);
      formData.append('standings_league', data?.standings_league);
      formData.append(
        'information_teams_fan_base_and_support',
        data?.information_teams_fan_base_and_support
      );
      formData.append('stadium_arena_details', data?.stadium_arena_details);
      formData.append(
        'home_venue_current_record',
        data?.home_venue_current_record
      );
      formData.append('notable_features', data?.notable_features);
      formData.append(
        'links_official_social_media_profiles',
        data?.links_official_social_media_profiles
      );
      formData.append('press_releases_news', data?.press_releases_news);
      Object.keys(data?.attachments)?.forEach((key: any) =>
        formData.append('attachments[]', data?.attachments[key])
      );

      const response = id
        ? await updateTeam(formData)
        : await addTeam(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        if (user?.profile_completion?.teamsInfo == 0) {
          dispatch(
            setProfileCompletion({
              ...user,
              profile_completion: {
                ...user?.profile_completion,
                teamsInfo: 1,
              },
            })
          );
          history('/');
        }
        emptyFields();
        if (location.pathname == '/profile/add-team') {
          history('/profile');
        }
      }
    } else {
      toast.error(
        'Please add atleast team name and location and images to complete the step'
      );
    }
  });

  useEffect(() => {
    teamDetails && id && populateFields();
  }, [teamDetails]);

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{id ? 'Edit Team' : 'Add Team'}</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <h4 className="fw-bold mb-3">Team Information</h4>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Team Information</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Team Information"
                    {...register('information')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Team Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Team Name"
                    {...register('name')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location"
                    {...register('location')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Year Founded</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Year Founded"
                    {...register('year_founded')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Team color and mascot</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Team color and mascot"
                    {...register('team_color_mascot')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">History</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Team history and evolution</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Team history and evolution"
                    {...register('team_history_evolution')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Significant moments or achievements in the team's history
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Significant moments or achievements in the team's history"
                    {...register('significant_moments')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Roster</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>List of current team members</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="List of current team members"
                    {...register('list_current_team_members')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Player profiles (using the athlete profile format above)
                  </Form.Label>
                  <Select
                    value={watchPlayerProfiles}
                    isMulti
                    name="player_profiles"
                    options={users}
                    styles={colourStyles}
                    onChange={(value) => {
                      setValue('player_profiles', value);
                    }}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Coaching Staff</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Head coach and assistant coaches</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Head coach and assistant coaches"
                    {...register('head_and_assistant_coaches')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Coaching staff profiles</Form.Label>
                  <Select
                    value={watchCoachingStaffProfiles}
                    isMulti
                    name="coaching_staff_profiles"
                    options={users}
                    styles={colourStyles}
                    onChange={(value) => {
                      setValue('coaching_staff_profiles', value);
                    }}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Team Achievements</h4>

              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Championships won</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Championships won"
                    {...register('championships_won')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Playoff appearances</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Playoff appearances"
                    {...register('playoff_appearances')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Notable victories or matches</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Notable victories or matches"
                    {...register('notable_victories_matches')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Matches</h4>

              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Current Season</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current Season"
                    {...register('current_season')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Current Record</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current Record"
                    {...register('matches_current_record')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Standings in the league or competition
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Standings in the league or competition"
                    {...register('standings_league')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Fan Base</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Information about the team's fan base and support
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Information about the team's fan base and support"
                    {...register('information_teams_fan_base_and_support')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Home Venue</h4>

              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Stadium or arena details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Stadium or arena details"
                    {...register('stadium_arena_details')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Current Record</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current Record"
                    {...register('home_venue_current_record')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Notable features</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Notable features"
                    {...register('notable_features')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Media And Publicity</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Links to official social media profiles
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Links to official social media profiles"
                    {...register('links_official_social_media_profiles')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Press releases or news articles related to the team
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Press releases or news articles related to the team"
                    {...register('press_releases_news')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-2">Photos</h4>
              <Col md="12">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Team photos, logos, and videos</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    {...register('attachments')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
              onClick={handleAddTeam}
            >
              {id ? 'Update' : 'Submit'}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddTeamForm;
