import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function AuthGuard({ children }: any) {
  const history = useNavigate();
  const { pathname } = useLocation();

  const { user } = useSelector((state: any) => state.auth);
  const { rehydrated } = useSelector((state: any) => state._persist);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if (rehydrated === true)
      if (!user && !pathname.includes('auth')) history('/auth/sign-in');
      else if (user && pathname.includes('auth')) history('/');
  }, [rehydrated]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return ((user && !pathname.includes('auth')) ||
    (!user && pathname.includes('auth')) ||
    pathname == '/') &&
    isClient &&
    rehydrated ? (
    children
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default AuthGuard;
