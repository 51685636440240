const newFriends =
  'It is a long established fact that a reader will be distracted by the readable content.';
const connectToWorld =
  'It is a long established fact that a reader will be distracted by the readable content.';
const enterEmail = 'Enter your email address and password to Sign In.';
const slideOne =
  'SportConn is the leading network created to connect the sports world, giving visiblity, business and career growth, and social networking in one platform. ';
const slideTwo =
  'Daily our platform is used to build valuable connections , create impactful networks, and find an unlimited talent pool in the sports industry.';
const slideThree =
  'We aim to bring visibilty that is otherwise burreid and limited, to those seeking to be known in their field of the sports world ';

export {
  newFriends,
  enterEmail,
  connectToWorld,
  slideOne,
  slideTwo,
  slideThree,
};
