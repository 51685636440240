import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../config/types/base-url';

export const api = createApi({
  tagTypes: [
    'Jobs',
    'Group',
    'Posts',
    'Groups',
    'Network',
    'UserPosts',
    'GroupPosts',
    'ProfileTeam',
    'UserDetails',
    'PostDetails',
    'ShouldFollow',
    'GroupsByUser',
    'GroupMembers',
    'GroupRequests',
    'ProfileEducation',
    'ProfileWorkExperience',
    'ProfilePersonalInformation',
    'ShouldFollow',
    'Network',
    'MarketPlace',
    'Chat',
    'Notifications',
    'UserChat',
    'GroupNotification',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }: any) => {
      const token = getState().auth.user?.token;

      if (token) headers.set('authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
