import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileHeader from '../shared/components/ProfileHeader';
import TeamDetailCard from '../shared/components/TeamDetailCard';
import AuthGuard from 'src/app/config/auth/AuthGuard';

const UserTeamDetail = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <TeamDetailCard name="Alpha Team" />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default UserTeamDetail;
