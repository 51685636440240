import { Col, Row } from 'react-bootstrap'
import groupBgImage from "../../assets/images/profile-bg3.jpg"
import JobHistoryHeader from '../shared/components/JobHistoryHeader/JobHistoryHeader'
import PostedJobsCard from '../shared/components/PostedJobsCard/PostedJobsCard'
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const PostedJobs = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    {/* <Container
style={{
border: '0px solid red',
}}
> */}
                    {/* //todo: Get content from template and design acc to XD */}
                    <Row>
                        <Col md={12} xl={10}>
                            <ProfilePageHeader
                                title="Job History"
                                image={groupBgImage}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xl={10}>
                            <JobHistoryHeader activeLink="/posted-jobs" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xl={10}>
                            <PostedJobsCard />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default PostedJobs