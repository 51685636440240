import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useModalDialog from 'src/app/hooks/useModalDialog';
import team01 from '../../../../assets/images/t2.jpg';
import sportconnLogo from '../../../../assets/images/blue-icon.png';
import EditEducationModal from '../Modals/EditEducationModal';
import EditWorkplaceExperienceModal from '../Modals/EditWorkplaceExperienceModal';
import {
  useGetEducationQuery,
  useGetWorkExperienceQuery,
} from 'src/app/screens/profile/services/profileApi';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface IWorkCard {
  redirectTo: string;
  education?: boolean;
  workExperience?: boolean;
}

const WorkCard = ({
  redirectTo = '#',
  education = false,
  workExperience = false,
}: IWorkCard) => {
  const { isOpen, open, close } = useModalDialog();

  const [editId, setEditId] = useState('');

  const { mode } = useSelector((state: any) => state.styles);

  const { data: getEducation, isLoading: gettingEducation } =
    useGetEducationQuery(
      {},
      {
        skip: !education,
      }
    );

  const { data: getWorkExperience, isLoading: gettingWorkExperience } =
    useGetWorkExperienceQuery(
      {},
      {
        skip: !workExperience,
      }
    );

  const data = getEducation?.data || getWorkExperience?.data;
  const isLoading = gettingEducation || gettingWorkExperience;

  const handleEdit = (id: any) => {
    setEditId(id);
    open();
  };

  return (
    <>
      {!isLoading ? (
        data?.length > 0 ? (
          data?.map?.((data: any) => (
            <Card
              style={{
                padding: '16px',
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="fw-bold">
                  <Link to={`${redirectTo}/${data?.id}`}>
                    {data?.education_level?.title || data?.job_title}
                  </Link>
                </h4>
                <div
                  className="d-flex gap-1 align-items-center"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleEdit(data?.id)}
                >
                  <span
                    style={{
                      color: mode == 'dark' ? 'white' : 'black',
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={{
                      color: 'green',
                    }}
                  >
                    edit_square
                  </span>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-start">
                <Image
                  src={sportconnLogo || data?.media}
                  alt="Logo"
                  className="rounded-circle img-fluid"
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
                <div>
                  <h6>
                    {data?.college_university || data?.niche_industry?.title}
                  </h6>
                  <p className="mb-0">
                    {data?.field_of_study || data?.sport_name?.title}
                  </p>
                  <p className="mb-0">
                    {data?.start_date?.replaceAll('-', '/')}
                    {data?.end_date && '-'}
                    {data?.end_date?.replaceAll('-', '/')}
                  </p>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <p className="mb-0">No data found</p>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {education && (
        <EditEducationModal open={isOpen} close={close} editId={editId} />
      )}

      {workExperience && (
        <EditWorkplaceExperienceModal
          open={isOpen}
          close={close}
          editId={editId}
        />
      )}
    </>
  );
};

export default WorkCard;
