import cls from 'classnames';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Image, Row
} from 'react-bootstrap';
import {
    useSelector
} from 'react-redux';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import {
    ReactComponent as Logo
} from '../../../assets/icons/logo.svg';
import cycling from '../../../assets/images/cycling.png';
import {
    newFriends
} from '../../config/constants';

const Subscription = () => {

    const history
        = useNavigate()

    const {
        theme,
    } = useSelector(state =>
        state.styles
    )

    const {
        palette: {
            background,
            button,
            border,
        }
    } = theme

    return (
        <>
            <section
                className={cls(
                    'sign-in-page',
                )}
                style={{
                    background:
                        background.dark
                }}
            >
                <div
                    id="container-inside"
                    style={{
                        border: '0px solid red',
                    }}
                >
                    <div
                        id="circle-small"
                    />
                    <div
                        id="circle-medium"
                    />
                    <div
                        id="circle-large"
                    />
                    <div
                        id="circle-xlarge"
                    />
                    <div
                        id="circle-xxlarge"
                    />
                </div>
                <Container
                    className="p-0"
                >
                    <Row
                        className="no-gutters"
                    >
                        <Col
                            md="6"
                            className="text-center "
                            style={{
                                border: '0px solid red',
                            }}
                        >
                            <div
                                className="sign-in-detail text-white"
                                style={{
                                    border: '0px solid red',
                                    padding: 0,
                                    marginTop: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        marginTop: '-20%',
                                    }}
                                >
                                    <Link
                                        className="sign-in-logo"
                                        to="#"
                                    >
                                        <Logo
                                            height='73px'
                                            width='342px'
                                        />
                                    </Link>
                                    <p>Your Sports Network</p>
                                    <div
                                        className="sign-slider"
                                        style={{
                                            border: '0px solid red'
                                        }}
                                    >
                                        <Image
                                            src={cycling}
                                            className="img-fluid mb-4"
                                            alt="logo"
                                            style={{
                                                height: '237px',
                                                maxWidth: '405px'
                                            }}
                                        />
                                        <div
                                            style={{
                                                border: '0px solid red',
                                                width: '389px',
                                                margin: '0 auto'
                                            }}
                                        >
                                            <h4
                                                className="mb-1 text-white"
                                            >
                                                Create new events
                                            </h4>
                                            <p>
                                                {newFriends}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col
                            md="6"
                            className="bg-white pt-5 pt-5 pb-lg-0 pb-5"
                        >
                            <div
                                className="sign-in-from"
                                style={{
                                    border: '0px solid red',
                                }}
                            >
                                <h1
                                    className="mb-5"
                                >
                                    Subscription
                                </h1>
                                <Col sm="12">
                                    <Card>
                                        <Card.Body className="border text-center rounded">
                                            <span className="text-uppercase">Basic</span>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <h2 className="mb-4 display-3">$26</h2>
                                                <small className=" text-muted">/ Month</small>
                                            </div>
                                            <ul className="list-unstyled line-height-4 mb-0">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Consectetur adipiscing elit</li>
                                                <li>Integer molestie lorem at massa</li>
                                            </ul>
                                            <div className='d-grid mt-3'>
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    onClick={() =>
                                                        history('/')
                                                    }
                                                    style={{
                                                        background:
                                                            button.background,
                                                        borderColor:
                                                            border.dark,
                                                    }}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12">
                                    <Card>
                                        <Card.Body className="border text-center rounded">
                                            <span className="text-uppercase">Advanced</span>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <h2 className="mb-4 display-3">$100</h2>
                                                <small className=" text-muted">/ Month</small>
                                            </div>
                                            <ul className="list-unstyled line-height-4 mb-0">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Consectetur adipiscing elit</li>
                                                <li>Integer molestie lorem at massa</li>
                                            </ul>
                                            <div className='d-grid mt-3'>
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    onClick={() =>
                                                        history('/')
                                                    }
                                                    style={{
                                                        background:
                                                            button.background,
                                                        borderColor:
                                                            border.dark,
                                                    }}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Form
                                    className="mt-4"
                                >
                                    <div
                                        className="sign-info"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Link
                                            to="/auth/sign-in"
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}
                                        >
                                            <span className="material-symbols-outlined">
                                                arrow_back
                                            </span>
                                            Back to Sign In
                                        </Link>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Subscription
