import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'

interface IMoreOptions {
    icon?: string,
    children: React.ReactElement,
}

const MoreOptions = ({
    icon = 'more_horiz',
    children,
}: IMoreOptions) => {

    const {
        theme,
        mode,
      } = useSelector((
        state: any
      ) => state.styles)
    
      const {
        palette: {
          background
        }
      } = theme

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle className="bg-transparent"
                    style={{
                        border: 'none',
                        padding: 0
                    }}
                >
                    <span
                        className="material-symbols-outlined"
                        style={{
                            color: background.icon
                        }}
                    >
                        {icon}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className=" m-0 p-0" style={{
                    zIndex: '2'
                }}>
                    {children}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default MoreOptions