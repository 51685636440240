import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';
import useModalDialog from 'src/app/hooks/useModalDialog';
import {
  useGetCategoriesQuery,
  useGetIndustriesQuery,
  useGetNamesQuery,
  useGetRolesQuery,
  useGetSportTypesQuery,
} from '../../services/sharedApi';
import JobPostedModal from '../Modals/JobPostedModal';
import {
  useAddJobMutation,
  useGetHearJobTypesQuery,
  useGetJobCategoriesQuery,
  useGetJobTypesQuery,
} from 'src/app/screens/jobs/services/jobsAPI';
import { toast } from 'react-hot-toast';
import postJobFormSchema from './schema/postJobForm.schema';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';

const PostJobForm = () => {
  const defaultValues = {
    sport_industry_id: '',
    role_id: '',
    sport_category_id: '',
    sport_name_id: '',
    sports_name_sub_category_id: '',
    job_type_id: '',
    job_category_id: '',
    title: '',
    location: '',
    latitude: '',
    longitude: '',
    description: '',
    skills: [],
    salary_package: '',
    email: '',
    company: '',
  };

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: postJobFormSchema,
  });

  const watchSportIndustry = watch('sport_industry_id');
  const watchSportCategory = watch('sport_category_id');
  const watchSportName = watch('sport_name_id');

  const [selected, setSelected] = useState<any>([]);

  const { isOpen, open, close } = useModalDialog();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { data: allIndustries } = useGetIndustriesQuery({});

  const { data: allRoles } = useGetRolesQuery(watchSportIndustry, {
    skip: !watchSportIndustry,
  });

  const { data: allCategories } = useGetCategoriesQuery({});

  const { data: allJobTypes } = useGetJobTypesQuery({});

  const { data: allJobCategories } = useGetJobCategoriesQuery({});

  const { data: allNames } = useGetNamesQuery(watchSportCategory, {
    skip: !watchSportCategory,
  });

  const { data: allSportTypes } = useGetSportTypesQuery(watchSportName, {
    skip: !watchSportName,
  });

  const [addJob, { isLoading }] = useAddJobMutation();

  const emptyFilds = () => {
    setValue('sport_industry_id', '');
    setValue('role_id', '');
    setValue('sport_category_id', '');
    setValue('sport_name_id', '');
    setValue('sports_name_sub_category_id', '');
    setValue('job_type_id', '');
    setValue('job_category_id', '');
    setValue('title', '');
    setValue('location', '');
    setValue('latitude', '');
    setValue('longitude', '');
    setValue('description', '');
    setValue('skills', []);
    setValue('salary_package', '');
    setValue('email', '');
    setValue('company', '');
    setSelected([]);
  };

  const handleAddJob = handleSubmit(async (data: any) => {
    const bodyData = {
      ...data,
      skills: selected,
    };
    const response = await addJob(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email);

    if (respData) {
      emptyFilds();
      history('/jobs');
    }
  });

  useEffect(() => {
    personalInformation &&
      setValue('company', personalInformation?.company_name);
    setValue('email', personalInformation?.email_id);
  }, [personalInformation]);

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Post Job</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Sports Industry: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('sport_industry_id')}
                  >
                    <option>Select</option>
                    {allIndustries?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">
                    {errors?.sport_industry_id?.message}
                  </p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Role: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('role_id')}
                  >
                    <option>Select</option>
                    {allRoles?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">{errors?.role_id?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Sports Category: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('sport_category_id')}
                  >
                    <option>Sport Category</option>
                    {allCategories?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">
                    {errors?.sport_category_id?.message}
                  </p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Sports Name: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('sport_name_id')}
                  >
                    <option>Select</option>
                    {allNames?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">
                    {errors?.sport_name_id?.message}
                  </p>
                </Form.Group>
              </Col>
              {allSportTypes?.data?.length > 0 && (
                <Col md="6">
                  <Form.Group className="form-group">
                    <Form.Label>
                      Sports Type: <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register('sports_name_sub_category_id')}
                    >
                      <option>Select</option>
                      {allSportTypes?.data?.map?.((data: any) => {
                        const { uid, title } = data;
                        return <option value={uid}>{title}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Job Category: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('job_category_id')}
                  >
                    <option>Select</option>
                    {allJobCategories?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">
                    {errors?.job_category_id?.message}
                  </p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Job Type: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('job_type_id')}
                  >
                    <option>Select</option>
                    {allJobTypes?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                  <p className="text-danger">{errors?.job_type_id?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Job Title: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="jobTitle"
                    placeholder="Job Title"
                    {...register('title')}
                  />
                  <p className="text-danger">{errors?.title?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Company: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    placeholder="Company"
                    {...register('company')}
                  />
                  <p className="text-danger">{errors?.company?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Email: <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    {...register('email')}
                  />
                  <p className="text-danger">{errors?.email?.message}</p>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    placeholder="Location"
                    {...register('location')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Tell Us About This Role</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    {...register('description')}
                  />
                </Form.Group>
              </Col>
              <Form.Label>Skills</Form.Label>
              <Col md="12" className="mb-3">
                <TagsInput
                  value={selected}
                  onChange={setSelected}
                  name="fruits"
                />
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Offering Salary Package</Form.Label>
                  <Form.Control
                    type="text"
                    name="salary"
                    placeholder="Salary"
                    {...register('salary_package')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
              onClick={handleAddJob}
            >
              Post Job
            </Button>
          </div>
        </Card.Body>
      </Card>

      <JobPostedModal open={isOpen} close={close} />
    </>
  );
};

export default PostJobForm;
