import { Col, Row } from 'react-bootstrap';
import groupBgImage from '../../assets/images/profile-bg2.jpg';
import PostJobForm from '../shared/components/Forms/PostJobForm';
import JobsFilter from '../shared/components/JobsFilter/JobsFilter';
import JobsHeader from '../shared/components/JobsHeader/JobsHeader';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { useGetPersonalInformationQuery } from '../profile/services/profileApi';
import { Link, useNavigate } from 'react-router-dom';
import {
  setBusinessDetails,
  setScrollToBottom,
} from '../shared/store/sharedSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const PostJob = () => {
  const defaultValues = {
    sport_industry_id: 0,
    role_id: 0,
    sport_category_id: 0,
    sport_name_id: 0,
    sports_name_sub_category_id: 0,
    location: '',
  };

  const dispatch = useDispatch();
  const history = useNavigate();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const checkBuinessDetailAvailability = () => {
    if (
      personalInformation?.company_name ||
      personalInformation?.website ||
      personalInformation?.sports_industry ||
      personalInformation?.business_size ||
      personalInformation?.description ||
      personalInformation?.tag_line ||
      personalInformation?.verify_authorized_representative
    )
      return true;
    else return false;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const handleFindJobs = handleSubmit(async (data: any) => {
    console.log('🚀 ~ file: JobsFilter.tsx:158 ~ handleFindJobs ~ data:', data);
  });

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Jobs" image={groupBgImage} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10} className="mt-3">
              <JobsFilter
                register={register}
                handleSubmit={handleSubmit}
                setValue={setValue}
                watch={watch}
                handleFindJobs={handleFindJobs}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <JobsHeader activeLink="/post-job" />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              {checkBuinessDetailAvailability() ? (
                <PostJobForm />
              ) : (
                <p>
                  Please add{' '}
                  <span
                    onClick={() => {
                      dispatch(setBusinessDetails(true));
                      dispatch(setScrollToBottom(true));
                      history('/profile/edit-personal-information');
                    }}
                  >
                    <Link to="">business details</Link>{' '}
                  </span>
                  to post jobs.
                </p>
              )}
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default PostJob;
