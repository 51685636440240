import { Button, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cls from 'classnames';

type GroupsHeaderType = {
  loading: boolean;
  setPage: any;
};

const GroupsHeader = ({ loading, setPage }: GroupsHeaderType) => {
  const history = useNavigate();

  const location = useLocation();

  const { theme } = useSelector((state: any) => state.styles);
  const { user }: any = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border },
  } = theme;

  return (
    <>
      <div
        className={cls(
          'justify-content-between',
          'align-items-end',
          'flex-wrap',
          'my-3',
          'custom-create-group-container',
        )}
      >
        <nav
          className="tab-bottom-bordered"
          style={{
            borderBottom: 0,
            overflowX: 'auto'
          }}
        >
          <Nav
            variant="tabs"
            className="mb-0 rounded-top border-0"
            activeKey={location.pathname}
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'flex-start',
              flexWrap: 'nowrap',
              height: '50px'
            }}
            onSelect={(selectedKey) => {
              setPage(1);
              history(`${selectedKey}`);
            }}
          >
            <Nav.Link
              disabled={loading}
              eventKey="/groups"
              style={{
                opacity: loading ? 0.5 : 1,
                cursor: 'pointer',
                color: background.dark,
                whiteSpace: 'nowrap'
              }}
            >
              All Groups
            </Nav.Link>
            <Nav.Link
              disabled={loading}
              eventKey={`/groups/${user?.uid}`}
              style={{
                opacity: loading ? 0.5 : 1,
                cursor: 'pointer',
                color: background.dark,
                whiteSpace: 'nowrap'
              }}
            >
              Your Groups
            </Nav.Link>
            <Nav.Link
              disabled={loading}
              eventKey={`/groups/${user?.uid}/${1}`}
              style={{
                opacity: loading ? 0.5 : 1,
                cursor: 'pointer',
                color: background.dark,
                whiteSpace: 'nowrap'
              }}
            >
              Requested Groups
            </Nav.Link>
          </Nav>
        </nav>
        <Button
          variant="primary"
          type="button"
          className="float-end mt-4"
          onClick={() => history('/create-group/0')}
          style={{
            background: 'transparent',
            borderColor: border.dark,
            color: button.background,
          }}
        >
          Create Group
        </Button>
      </div>
    </>
  );
};

export default GroupsHeader;
