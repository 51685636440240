import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ImageSliderModal.css';
import { baseUrl } from 'src/app/config/types/base-url';

const ImageSliderModal = ({ images, open, close }: any) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="image-slider">
      <Modal show={open} onHide={close} centered className="image-slider-modal">
        <Modal.Body>
          {images[currentImageIndex]?.fileExtension !== 'mp4' &&
          images[currentImageIndex]?.fileExtension !== 'mov' ? (
            <img
              src={`${baseUrl}/storage/${images[currentImageIndex]?.path}`}
              alt={`modal-image-${currentImageIndex}`}
              className="modal-image"
            />
          ) : (
            <video
              controls
              style={{
                width: '100%',
              }}
            >
              <source
                src={baseUrl + '/storage/' + images[currentImageIndex]?.path}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
          <Button
            variant="light"
            className="prev pre_next_btn pre_left"
            onClick={prevImage}
          >
            &#10094;
          </Button>
          <Button
            variant="light"
            className="next pre_next_btn next_right"
            onClick={nextImage}
          >
            &#10095;
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageSliderModal;
