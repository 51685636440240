import React from 'react';
import Avatar from '../Avatar';
import profileBg from '../../../../assets/images/profile-bg.jpg';

type ProfilePageHeaderType = {
  image: string;
  title: string;
  pfp?: string;
  changePfp?: any;
  changeBackground?: any;
};

const ProfilePageHeader = ({
  image,
  title,
  pfp,
  changePfp,
  changeBackground,
}: ProfilePageHeaderType) => {
  return (
    <>
      <div style={{ border: '0px solid red' }} className="header-for-bg">
        <div
          style={{ border: '0px solid red', position: 'relative' }}
          className="background-header position-relative"
        >
          <div
            style={{
              position: 'relative',
              backgroundImage: `url("${profileBg}")`,
            }}
            className="custom-profile-page-header"
          >
            <img
              style={{
                objectFit: 'cover',
                textIndent: '-10000px',
              }}
              src={image}
              className="img-fluid w-100 custom-profile-page-header"
              alt=""
            />
          </div>
          {changeBackground && (
            <span
              onClick={changeBackground}
              style={{
                border: '0px solid red',
                position: 'absolute',
                top: 10,
                right: 10,
                background: '#0008',
                borderRadius: '50%',
                padding: '5px',
                cursor: 'pointer',
              }}
              className="material-symbols-outlined"
            >
              edit
            </span>
          )}
          <div className="title-on-header">
            {pfp || changePfp ? (
              <Avatar pfp={pfp} changePfp={changePfp} />
            ) : (
              <div className="data-block">
                <h2>{title}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePageHeader;
