import { yupResolver } from '@hookform/resolvers/yup';
import {
  file_required_validator,
  images_validator,
  string_required_validator,
} from 'src/app/config/types/validators';
import { object } from 'yup';

const addNewItemFormSchema = object().shape({
  title: string_required_validator,
  currency_id: string_required_validator,
  price: string_required_validator,
  description: string_required_validator,
  location: string_required_validator,
});

export default yupResolver(addNewItemFormSchema);
