import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useGetEducationDetailQuery } from 'src/app/screens/profile/services/profileApi'
import Spinner from 'react-bootstrap/Spinner';

const EducationDetailCard = () => {

    const {
        id
    } = useParams()

    const {
        data: getEducationDetail = {},
        isLoading,
    } = useGetEducationDetailQuery(id)

    const {
        data = {}
    } = getEducationDetail

    return (
        <>
            {
                !isLoading
                    ?
                    <Card>
                        <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">
                                    Higher Education
                                </h4>
                            </div>
                        </div>
                        <Card.Body>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>Education Level:</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.education_level?.title}</h5>
                                        </div>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>College/University:</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.college_university}</h5>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>Field of Study:</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.field_of_study}</h5>
                                        </div>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>Start Date:</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.start_date}</h5>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>End Date (Or Expected)::</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.end_date}</h5>
                                        </div>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <div className="col-6">
                                            <h5 className='fw-bold'>Grade:</h5>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-0">{data?.grade}</h5>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    :
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spinner
                            animation="border"
                            variant="primary"
                        />
                    </div>
            }
        </>
    )
}

export default EducationDetailCard