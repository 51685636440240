import { Card, Col, Image, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import { useGetTeamDetailsQuery } from 'src/app/screens/profile/services/profileApi';

interface ITeamDetailCard {
  name: string;
  icon?: boolean;
}

const TeamDetailCard = ({ name, icon }: ITeamDetailCard) => {
  const history = useNavigate();

  const { teamId } = useParams();

  const { user } = useSelector((state: any) => state.auth);

  const { data: teamDetails = {}, isLoading } = useGetTeamDetailsQuery(teamId);

  const { data = {} } = teamDetails;

  return (
    <>
      {!isLoading ? (
        <Card>
          <div className="card-header d-flex justify-content-between align-items-center">
            <div className="header-title">
              <h4 className="card-title">{data?.name}</h4>
            </div>
            {user?.uid == data?.user_id && (
              <div
                style={{
                  boxShadow: '0 8px 16px rgba(0,0,0,.15)',
                  color: 'green',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history(`/profile/profile-edit-teams/${data?.id}`)
                }
              >
                <span className="material-symbols-outlined">edit_square</span>
              </div>
            )}
          </div>
          <Card.Body>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-4">
                    <h5 className="fw-bold">Team Information:</h5>
                  </div>
                  <div className="col-8">
                    <h5 className="mb-0">{data?.information}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-4">
                    <h5 className="fw-bold">Team Name:</h5>
                  </div>
                  <div className="col-8">
                    <h5 className="mb-0">{data?.name}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-4">
                    <h5 className="fw-bold">Location:</h5>
                  </div>
                  <div className="col-8">
                    <h5 className="mb-0">{data?.location}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-4">
                    <h5 className="fw-bold">Year Founded:</h5>
                  </div>
                  <div className="col-8">
                    <h5 className="mb-0">{data?.year_founded}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-4">
                    <h5 className="fw-bold">Team Colors & Mascot:</h5>
                  </div>
                  <div className="col-8">
                    <h5 className="mb-0">{data?.team_color_mascot}</h5>
                  </div>
                </Row>
              </Col>
            </Row>

            <hr />
            <h4 className="fw-bold mb-3">History</h4>
            <Row className="mb-2">
              <div className="col-10">
                <h5>
                  <b>Teams History & Evolution: </b>
                  {data?.team_history_evolution}
                </h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-10">
                <h5>
                  <b>
                    Significant moments or achievements in the team's history:{' '}
                  </b>
                  {data?.significant_moments}
                </h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Roster</h4>
            <Row className="mb-2">
              <div className="col-10">
                <h5>
                  <b>List of Current Team Members: </b>
                  {data?.list_current_team_members}
                </h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-10">
                <h5>
                  <b>
                    Player profiles (using the athlete profile format above):{' '}
                  </b>
                  {data?.members?.map?.((member: any, index: any) => {
                    const { id, name, profile_url } = member;
                    return (
                      <span key={id}>
                        <Link to={`/user-profile/${id}`}>{name || profile_url}</Link>
                        {index < data?.members?.length - 1 && ', '}
                      </span>
                    );
                  })}
                </h5>
              </div>
            </Row>

            <hr />
            <h4 className="fw-bold mb-3">Coaching Staff</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Head Coach & Assistant Coaches:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.head_and_assistant_coaches}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-10">
                <h5>
                  <b>Coaching Staff Profiles: </b>
                  {data?.coaching_staff?.map?.((staff: any, index: any) => {
                    const { id, name, profile_url } = staff;
                    return (
                      <span key={id}>
                        <Link to={`/user-profile/${id}`}>{name || profile_url}</Link>
                        {index < data?.coaching_staff?.length - 1 && ', '}
                      </span>
                    );
                  })}
                </h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Team Achievements</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Championships Won:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.championships_won}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Playoff Appearances:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.playoff_appearances}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Notable Victory & Matches:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.notable_victories_matches}</h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Matches</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Current Season:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.current_season}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Current Record:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.matches_current_record}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">
                  Standings in the league or competition:
                </h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.standings_league}</h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Fan Base</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">
                  Information About The Team's Fan Base And Support:
                </h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">
                  {data?.information_teams_fan_base_and_support}
                </h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Home Venue</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Stadium Or Arena Details:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.stadium_arena_details}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Current Record:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.home_venue_current_record}</h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">Notable Features:</h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.notable_features}</h5>
              </div>
            </Row>
            <hr />
            <h4 className="fw-bold mb-3">Media & Publicity</h4>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">
                  Links To Official Social Media Profiles:
                </h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">
                  {data?.links_official_social_media_profiles}
                </h5>
              </div>
            </Row>
            <Row className="mb-2">
              <div className="col-3">
                <h5 className="fw-bold">
                  Press releases or news articles related to the team:
                </h5>
              </div>
              <div className="col-9">
                <h5 className="mb-0">{data?.press_releases_news}</h5>
              </div>
            </Row>

            <hr />
            <h4 className="fw-bold mb-3">Photos</h4>
            <Row className="mb-2">
              <div className="col-10">
                <h5 className="fw-bold">Team Photos, Logos, And Videos</h5>
              </div>
            </Row>
            <Row className="mb-2 mt-4">
              <div className="col-10">
                {data?.attachments?.map((data: any) => {
                  const { id, path } = data;
                  return (
                    <Image
                      key={id}
                      loading="lazy"
                      src={`${baseUrl}/storage/${path}`}
                      alt="gallary"
                      className="img-fluid"
                      style={{
                        width: '80px',
                      }}
                    />
                  );
                })}
              </div>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default TeamDetailCard;
