import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import { useGetUserPhotosQuery } from 'src/app/screens/profile/services/profileApi';
import NoData from '../NoData/NoData';

interface IPhotoCard {
  photos: string[];
  loggedInUserProfile: boolean;
  redirectTo?: string;
  style?: any;
}

const PhotoCard = ({
  photos,
  loggedInUserProfile,
  redirectTo,
  style,
}: IPhotoCard) => {
  const { id } = useParams();

  const { pathname } = useLocation();

  const { user } = useSelector((state: any) => state.auth);

  const { data: getUsersPhotos, isLoading } = useGetUserPhotosQuery(
    id ? id : user?.uid
  );
  const { data: userPhotos } = getUsersPhotos || {};

  const shouldSlice =
    !pathname.includes('profile/photos') &&
    !pathname.includes('user-profile/photos');

  const renderViewLink = () => {
    return (
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">Photos</h4>
        {!pathname.includes('profile/photos') &&
          !pathname?.includes('user-profile/photos') && (
            <Link to={redirectTo || '#'}>View</Link>
          )}
      </div>
    );
  };

  return (
    <>
      <Card>
        {renderViewLink()}
        <Card.Body>
          <Row>
            {userPhotos?.length > 0 ? (
              userPhotos
                ?.slice(0, shouldSlice ? 8 : undefined)
                ?.map((data: any) => {
                  return (
                    <Col
                      md={4}
                      lg={3}
                      xs={4}
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <div>
                        <Image
                          loading="lazy"
                          src={baseUrl + '/storage/' + data?.path}
                          alt="gallary"
                          className="img-fluid"
                          style={{
                            ...style,
                          }}
                        />
                      </div>
                    </Col>
                  );
                })
            ) : (
              <NoData name="photos" />
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PhotoCard;
