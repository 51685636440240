import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDeleteGroupMutation } from '../../services/groupsApi';

type DeleteModalType = {
  open: boolean;
  setOpen: any;
  setId?: any;
  id: number;
};

const DeleteGroup = ({ open, setOpen, id, setId }: DeleteModalType) => {
  const navigate = useNavigate();

  const [deleteGroup, { isLoading }] = useDeleteGroupMutation();

  const { user } = useSelector((state: any) => state.auth);
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const handleClose = () => {
    setId?.(0);
    setOpen(false);
  };

  const handleDelete = async () => {
    let response = await deleteGroup({
      groupId: id,
    });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      handleClose();
      navigate(`/groups/${user?.uid}`);
    }
  };

  return (
    <Modal centered show={open} onHide={handleClose}>
      <Modal.Header className="d-flex justify-content-between">
        <h5 className="modal-title" id="post-modalLabel">
          Delete Group
        </h5>
        <Link className="lh-1" to="#" onClick={handleClose}>
          <span className="material-symbols-outlined">close</span>
        </Link>
      </Modal.Header>
      <Modal.Body>
        <h4>Are you sure?</h4>
        <p>You will not be able to undo this later.</p>
        <Button
          disabled={isLoading}
          variant="primary"
          type="button"
          className="float-end"
          style={{
            background: button.background,
            borderColor: border.dark,
          }}
          onClick={handleDelete}
        >
          Delete Group
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteGroup;
