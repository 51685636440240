import { useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';

interface IMarketPlaceCard {
  id?: any;
  userId?: any;
  companyId?: any;
  image?: any;
  name?: string;
  description?: string;
  price?: string;
  link?: string;
  location?: string;
  currency?: any;
}

const MarketPlaceCard = ({
  id,
  userId,
  companyId,
  image,
  name,
  description,
  price,
  link,
  location,
  currency,
}: IMarketPlaceCard) => {
  const { pathname } = useLocation();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const truncatedName =
    name && name?.length > 10 ? `${name?.slice(0, 10)}...` : name;

  const truncatedDescription =
    description && description?.length > 30
      ? `${description?.slice(0, 30)}...`
      : description;

  const truncatedLocation =
    location && location?.length > 30
      ? `${location?.slice(0, 30)}...`
      : location;

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : image?.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < image?.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div
            className="position-relative"
            style={{
              height: '300px',
            }}
          >
            {image && image?.length > 1 && typeof image != 'string' ? (
              <>
                <span
                  className="material-symbols-outlined arrow"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    cursor: 'pointer',
                    left: '-12px',
                    width: '28px',
                    height: '28px',
                    borderRadius: '28px',
                    backgroundColor: 'white',
                    bottom: '50%',
                    transform: 'translateY(-50%)',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  chevron_left
                </span>
                {image[currentImageIndex].fileExtension != 'mp4' ? (
                  <Image
                    src={baseUrl + '/storage/' + image[currentImageIndex].path}
                    alt={name}
                    className="img-fluid"
                    style={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <video
                    controls
                    style={{
                      width: '100%',
                      height: '300px',
                    }}
                  >
                    <source
                      src={
                        baseUrl + '/storage/' + image[currentImageIndex].path
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
                <span
                  className="material-symbols-outlined arrow"
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    top: '50%',
                    right: '-12px',
                    bottom: '50%',
                    transform: 'translateY(-50%)',
                    width: '28px',
                    height: '28px',
                    borderRadius: '28px',
                    backgroundColor: 'white',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  chevron_right
                </span>
              </>
            ) : (
              <Image
                src={
                  image.includes('https://')
                    ? image
                    : baseUrl + '/storage/' + image[0]?.path
                }
                alt={name}
                className="img-fluid"
                style={{
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                }}
              />
            )}
          </div>
          <div
            style={{
              height: '80px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '16px',
                alignItems: 'flex-start',
              }}
            >
              <h4 className="fw-bold">
                <Link
                  to={
                    !price
                      ? `/company-items/${userId}`
                      : pathname.includes('my-items')
                      ? `/company-item-detail/${id}`
                      : `/item-detail/${id}`
                  }
                  style={{
                    color: background.dark,
                  }}
                >
                  {truncatedName}
                </Link>
              </h4>
            </div>
            <h6>{truncatedDescription}</h6>
            {price && (
              <h6 className="d-flex mt-2 gap-1 align-items-start">
                <span className="material-symbols-outlined">location_on</span>
                {truncatedLocation}
              </h6>
            )}
          </div>
          {price && (
            <h6
              style={{
                position: 'absolute',
                top: '32px',
                right: '32px',
                background: '#F5B354',
                padding: '2px 8px',
                fontWeight: 'bold',
                color: background.blue,
              }}
            >
              {currency?.symbol}{price}
            </h6>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default MarketPlaceCard;
