import cls from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { baseUrl } from 'src/app/config/types/base-url';
import Avatar from '../shared/components/Avatar';
import Loader from '../shared/components/Loader';
import NoData from '../shared/components/NoData';
import ProfilePageHeader from '../shared/components/ProfilePageHeader';
import {
  useGetGroupMembersQuery,
  useGetGroupQuery,
} from './services/groupsApi';

const GroupMembers = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetGroupMembersQuery({ groupId: id });
  const { data: groupData }: any = useGetGroupQuery({ groupId: id });

  const { background_image } = groupData?.data || {};

  const renderData = data?.data?.data?.[0]?.group_member?.map(
    ({ member_user: { id, profile_url, username, connections } }: any) => {
      return (
        <li
          key={id}
          className={cls(
            'd-flex',
            'align-items-center',
            'justify-content-between',
            'flex-wrap'
          )}
        >
          <div className="user-img img-fluid flex-shrink-0">
            <Avatar
              size="50"
              pfp={baseUrl + '/' + profile_url}
              name={username}
            />
          </div>
          <div className="flex-grow-1 ms-3">
            <h6>{username}</h6>
            <p className="mb-0">{connections?.length} friends</p>
          </div>
        </li>
      );
    }
  );

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader
                image={baseUrl + '/' + background_image}
                title="Groups"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">{'Group Members'}</h4>
                  </div>
                </div>
                <Card.Body>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <ul className="request-list list-inline m-0 p-0">
                      {data?.data?.data?.length > 0 ? (
                        renderData
                      ) : (
                        <NoData name="Group Member" />
                      )}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default GroupMembers;
