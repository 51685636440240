import { mixed, number, ref, string, array, bool } from 'yup';
import { emailRx } from '../constants/regex';

const email_validator = string()
  .required('Email is required.')
  .matches(emailRx, 'Enter a valid email.');
const email_validator1 = string().matches(
  emailRx,
  'Please enter email address to receive password reset link.'
);
const password_validator = string().required('Password is required.');
const string_required_validator = string().required('Field is required');
const number_required_validator = string().required('Field is required');
const policy_checkbox_validator = (label: string) =>
  bool().oneOf([true], `Please mark ${label}.`);
const object_required_validator = mixed()
  .transform((v) => (!v ? undefined : v))
  .required('Field is required');
const confirm_password_validator = string()
  .oneOf([ref('password')], 'Passwords must match')
  .required('Confirm Password should match with the first one entered above.');
const confirm_password_validator2 = string()
  .test('password', (value: any) => value != ref('password'))
  .oneOf(
    [ref('password')],
    'Confirm Password should match with the first one entered above.'
  );
const label_required_validator = (label: string) =>
  string().required(`${label} is required.`);
const username_validator = string()
  .trim()
  .required('Username is required.')
  .matches(/^\S+$/, 'Username cannot contain empty spaces.');
const custom_message_required_validator = (message: string) =>
  string().required(`${message}`);
const file_required_validator = (label: string) =>
  mixed().required(`${label} is required`);
const custom_message_file_required_validator = (message: string) =>
  mixed().required(`${message}`);
const dropdown_validator = (label: string) =>
  number().notOneOf([0], `Please select ${label}`).required().label(label);
const images_validator = array()
  .of(
    mixed().test('file-type', 'Invalid file format', (value) => {
      if (!value) return true;
      return value && value instanceof File;
    })
  )
  .required('Images are required')
  .test(
    'min-length',
    'At least one image is required',
    (value) => value && value.length > 0
  );

export {
  email_validator,
  username_validator,
  email_validator1,
  password_validator,
  string_required_validator,
  number_required_validator,
  object_required_validator,
  confirm_password_validator,
  confirm_password_validator2,
  custom_message_required_validator,
  dropdown_validator,
  file_required_validator,
  label_required_validator,
  images_validator,
  policy_checkbox_validator,
  custom_message_file_required_validator,
};
