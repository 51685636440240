import { api } from 'src/app/services';

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    allNotifications: builder.query({
      query: () => {
        return {
          url: `/api/user/notification/get`,
          method: 'GET',
        };
      },
      providesTags: ['Notifications'],
    }),
    readNotification: builder.mutation({
      query: (id) => {
        return {
          url: `/api/user/notification/read?id=${id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Notifications'],
    }),
    readAllNotification: builder.mutation({
      query: () => {
        return {
          url: `/api/user/notification/clear`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useAllNotificationsQuery,
  useReadNotificationMutation,
  useReadAllNotificationMutation,
} = notificationApi;
