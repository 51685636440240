import React from 'react';
import { toast } from 'react-hot-toast';
import { useSharePostMutation } from 'src/app/screens/feed/services/feedApi';

const PostShare = ({ postId }: any) => {
  const [sharePost, { isLoading }] = useSharePostMutation();

  const handleSharePost = async () => {
    const bodyData = {
      parent_id: postId,
    };
    const response = await sharePost(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      <div
        className="d-flex gap-2 align-items-center"
        style={{
          // cursor: 'pointer',
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        <span className="material-symbols-outlined">share</span>
        <p className="mb-0">
          {' '}
          <span className="custom-post-footer-text">Share</span>
        </p>
      </div>
    </>
  );
};

export default PostShare;
