import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GroupTypeEnum } from 'src/app/config/constants';
import { GroupUserStatusEnum } from 'src/app/config/constants/enums';
import { capitlizeFirstLetter } from 'src/app/config/helper';
import { webBaseUrl } from 'src/app/config/types/base-url';
import Avatar from 'src/app/screens/shared/components/Avatar';
import MoreOptions from 'src/app/screens/shared/components/MoreOptions/MoreOptions';
import {
  useAddGroupRequestMutation,
  useLeaveGroupMutation,
  useRejectGroupRequestsMutation,
} from '../../services/groupsApi';
import BackgroundImage from './BackgroundImage';

type GroupType = {
  id?: number;
  groupBgImage: string;
  groupImage: string;
  name: string;
  description: string;
  posts: string;
  members: string;
  requestedGroups?: boolean;
  children?: React.ReactElement;
  groupMembers: string[];
  ownerId: number;
  groupType: string | GroupTypeEnum;
  groupUserStatus: GroupUserStatusEnum | '';
};

const GroupCard = ({
  id,
  groupBgImage,
  groupImage,
  name,
  description,
  posts,
  members,
  requestedGroups,
  children,
  groupMembers,
  ownerId,
  groupType,
  groupUserStatus,
}: GroupType) => {
  const history = useNavigate();

  const { userId, requested } = useParams();

  const [addGroupRequest, { isLoading: addingRequest }] =
    useAddGroupRequestMutation();
  const [leaveGroup, { isLoading: addingLeaveGroup }] = useLeaveGroupMutation();
  const [rejectGroupRequests, { isLoading: addingRejectGroupRequests }] =
    useRejectGroupRequestsMutation();

  const { user } = useSelector((state: any) => state.auth);
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const buttonProps: any = {
    variant: 'primary',
    type: 'button',
    className: 'float-end',
    style: {
      background: requestedGroups ? 'transparent' : button.background,
      borderColor: border.dark,
      color: requestedGroups ? button.background : 'button.light',
    },
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${webBaseUrl}/groups/group-detail/${id}`);
    toast.success('Link has been copied.');
  };

  const handleClick = async () => {
    let response;

    if (!groupUserStatus)
      response = await addGroupRequest({
        body: {
          group_id: id,
          creator_id: ownerId,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Requested)
      response = await rejectGroupRequests({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Joined)
      response = await leaveGroup({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  const truncatedName =
    name && name?.length > 10 ? `${name?.slice(0, 30)}...` : name;

  const truncatedDescription =
    description && description?.length > 30
      ? `${description?.slice(0, 70)}...`
      : description;

  const getGroupUserStatus = () => {
    return groupUserStatus == GroupUserStatusEnum.Joined
      ? button.join
      : groupUserStatus == GroupUserStatusEnum.Owner
      ? button.admin
      : groupUserStatus == GroupUserStatusEnum.Requested
      ? button.cancelRequest
      : capitlizeFirstLetter(groupType) == GroupTypeEnum.Private
      ? button.other
      : button.requested;
  };

  return (
    <>
      <div className="card mb-0">
        <div className="top-bg-image">
          <BackgroundImage image={groupBgImage} />
        </div>
        <div className="card-body text-center position-relative">
          <div className="group-icon position-relative">
            <div onClick={() => history(`/groups/group-detail/${id}`)}>
              <Avatar
                pfp={groupImage}
                name={name}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="position-absolute end-0 top-50">
              <MoreOptions icon="more_horiz">
                <>
                  <Dropdown.Item
                    onClick={handleCopyLink}
                    className=" p-3"
                    href=""
                  >
                    <div className="d-flex align-items-center">
                      <span className="material-symbols-outlined">
                        content_copy
                      </span>
                      <div className="data ms-2">
                        <h6>Copy group link</h6>
                      </div>
                    </div>
                  </Dropdown.Item>
                  {userId && requested && (
                    <Dropdown.Item
                      onClick={handleClick}
                      className=" p-3"
                      href=""
                    >
                      <div className="d-flex align-items-center">
                        <span className="material-symbols-outlined">
                          cancel
                        </span>
                        <div className="data ms-2">
                          <h6>Cancel request</h6>
                        </div>
                      </div>
                    </Dropdown.Item>
                  )}
                </>
              </MoreOptions>
            </div>
          </div>
          <div className="position-absolute top-0 end-0">{children}</div>
          <div
            className="group-info pt-3"
            style={{
              height: '110px',
            }}
          >
            <h4 className="fw-bold">
              <Link
                to={`/groups/group-detail/${id}`}
                style={{
                  color: background.dark,
                }}
              >
                {truncatedName}
              </Link>
            </h4>
            <p className="mb-0">{truncatedDescription}</p>
          </div>
          <div
            style={{
              border: '0px solid red',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ border: '0px solid red', marginRight: 10 }}>
              <p className="mb-0 fw-bold">Post</p>
              <h6
                style={{
                  color: 'red',
                }}
              >
                {posts}
              </h6>
            </div>
            <div style={{ border: '0px solid red', marginLeft: 10 }}>
              <p className="mb-0 fw-bold">Member</p>
              <h6
                style={{
                  color: 'red',
                }}
              >
                {members}
              </h6>
            </div>
          </div>
          <div className="group-member mb-3">
            <div
              style={{
                border: '0px solid red',
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {groupMembers?.map?.(({ id, member_user }: any) => {
                const { personal_information, username } = member_user || {};

                return (
                  <Avatar
                    key={id}
                    pfp={personal_information?.imagepath}
                    name={username}
                    size="40"
                    style={{
                      border: '0px solid red',
                      margin: 'unset',
                      marginLeft: '-20px',
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="d-grid">
            <Button
              {...buttonProps}
              disabled={
                addingRequest || addingLeaveGroup || addingRejectGroupRequests
              }
              onClick={() =>
                groupUserStatus != GroupUserStatusEnum.Owner && handleClick()
              }
              style={{
                borderColor: getGroupUserStatus(),
                background: getGroupUserStatus(),
              }}
            >
              {groupUserStatus == GroupUserStatusEnum.Requested &&
                'Cancel Request'}

              {groupUserStatus == GroupUserStatusEnum.Joined && 'Leave Group'}

              {groupUserStatus == GroupUserStatusEnum.Owner && 'Admin'}

              {!groupUserStatus &&
                (capitlizeFirstLetter(groupType) == GroupTypeEnum.Private
                  ? 'Request'
                  : 'Join')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupCard;
