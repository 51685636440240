import { paramsToObj } from 'src/app/config/helper';
import { api } from 'src/app/services';

export const sharedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addGroup: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/api/user/group/create`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Groups'],
    }),
    updateGroup: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/api/user/group/update_group`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Groups', 'Group'],
    }),
    getGroups: builder.query({
      query: ({ params, userId, requested }) => ({
        url: `/api/user/group/${
          userId
            ? requested
              ? 'get_requested_groups/' + userId + '?' + params
              : 'get_user_groups/' + userId
            : 'get_all_groups?' + params
        }`,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        let params = queryArgs?.params && paramsToObj(queryArgs?.params);

        if (!params?.page)
          return endpointName + `(${JSON.stringify(queryArgs)})`;
        return endpointName;
      },
      merge: (prevData, newData, { arg }) => {
        console.log('🚀 ~ arg:', arg);
        const { params, userId, requested } = arg || {};

        const page = params?.slice?.(-1);

        let data = newData?.data;
        let last_page = newData?.last_page;
        if (!(data?.length > 0)) {
          data = newData?.data?.data;
          last_page = newData?.data?.last_page;
        }

        if ((!requested && userId) || page == 1)
          return {
            ...newData,
            data: {
              last_page,
              data: data,
            },
          };

        return {
          ...newData,
          data: {
            ...newData?.data,
            data: [...prevData?.data?.data, ...newData?.data?.data],
          },
        };
      },
      forceRefetch({ currentArg, previousArg }: any) {
        return currentArg !== previousArg;
      },
      providesTags: ['Groups'],
    }),
    getGroup: builder.query({
      query: ({ groupId }) => ({
        url: `api/user/group/get_group_details/${groupId}`,
      }),
      providesTags: ['Group'],
    }),
    addGroupRequest: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/api/user/group/request_group`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['GroupRequests', 'Groups', 'Group', 'Posts'],
    }),
    getGroupRequests: builder.query({
      query: ({ groupId }) => ({
        url: `/api/user/group/get_group_requests/${groupId}`,
      }),
      providesTags: ['GroupRequests'],
    }),
    getGroupMembers: builder.query({
      query: ({ groupId }) => ({
        url: `/api/user/group/get_group_members/${groupId}`,
      }),
      providesTags: ['GroupMembers'],
    }),
    acceptGroupRequests: builder.mutation({
      query: ({ requestId }) => {
        return {
          url: `/api/user/group/accept_group_request/${requestId}`,
        };
      },
      invalidatesTags: ['GroupRequests'],
    }),
    rejectGroupRequests: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/api/user/group/reject_group_request`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['GroupRequests', 'Groups', 'Group'],
    }),
    deleteGroup: builder.mutation({
      query: ({ groupId }) => ({
        url: `api/user/group/delete_group/${groupId}`,
      }),
      invalidatesTags: ['Groups', 'Posts'],
    }),
    leaveGroup: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/api/user/group/leave_group`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Groups', 'Group', 'Posts'],
    }),
    groupNotificationSetting: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/group/notification_setting`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['GroupNotification'],
    }),
    getGroupNotificationSetting: builder.query({
      query: (id) => {
        return {
          url: `/api/user/group/notification_setting/get?group_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['GroupNotification'],
    }),
  }),
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useAddGroupMutation,
  useLeaveGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useGetGroupMembersQuery,
  useGetGroupRequestsQuery,
  useAddGroupRequestMutation,
  useAcceptGroupRequestsMutation,
  useRejectGroupRequestsMutation,
  useGroupNotificationSettingMutation,
  useGetGroupNotificationSettingQuery,
} = sharedApi;
