import { Card, Col, Row, Image } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import {
  useGetTeamsQuery,
  useGetUserTeamsQuery,
} from 'src/app/screens/profile/services/profileApi';
import NoData from '../NoData/NoData';

interface TeamCardProps {
  teams?: {
    image: string;
    name: string;
    link: string;
  }[];
  loggedInUserProfile: any;
  profile?: any;
  home?: any;
  redirectTo?: string;
  myProfile?: boolean;
  style?: any;
  showAll?: any;
  cb?: () => void;
}

const TeamCard = ({
  teams,
  loggedInUserProfile,
  profile,
  home,
  redirectTo,
  myProfile,
  style,
  showAll,
  cb,
}: TeamCardProps) => {
  const history = useNavigate();

  const { user } = useSelector((state: any) => state.auth);

  const { id } = useParams();
  const { pathname } = useLocation();

  const { data: allTeams = {}, isLoading: gettingAllTeams } = useGetTeamsQuery(
    {},
    {
      skip: !home,
    }
  );

  const { data: userTeams = {}, isLoading: gettingUserTeams } =
    useGetUserTeamsQuery(id ? id : user?.uid, {
      skip: !profile,
    });

  const data = allTeams?.data || userTeams?.data;
  const isLoading = gettingAllTeams || gettingUserTeams;

  const renderViewLink = () => {
    return (
      <div className="card-header d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Teams</h4>
        </div>
        {loggedInUserProfile === true && (
          <div className="card-header-toolbar d-flex align-items-center">
            <Link
              to={
                myProfile ? '/profile/profile-add-teams' : '/profile/add-team'
              }
            >
              Add Team
            </Link>
          </div>
        )}
        {loggedInUserProfile == false &&
          !pathname.includes('/user-profile/teams/') && (
            <div className="card-header-toolbar d-flex align-items-center">
              <Link
                to={
                  !id ? '/profile/profile-teams' : `/user-profile/teams/${id}`
                }
              >
                View
              </Link>
            </div>
          )}
      </div>
    );
  };

  return (
    <>
      {!isLoading ? (
        <Card>
          {renderViewLink()}
          <Card.Body>
            <Row>
              {data?.length > 0 ? (
                (showAll ? data : data?.slice(0, 8))?.map?.((data: any) => {
                  const { id, attachments, name, user_id } = data;
                  return (
                    <Col
                      md={4}
                      lg={3}
                      xs={4}
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      <div>
                        <Link
                          to={
                            user?.uid == user_id
                              ? `${redirectTo}/${id}`
                              : `/user-profile/team-details/${user_id}/${id}`
                          }
                        >
                          <Image
                            loading="lazy"
                            src={`${baseUrl}/storage/${attachments[0]?.path}`}
                            alt="gallary"
                            className="img-fluid"
                            style={{
                              width: '300px',
                              ...style,
                            }}
                          />
                        </Link>
                        <h6
                          className="mt-2 text-center"
                          style={{
                            maxWidth: '300px',
                          }}
                        >
                          {name}
                        </h6>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <NoData name="teams" />
              )}
            </Row>
            {data?.length > 8 && !showAll && (
              <div
                onClick={() => history('/teams')}
                style={{ border: '0px solid red', cursor: 'pointer' }}
                className="text-center mt-2"
              >
                <small>VIEW ALL</small>
              </div>
            )}
          </Card.Body>
        </Card>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default TeamCard;
