import { Card, Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import { setBusinessDetails, setScrollToBottom } from '../../store/sharedSlice';

const PersonalInformationCard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { mode } = useSelector((state: any) => state.styles);

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data = {} } = getPersonalInformation;

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="header-title">
            <h4 className="card-title">Personal Info</h4>
          </div>
          <div
            className="d-flex gap-1 align-items-center"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(setBusinessDetails(false));
              dispatch(setScrollToBottom(false));
              history('/profile/edit-personal-information');
            }}
          >
            <span
              style={{
                color: mode == 'dark' ? 'white' : 'black',
              }}
            >
              Edit
            </span>
            <div
              style={{
                boxShadow: '0 8px 16px rgba(0,0,0,.15)',
                color: 'green',
                width: '40px',
                height: '40px',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <span className="material-symbols-outlined">edit_square</span>
            </div>
          </div>
        </div>
        <Card.Body>
          <h4 className="fw-bold mb-3">Personal Details</h4>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">User Id:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.user_id || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Profile Url:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.profile_url || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Email ID:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.email_id || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Username:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.username || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Full Name:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.full_name || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Gender:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.gender || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Date of birth:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.date_of_birth || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Place of birth:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.place_of_birth || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Nationality:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.nationality || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Headline:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.headline || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Contact Type:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.contact_type || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Contact Number:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.contact_number || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Favorite Player/Team:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">
                    {data?.favorite_player_Team || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Biographical Information</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Brief Background & Biography:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.brief_background_biography || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Early Life & Upbringing:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.early_life_upbringing || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Education:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.education || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Sporting Career</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Sport Played:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.sport_played || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Position & Specialization:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.position_specialization || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Teams or Clubs Played for:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.teams_clubs_played_for || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Important Milestone & Achievements:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.important_milestone_achievements || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Career Statics (if applicable):</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.career_statics || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Accomplishments</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Major Award & Honors:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.major_award_honors || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Records held (if any):</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.records_held || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Notable Performances:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.notable_performances || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Current Status</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Current Team Or Affiliation:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.current_team_affiliation || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Injury Status (if relevant):</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.injury_status || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Upcoming events or competitions:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.upcoming_events_competitions || 'N/A'}
              </h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Physical Attributes</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Height</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.height || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Weight</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.weight || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Strength & Weakness</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.strength_weakness || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Personal Life</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Hobbies & Interests Outside Sports:</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.hobbies_interests_outside_sports || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Family information (optional):</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.family_information || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Quotes & Interviews</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">
                Any Noteworthy Statements Or Interviews:
              </h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.any_noteworthy_statements_interviews || 'N/A'}
              </h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Address Details</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Address:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.address || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Zip Code:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.zip_code || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">City:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.city || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">State:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.state || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Photos & Videos</h4>
          <Col md="12">
            <Row className="mb-2 mt-4">
              <div className="col-10">
                <Image
                  loading="lazy"
                  src={data?.profile_image}
                  alt="gallary"
                  className="img-fluid"
                  style={{
                    width: '80px',
                  }}
                />
              </div>
            </Row>
          </Col>
          <hr />
          <h4 className="fw-bold mb-3">Profession</h4>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Niche/Industry</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.niche_industry?.title || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Roles</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.roles?.title || 'N/A'}</h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Sports Categories</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {data?.sports_categories?.title || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-5">
              <h5 className="fw-bold">Sports Name</h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">{data?.sport_name?.title || 'N/A'}</h5>
            </div>
          </Row>
          <hr />
          <h4 className="fw-bold mb-3">Business Details</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Company Name:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.company_name || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Public page Url:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.profile_url || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Sports Industry:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">
                    {data?.sports_industry?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Organization Size:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">
                    {data?.business_size?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Description:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.description || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Tag Line:</h5>
                </div>
                <div className="col-7">
                  <h5 className="mb-0">{data?.tag_line || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-5">
                  <h5 className="fw-bold">Logo</h5>
                </div>
                <div className="col-7">
                  <Image
                    loading="lazy"
                    src={data?.logo}
                    alt="gallary"
                    className="img-fluid"
                    style={{
                      width: '80px',
                    }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PersonalInformationCard;
