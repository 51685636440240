import { createSlice } from '@reduxjs/toolkit';

const sharedSlice = createSlice({
  name: 'shared',
  initialState: {
    openChat: false,
    chatType: 'general',
    profileSetup: 1,
    receiverId: null,
    productDetail: null,
    businessDetails: false,
    scrollToBottom: false,
  },
  reducers: {
    setOpenChat: (state, { payload }) => {
      state.openChat = payload;
      if (payload) document.body.style.overflow = 'hidden';
      else document.body.style.overflow = 'initial';
    },
    setChatType: (state, { payload }) => {
      state.chatType = payload;
    },
    setProfileSetup: (state, { payload }) => {
      state.profileSetup = payload;
    },
    setReceiverId: (state, { payload }) => {
      state.receiverId = payload;
    },
    setProductDetail: (state, { payload }) => {
      state.productDetail = payload;
    },
    setBusinessDetails: (state, { payload }) => {
      state.businessDetails = payload;
    },
    setScrollToBottom: (state, { payload }) => {
      state.scrollToBottom = payload;
    },
  },
});

export const {
  setOpenChat,
  setChatType,
  setProfileSetup,
  setReceiverId,
  setProductDetail,
  setBusinessDetails,
  setScrollToBottom,
} = sharedSlice.actions;

export default sharedSlice.reducer;
