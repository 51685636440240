import { Card, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import { setProfileSetup } from '../../store/sharedSlice';
import Avatar from '../Avatar/Avatar';
import VerifiedIcon from '../../../../assets/images/check-circle.png';
import cls from 'classnames';

const MiniProfileCard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.auth);

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data = {} } = getPersonalInformation;

  const numberOfOnes = Object?.values(user?.profile_completion)?.filter(
    (value) => value === 1
  )?.length;

  const progress = (numberOfOnes / 4) * 100;

  const handleRedirectToProfileDetails = () =>
    history('/profile/personal-information');

  const handleCompleteProfile = () => {
    user?.profile_completion?.personalInfo == 0
      ? history('/profile/edit-personal-information')
      : user?.profile_completion?.educationInfo == 0
      ? history('/profile/add-education')
      : user?.profile_completion?.workExperience == 0
      ? history('/profile/add-workplace-experience')
      : user?.profile_completion?.teamsInfo == 0 &&
        history('/profile/profile-add-teams');

    dispatch(setProfileSetup(0));
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="text-center">
            {data?.profile_image && (
              <div onClick={handleRedirectToProfileDetails}>
                <Avatar
                  pfp={data?.profile_image}
                  size="130"
                  name={data?.username}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </div>
            )}
            <div
              className={cls(
                'd-flex',
                'align-items-center',
                'justify-content-center',
                'gap-1'
              )}
            >
              <h5
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={handleRedirectToProfileDetails}
              >
                {data?.full_name}
              </h5>
              {data?.is_verified && (
                <img
                  src={VerifiedIcon}
                  alt="Verified User"
                  width={20}
                  className="ms-1 mb-1"
                />
              )}
            </div>
            <p onClick={handleRedirectToProfileDetails} className="mb-0">
              {data?.username}
            </p>
            <Link to="#" onClick={handleRedirectToProfileDetails}>
              {data?.roles?.title}
            </Link>
            <p>{data?.tag_line}</p>
          </div>
          <hr />
          <div className="text-center">
            <h5
              style={{
                fontWeight: 'bold',
                marginBottom: '16px',
              }}
            >
              Your Profile
            </h5>
            <ProgressBar now={progress} className="custom-pogress" />
            <h6
              className="text-end"
              style={{
                marginTop: '8px',
              }}
            >
              {progress}% Complete
            </h6>
            {progress < 100 && (
              <div
                className="d-flex justify-content-end"
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                }}
              >
                <Link to="" onClick={handleCompleteProfile}>
                  Complete your profile
                </Link>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default MiniProfileCard;
