import { useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GroupUserStatusEnum } from 'src/app/config/constants/enums';
import { GroupTypeEnum } from 'src/app/config/constants/enums/group-visibility.enum';
import { webBaseUrl } from 'src/app/config/types/base-url';
import Avatar from '../../../shared/components/Avatar';
import MoreOptions from '../../../shared/components/MoreOptions';
import cls from 'classnames';
import {
  useAddGroupRequestMutation,
  useGetGroupNotificationSettingQuery,
  useGroupNotificationSettingMutation,
  useLeaveGroupMutation,
  useRejectGroupRequestsMutation,
} from '../../services/groupsApi';
import DeleteGroup from '../DeleteGroup';
import './GroupDetailHeader.css';

type PostHeaderProps = {
  image: string;
  name: string;
  visibility: GroupTypeEnum | string;
  members: string;
  groupUserStatus: GroupUserStatusEnum | '';
  ownerId: number;
  id: number;
};

const GroupDetailHeader = ({
  image,
  name,
  visibility,
  members,
  groupUserStatus,
  ownerId,
  id,
}: PostHeaderProps) => {
  const history = useNavigate();
  const { pathname } = useLocation();

  const [addGroupRequest, { isLoading: addingRequest }] =
    useAddGroupRequestMutation();
  const [leaveGroup, { isLoading: addingLeaveGroup }] = useLeaveGroupMutation();
  const [rejectGroupRequests, { isLoading: addingRejectGroupRequests }] =
    useRejectGroupRequestsMutation();
  const [groupNotificationSetting] = useGroupNotificationSettingMutation();
  const { data: getGroupNotificationSetting } =
    useGetGroupNotificationSettingQuery(id);

  const { data: notificationSetting } = getGroupNotificationSetting || {};

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { button, border },
  } = theme;

  const [groupNotification, setGroupNotification] = useState('all');

  const buttonProps: any = {
    variant: 'primary',
    type: 'button',
    className: 'float-end',
    style: {
      background: button.background,
      borderColor: border.dark,
      paddingLeft: '32px',
      paddingRight: '32px',
    },
  };

  const [deleteGroup, setDeleteGroup] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${webBaseUrl}/groups/group-detail/${id}`);
    toast.success('Link has been copied.');
  };

  const handleGroupNotificationSetting = async (notificationSetting: any) => {
    const bodyData = {
      group_id: id,
      notification_setting: notificationSetting,
    };
    const response = await groupNotificationSetting(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  const handleClick = async () => {
    let response;

    if (!groupUserStatus)
      response = await addGroupRequest({
        body: {
          group_id: id,
          creator_id: ownerId,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Requested)
      response = await rejectGroupRequests({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Joined)
      response = await leaveGroup({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  useEffect(() => {
    notificationSetting &&
      setGroupNotification(notificationSetting?.notification_setting);
  }, [notificationSetting?.notification_setting]);

  return (
    <>
      <div
        className={cls(
          'flex-wrap',
          'justify-content-between',
          'gap-3',
          'my-3',
          'custom-create-group-container'
        )}
      >
        <div className="me-3 d-flex gap-3">
          <Avatar pfp={image} name={name} size="80" style={{ margin: 0 }} />
          <div>
            <h4 className="mb-0 d-inline-block fw-bold">{name}</h4>
            <div className="d-flex flex-wrap gap-2 align-items-center">
              <p className="mb-0 d-flex align-items-center fw-bold">
                {visibility == GroupTypeEnum.Private ? (
                  <span className="material-symbols-outlined me-1">lock</span>
                ) : (
                  <span className="material-symbols-outlined me-1">
                    visibility
                  </span>
                )}
                {visibility} Group
              </p>
              <p
                onClick={() => history(`/groups/group-members/${id}`)}
                className="ms-1 mb-0 d-inline-block"
                style={{
                  color: 'red',
                  cursor: 'pointer',
                }}
              >
                {members} members
              </p>
            </div>
          </div>
        </div>
        <div
          className={cls(
            'd-flex',
            'flex-column',
            'custom-group-detail-header',
            'custom-group-icon-btn'
          )}
        >
          <div className="d-flex">
            {!pathname.includes('about-group') &&
              (groupUserStatus == GroupUserStatusEnum.Joined ||
                groupUserStatus == GroupUserStatusEnum.Owner) && (
                <span
                  className="material-symbols-outlined px-3"
                  onClick={() => history(`/groups/about-group/${id}`)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  info
                </span>
              )}
            <MoreOptions icon="notifications">
              <div
                style={{
                  width: '300px',
                }}
              >
                <h5 className="px-3 pt-3 pb-0">Manage Notifications</h5>
                <hr />
                <p
                  className="px-3"
                  style={{
                    fontSize: '13px',
                  }}
                >
                  Manage notifications from football masters. FM messages &
                  collaborations.
                </p>
                <hr />
                <div className="px-3">
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      name="notification"
                      value="all"
                      checked={groupNotification === 'all'}
                      onChange={(e) => {
                        setGroupNotification(e.target.value);
                        handleGroupNotificationSetting(e.target.value);
                      }}
                    />
                    <Form.Check.Label>All New Posts</Form.Check.Label>
                  </Form.Check>
                  <p
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Show all notification from this group.
                  </p>
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      name="notification"
                      value="highlights"
                      checked={groupNotification === 'highlights'}
                      onChange={(e) => {
                        setGroupNotification(e.target.value);
                        handleGroupNotificationSetting(e.target.value);
                      }}
                    />
                    <Form.Check.Label>Highlights</Form.Check.Label>
                  </Form.Check>
                  <p
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Show notification of only my followers or connections in
                    this group.
                  </p>
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      name="notification"
                      value="no_new_post"
                      checked={groupNotification === 'no_new_post'}
                      onChange={(e) => {
                        setGroupNotification(e.target.value);
                        handleGroupNotificationSetting(e.target.value);
                      }}
                    />
                    <Form.Check.Label>No New Posts</Form.Check.Label>
                  </Form.Check>
                  <p
                    style={{
                      fontSize: '13px',
                    }}
                  >
                    Do not show only notification from this group.
                  </p>
                </div>
              </div>
            </MoreOptions>
            <MoreOptions icon="more_horiz">
              <>
                <Dropdown.Item
                  onClick={handleCopyLink}
                  className=" p-3"
                  href=""
                >
                  <div className="d-flex align-items-center">
                    <span className="material-symbols-outlined">
                      content_copy
                    </span>
                    <div className="data ms-2">
                      <h6>Copy group link</h6>
                    </div>
                  </div>
                </Dropdown.Item>
                {ownerId == user?.uid ? (
                  <>
                    <Dropdown.Item
                      onClick={() => history(`/create-group/${id}`)}
                      className=" p-3"
                      href=""
                    >
                      <div className="d-flex align-items-center">
                        <span className="material-symbols-outlined">edit</span>
                        <div className="data ms-2">
                          <h6>Edit Group</h6>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setDeleteGroup(true)}
                      className=" p-3"
                      href=""
                    >
                      <div className="d-flex align-items-center">
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                        <div className="data ms-2">
                          <h6>Delete Group</h6>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    {groupUserStatus == GroupUserStatusEnum.Joined && (
                      <Dropdown.Item className=" p-3" href="">
                        <div className="d-flex align-items-center">
                          <span className="material-symbols-outlined">
                            exit_to_app
                          </span>
                          <div className="data ms-2">
                            <h6>Leave group</h6>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </>
            </MoreOptions>
          </div>
          {ownerId == user?.uid ? (
            <Button
              {...buttonProps}
              onClick={() => history(`/groups/group-requests/${id}`)}
            >
              Group Requests
            </Button>
          ) : (
            <Button
              {...buttonProps}
              className="text-nowrap"
              disabled={
                addingRequest || addingLeaveGroup || addingRejectGroupRequests
              }
              onClick={handleClick}
            >
              {groupUserStatus == GroupUserStatusEnum.Requested &&
                'Cancel Request'}

              {groupUserStatus == GroupUserStatusEnum.Joined && 'Leave Group'}

              {!groupUserStatus &&
                (visibility == GroupTypeEnum.Private ? 'Request' : 'Join')}
            </Button>
          )}
        </div>
      </div>
      <DeleteGroup open={deleteGroup} setOpen={setDeleteGroup} id={id} />
    </>
  );
};

export default GroupDetailHeader;
