import { useState } from 'react';
import { Button, Card, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetCategoriesQuery,
  useGetIndustriesQuery,
  useGetNamesQuery,
  useGetRolesQuery,
  useGetSportTypesQuery,
} from '../../services/sharedApi';

type JobsFilterType = {
  register?: any;
  handleSubmit?: any;
  setValue?: any;
  watch?: any;
  handleFindJobs?: any;
  handleSearchLocation?: any;
  handleSearchJobTitle?: any;
};

const JobsFilter = ({
  register,
  handleSubmit,
  setValue,
  watch,
  handleFindJobs,
  handleSearchLocation,
  handleSearchJobTitle,
}: JobsFilterType) => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const watchSportIndustry = watch('sport_industry_id');
  const watchSportCategory = watch('sport_category_id');
  const watchSportName = watch('sport_name_id');

  const { data: allIndustries } = useGetIndustriesQuery({});

  const { data: allRoles } = useGetRolesQuery(watchSportIndustry, {
    skip: !watchSportIndustry,
  });

  const { data: allCategories } = useGetCategoriesQuery({});

  const { data: allNames } = useGetNamesQuery(watchSportCategory, {
    skip: !watchSportCategory,
  });

  const { data: allSportTypes } = useGetSportTypesQuery(watchSportName, {
    skip: !watchSportName,
  });

  const [industryValue, setIndustryValue] = useState('');
  const [industrySuggestions, setIndustrySuggestions] = useState([]);
  const [roleValue, setRoleValue] = useState('');
  const [roleSuggestions, setRoleSuggestions] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [nameValue, setNameValue] = useState('');
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [typeValue, setTypeValue] = useState('');
  const [typeSuggestions, setTypeSuggestions] = useState([]);

  const handleIndustryChange = (e: any) => {
    const value = e.target.value;
    setIndustryValue(value);
    const newSuggestions = allIndustries?.data?.filter((data: any) =>
      data?.title?.toLowerCase()?.includes(value)
    );
    setIndustrySuggestions(newSuggestions);
    value === '' && setValue('sport_industry_id', 0);
  };

  const handleIndustrySuggestion = (suggestion: any) => {
    setIndustryValue(suggestion?.title);
    setIndustrySuggestions([]);
    setValue('sport_industry_id', suggestion?.uid);
  };

  const handleRoleChange = (e: any) => {
    const value = e.target.value;
    setRoleValue(value);
    const newSuggestions = allRoles?.data?.filter((data: any) =>
      data?.title?.toLowerCase()?.includes(value)
    );
    setRoleSuggestions(newSuggestions);
    value === '' && setValue('role_id', 0);
  };

  const handleRoleSuggestion = (suggestion: any) => {
    setRoleValue(suggestion?.title);
    setRoleSuggestions([]);
    setValue('role_id', suggestion?.uid);
  };

  const handleCategoryChange = (e: any) => {
    const value = e.target.value;
    setCategoryValue(value);
    const newSuggestions = allCategories?.data?.filter((data: any) =>
      data?.title?.toLowerCase()?.includes(value)
    );
    setCategorySuggestions(newSuggestions);
    value === '' && setValue('sport_category_id', 0);
  };

  const handleCategorySuggestion = (suggestion: any) => {
    setCategoryValue(suggestion?.title);
    setCategorySuggestions([]);
    setValue('sport_category_id', suggestion?.uid);
  };

  const handleNameChange = (e: any) => {
    const value = e.target.value;
    setNameValue(value);
    const newSuggestions = allNames?.data?.filter((data: any) =>
      data?.title?.toLowerCase()?.includes(value)
    );
    setNameSuggestions(newSuggestions);
    value === '' && setValue('sport_name_id', 0);
  };

  const handleNameSuggestion = (suggestion: any) => {
    setNameValue(suggestion?.title);
    setNameSuggestions([]);
    setValue('sport_name_id', suggestion?.uid);
  };

  const handleTypeChange = (e: any) => {
    const value = e.target.value;
    setTypeValue(value);
    const newSuggestions = allSportTypes?.data?.filter((data: any) =>
      data?.title?.toLowerCase()?.includes(value)
    );
    setTypeSuggestions(newSuggestions);
    value === '' && setValue('sports_name_sub_category_id', 0);
  };

  const handleTypeSuggestion = (suggestion: any) => {
    setTypeValue(suggestion?.title);
    setTypeSuggestions([]);
    setValue('sports_name_sub_category_id', suggestion?.uid);
  };

  return (
    <>
      <Card>
        <div className="d-flex gap-3 align-items-center flex-wrap m-3">
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Sport Industry"
                style={{
                  borderRight: 0,
                }}
                value={industryValue}
                onChange={handleIndustryChange}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                  borderLeft: 0,
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {industrySuggestions?.length > 0 && industryValue !== '' && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {industrySuggestions?.map((suggestion: any, index: any) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleIndustrySuggestion(suggestion)}
                      action
                    >
                      {suggestion?.title}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Role"
                style={{
                  borderRight: '0px',
                }}
                value={roleValue}
                onChange={handleRoleChange}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                  borderLeft: 0,
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {roleSuggestions?.length > 0 && roleValue !== '' && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {roleSuggestions?.map((suggestion: any, index: any) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleRoleSuggestion(suggestion)}
                      action
                    >
                      {suggestion?.title}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Sport Category"
                style={{
                  borderRight: 0,
                }}
                value={categoryValue}
                onChange={handleCategoryChange}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {categorySuggestions?.length > 0 && categoryValue !== '' && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {categorySuggestions?.map((suggestion: any, index: any) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleCategorySuggestion(suggestion)}
                      action
                    >
                      {suggestion?.title}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Sport Name"
                style={{
                  borderRight: 0,
                }}
                value={nameValue}
                onChange={handleNameChange}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {nameSuggestions?.length > 0 && nameValue !== '' && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {nameSuggestions?.map((suggestion: any, index: any) => (
                    <ListGroup.Item
                      key={index}
                      onClick={() => handleNameSuggestion(suggestion)}
                      action
                    >
                      {suggestion?.title}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          {allSportTypes?.data?.length > 0 && (
            <div className="iq-search-bar device-search position-relative">
              <InputGroup
                style={{
                  width: '200px',
                }}
                className="custom-input-group"
              >
                <Form.Control
                  placeholder="Sport Type"
                  style={{
                    borderRight: 0,
                  }}
                  value={typeValue}
                  onChange={handleTypeChange}
                />
                <InputGroup.Text
                  id="basic-addon1"
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <span className="material-symbols-outlined">search</span>
                </InputGroup.Text>
                {typeSuggestions?.length > 0 && typeValue !== '' && (
                  <ListGroup
                    style={{
                      position: 'absolute',
                      zIndex: 9999999,
                      width: '100%',
                      maxHeight: '300px',
                      overflowY: 'auto',
                      marginTop: '40px',
                    }}
                  >
                    {typeSuggestions?.map((suggestion: any, index: any) => (
                      <ListGroup.Item
                        key={index}
                        onClick={() => handleTypeSuggestion(suggestion)}
                        action
                      >
                        {suggestion?.title}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </InputGroup>
            </div>
          )}
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Location"
                style={{
                  borderRight: 0,
                }}
                onChange={handleSearchLocation}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '200px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                placeholder="Job Title"
                style={{
                  borderRight: 0,
                }}
                onChange={handleSearchJobTitle}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <Button
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
              paddingRight: '32px',
              paddingLeft: '32px',
            }}
            onClick={handleFindJobs}
          >
            Find Jobs
          </Button>
        </div>
      </Card>
    </>
  );
};

export default JobsFilter;
