import { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUpdateAccountSettingsMutation } from 'src/app/screens/auth/services/authApi';

const AccountSettingsForm = () => {

    const defaultValues = {
        email_id: '',
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    }

    const history
        = useNavigate()

    const {
        user,
    } = useSelector((state: any) =>
        state.auth
    )

    const {
        theme,
    } = useSelector((state: any) =>
        state.styles
    )

    const {
        palette: {
            background,
            button,
            border,
        }
    } = theme

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    }: any = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const watchEmail = watch('email_id')
    const watchOldPassword = watch('old_password')
    const watchNewPassword = watch('new_password')
    const watchNewPasswordConfirmation = watch('new_password_confirmation')

    const [
        updateAccountSettings,
        { isLoading }
    ] = useUpdateAccountSettingsMutation()

    const emptyFields
        = () => {
            setValue('old_password', '')
            setValue('new_password', '')
            setValue('new_password_confirmation', '')
        }

    const handleUpdateAccountSettings
        = handleSubmit(async (
            data: any
        ) => {

            if (
                watchOldPassword !== ""
                &&
                (
                    watchNewPassword == ""
                    ||
                    watchNewPasswordConfirmation == ""
                )
            ) {
                toast.error('All passwords are required')
            } else if (
                (
                    watchNewPassword !== ""
                    ||
                    watchNewPasswordConfirmation !== ""
                )
                &&
                watchOldPassword == ""
            ) {
                toast.error('All passwords are required')
            } else if (
                watchNewPassword !== watchNewPasswordConfirmation
            ) {
                toast.error('New password must match')
            } else if (
                user?.email === watchEmail
                &&
                watchOldPassword == ""
            ) {
                return
            } else {

                const modifiedData = Object
                    ?.fromEntries(
                        Object
                            ?.entries(data)
                            ?.filter(([key, value]) => value !== '')
                    );

                const response
                    = await updateAccountSettings(modifiedData)

                const {
                    error,
                    data: respData
                }: any
                    = response || {}

                if (error)
                    toast.error(
                        error
                            ?.data
                            ?.errors
                            ?.email_id
                        ||
                        error
                            ?.data
                            ?.message
                    )

                if (respData) {
                    toast.success(
                        respData?.message
                    )
                    emptyFields()
                }
            }

        })

    useEffect(() => {
        setValue('email_id', user?.email)
    }, [])

    return (
        <>
            <Card>
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4
                            className="card-title">Account Settings
                        </h4>
                    </div>
                </div>
                <Card.Body>
                    <h4 className='fw-bold mb-3'>Change Email</h4>
                    <Row>
                        <Col md="3">
                            <h5>Email</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    {...register('email_id')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h4 className='fw-bold mb-3'>Change Password</h4>
                    <Row>
                        <Col md="3">
                            <h5>Current Password</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="password"
                                    placeholder="Current Password"
                                    {...register('old_password')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <h5>New Password</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    {...register('new_password')}
                                />
                            </Form.Group>
                            <p
                                className='text-danger'
                            >
                                {
                                    errors
                                        ?.new_password
                                        ?.message
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <h5>Confirm new Password</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm new Password"
                                    {...register('new_password_confirmation')}
                                />
                            </Form.Group>
                            <p
                                className='text-danger'
                            >
                                {
                                    errors
                                        ?.new_password_confirmation
                                        ?.message
                                }
                            </p>
                        </Col>
                    </Row>
                    <Button
                        disabled={isLoading}
                        variant="primary"
                        type="button"
                        className="float-end"
                        style={{
                            background:
                                button.background,
                            borderColor:
                                border.dark,
                        }}
                        onClick={handleUpdateAccountSettings}
                    >
                        Save Changes
                    </Button>
                </Card.Body>
            </Card>
        </>
    )
}

export default AccountSettingsForm