import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ItemImage01 from '../../../../assets/images/marketplace-1.png';
import {
  setChatType,
  setOpenChat,
  setProductDetail,
  setReceiverId,
} from '../../store/sharedSlice';
import { useGetMarketPlaceItemDetailsQuery } from 'src/app/screens/market-place/services/marketPlaceApi';
import { baseUrl, webBaseUrl } from 'src/app/config/types/base-url';
import { useState } from 'react';
import Loader from '../Loader/Loader';
import { toast } from 'react-hot-toast';

interface IItemDetailCard {
  edit?: boolean;
}

const ItemDetailCard = ({ edit }: IItemDetailCard) => {
  const { id, itemId } = useParams();

  const history = useNavigate();
  const dispatch = useDispatch();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);
  const { openChat } = useSelector((state: any) => state.shared);

  const {
    palette: { background, button, border },
  } = theme;

  const { data: getMarketPlaceItemDetails, isLoading } =
    useGetMarketPlaceItemDetailsQuery(id ? id : itemId);

  const { data: marketPlaceItemDetails } = getMarketPlaceItemDetails || {};

  const handleOpenChat = () => {
    dispatch(setProductDetail(marketPlaceItemDetails));
    dispatch(setOpenChat(true));
    dispatch(setChatType('marketplace'));
    dispatch(setReceiverId(marketPlaceItemDetails?.user_id));
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0
        ? prevIndex - 1
        : marketPlaceItemDetails?.attachments?.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < marketPlaceItemDetails?.attachments?.length - 1
        ? prevIndex + 1
        : 0
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${webBaseUrl}/item-detail/${id ? id : itemId}`
    );
    toast.success('Item link has been copied to be shared.');
  };

  const renderDetails = () => {
    return (
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div className="header-title">
              <h4
                className="card-title"
                style={{
                  fontWeight: 600,
                }}
              >
                {marketPlaceItemDetails?.title}
              </h4>
            </div>
            {edit && (
              <div
                style={{
                  boxShadow: '0 8px 16px rgba(0,0,0,.15)',
                  color: 'green',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => history(`/edit-item-detail/${itemId}`)}
              >
                <span className="material-symbols-outlined">edit_square</span>
              </div>
            )}
          </div>
        </div>
        <Row className="p-3">
          <Col
            md={3}
            sm={12}
            style={{
              fontWeight: 600,
            }}
          >
            <div
              className="position-relative"
              style={{
                height: '300px',
                marginRight: '32px',
              }}
            >
              {marketPlaceItemDetails &&
              marketPlaceItemDetails?.attachments?.length > 0 &&
              typeof marketPlaceItemDetails?.attachments != 'string' ? (
                <>
                  <span
                    className="material-symbols-outlined arrow"
                    onClick={handlePrevClick}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      cursor: 'pointer',
                      left: '8px',
                      bottom: '50%',
                      transform: 'translateY(-50%)',
                      width: '28px',
                      height: '28px',
                      borderRadius: '28px',
                      backgroundColor: 'white',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    chevron_left
                  </span>
                  {marketPlaceItemDetails?.attachments[currentImageIndex]
                    .fileExtension != 'mp4' ? (
                    <Image
                      src={
                        baseUrl +
                        '/storage/' +
                        marketPlaceItemDetails?.attachments[currentImageIndex]
                          .path
                      }
                      alt="Image"
                      className="img-fluid"
                      style={{
                        width: '100%',
                        height: '300px',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <video
                      controls
                      style={{
                        width: '100%',
                        height: '300px',
                      }}
                    >
                      <source
                        src={
                          baseUrl +
                          '/storage/' +
                          marketPlaceItemDetails?.attachments[currentImageIndex]
                            .path
                        }
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  <span
                    className="material-symbols-outlined arrow"
                    onClick={handleNextClick}
                    style={{
                      position: 'absolute',
                      cursor: 'pointer',
                      top: '50%',
                      right: '8px',
                      bottom: '50%',
                      transform: 'translateY(-50%)',
                      width: '28px',
                      height: '28px',
                      borderRadius: '28px',
                      backgroundColor: 'white',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    chevron_right
                  </span>
                </>
              ) : (
                <Image
                  src={marketPlaceItemDetails?.attachments}
                  alt="Image"
                  className="img-fluid"
                  style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                />
              )}
            </div>
          </Col>
          <Col md={7} sm={12}>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Title
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.title}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Currency
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.currency?.country}
                {' - '}
                {marketPlaceItemDetails?.currency?.currency}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Price
              </Col>
              <Col
                md={9}
                sm={12}
                style={{
                  color: '#50B5FF',
                }}
              >
                {marketPlaceItemDetails?.currency?.symbol}
                {marketPlaceItemDetails?.price}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Sports Industry
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.sports_industry?.title}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Sports Name
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.sport_name?.title}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Item Category
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.item_category?.title}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Condition
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.item_condition}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Description
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.description}
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                style={{
                  fontWeight: 600,
                }}
              >
                Location
              </Col>
              <Col md={9} sm={12}>
                {marketPlaceItemDetails?.location}
              </Col>
            </Row>
          </Col>
          <Col
            md={2}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              gap: 8,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 24,
              }}
            >
              <span
                className="material-symbols-outlined"
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleCopyLink}
              >
                share
              </span>
              {marketPlaceItemDetails?.user_id !== user?.uid && (
                <Button
                  variant="primary"
                  type="button"
                  className="float-end"
                  onClick={handleOpenChat}
                  style={{
                    background: button.background,
                    borderColor: border.dark,
                  }}
                >
                  Contact Me
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  return <>{!isLoading ? renderDetails() : <Loader />}</>;
};

export default ItemDetailCard;
