import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    email_validator,
    password_validator,
    policy_checkbox_validator
} from "src/app/config/types/validators"
import {
    object
} from "yup"

const loginSchema = object().shape({
    email: email_validator,
    password: password_validator,
    terms_and_conditions: policy_checkbox_validator('Terms and Conditions'),
})

export default yupResolver(loginSchema)