import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import setting from '../screens/shared/store/setting/reducers';
import styles from '../styles/store/stylesSlice';
import shared from '../screens/shared/store/sharedSlice';
import { api } from '../services';
import { persistReducer } from 'redux-persist';
import storage from './storage';
import auth from '../screens/auth/store/authSlice';

const combinedReducer = combineReducers({
  auth,
  shared,
  setting,
  styles,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/setLogout') {
    const { styles: currentStyles } = state;
    state = {
      styles: currentStyles
    };
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'styles'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export type IRootState = ReturnType<typeof rootReducer>;
