import ReactAvatar from 'react-avatar';

type AvatarType = {
  pfp?: string;
  changePfp?: any;
  name?: string;
  size?: string;
  style?: any;
};

function Avatar({ size = '130', pfp, changePfp, name, style }: AvatarType) {
  return (
    <div
      style={{
        position: 'relative',
        border: '0px solid red',
        width: `${size}px`,
        margin: '0 auto',
        ...style,
      }}
    >
      <ReactAvatar
        size={size}
        round
        src={pfp}
        name={name}
        textMarginRatio={0.15}
      />
      {changePfp && (
        <span
          onClick={changePfp}
          style={{
            border: '0px solid red',
            position: 'absolute',
            bottom: 5,
            right: 5,
            background: '#0008',
            borderRadius: '50%',
            padding: '5px',
            cursor: 'pointer',
          }}
          className="material-symbols-outlined"
        >
          edit
        </span>
      )}
    </div>
  );
}

export default Avatar;
