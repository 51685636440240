import moment from 'moment';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserDetailsQuery } from 'src/app/screens/profile/services/profileApi';

const ProfileAboutCard = () => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { id } = useParams();

  const { data: getUserDetails = {}, isLoading } = useGetUserDetailsQuery(id);
  const { data: userDetails = {} } = getUserDetails;

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">About</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex gap-1 align-items-center mb-1">
            <span className="material-symbols-outlined">person</span>
            <span>{userDetails?.personal_info?.roles?.title}</span>
          </div>
          <div className="d-flex gap-1 align-items-center  mb-1">
            <span className="material-symbols-outlined">location_on</span>
            <span>{userDetails?.personal_info?.city}</span>
          </div>
          <div className="d-flex gap-1 align-items-center  mb-1">
            <span className="material-symbols-outlined">calendar_month</span>
            <span>
              {moment(userDetails?.personal_info?.join_date).format(
                'MMMM Do YYYY'
              )}
            </span>
          </div>
          <div className="mt-2">
            <div className="d-grid">
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={() =>
                  history(
                    `/user-profile/about/${userDetails?.personal_info?.uid}`
                  )
                }
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                View Details
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileAboutCard;
