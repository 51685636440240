import {
  Container
} from 'react-bootstrap'
import {
  Outlet
} from 'react-router-dom'

const ChatLayout = () => {
  return (
    <>
      <main className="main-content">
        <Container
          fluid
          className="content-inner p-0"
          id="page_layout"
        >
          <Outlet />
        </Container>
      </main>
    </>
  )
}

export default ChatLayout