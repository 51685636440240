import { createSlice } from '@reduxjs/toolkit';
import { stylesLightTheme } from '../../config/constants/slices/styles.light';
import { addClass, removeClass } from 'src/app/config/utilities/dom';
import { stylesDarkTheme } from 'src/app/config/constants/slices/styles.dark';

const stylesSlice = createSlice({
  name: 'styles',
  initialState: {
    theme: stylesDarkTheme,
    mode: 'dark',
  },
  reducers: {
    setMode: (state: any, { payload }) => {
      state.mode = payload;
      addClass('body', payload);
      removeClass('body', payload === 'dark' ? 'light' : 'dark');
      state.theme = payload === 'dark' ? stylesDarkTheme : stylesLightTheme;
    },
  },
});

export const { setMode } = stylesSlice.actions;

export default stylesSlice.reducer;
