import React from 'react';

const LinkifyText = ({ text }: any) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = text?.split(urlRegex);

  const elements = parts.map((part: any, index: any) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      return <span key={index}>{part}</span>;
    }
  });

  return <>{elements}</>;
};

export default LinkifyText;
