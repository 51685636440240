import team1 from "../assets/images/t1.jpg"
import team2 from "../assets/images/t2.jpg"
import team3 from "../assets/images/t3.jpg"
import team4 from "../assets/images/t4.jpg"
import team5 from "../assets/images/t5.jpg"
import team6 from "../assets/images/t6.jpg"

export const photosData = [
    team1,
    team2,
    team3,
    team4,
    team5,
    team6,
]