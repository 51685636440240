import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  GroupTypeEnum,
  GroupUserStatusEnum,
} from 'src/app/config/constants/enums';
import { capitlizeFirstLetter, truncateText } from 'src/app/config/helper';
import Avatar from 'src/app/screens/shared/components/Avatar';
import {
  useAddGroupRequestMutation,
  useLeaveGroupMutation,
  useRejectGroupRequestsMutation,
} from '../../services/groupsApi';

type GroupsMiniCardType = {
  id: number;
  image: string;
  name: string;
  description: string;
  groupUserStatus: GroupUserStatusEnum | '';
  groupType: string | GroupTypeEnum;
  ownerId: number;
  sportIndustry?: string;
};

const GroupsMiniCard = ({
  id,
  image,
  name,
  description,
  groupUserStatus,
  groupType,
  ownerId,
  sportIndustry,
}: GroupsMiniCardType) => {
  const [addGroupRequest, { isLoading: addingRequest }] =
    useAddGroupRequestMutation();
  const [leaveGroup, { isLoading: addingLeaveGroup }] = useLeaveGroupMutation();
  const [rejectGroupRequests, { isLoading: addingRejectGroupRequests }] =
    useRejectGroupRequestsMutation();

  const { user } = useSelector((state: any) => state.auth);
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const handleClick = async () => {
    let response;

    if (!groupUserStatus)
      response = await addGroupRequest({
        body: {
          group_id: id,
          creator_id: ownerId,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Requested)
      response = await rejectGroupRequests({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });
    else if (groupUserStatus == GroupUserStatusEnum.Joined)
      response = await leaveGroup({
        body: {
          group_id: id,
          member_id: user?.uid,
        },
      });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      <li className="d-flex mb-3 align-items-center flex-wrap">
        <Avatar size="40" name={name} pfp={image} />
        <div className="ms-2 flex-grow-1">
          <h6 className="d-flex align-items-center">
            {truncateText(name, 20)}
          </h6>
          <small>{truncateText(sportIndustry, 30)}</small>
        </div>
        {groupUserStatus != GroupUserStatusEnum.Owner && (
          <Button
            variant="primary"
            type="button"
            className="float-end"
            disabled={
              addingRequest || addingLeaveGroup || addingRejectGroupRequests
            }
            onClick={handleClick}
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
          >
            {groupUserStatus == GroupUserStatusEnum.Requested &&
              'Cancel Request'}

            {groupUserStatus == GroupUserStatusEnum.Joined && 'Leave Group'}

            {!groupUserStatus &&
              (capitlizeFirstLetter(groupType) == GroupTypeEnum.Private
                ? 'Request'
                : 'Join')}
          </Button>
        )}
      </li>
    </>
  );
};

export default GroupsMiniCard;
