import { Col, Row } from 'react-bootstrap';
import ProfileContainer from '../shared/components/ProfileContainer/ProfileContainer';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';
import { userprofileData } from 'src/app/mock/userProfile';
import AuthGuard from 'src/app/config/auth/AuthGuard';

const Connections = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfileHeader
                activeLink="connections"
                loggedInUserProfile={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <ProfileContainer
                title="Connections"
                linkName="Pending Requests"
                link="/profile/pending-requests"
                data={userprofileData}
                type="connection"
              />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default Connections;
