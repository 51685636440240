import { useState } from 'react';
import Avatar from '../Avatar';
import moment from 'moment';

interface IPostCommentList {
  comments?: {
    image?: string;
    name?: string;
    message?: string;
  }[];
}

const PostCommentList = ({ comments }: IPostCommentList) => {
  const [showAllComments, setShowAllComments] = useState(false);

  const toggleCommentsVisibility = () => {
    setShowAllComments(!showAllComments);
  };

  const renderComments = () => {
    return (
      <>
        {comments?.slice(0, showAllComments ? comments.length : 1).map?.(
          (
            {
              image,
              created_at,
              user: {
                personal_information: { username, name, imagepath },
                name: userObjName,
                username: userObjUsername,
              },
              comment,
            }: any,
            index
          ) => {
            return (
              <li key={index} className="mb-2">
                <div className="d-flex">
                  <div className="user-img">
                    <Avatar size="35" name={username} pfp={imagepath} />
                  </div>
                  <div className="comment-data-block ms-3">
                    <div className="d-flex gap-2 align-items-center">
                      <h6>
                        {name || userObjName || username || userObjUsername}
                      </h6>
                      <p className="mb-0 opacity-50">
                        {moment.utc(created_at).local().fromNow()}
                      </p>
                    </div>
                    <p
                      className="mb-0"
                      style={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {comment}
                    </p>
                    {image && (
                      <img
                        loading="lazy"
                        src={`${image}`}
                        alt="post"
                        className="img-fluid w-25 mt-2"
                      />
                    )}
                  </div>
                </div>
              </li>
            );
          }
        )}
      </>
    );
  };

  return (
    <>
      <ul
        className="post-comments p-0 m-0 mt-3"
        style={{
          maxHeight: showAllComments ? '300px' : 'none',
          overflowY: 'auto',
        }}
      >
        {renderComments()}
      </ul>
      {comments && comments?.length > 1 && (
        <p
          style={{ color: '#50B5FF', cursor: 'pointer', paddingTop: '4px' }}
          onClick={toggleCommentsVisibility}
        >
          {showAllComments ? 'View Less' : 'View More'}
        </p>
      )}
    </>
  );
};

export default PostCommentList;
