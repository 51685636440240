import React from 'react';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { useStaticPagesQuery } from './services/authApi';
import { ReactComponent as LogoDark } from '../../../assets/icons/logo-dark.svg';
import { ReactComponent as LogoLight } from '../../../assets/icons/logo-light.svg';
import { Container, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const PrivacyPolicy = () => {
  const { data: getStaticPages } = useStaticPagesQuery({});
  const { data: staticPages } = getStaticPages || {};

  const { theme, mode } = useSelector((state: any) => state.styles);

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
          style={{
            border: '0px solid red',
          }}
        >
          <Container
            fluid
            className="navbar-inner"
            style={{
              border: '0px solid red',
              padding: '5px 20px',
            }}
          >
            <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
              <Link
                to="/auth/sign-up"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                {mode == 'dark' ? (
                  <LogoDark height="64px" width="282px" />
                ) : (
                  <LogoLight height="64px" width="282px" />
                )}
              </Link>
            </div>
          </Container>
        </Nav>
      </div>
      <AuthGuard>
        {staticPages?.map((data: any) => {
          const { title, description } = data;
          return (
            <div
              style={{
                padding: '16px 48px',
              }}
            >
              <h1>{title}</h1>
              <>{ReactHtmlParser(description)}</>
            </div>
          );
        })}
      </AuthGuard>
    </>
  );
};

export default PrivacyPolicy;
