import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import GroupsMiniCard from '../GroupsMiniCard';

type GroupsListType = {
  title: string;
  groups: {
    image: string;
    name: string;
    description: string;
  }[];
  seeAllLink: string;
};

const GroupsList = ({ title, groups, seeAllLink }: GroupsListType) => {
  console.log("🚀 ~ GroupsList ~ groups:", groups)
  const history = useNavigate();

  const renderGroups = groups?.map?.((item: any, key: number) => {
    const {
      description,
      name,
      profile_image,
      group_user_status,
      group_type,
      id,
      user_id,
      sport_industry
    } = item?.group || item || {};

    return (
      <GroupsMiniCard
        id={id}
        key={key}
        image={baseUrl + '/' + profile_image}
        name={name}
        description={description}
        groupUserStatus={group_user_status || item?.group_user_status}
        groupType={group_type}
        ownerId={user_id}
        sportIndustry={sport_industry?.title}
      />
    );
  });

  if (!(groups?.length > 0)) return null;

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{title}</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <ul className="list-inline m-0 p-0">{renderGroups}</ul>
          <div
            onClick={() => history(seeAllLink)}
            style={{ border: '0px solid red', cursor: 'pointer' }}
            className="text-center mt-2"
          >
            <small>SEE ALL</small>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GroupsList;
