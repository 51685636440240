import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import supportFormSchema from './schema/supportForm.schema';
import { useAddSupportMutation } from 'src/app/screens/profile/services/profileApi';
import { toast } from 'react-hot-toast';

const SupportForm = () => {

    const defaultValues = {
        subject: '',
        type: '',
        description: '',
        attachment: {},
    }

    const history
        = useNavigate()

    const {
        theme,
    } = useSelector((state: any) =>
        state.styles
    )

    const {
        palette: {
            background,
            button,
            border,
        }
    } = theme

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    }: any = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: supportFormSchema
    });

    const [
        addSupport,
        { isLoading }
    ] = useAddSupportMutation()

    const emptyFields
        = () => {
            setValue('subject', '')
            setValue('type', '')
            setValue('description', '')
            setValue('attachment', {})
        }

    const handleAddSupport
        = handleSubmit(async (
            data: any
        ) => {

            const formData = new FormData()

            formData.append('subject', data?.subject)
            formData.append('type', data?.type)
            formData.append('description', data?.description)
            formData.append('attachment', data?.attachment[0])

            const response
                = await addSupport(formData)

            const {
                error,
                data: respData
            }: any
                = response || {}

            if (error)
                toast.error(
                    error
                        ?.data
                        ?.errors
                        ?.email
                )

            if (respData) {
                toast.success(
                    respData?.message
                )
                emptyFields()
            }
        })

    return (
        <>
            <Card>
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4
                            className="card-title">Support
                        </h4>
                    </div>
                </div>
                <Card.Body>
                    <Row>
                        <Col md="3">
                            <h5>Subject</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="text"
                                    placeholder="Subject"
                                    {...register('subject')}
                                />
                            </Form.Group>
                            <p
                                className='text-danger'
                            >
                                {
                                    errors
                                        ?.subject
                                        ?.message
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <h5>Type</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="text"
                                    placeholder="Type"
                                    {...register('type')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <h5>Description</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group className="form-group">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder='Description'
                                    {...register('description')}
                                />
                            </Form.Group>
                            <p
                                className='text-danger'
                            >
                                {
                                    errors
                                        ?.description
                                        ?.message
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <h5>Attachment</h5>
                        </Col>
                        <Col md="9">
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control
                                    type="file"
                                    {...register('attachment')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button
                        disabled={isLoading}
                        variant="primary"
                        type="button"
                        className="float-end"
                        style={{
                            background:
                                button.background,
                            borderColor:
                                border.dark,
                        }}
                        onClick={handleAddSupport}
                    >
                        Submit
                    </Button>
                </Card.Body>
            </Card>
        </>
    )
}

export default SupportForm