import { yupResolver } from '@hookform/resolvers/yup';
import {
  custom_message_file_required_validator,
  custom_message_required_validator,
  dropdown_validator
} from 'src/app/config/types/validators';
import { object } from 'yup';

const createGroupSchema: any = object().shape({
  name: custom_message_required_validator('Please enter a group name.'),
  sports_industry_id: dropdown_validator('sports industry'),
  sports_category_id: dropdown_validator('sports category'),
  sports_name_id: dropdown_validator('sports name'),
  location: custom_message_required_validator('Please enter location.'),
  description: custom_message_required_validator('Description is required.'),
  rules: custom_message_required_validator(
    'Please define rules for this group.'
  ),
  group_type: custom_message_required_validator(
    'Please select a type for this group.'
  ),
  profile_image: custom_message_file_required_validator(
    'Please add profile picture for this group.'
  ),
});

export default yupResolver(createGroupSchema);
