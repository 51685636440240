import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetUserDetailsQuery } from 'src/app/screens/profile/services/profileApi';
import Avatar from '../Avatar/Avatar';

const CompanyDetailCard = () => {
  const { id } = useParams();

  const history = useNavigate();
  const { pathname } = useLocation();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { button, border },
  } = theme;

  const { data: getUserPersonalInformation, isLoading } =
    useGetUserDetailsQuery(id ? id : user?.uid);

  const { data: userPersonalInformation } = getUserPersonalInformation || {};

  const {
    logo,
    company_name,
    website,
    description,
    business_size,
    sports_industry,
  } = userPersonalInformation?.personal_info || {};

  const renderData = () => {
    return (
      <Card className="p-3">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar pfp={logo} size="100" name={company_name} />
          <h5>{company_name}</h5>
          <p
            style={{
              color: '#ABD5FF',
            }}
          >
            {website}
          </p>
          <p className="text-center">{description}</p>
          <div
            style={{
              background: '#F3F3F3',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              gap: 4,
              marginTop: '16px',
            }}
          >
            <p className="mb-0 fw-bold">Sports Industry:</p>
            <p className="mb-0">{sports_industry?.title}</p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 4,
            }}
          >
            <p className="mb-0 fw-bold">Organization Size:</p>
            <p className="mb-0">{business_size?.title}</p>
          </div>
          {(id == user?.uid || pathname?.includes('my-items')) && (
            <Button
              variant="primary"
              type="button"
              className="float-end mt-4"
              style={{
                background: button.background,
                borderColor: border.dark,
                paddingRight: '32px',
                paddingLeft: '32px',
                width: '100%',
              }}
              onClick={() => history('/add-item')}
            >
              Add Item
            </Button>
          )}
        </div>
      </Card>
    );
  };
  return <>{!isLoading && renderData()}</>;
};

export default CompanyDetailCard;
