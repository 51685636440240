export const stylesLightTheme
    = {
    palette: {
        background: {
            dark: '#000000',
            light: '#E8E8E8',
            blue: '#283645',
            search: '#F5F5F5',
            tag: '#E8E8E8',
            icon: '#000000'
        },
        button: {
            background: '#000000',
            info: '#007BFF',
            success: '#28A745',
            join: '#00BC4E',
            cancelRequest: '#FF0000',
            requested: '#00A5D3',
            other: '#3f37c9',
            admin: '#FF8800',
        },
        border: {
            dark: '#000000',
            divider: '#F3F3F3',
        },
        textInput: {
            border: '#CED4DA',
            background: '#F5F5F5'
        },
        card: {
            background: '#FFFFFF',
            mainBackground: '#19191A',
        },
        chat: {
            sender: '#50B5FF',
            receiver: '#E4E4E4',
        }
    }
}