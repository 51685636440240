import { Card, Col, Row } from 'react-bootstrap';
import MarketPlaceCard from '../MarketPlaceCard/MarketPlaceCard';

type MarketPlaceItemsType = {
  data?: any;
  col?: any;
};

const MarketPlaceItems = ({ data, col = 3 }: MarketPlaceItemsType) => {
  const renderData = () => {
    return data?.map?.((data: any) => {
      const {
        id,
        attachments,
        title,
        description,
        price,
        location,
        logo,
        company_name,
        user_id,
        company_id,
        currency,
      } = data;
      return (
        <Col
          key={id}
          md={6}
          lg={col}
          sm={6}
          xs={12}
          style={{
            marginBottom: '10px',
          }}
        >
          <MarketPlaceCard
            id={id}
            userId={user_id}
            companyId={company_id}
            image={attachments ? attachments : logo}
            name={title ? title : company_name}
            description={description}
            price={price}
            location={location}
            currency={currency}
          />
        </Col>
      );
    });
  };
  return (
    <>
      <Row>{renderData()}</Row>
    </>
  );
};

export default MarketPlaceItems;
