import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { baseUrl } from 'src/app/config/types/base-url';
import groupBgImage from '../../assets/images/profile-bg.jpg';
import Avatar from '../shared/components/Avatar';
import Loader from '../shared/components/Loader';
import NoData from '../shared/components/NoData';
import ProfilePageHeader from '../shared/components/ProfilePageHeader';
import {
  useAcceptGroupRequestsMutation,
  useGetGroupQuery,
  useGetGroupRequestsQuery,
  useRejectGroupRequestsMutation,
} from './services/groupsApi';
import cls from 'classnames';
import toast from 'react-hot-toast';

const GroupRequests = () => {
  const { id } = useParams();

  const [acceptGroupRequests, { isLoading: acceptingRequest }] =
    useAcceptGroupRequestsMutation();
  const [rejectGroupRequests, { isLoading: rejectingRequest }] =
    useRejectGroupRequestsMutation();

  const { data, isLoading } = useGetGroupRequestsQuery({ groupId: id });
  const { data: groupData }: any = useGetGroupQuery({ groupId: id });

  const { background_image } = groupData?.data || {};

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const handleAccept = async (id: number) => {
    let response;

    response = await acceptGroupRequests({
      requestId: id,
    });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email || error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  const handleReject = async (id: number, group_id: number) => {
    let response;

    response = await rejectGroupRequests({
      body: {
        group_id,
        member_id: id,
      },
    });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email || error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  const renderData = data?.data?.data?.map(
    ({
      id: requestId,
      group_id,
      member_user: { id, profile_url, username, connections },
    }: any) => {
      return (
        <li
          key={id}
          className={cls(
            'd-flex',
            'align-items-center',
            'justify-content-between',
            'flex-wrap'
          )}
        >
          <div className="user-img img-fluid flex-shrink-0">
            <Avatar
              size="50"
              pfp={baseUrl + '/' + profile_url}
              name={username}
            />
          </div>
          <div className="flex-grow-1 ms-3">
            <h6>{username}</h6>
            <p className="mb-0">{connections?.length} friends</p>
          </div>
          <div className="d-flex align-items-center gap-2 mt-2 mt-md-0">
            <>
              <Button
                disabled={acceptingRequest || rejectingRequest}
                variant="primary"
                type="button"
                className="float-end"
                onClick={() => handleAccept(requestId)}
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                Accept
              </Button>
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={() => handleReject(id, group_id)}
                style={{
                  background: 'transparent',
                  borderColor: border.dark,
                  color: border.dark,
                }}
              >
                Reject
              </Button>
            </>
          </div>
        </li>
      );
    }
  );

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader
                image={baseUrl + '/' + background_image}
                title="Groups"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">{'Group Requests'}</h4>
                  </div>
                </div>
                <Card.Body>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <ul className="request-list list-inline m-0 p-0">
                      {data?.data?.data?.length > 0 ? (
                        renderData
                      ) : (
                        <NoData name="requests" />
                      )}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default GroupRequests;
