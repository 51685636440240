import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import groupBgImage from '../../assets/images/profile-bg2.jpg';
import JobCard from '../shared/components/JobCard/JobCard';
import JobsFilter from '../shared/components/JobsFilter/JobsFilter';
import JobsHeader from '../shared/components/JobsHeader/JobsHeader';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import Advertisement from '../shared/components/advertisement/Advertisement';
import useDebounce from 'src/app/hooks/useDebounce';

const Jobs = () => {
  const defaultValues = {
    sport_industry_id: 0,
    role_id: 0,
    sport_category_id: 0,
    sport_name_id: 0,
    sports_name_sub_category_id: 0,
    location: '',
    job_title: '',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchSportIndustryId = watch('sport_industry_id');
  const watchSportCategoryId = watch('sport_category_id');
  const watchRoleId = watch('role_id');
  const watchSportNameId = watch('sport_name_id');
  const watchSportNameSubCategoryId = watch('sports_name_sub_category_id');

  const { search: searchLocation, handleSearch: handleSearchLocation } =
    useDebounce();
  const { search: searchJobTitle, handleSearch: handleSearchJobTitle } =
    useDebounce();

  const handleFindJobs = handleSubmit(async (data: any) => {
    console.log('🚀 ~ file: JobsFilter.tsx:158 ~ handleFindJobs ~ data:', data);
  });

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Jobs" image={groupBgImage} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10} className="mt-3">
              <JobsFilter
                register={register}
                handleSubmit={handleSubmit}
                setValue={setValue}
                watch={watch}
                handleFindJobs={handleFindJobs}
                handleSearchLocation={handleSearchLocation}
                handleSearchJobTitle={handleSearchJobTitle}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <JobsHeader activeLink="/jobs" />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Find a Job</h4>
                  </div>
                </div>
                <Card.Body>
                  <Row>
                    <JobCard
                      filterJob={true}
                      sportIndustryId={watchSportIndustryId}
                      roleId={watchRoleId}
                      sportCategoryId={watchSportCategoryId}
                      sportNameId={watchSportNameId}
                      sportNameSubCategoryId={watchSportNameSubCategoryId}
                      location={searchLocation}
                      jobTitle={searchJobTitle}
                    />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default Jobs;
