import { GoogleOAuthProvider } from '@react-oauth/google';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import App from './App';
import { ChatRouter } from './app/config/routes/chat-router';
import { IndexRouters } from './app/config/routes/index.js';
import { SimpleRouter } from './app/config/routes/simple-router';
import { store } from './app/store';

const router = createBrowserRouter([
  ...IndexRouters,
  ...SimpleRouter,
  ...ChatRouter,
]);

let persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    <StrictMode>
      <Provider store={store}>
        {/* //! clientId should be in .env */}
        <GoogleOAuthProvider clientId="910014539083-5fuufnv4v9b92ki74j0c9tvv9kmqcsih.apps.googleusercontent.com">
          <App>
            <RouterProvider router={router}></RouterProvider>
          </App>
        </GoogleOAuthProvider>
      </Provider>
    </StrictMode>
    <Toaster position="top-right" />
  </>
);
