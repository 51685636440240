import { useState } from 'react';
import { Card, Container, Dropdown, Form, Nav } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
  useChangeStatusMutation,
  useLazyLogoutQuery,
} from 'src/app/screens/auth/services/authApi';
import { setLogout, setUser } from 'src/app/screens/auth/store/authSlice';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import { setMode } from 'src/app/styles/store/stylesSlice';
import { ReactComponent as LogoDark } from '../../../../../assets/icons/logo-dark.svg';
import { ReactComponent as LogoLight } from '../../../../../assets/icons/logo-light.svg';
import Avatar from '../Avatar/Avatar';
import CustomToggle from '../dropdowns';
import GlobalSearch from './components/GlobalSearch';
import NotificationPopup from './components/NotificationPopup';
import './header.css';

const Header = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { theme, mode } = useSelector((state) => state.styles);

  const { user } = useSelector((state) => state.auth);

  const {
    palette: { textInput },
  } = theme;

  const [show, setShow] = useState(false);
  const [darkMode, setDarkMode] = useState('dark');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [logout] = useLazyLogoutQuery();

  const [changeStatus, { isLoading }] = useChangeStatusMutation();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data = {} } = getPersonalInformation;

  const handleModeToggle = (mode) => {
    setDarkMode(mode);
    dispatch(setMode(mode));
  };

  const handleChangeStatus = async () => {
    dispatch(
      setUser({
        ...user,
        active_status: user?.active_status == '0' ? '1' : '0',
      })
    );
    await changeStatus();
  };

  const handleSignOut = async () => {
    const response = await logout({});

    const { error, data: respData } = response || {};

    if (error) {
      dispatch(setLogout(null));
      history('/auth/sign-in');
    }

    if (respData) {
      dispatch(setLogout(null));
      history('/auth/sign-in');
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
          style={{
            border: '0px solid red',
          }}
        >
          <Container
            fluid
            className="navbar-inner"
            style={{
              border: '0px solid red',
              padding: '5px 20px',
            }}
          >
            <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                {mode == 'dark' ? (
                  <LogoDark height="64px" width="282px" />
                ) : (
                  <LogoLight height="64px" width="282px" />
                )}
              </Link>
            </div>

            <GlobalSearch />

            <ul className="navbar-nav navbar-list">
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Home"
              >
                <NavLink
                  to="/"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">home</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Groups"
              >
                <NavLink
                  to="/groups"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">group</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Jobs"
              >
                <NavLink
                  to="/jobs"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">
                    business_center
                  </span>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Chat"
              >
                <NavLink
                  to="/chat"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">forum</span>
                </NavLink>
              </Nav.Item>
              <NotificationPopup />
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Advertisement"
              >
                <NavLink
                  to="/post-advertisement"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: '38px',
                    }}
                  >
                    campaign
                  </span>
                </NavLink>
              </Nav.Item>
              <Nav.Item
                as="li"
                data-toggle="tooltip"
                data-placement="top"
                title="Marketplace"
              >
                <NavLink
                  to="/market-place"
                  className="top-nav-bar d-flex align-items-center"
                >
                  <span className="material-symbols-outlined">storefront</span>
                </NavLink>
              </Nav.Item>

              <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item>

              <Dropdown
                as="li"
                className="nav-item user-dropdown"
                show={dropdownOpen}
                onToggle={handleDropdownToggle}
              >
                <Dropdown.Toggle
                  href="#"
                  id="dropdown-autoclose-true"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  {data?.profile_image && (
                    <div
                      className={
                        user?.active_status == '1'
                          ? `iq-profile-avatar status-online custom-profile-avatar`
                          : 'custom-profile-avatar'
                      }
                    >
                      <Avatar
                        pfp={data?.profile_image}
                        size="40"
                        name={data?.username}
                        style={{
                          lineHeight: '40px',
                        }}
                      />
                    </div>
                  )}
                  <div className="caption d-none d-lg-block ms-2">
                    <h6
                      className="mb-0"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {data?.full_name || user?.username}
                    </h6>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="sub-drop caption-menu custom-avatar-popup"
                  style={{
                    boxShadow: '0 15px 30px -5px rgba(0,0,0,.15)',
                    // marginTop: '85px',
                  }}
                >
                  <Card className="shadow-none m-0">
                    <Card.Header>{/* Your header content */}</Card.Header>
                    <Card.Body className="p-0 ">
                      <Link to="/profile" className="mb-0 h6">
                        <div
                          className="d-flex align-items-center iq-sub-card border-0"
                          onClick={() => {
                            setDropdownOpen(false);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            line_style
                          </span>
                          <div className="ms-3">My Timeline</div>
                        </div>
                      </Link>
                      <Link to="/profile/support" className="mb-0 h6">
                        <div
                          className="d-flex align-items-center iq-sub-card border-0"
                          onClick={() => {
                            setDropdownOpen(false);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            support_agent
                          </span>
                          <div className="ms-3">Support</div>
                        </div>
                      </Link>
                      <Link to="/profile/account-settings" className="mb-0 h6">
                        <div
                          className="d-flex align-items-center iq-sub-card border-0"
                          onClick={() => {
                            setDropdownOpen(false);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            settings
                          </span>
                          <div className="ms-3">Account Setting</div>
                        </div>
                      </Link>
                      <div
                        className="d-flex align-items-center iq-sub-card"
                        onClick={handleSignOut}
                      >
                        <span className="material-symbols-outlined">login</span>
                        <div className="ms-3">
                          <p className="mb-0 h6">Sign out</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          dark_mode
                        </span>
                        <p className="mb-0 ms-3">Status</p>
                        <div className="ms-3">
                          <Form.Check
                            type="switch"
                            checked={user?.active_status == '0' ? false : true}
                            onChange={handleChangeStatus}
                            disabled={isLoading}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          dark_mode
                        </span>
                        <p className="mb-0 ms-3">Theme</p>
                        <div className="ms-3">
                          <Form.Check
                            type="switch"
                            checked={mode === 'dark'}
                            onChange={() =>
                              handleModeToggle(
                                mode === 'dark' ? 'light' : 'dark'
                              )
                            }
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
        </Nav>
      </div>
    </>
  );
};

export default Header;
