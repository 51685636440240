import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import ProfileHeader from '../shared/components/ProfileHeader';
import CreatePost from '../shared/components/CreatePost';
import { postsData } from 'src/app/mock/posts';
import Post from '../shared/components/Post';
import FollowersCard from '../shared/components/FollowersCard';
import { followersData } from 'src/app/mock/followers';
import PhotoCard from '../shared/components/PhotoCard';
import { photosData } from 'src/app/mock/photos';
import ProfileIntroCard from '../shared/components/ProfileIntroCard';
import TeamCard from '../shared/components/TeamCard';
import { teamsData } from 'src/app/mock/teams';
import MoreOptions from '../shared/components/MoreOptions';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import Advertisement from '../shared/components/advertisement/Advertisement';
import { toast } from 'react-hot-toast';
import {
  useGetPersonalInformationQuery,
  useUpdateBgImageMutation,
} from './services/profileApi';

const Profile = () => {
  const ref = useRef(null);
  const [bgImage, setBgImage] = useState({});

  const [updateBgImage, { isLoading }] = useUpdateBgImageMutation();
  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { full_name, username } = personalInformation || {};

  const handleChangeBackground = () => {
    ref.current?.click();
  };

  const handeUpdateBgImage = async () => {
    const formData = new FormData();
    formData.append('background_image', bgImage);
    const response = await updateBgImage(formData);

    const { error, data: respData } = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      setBgImage({});
    }
  };

  useEffect(() => {
    bgImage?.name && handeUpdateBgImage();
  }, [bgImage?.name]);

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader
                activeLink="profile"
                loggedInUserProfile={true}
                changeBackground={handleChangeBackground}
              />
            </Col>
            <input
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              ref={(el) => (ref.current = el)}
              type="file"
              name="background_image"
              onChange={() => setBgImage(ref.current.files[0])}
            />
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={7}>
              <CreatePost
                placeholder={`What's your game plan, ${full_name || username}`}
              />
              <Post profile />
            </Col>
            <Col sm={12} md={12} lg={3}>
              <ProfileIntroCard />
              <PhotoCard
                photos={photosData}
                loggedInUserProfile={true}
                redirectTo="/profile/photos"
                style={{
                  height: '70px',
                  objectFit: 'cover',
                }}
              />
              <FollowersCard followers={followersData} />
              <TeamCard
                teams={teamsData}
                loggedInUserProfile={false}
                profile
                redirectTo="/team-details"
                style={{
                  height: '70px',
                  objectFit: 'cover',
                }}
              />
            </Col>
          </Row>
        </div>

        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default Profile;
