import PostComment from './PostComment';
import PostCommentField from './PostCommentField';
import PostCommentList from './PostCommentList';
import PostLike from './PostLike';
import PostShare from './PostShare';

type PostType = {
  postId?: string;
  sharePostId?: string;
  totalLikes?: string;
  totalComments?: string;
  image?: string;
  isCommentsAllowed?: boolean;
  isLiked?: boolean;
  comments?: {
    image?: string;
    name?: string;
    message?: string;
  }[];
};

const PostFooter = ({
  postId,
  sharePostId,
  totalLikes,
  totalComments,
  comments,
  isCommentsAllowed,
  isLiked,
}: PostType) => {
  return (
    <>
      <div className="comment-area mt-3">
        <div className="d-flex gap-5 align-items-center">
          <PostLike totalLikes={totalLikes} postId={postId} isLiked={isLiked} />
          {!isCommentsAllowed && <PostComment totalComments={totalComments} />}
          <PostShare postId={sharePostId} />
        </div>
        <PostCommentList comments={comments} />
        {!isCommentsAllowed && <PostCommentField postId={postId} />}
      </div>
    </>
  );
};

export default PostFooter;
