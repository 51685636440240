import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import groupBgImage from '../../assets/images/profile-bg2.jpg';
import JobDetailCard from '../shared/components/JobDetailCard/JobDetailCard';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';

const JobDetails = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Jobs" image={groupBgImage} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10} className="mt-3">
              <JobDetailCard />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default JobDetails;
