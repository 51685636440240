import { Card, Col, Dropdown, Image } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { webBaseUrl } from 'src/app/config/types/base-url';
import {
  useAllJobsQuery,
  useAppliedJobsQuery,
  useFilterJobQuery,
  useIgnoreJobMutation,
  usePostedJobsQuery,
} from 'src/app/screens/jobs/services/jobsAPI';
import image1 from '../../../../assets/images/job-icon-2.png';
import MoreOptions from '../MoreOptions/MoreOptions';

interface IJobCard {
  jobHistory?: boolean;
  findJob?: boolean;
  filterJob?: boolean;
  appliedJobs?: boolean;
  postedJobs?: boolean;
  sportIndustryId?: any;
  roleId?: any;
  sportCategoryId?: any;
  sportNameId?: any;
  sportNameSubCategoryId?: any;
  location?: any;
  jobTitle?: any;
}

const JobCard = ({
  jobHistory,
  appliedJobs,
  postedJobs,
  findJob,
  filterJob,
  sportIndustryId,
  roleId,
  sportCategoryId,
  sportNameId,
  sportNameSubCategoryId,
  location,
  jobTitle,
}: IJobCard) => {
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, card },
  } = theme;

  const { data: allJobs = {}, isLoading: gettingAllJobs } = useAllJobsQuery(
    {},
    {
      skip: !findJob,
    }
  );
  const { data: allAppliedJobs = {}, isLoading: gettingAppliedJobs } =
    useAppliedJobsQuery(
      {},
      {
        skip: !appliedJobs,
      }
    );
  const { data: allPostedJobs = {}, isLoading: gettingPostedJobs } =
    usePostedJobsQuery(
      {},
      {
        skip: !postedJobs,
      }
    );
  const { data: allFilterJobs = {}, isLoading: gettingFilterJobs } =
    useFilterJobQuery(
      {
        sportIndustryId,
        roleId,
        sportCategoryId,
        sportNameId,
        sportNameSubCategoryId,
        location,
        jobTitle,
      },
      {
        skip: !filterJob,
      }
    );

  const [ignoreJob, { isLoading: ignoringJob }] = useIgnoreJobMutation();

  const data =
    allJobs?.data ||
    allAppliedJobs?.data ||
    allPostedJobs?.data ||
    allFilterJobs?.data;
  const isLoading =
    gettingAllJobs ||
    gettingAppliedJobs ||
    gettingPostedJobs ||
    gettingFilterJobs;

  const handleCopyLink = (id: any) => {
    navigator.clipboard.writeText(`${webBaseUrl}/jobs/job-details/${id}`);
    toast.success('Link has been copied.');
  };

  const handleIgnoreJob = async (data: any) => {
    const bodyData = {
      job_id: data,
    };
    const response = await ignoreJob(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {!isLoading ? (
        data?.length > 0 ? (
          data?.map((job: any) => {
            return (
              <Col
                md={6}
                lg={3}
                sm={6}
                xs={12}
                style={{
                  marginBottom: '10px',
                }}
              >
                <Card key={job?.id}>
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <Image
                        src={job?.company_logo || image1}
                        alt="Image"
                        className="img-fluid"
                        style={{
                          width: '120px',
                          height: '120px',
                          objectFit: 'cover',
                          marginBottom: '8px',
                        }}
                      />
                      {findJob && (
                        <MoreOptions>
                          <>
                            <Dropdown.Item
                              className=" p-3"
                              href=""
                              onClick={() => handleCopyLink(job?.id)}
                            >
                              <div className="d-flex align-items-center">
                                <span className="material-symbols-outlined">
                                  content_copy
                                </span>
                                <div className="data ms-2">
                                  <h6>Copy job link</h6>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className=" p-3"
                              href=""
                              onClick={() => handleIgnoreJob(job?.id)}
                            >
                              <div className="d-flex align-items-center">
                                <span className="material-symbols-outlined">
                                  exit_to_app
                                </span>
                                <div className="data ms-2">
                                  <h6>Ignore this job</h6>
                                </div>
                              </div>
                            </Dropdown.Item>
                          </>
                        </MoreOptions>
                      )}
                    </div>
                    <h4 className="fw-bold">
                      <Link
                        to={`/jobs/job-details/${job?.id}`}
                        style={{
                          color: background.dark,
                        }}
                      >
                        {job?.title}
                      </Link>
                    </h4>
                    <h6>{job?.company}</h6>
                    <h6 className="d-flex gap-1 align-items-center">
                      <span className="material-symbols-outlined">
                        location_on
                      </span>
                      {job?.location}
                    </h6>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-2">
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            padding: '1px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            background: '#ECF5FF',
                            color: background.blue,
                          }}
                        >
                          {job?.job_category?.title}
                        </div>
                        <div
                          style={{
                            padding: '1px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            background: '#FFE7A5',
                            color: background.blue,
                          }}
                        >
                          {job?.job_type?.title}
                        </div>
                      </div>
                      {jobHistory && (
                        <p className="mb-0">
                          {job?.total_applications} Applicants
                        </p>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        ) : (
          <p>No record found</p>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default JobCard;
