import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import VerifiedIcon from '../../../../assets/images/check-circle.png';
import Avatar from '../Avatar/Avatar';
import moment from 'moment';

interface IPostHeader {
  userId?: string;
  image?: string;
  isVerified?: boolean;
  isAdvertisement?: boolean;
  loggedInUserPost?: boolean;
  name?: string;
  username?: string;
  postType?: string;
  date?: string;
  time?: string;
  repost?: any;
  groupId?: any;
  groupName?: any;
  createdAt?: any;
  isPostEdit?: any;
  children?: React.ReactElement;
}

const PostHeader = ({
  userId,
  image,
  name,
  username,
  isVerified,
  isAdvertisement,
  date,
  time,
  children,
  postType,
  repost,
  groupId,
  groupName,
  createdAt,
  isPostEdit,
  loggedInUserPost,
}: IPostHeader) => {
  const { pathname } = useLocation();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background },
  } = theme;

  return (
    <>
      <div className="user-post-data pb-3">
        <div className="d-flex justify-content-between">
          <div className="me-3">
            {image && <Avatar pfp={image} size="40" name={username} />}
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <h5 className="mb-0 d-inline-block fw-bold">
                  <Link
                    to={
                      user?.uid == userId
                        ? `/profile`
                        : `/user-profile/${userId}`
                    }
                    style={{
                      color: background.dark,
                    }}
                  >
                    {name || username}
                  </Link>
                </h5>
                {isVerified ? (
                  <img
                    src={VerifiedIcon}
                    alt="Verified User"
                    width={20}
                    className="ms-1 mb-1"
                  />
                ) : null}
                {!isAdvertisement && postType == 'shared' && (
                  <p className="ms-1 mb-0 d-inline-block">
                    added this post{' '}
                    {isPostEdit == 1 && (
                      <span style={{ opacity: 0.4, marginLeft: '4px' }}>
                        edited
                      </span>
                    )}
                    {groupId && !pathname?.includes('groups') && 'in'}
                  </p>
                )}
                {!isAdvertisement && postType == 'new' && (
                  <p className="ms-1 mb-0 d-inline-block">
                    {moment().diff(moment(createdAt), 'seconds') < 60
                      ? 'added a new post'
                      : 'added a post'}{' '}
                    {isPostEdit == 1 && (
                      <span style={{ opacity: 0.4, marginLeft: '4px' }}>
                        edited
                      </span>
                    )}
                    {groupId && !pathname?.includes('groups') && 'in'}
                  </p>
                )}{' '}
                {groupId && !pathname?.includes('groups') && (
                  <h5 className="mb-0 d-inline-block fw-bold">
                    <Link
                      to={`/groups/group-detail/${groupId}`}
                      style={{
                        color: background.dark,
                      }}
                    >
                      {groupName}
                    </Link>
                  </h5>
                )}
                {!isAdvertisement && postType == 'repost' && (
                  <p className="ms-1 mb-0 d-inline-block">Reposted</p>
                )}
                <p className="mb-0 d-flex align-items-center gap-2">
                  {!isAdvertisement ? (
                    <>
                      <span>{date}</span>
                      <span>{time}</span>
                    </>
                  ) : (
                    <span>Advertisement</span>
                  )}
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: '16px',
                    }}
                  >
                    globe
                  </span>
                </p>
              </div>
              <div className="card-post-toolbar">
                {!isAdvertisement && children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostHeader;
