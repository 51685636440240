import { Card, Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { skillsInArray } from 'src/app/config/helper';
import { useJobDetailsQuery } from 'src/app/screens/jobs/services/jobsAPI';
import HorizontalJobCard from '../HorizontalJobCard/HorizontalJobCard';

const JobDetailCard = () => {
  const { id } = useParams();
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { border, background },
  } = theme;

  const { data: jobDetails, isLoading } = useJobDetailsQuery(id);
  const data = jobDetails?.data;

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title fw-bold">Job Details</h4>
          </div>
        </div>
        {!isLoading ? (
          <Card.Body>
            <Row>
              <Col
                md={12}
                style={{
                  marginBottom: '10px',
                }}
              >
                <HorizontalJobCard applyNow />
              </Col>
            </Row>
            <div
              style={{
                border: `1px solid ${border.divider}`,
                padding: '16px',
                borderRadius: '8px',
              }}
            >
              <p>
                Here’s how the job details align with your job preferences.
                Manage job preferences anytime in your profile.
              </p>

              <div className="d-flex gap-2 align-items-center mt-5">
                <h5 className="fw-bold">Job Type</h5>
                <div
                  style={{
                    padding: '1px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    background: '#FFE7A5',
                    color: background.blue,
                  }}
                >
                  {data?.job_type?.title}
                </div>
              </div>
              <h5 className="fw-bold mt-5">Job Description</h5>
              <p>{data?.description}</p>
              <hr />
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Sports Industry:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.sport_industry?.title}</p>
                </Col>
              </Row>
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Role:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.sport_roles?.title}</p>
                </Col>
              </Row>
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Sports Category:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.sport_category?.title}</p>
                </Col>
              </Row>
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Sports Name:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.sport_name?.title}</p>
                </Col>
              </Row>
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Company:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.company}</p>
                </Col>
              </Row>
              <Row  className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Location:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{data?.location}</p>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Skills Required:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>{skillsInArray(data?.job_skills)?.join(', ')}</p>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={3}>
                  <h5 className="fw-bold">Offering Salary Package:</h5>
                </Col>
                <Col md={7}>
                  <p className='mb-0'>${data?.salary_package}</p>
                </Col>
              </Row>
            </div>
            <Row></Row>
          </Card.Body>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </Card>
    </>
  );
};

export default JobDetailCard;
