import { useState } from 'react';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';
import {
  useGetCategoriesQuery,
  useGetIndustriesQuery,
  useGetNamesQuery,
} from '../../../shared/services/sharedApi';
import Loader from '../../../shared/components/Loader';

type GroupsFilterType = {
  categoryId: number;
  setCategoryId: any;
  setSportNameId: any;
  setIndustryId: any;
  setGroupName: any;
};

const GroupsFilter = ({
  categoryId,
  setCategoryId,
  setSportNameId,
  setIndustryId,
  setGroupName,
}: GroupsFilterType) => {
  const {
    data: industries = {
      data: [],
    },
    isLoading: gettingIndustries,
  } = useGetIndustriesQuery({});
  const {
    data: categories = {
      data: [],
    },
    isLoading: gettingCategories,
  } = useGetCategoriesQuery({});

  const {
    data: sportNames = {
      data: [],
    },
  } = useGetNamesQuery(categoryId, { skip: !categoryId });

  const [categorySearch, setCategorySearch] = useState('');
  const [industrySearch, setIndustrySearch] = useState('');
  const [sportNameSearch, setSportNameSearch] = useState('');
  const [sportGroupName, setSportGroupName] = useState('');
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSportNameDropdown, setShowSportNameDropdown] = useState(false);

  const filterIndustryList = industries?.data?.filter((industry: any) =>
    industry.title.toLowerCase().includes(industrySearch.toLowerCase())
  );
  const filterCategoryList = categories?.data?.filter((category: any) =>
    category.title.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const filterSportNameList = sportNames?.data?.filter((sportName: any) =>
    sportName.title.toLowerCase().includes(sportNameSearch.toLowerCase())
  );

  return (
    <>
      {gettingIndustries || gettingCategories ? (
        <div
          style={{
            border: '0px solid red',
            display: 'flex',
            paddingLeft: '50px',
            marginBottom: '20px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center flex-wrap my-3">
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '250px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                type="text"
                value={industrySearch}
                placeholder="Search Industry"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIndustrySearch(e.target.value);
                  setShowIndustryDropdown(true);
                  e.target.value.length == 0 && setIndustryId(0);
                }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {showIndustryDropdown && industrySearch?.length > 2 && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {filterIndustryList.length > 0 &&
                    filterIndustryList.map((industry: any) => (
                      <ListGroup.Item
                        key={industry.uid}
                        onClick={() => {
                          setIndustryId(industry.uid);
                          setIndustrySearch(industry.title);
                          setShowIndustryDropdown(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {industry.title}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '250px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                type="text"
                value={categorySearch}
                placeholder="Search Category"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCategorySearch(e.target.value);
                  setShowCategoryDropdown(true);
                  e.target.value.length == 0 && setCategoryId(0);
                }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {showCategoryDropdown && categorySearch?.length > 2 && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {filterCategoryList.length > 0 &&
                    filterCategoryList.map((category: any) => (
                      <ListGroup.Item
                        key={category.uid}
                        onClick={() => {
                          setCategoryId(category.uid);
                          setCategorySearch(category.title);
                          setShowCategoryDropdown(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {category.title}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '250px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                type="text"
                value={sportNameSearch}
                placeholder="Search Sports Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSportNameSearch(e.target.value);
                  setShowSportNameDropdown(true);
                  e.target.value.length == 0 && setSportNameId(0);
                }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
              {showSportNameDropdown && sportNameSearch?.length > 2 && (
                <ListGroup
                  style={{
                    position: 'absolute',
                    zIndex: 9999999,
                    width: '100%',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    marginTop: '40px',
                  }}
                >
                  {filterSportNameList.length > 0 &&
                    filterSportNameList.map((sportName: any) => (
                      <ListGroup.Item
                        key={sportName.uid}
                        onClick={() => {
                          setSportNameId(sportName.uid);
                          setSportNameSearch(sportName.title);
                          setShowSportNameDropdown(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {sportName.title}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
            </InputGroup>
          </div>
          <div className="iq-search-bar device-search position-relative">
            <InputGroup
              style={{
                width: '250px',
              }}
              className="custom-input-group"
            >
              <Form.Control
                type="text"
                value={sportGroupName}
                placeholder="Search Group Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSportGroupName(e.target.value);
                  setGroupName(e.target.value);
                  e.target.value.length == 0 && setGroupName('');
                }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <span className="material-symbols-outlined">search</span>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupsFilter;
