import React from 'react';
import {
    ReactComponent as HideSvg
} from './hide.svg'

function Hide() {

    return <HideSvg />
}

export default Hide