import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IMarketPlaceHeader {
  activeLink: string;
}

const MarketPlaceHeader = ({ activeLink }: IMarketPlaceHeader) => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  return (
    <>
      <div className="d-flex justify-content-between align-items-end flex-wrap my-3">
        <nav
          className="tab-bottom-bordered"
          style={{
            borderBottom: 0,
          }}
        >
          <Nav
            variant="tabs"
            className="mb-0 rounded-top border-0"
            activeKey={activeLink}
            style={{
              background: 'transparent',
            }}
            onSelect={(selectedKey) => history(`${selectedKey}`)}
          >
            <Nav.Link
              eventKey="/add-item"
              style={{
                color: background.dark,
                backgroundColor: 'transparent',
              }}
            >
              Add an Item
            </Nav.Link>
            <Nav.Link
              eventKey="/my-items"
              style={{
                color: background.dark,
                backgroundColor: 'transparent',
              }}
            >
              My Items
            </Nav.Link>
          </Nav>
        </nav>
      </div>
    </>
  );
};

export default MarketPlaceHeader;
