import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { marketPlaceItemsData } from 'src/app/mock/marketPlace';
import marketPlaceImg from '../../assets/images/marketplace.png';
import CompanyDetailCard from '../shared/components/CompanyDetailCard/CompanyDetailCard';
import MarketPlaceHeader from '../shared/components/MarketPlaceHeader/MarketPlaceHeader';
import MarketPlaceItems from '../shared/components/MarketPlaceItems/MarketPlaceItems';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import { useGetUserMarketPlaceItemsQuery } from '../market-place/services/marketPlaceApi';
import { useSelector } from 'react-redux';
import NoData from '../shared/components/NoData/NoData';
import Loader from '../shared/components/Loader/Loader';

const MyItems = () => {
  const { user } = useSelector((state: any) => state.auth);

  const { data: getUserMarketPlaceItems, isLoading } =
    useGetUserMarketPlaceItemsQuery(user?.uid, { skip: !user?.uid });
  const { data: userMarketPlaceItems } = getUserMarketPlaceItems || {};

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Market Place" image={marketPlaceImg} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <MarketPlaceHeader activeLink="/my-items" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row className="mt-3">
                <Col md={3}>
                  <CompanyDetailCard />
                </Col>
                <Col md={7}>
                  {!isLoading ? (
                    userMarketPlaceItems?.length > 0 ? (
                      <MarketPlaceItems data={userMarketPlaceItems} col={4} />
                    ) : (
                      <NoData name="items" />
                    )
                  ) : (
                    <Loader />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default MyItems;
