import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import FollowersCard from '../shared/components/FollowersCard/FollowersCard';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';

const UserFollowers = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <FollowersCard lg={2} md={2} xs={3} />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default UserFollowers;
