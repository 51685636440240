import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { usePostLikeAndUnlikeMutation } from 'src/app/screens/feed/services/feedApi';

interface IPostLike {
  totalLikes?: any;
  postId?: any;
  isLiked?: boolean;
}

const PostLike = ({ totalLikes, postId, isLiked }: IPostLike) => {
  const [postLike, setPostLike] = useState<boolean>(isLiked || false);
  const [postLikeCount, setPostLikeCount] = useState<any>(totalLikes);

  const [postLikeAndUnlike, { isLoading }] = usePostLikeAndUnlikeMutation();

  const handlePostUnLike = async () => {
    try {
      const bodyData = {
        post_id: postId,
      };

      const response = await postLikeAndUnlike(bodyData);
      const { error, data: respData }: any = response || {};

      if (error) {
        toast.error(error?.data?.message);
        setPostLike(true);
        setPostLikeCount((prePostLikeCount: any) => prePostLikeCount + 1);
      }
    } catch (error) {
      console.error('Error while unliking post:', error);
    }
  };

  const handlePostLike = async () => {
    try {
      const bodyData = {
        post_id: postId,
      };

      const response = await postLikeAndUnlike(bodyData);
      const { error, data: respData }: any = response || {};

      if (error) {
        toast.error(error?.data?.message);
        setPostLike(false);
        setPostLikeCount((prePostLikeCount: any) => prePostLikeCount - 1);
      }
    } catch (error) {
      console.error('Error while liking post:', error);
    }
  };

  const handlePostLikeAndUnlike = async () => {
    if (!isLoading) {
      if (postLike) {
        setPostLike(false);
        setPostLikeCount((prePostLikeCount: any) => prePostLikeCount - 1);
        await handlePostUnLike();
      } else {
        setPostLike(true);
        setPostLikeCount((prePostLikeCount: any) => prePostLikeCount + 1);
        await handlePostLike();
      }
    }
  };

  useEffect(() => {
    setPostLike(isLiked || false);
    setPostLikeCount(totalLikes);
  }, [isLiked, totalLikes]);

  return (
    <>
      <div
        className="d-flex gap-2 align-items-center"
        style={{
          cursor: 'pointer',
        }}
        onClick={handlePostLikeAndUnlike}
      >
        <span
          className="material-symbols-outlined"
          style={{
            color: postLike ? 'red' : '',
          }}
        >
          favorite
        </span>
        <p className="mb-0">
          {postLikeCount} <span className="custom-post-footer-text">Likes</span>
        </p>
      </div>
    </>
  );
};

export default PostLike;
