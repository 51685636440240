import React from 'react';
import { Button, Card, Col, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import img1 from '../../../../assets/images/profile-bg2.jpg';
import user01 from '../../../../assets/images/04.jpg';
import user02 from '../../../../assets/images/05.jpg';
import { useSelector } from 'react-redux';
import {
  useCancelRequestMutation,
  useGetUserNetworkQuery,
} from 'src/app/screens/profile/services/profileApi';
import Loader from '../Loader/Loader';
import NoData from '../NoData';
import Avatar from '../Avatar/Avatar';
import { toast } from 'react-hot-toast';

interface IUserProfileCard {
  image?: string;
  name?: string;
  description?: string;
  btnFor?: string;
  type?: string;
}

const UserProfileCard = ({
  image,
  name,
  description,
  btnFor,
  type,
}: IUserProfileCard) => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { user } = useSelector((state: any) => state.auth);

  const { data: getUserNetwork = {}, isLoading } = useGetUserNetworkQuery(
    {
      user_id: user?.uid,
      type: type,
    },
    {
      skip: !user?.uid,
    }
  );

  const { data: userNetwork } = getUserNetwork || {};
  const [cancelRequest, { isLoading: cancellingRequest }] =
    useCancelRequestMutation();

  const handleCancelRequest = async (id: any) => {
    const bodyData = {
      followee_id: id,
      type: btnFor === 'following' ? 'follower' : 'connection',
    };

    const response = await cancelRequest(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {!isLoading ? (
        userNetwork?.length > 0 ? (
          userNetwork?.map?.((data: any) => {
            const {
              id,
              followee_id,
              follower_id,
              follower_profile,
              total_friends,
              follower_name,
              follower_username,
              sharedFriendsUsernames,
              remaining_shared_friends,
            } = data;
            return (
              <Col
                md={4}
                lg={4}
                xs={12}
                style={{
                  marginBottom: '10px',
                }}
              >
                <Card className=" card-block card-stretch card-height" key={id}>
                  <Card.Body className=" profile-page p-0">
                    <div className="profile-header-image">
                      <div className="cover-container">
                        <img
                          loading="lazy"
                          src={img1}
                          alt="profile-bg"
                          className="rounded img-fluid w-100"
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-end px-4">
                        <div
                          className="profile-img pe-4"
                          style={{
                            marginTop: '-2.575rem',
                          }}
                        >
                          <Avatar
                            pfp={follower_profile}
                            size="100"
                            name={follower_name || follower_username}
                          />
                        </div>
                        <Button
                          variant="primary"
                          type="button"
                          className="float-end"
                          onClick={() =>
                            handleCancelRequest(
                              btnFor === 'following' ? followee_id : follower_id
                            )
                          }
                          style={{
                            background: button.background,
                            borderColor: border.dark,
                          }}
                        >
                          {btnFor === 'followers'
                            ? 'Remove'
                            : btnFor === 'following'
                            ? 'Unfollow'
                            : btnFor === 'connections'
                            ? 'Disconnect'
                            : ''}
                        </Button>
                      </div>
                      <div className="profile-info p-4">
                        <div className="user-detail">
                          <div className="d-flex flex-wrap justify-content-between align-items-start">
                            <div className="profile-detail d-flex">
                              <div className="user-data-block">
                                <h4>
                                  <Link
                                    to={`/user-profile/${
                                      btnFor === 'following'
                                        ? followee_id
                                        : follower_id
                                    }`}
                                    style={{
                                      color: background.dark,
                                    }}
                                  >
                                    {follower_name || follower_username}
                                  </Link>
                                </h4>
                                <p>{description}</p>
                                <div className="d-flex gap-3 align-items-center">
                                  <div className="group-member">
                                    <div className="iq-media-group">
                                      {sharedFriendsUsernames?.map?.(
                                        (data: any) => {
                                          const { profile, username } = data;
                                          return (
                                            <Avatar
                                              pfp={profile}
                                              size="40"
                                              name={username}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <span>
                                    {sharedFriendsUsernames
                                      ?.map?.((data: any) => data?.username)
                                      ?.join(', ')}{' '}
                                    {remaining_shared_friends > 0 &&
                                      remaining_shared_friends}
                                  </span>
                                </div>
                                <p
                                  className="mt-2"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  {total_friends} Followers
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        ) : (
          <NoData name="connections" />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserProfileCard;
