import { api } from 'src/app/services';

export const marketPlaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    marketPlaceItems: builder.query({
      query: () => {
        return {
          url: `/api/user/market/place/get/all_items`,
          method: 'GET',
        };
      },
      providesTags: ['MarketPlace'],
    }),
    getMarketPlaceItems: builder.query({
      query: ({ params }) => {
        return {
          url: `/api/user/market/place/search/company-items?${params}`,
          method: 'GET',
        };
      },
      providesTags: ['MarketPlace'],
    }),
    createMarketPlaceItem: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/market/place/create_item`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['MarketPlace'],
    }),
    updateMarketPlaceItem: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/market/place/update_item`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['MarketPlace'],
    }),
    getMarketPlaceItemDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/user/market/place/get/single_item?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['MarketPlace'],
    }),
    getUserMarketPlaceItems: builder.query({
      query: (id) => {
        return {
          url: `/api/user/market/place/get/items_by_userid?user_id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['MarketPlace'],
    }),
  }),
});

export const {
  useMarketPlaceItemsQuery,
  useGetMarketPlaceItemsQuery,
  useCreateMarketPlaceItemMutation,
  useUpdateMarketPlaceItemMutation,
  useGetMarketPlaceItemDetailsQuery,
  useGetUserMarketPlaceItemsQuery,
} = marketPlaceApi;
