import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IProfileSidebar {
  activeLink: string;
}

const ProfileSidebar = ({ activeLink }: IProfileSidebar) => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);
  const { profileSetup } = useSelector((state: any) => state.shared);

  const {
    palette: { button },
  } = theme;

  const handlePersonalInformation = () => {
    if (profileSetup == 0) {
      history('/profile/edit-personal-information');
    } else {
      history('/profile/personal-information');
    }
  };

  const handleEducation = () => {
    if (profileSetup == 0) {
      history('/profile/add-education');
    } else {
      history('/profile/education');
    }
  };

  const handleWorkExperience = () => {
    if (profileSetup == 0) {
      history('/profile/add-workplace-experience');
    } else {
      history('/profile/work-experience');
    }
  };

  const handleTeams = () => {
    if (profileSetup == 0) {
      history('/profile/profile-add-teams');
    } else {
      history('/profile/profile-teams');
    }
  };

  return (
    <>
      <Card className="p-3">
        <h4 className="fw-bold mb-3">My Profile</h4>
        <Button
          variant={`${
            activeLink === 'personal-information' ? 'light' : 'link'
          }`}
          style={{
            textAlign: 'left',
            color: button.background,
          }}
          onClick={handlePersonalInformation}
        >
          Personal Information
        </Button>
        <Button
          variant={`${activeLink === 'education' ? 'light' : 'link'}`}
          style={{
            textAlign: 'left',
            color: button.background,
          }}
          disabled={
            user?.profile_completion?.personalInfo == 0 && profileSetup == 0
          }
          onClick={handleEducation}
        >
          Education
        </Button>
        <Button
          variant={`${activeLink === 'work-experience' ? 'light' : 'link'}`}
          style={{
            textAlign: 'left',
            color: button.background,
          }}
          disabled={
            (user?.profile_completion?.personalInfo == 0 ||
              user?.profile_completion?.educationInfo == 0) &&
            profileSetup == 0
          }
          onClick={handleWorkExperience}
        >
          Work Experience
        </Button>
        <Button
          variant={`${activeLink === 'profile-teams' ? 'light' : 'link'}`}
          style={{
            textAlign: 'left',
            color: button.background,
          }}
          disabled={
            (user?.profile_completion?.personalInfo == 0 ||
              user?.profile_completion?.educationInfo == 0 ||
              user?.profile_completion?.workExperience == 0) &&
            profileSetup == 0
          }
          onClick={handleTeams}
        >
          Teams
        </Button>
      </Card>
    </>
  );
};

export default ProfileSidebar;
