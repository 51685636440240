import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import marketPlaceImg from '../../assets/images/marketplace.png';
import ItemDetailCard from '../shared/components/ItemDetailCard/ItemDetailCard';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import AuthGuard from 'src/app/config/auth/AuthGuard';

const ItemDetail = () => {
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Market Place" image={marketPlaceImg} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12} xl={10}>
              <ItemDetailCard />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default ItemDetail;
