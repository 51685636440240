import cls from 'classnames';
import { Button, Card, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import useModalDialog from 'src/app/hooks/useModalDialog';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import photoVideoImage from '../../../../assets/images/photo-video.png';
import Avatar from '../Avatar/Avatar';
import PostModal from '../Modals/PostModal';

type CreateType = {
  image?: string;
  placeholder: string;
  groupId?: number;
};

const CreatePost = ({ placeholder, groupId }: CreateType) => {
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { isOpen, open, close } = useModalDialog();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  return (
    <>
      <Card id="post-modal-data">
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Create Post</h4>
          </div>
        </div>
        {personalInformation?.profile_image && (
          <Card.Body data-toggle="modal" data-target="#post-modal">
            <div className="d-flex align-items-center">
              <div className="user-img">
                <Avatar
                  pfp={personalInformation?.profile_image}
                  size="60"
                  name={personalInformation?.username}
                />
              </div>

              <form className="post-text ms-3 w-100">
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder={placeholder}
                  style={{ border: 'none' }}
                  onClick={open}
                />
              </form>
            </div>
            <hr />
            <div
              className={cls(
                'post-opt-block',
                'd-flex',
                'list-inline',
                'm-0',
                'p-0',
                'flex-wrap',
                'justify-content-between'
              )}
            >
              <div
                className={cls(
                  'bg-soft-primarys',
                  'rounded',
                  'p-2',
                  'pointer',
                  'd-flex',
                  'align-items-center',
                  'me-3',
                  'mb-md-0',
                  'mb-2'
                )}
                style={{
                  background: background.search,
                }}
                onClick={open}
              >
                <Image
                  loading="lazy"
                  src={photoVideoImage}
                  alt="icon"
                  className="img-fluid me-2"
                />
                Photo/Video
              </div>
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={open}
                style={{
                  background: button.background,
                  borderColor: border.dark,
                  maxHeight: '41px'
                }}
              >
                Create Post
              </Button>
            </div>
          </Card.Body>
        )}
      </Card>

      <PostModal groupId={groupId} open={isOpen} close={close} type="add" />
    </>
  );
};

export default CreatePost;
