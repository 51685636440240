import { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  convertNullToEmptyString,
  getFilePreviewURL,
} from 'src/app/config/helper';
import {
  useEditWorkExperienceMutation,
  useGetWorkExperienceDetailQuery,
} from 'src/app/screens/profile/services/profileApi';
import {
  useGetCategoriesQuery,
  useGetEmploymentTypesQuery,
  useGetIndustriesQuery,
  useLazyGetNamesQuery,
} from '../../services/sharedApi';

interface EditWorkplaceExperienceProps {
  open: boolean;
  editId: any;
  close: () => void;
}

const EditWorkplaceExperienceModal = ({
  open,
  close,
  editId,
}: EditWorkplaceExperienceProps) => {
  const defaultValues = {
    job_title: '',
    employment_type_id: '',
    niche_industry_id: '',
    company: '',
    sports_category_id: '',
    location: '',
    latitude: '',
    longitude: '',
    currently_working: '',
    start_date: '',
    end_date: '',
    description: '',
    link: '',
    media: {},
    sport_name_id: '',
  };

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const [names, setNames] = useState([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchMedia = watch('media');
  const watchCurrentlyWorking = watch('currently_working');

  const watchSportsCategoryId = watch('sports_category_id');

  const { data: allEmploymentTypes } = useGetEmploymentTypesQuery({});

  const { data: allIndustries } = useGetIndustriesQuery({});

  const [getNames] = useLazyGetNamesQuery();

  const { data: allCategories } = useGetCategoriesQuery({});

  const {
    data: getWorkExperienceDetail = {},
    isFetching: gettingWorkExperienceDetail,
    refetch: refetchWorkExperienceDetail,
  } = useGetWorkExperienceDetailQuery(editId, {
    skip: editId ? false : true,
  });

  const [editWorkExperience, { isLoading }] = useEditWorkExperienceMutation();

  const { data } = getWorkExperienceDetail;

  const populateData = async () => {
    reset({
      ...convertNullToEmptyString(data),
    });
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setValue('media', file);
  };

  const getSportNames = async () => {
    const names = await getNames(watchSportsCategoryId);
    setNames(names?.data?.data);
  };

  const handleEditWorkExperience = handleSubmit(async (data: any) => {
    const currentlyWorking: any = data?.currently_working == true ? 1 : 0;
    const modifiedMedia =
      typeof data?.media == 'string' ? data?.media : data?.media;

    const formData = new FormData();

    formData.append('id', editId);
    formData.append('job_title', data?.job_title);
    formData.append('employment_type_id', data?.employment_type_id);
    formData.append('niche_industry_id', data?.niche_industry_id);
    formData.append('company', data?.company);
    formData.append('sports_category_id', data?.sports_category_id);
    formData.append('location', data?.location);
    formData.append('latitude', data?.latitude);
    formData.append('longitude', data?.longitude);
    formData.append('currently_working', currentlyWorking);
    formData.append('start_date', data?.start_date);
    formData.append('end_date', data?.currently_working ? '' : data?.end_date);
    formData.append('description', data?.description);
    formData.append('link', data?.link);
    typeof data?.media != 'string' && formData.append('media', modifiedMedia);
    formData.append('sport_name_id', data?.sport_name_id);

    const response = await editWorkExperience(formData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email);

    if (respData) {
      toast.success(respData?.message);
      setSelectedImage(null);
      reset({
        ...defaultValues,
      });
      close();
    }
  });

  useEffect(() => {
    data && names && populateData();
  }, [data, names]);

  useEffect(() => {
    watchSportsCategoryId && getSportNames();
  }, [watchSportsCategoryId]);

  useEffect(() => {
    editId && refetchWorkExperienceDetail();
  }, [editId]);

  return (
    <>
      <Modal centered show={open} onHide={close} size="lg">
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            Edit Work Experience
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Title/Role</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title/Role"
                  {...register('job_title')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Company Name"
                  {...register('company')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Employment Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register('employment_type_id')}
                >
                  <option>Select</option>
                  {allEmploymentTypes?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return <option value={uid}>{title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Niche/Industry Name</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register('niche_industry_id')}
                >
                  <option>Select</option>
                  {allIndustries?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return <option value={uid}>{title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Sports Category</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register('sports_category_id')}
                >
                  <option>Select</option>
                  {allCategories?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return <option value={uid}>{title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Sports Name</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register('sport_name_id')}
                >
                  <option>Select</option>
                  {names?.map?.((data: any) => {
                    const { uid, title } = data;
                    return <option value={uid}>{title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location"
                  {...register('location')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  {...register('start_date')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>End Date (Or Expected)</Form.Label>
                <Form.Control
                  type="date"
                  disabled={watchCurrentlyWorking}
                  placeholder="End Date (Or Expected)"
                  {...register('end_date')}
                />
              </Form.Group>
            </Col>
            <Col md="6" className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label="I am currently working in this role."
                {...register('currently_working')}
              />
            </Col>
            <Col md="12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register('description')}
                />
              </Form.Group>
            </Col>
            <p>
              <b>Media </b>Add media like images, documents, sites or
              presentations (eg.
              .pdf,.ppt/.pptx,.doc/.docx,.jpg/.jpeg/.png,.gif)
            </p>
            <Col md="6">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Add Link</Form.Label>
                <Form.Control type="text" {...register('link')} />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Add Media</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
              </Form.Group>
            </Col>
            {watchMedia &&
            !gettingWorkExperienceDetail &&
            !data?.media?.includes('.pdf') &&
            selectedImage == null ? (
              <Col md="12">
                <Image
                  loading="lazy"
                  src={watchMedia}
                  alt="userimg1"
                  style={{
                    width: '80px',
                  }}
                />
              </Col>
            ) : watchMedia?.type?.includes?.('application/pdf') ? (
              <p className="text-danger">File attached</p>
            ) : selectedImage &&
              !gettingWorkExperienceDetail &&
              !watchMedia?.type?.includes?.('application/pdf') ? (
              <Col md="12">
                <Image
                  loading="lazy"
                  src={getFilePreviewURL(selectedImage)}
                  alt="userimg2"
                  style={{
                    width: '80px',
                  }}
                />
              </Col>
            ) : (
              data?.media?.includes('.pdf') && (
                <a href={watchMedia} target="_blank">
                  See Attachment
                </a>
              )
            )}
          </Row>
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
            onClick={handleEditWorkExperience}
          >
            Save
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditWorkplaceExperienceModal;
