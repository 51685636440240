import React from 'react';
import { Image, Modal } from 'react-bootstrap';
import successImage from '../../../../assets/images/application-submitted.png';
import { Link, useNavigate } from 'react-router-dom';

interface ISubmitJobApplicationModal {
  open: boolean;
  close: () => void;
}

const SubmitJobApplicationModal = ({
  open,
  close,
}: ISubmitJobApplicationModal) => {
  const history = useNavigate();

  return (
    <>
      <Modal
        centered
        show={open}
        onHide={() => {
          close();
          history('/jobs');
        }}
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: 0,
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column mb-5">
            <Image
              src={successImage}
              alt="Application Submitted"
              className="img-fluid"
            />
            <h5 className="mt-3 mb-1">Application Submitted Successfully</h5>
            <Link to="/jobs">Continue</Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubmitJobApplicationModal;
