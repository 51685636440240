import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useModalDialog from 'src/app/hooks/useModalDialog';
import {
  useApplyJobMutation,
  useGetHearJobTypesQuery,
} from 'src/app/screens/jobs/services/jobsAPI';
import SubmitJobApplicationModal from '../Modals/SubmitJobApplicationModal';
import Resume from '../Resume/Resume';
import jobApplicationFormSchema from './schema/jobApplicationForm.schema';

const JobApplicationForm = () => {
  const defaultValues = {
    job_id: '',
    apply_type: '',
    name: '',
    email: '',
    contact: '',
    experience: '',
    last_employment: '',
    about_hear_id: '',
    cover_letter: '',
    cv_file: {},
  };

  const history = useNavigate();

  const { id } = useParams();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { isOpen, open, close } = useModalDialog();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: jobApplicationFormSchema,
  });

  const watchApplyType = watch('apply_type');

  const { data: allHearJobTypes } = useGetHearJobTypesQuery({});

  const [applyJob, { isLoading }] = useApplyJobMutation();

  const emptyFields = () => {
    setValue('name', '');
    setValue('email', '');
    setValue('contact', '');
    setValue('experience', '');
    setValue('last_employment', '');
    setValue('about_hear_id', '');
    setValue('cover_letter', '');
    setValue('cv_file', {});
  };

  const callApplyJobAPI = async (formData: any) => {
    const response = await applyJob(formData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email);

    if (respData) {
      emptyFields();
      open();
    }
  };

  const handleApplyJobUploadResume = handleSubmit(async (data: any) => {
    const formData = new FormData();

    id && formData.append('job_id', id);
    formData.append('apply_type', data?.apply_type);
    if (watchApplyType === '1') {
      formData.append('name', data?.name);
      formData.append('email', data?.email);
      formData.append('contact', data?.contact);
      formData.append('experience', data?.experience);
      formData.append('last_employment', data?.last_employment);
      formData.append('about_hear_id', data?.about_hear_id);
      formData.append('cover_letter', data?.cover_letter);
      formData.append('cv_file', data?.cv_file[0]);
    }

    formData && callApplyJobAPI(formData);
  });

  const handleApplyJobShareProfile = async () => {
    const formData = new FormData();

    id && formData.append('job_id', id);
    formData.append('apply_type', watchApplyType);

    formData && callApplyJobAPI(formData);
  };

  const handleApplyJob = (data: any) => {
    watchApplyType === '1'
      ? handleApplyJobUploadResume()
      : handleApplyJobShareProfile();
  };

  return (
    <>
      <div className="form-card text-start">
        <Row>
          <h5 className="fw-bold">How do you want to apply for this job?</h5>
          <Col md="3">
            <Form.Check
              type="radio"
              label="Upload Resume"
              name="job"
              onChange={() => setValue('apply_type', '1')}
            />
          </Col>
          <Col md="3">
            <Form.Check
              type="radio"
              name="job"
              label="Share Profile as your CV"
              onChange={(e) => setValue('apply_type', '2')}
            />
          </Col>
        </Row>
        {watchApplyType === '1' && (
          <Row className="mt-3">
            <div className="d-flex justify-content-end my-2">
              <p
                style={{
                  color: 'red',
                }}
              >
                * Indicates Required
              </p>
            </div>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  {...register('name')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>
                  Email: <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register('email')}
                />
                <p className="text-danger">{errors?.email?.message}</p>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Contact Number:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Contact Number"
                  {...register('contact')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Years of Experience:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Years of Experience"
                  {...register('experience')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>Last Employment Title:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Employment Title"
                  {...register('last_employment')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="form-group">
                <Form.Label>How did You hear about us?</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register('about_hear_id')}
                >
                  <option>Select</option>
                  {allHearJobTypes?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return <option value={uid}>{title}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Cover Letter:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Cover Letter"
                  {...register('cover_letter')}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Col md="12">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Resume/Cv:</Form.Label>
                  <Form.Control type="file" {...register('cv_file')} />
                </Form.Group>
              </Col>
            </Col>
          </Row>
        )}

        {watchApplyType === '2' && (
          <>
            <Row className="mt-3">
              <Col md={12}>
                <Resume />
              </Col>
            </Row>
          </>
        )}
        {watchApplyType !== '' && (
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
            onClick={handleApplyJob}
          >
            Submit Application
          </Button>
        )}
      </div>

      <SubmitJobApplicationModal open={isOpen} close={close} />
    </>
  );
};

export default JobApplicationForm;
