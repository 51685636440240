import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useReportUserMutation } from 'src/app/screens/feed/services/feedApi';

interface IReportUserModal {
  open: boolean;
  close: () => void;
  postId?: any;
  userId?: any;
}

const ReportUserModal = ({ open, close, postId, userId }: IReportUserModal) => {
  const defaultValues = {
    report_to: '',
    post_id: '',
    reason: '',
  };

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [reportUser, { isLoading }] = useReportUserMutation();

  const emptyFields = () => {
    setValue('reason', '');
    setValue('post_id', '');
    setValue('report_to', '');
  };

  const handleAddReport = handleSubmit(async (data: any) => {
    const bodyData = {
      ...data,
      report_to: userId,
      post_id: postId,
    };
    const response = await reportUser(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      emptyFields();
      close()
    }
  });

  return (
    <>
      <Modal centered show={open} onHide={close}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            Report User - Reason
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form.Group className="form-group">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Type reason here"
                  {...register('reason')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
            onClick={handleAddReport}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportUserModal;
