import { api } from 'src/app/services';

export const chatApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/chat/send`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Chat', 'UserChat'],
    }),
    allChat: builder.query({
      query: () => {
        return {
          url: `/api/user/chat/all-chat`,
          method: 'GET',
        };
      },
      providesTags: ['Chat'],
    }),
    getUserChat: builder.mutation({
      query: (channelId) => {
        return {
          url: `/api/user/chat/get_user_chat/${channelId}`,
          method: 'GET',
        };
      },
    }),
    getSingleUserChat: builder.query({
      query: (data) => {
        return {
          url: `/api/user/chat/get_chat_by_user/${data?.authId}/${data?.id}`,
          method: 'GET',
        };
      },
      providesTags: ['UserChat']
    }),
    deleteChat: builder.mutation({
      query: (userId) => {
        return {
          url: `/api/user/chat/delete-all/${userId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useAllChatQuery,
  useSendMessageMutation,
  useGetUserChatMutation,
  useGetSingleUserChatQuery,
  useDeleteChatMutation,
} = chatApi;
