import Dashboard from "../../screens/layouts/dashboard/dashboard";
import {
  DefaultRouter
} from "./default-router";
import {
  Layout1Router
} from "./layout1-router";

export const IndexRouters = [
  {
    path: "/",
    element: <Dashboard />,
    children: [
      ...DefaultRouter,
      ...Layout1Router
    ],
  },
];
