import { api } from 'src/app/services';

export const jobsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobTypes: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/job-type`,
          method: 'GET',
        };
      },
    }),
    getJobCategories: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/job-category`,
          method: 'GET',
        };
      },
    }),
    getHearJobTypes: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/hear-job-type`,
          method: 'GET',
        };
      },
    }),
    addJob: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/job/add`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Jobs'],
    }),
    allJobs: builder.query({
      query: () => {
        return {
          url: `/api/user/job/all`,
          method: 'GET',
        };
      },
      providesTags: ['Jobs'],
    }),
    appliedJobs: builder.query({
      query: () => {
        return {
          url: `/api/user/job/applied`,
          method: 'GET',
        };
      },
      providesTags: ['Jobs'],
    }),
    postedJobs: builder.query({
      query: () => {
        return {
          url: `/api/user/job/posted`,
          method: 'GET',
        };
      },
      providesTags: ['Jobs'],
    }),
    jobDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/user/job/detail?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Jobs'],
    }),
    applyJob: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/job/apply`,
          method: 'POST',
          body,
        };
      },
    }),
    ignoreJob: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/job/ignore`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Jobs'],
    }),
    filterJob: builder.query({
      query: (data) => {
        return {
          url: `/api/user/job/find?sport_industry_id=${data?.sportIndustryId}&role_id=${data?.roleId}&sport_category_id=${data?.sportCategoryId}&sport_name_id=${data?.sportNameId}&sports_name_sub_category_id=${data?.sportNameSubCategoryId}&location=${data?.location}&search=${data?.jobTitle}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetJobTypesQuery,
  useGetJobCategoriesQuery,
  useGetHearJobTypesQuery,
  useAddJobMutation,
  useAllJobsQuery,
  useAppliedJobsQuery,
  usePostedJobsQuery,
  useJobDetailsQuery,
  useApplyJobMutation,
  useIgnoreJobMutation,
  useFilterJobQuery,
} = jobsApi;
