import { Card, Col, Row } from 'react-bootstrap';
import { capitlizeFirstLetter } from 'src/app/config/helper';

const AboutGroupCard = ({
  name,
  sport_industry,
  sport_category,
  sport_name,
  location,
  description,
  rules,
  group_type,
}: any) => {
  return (
    <>
      <Card
        style={{
          marginBottom: '70px',
        }}
      >
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4
              className="card-title"
              style={{
                fontWeight: 600,
              }}
            >
              About this group
            </h4>
          </div>
        </div>
        <Card.Body>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Group Name:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{name}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Sports Category:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{sport_category.title}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Sports Industry:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{sport_industry.title}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Sports Name:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{sport_name.title}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Location:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{location}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Group Type:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{capitlizeFirstLetter(group_type)}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Description:</h5>
                </div>
                <div className="col-8">
                  <h5 className="mb-0">{description}</h5>
                </div>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <div className="col-4">
                  <h5 className="fw-bold">Rules:</h5>
                </div>
                <div className="col-8">{rules}</div>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AboutGroupCard;
