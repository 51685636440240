import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ProfileHeader from '../shared/components/ProfileHeader';
import { Link, useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { useGetUserDetailsQuery } from '../profile/services/profileApi';

const UserAbout = () => {
  const { id } = useParams();

  const { data: getUserDetails } = useGetUserDetailsQuery(id);
  const { data: userDetails = {} } = getUserDetails || {};

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">About</h4>
                  </div>
                </div>
                <Card.Body>
                  <h4 className="fw-bold mb-3">Personal Details</h4>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">User Id:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.user_id}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Profile Url:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            <Link to="#">
                              {userDetails?.personal_info?.profile_url}
                            </Link>
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Email ID:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.email_id}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Username:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.username}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Full Name:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.full_name}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Gender:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.gender}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Date of birth:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.date_of_birth}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Place of birth:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.place_of_birth}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Nationality:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.nationality}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Headline:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.headline}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Contact Type:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.contact_type}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Contact Number:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.contact_number}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Biographical Information</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Brief Background & Biography:</h5>
                    </div>
                    <div className="col-7">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.brief_background_biography}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Early Life & Upbringing:</h5>
                    </div>
                    <div className="col-7">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.early_life_upbringing}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Education:</h5>
                    </div>
                    <div className="col-7">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.education}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Sporting Career</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Sport Played:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.sport_played}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Position Or Specialization:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.position_specialization}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Teams or Clubs Played for:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.teams_clubs_played_for}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Important Milestone & Achievements:
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {
                          userDetails?.personal_info
                            ?.important_milestone_achievements
                        }
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Career Statics (if applicable):
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.career_statics}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Accomplishments</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Major Award & Honors:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.major_award_honors}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Records held (if any):</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.records_held}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Notable Performances:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.notable_performances}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Current Status</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Current Team Or Affiliation:</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.current_team_affiliation}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Injury Status (if relevant):</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.injury_status}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Upcoming events or competitions:
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {
                          userDetails?.personal_info
                            ?.upcoming_events_competitions
                        }
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Physical Attributes</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Height</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.height}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Weight</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.weight}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Strength & Weakness</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.strength_weakness}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Personal Life</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Hobbies & Interests Outside Sports:
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {
                          userDetails?.personal_info
                            ?.hobbies_interests_outside_sports
                        }
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Family information (optional):
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.family_information}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Quotes & Interviews</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">
                        Any Noteworthy Statements Or Interviews:
                      </h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {
                          userDetails?.personal_info
                            ?.any_noteworthy_statements_interviews
                        }
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Address Details</h4>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Address:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.address}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Street:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.street}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">City:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.city}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">State:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.state}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Zip Code:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.zip_code}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Profession</h4>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Niche/Industry</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.niche_industry?.title}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Roles</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.roles?.title}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Sports Categories</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.sports_categories?.title}
                      </h5>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-3">
                      <h5 className="fw-bold">Sports Name</h5>
                    </div>
                    <div className="col-9">
                      <h5 className="mb-0">
                        {userDetails?.personal_info?.sport_name?.title}
                      </h5>
                    </div>
                  </Row>
                  <hr />
                  <h4 className="fw-bold mb-3">Business Details</h4>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Company Name:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.company_name}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Public page Url:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">URL Link</h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Business Type:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.business_size?.title}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Business Size:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.business_size?.title}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Website:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.website}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <div className="col-3">
                          <h5 className="fw-bold">Tag Line:</h5>
                        </div>
                        <div className="col-9">
                          <h5 className="mb-0">
                            {userDetails?.personal_info?.tag_line}
                          </h5>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default UserAbout;
