import React from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useAcceptOrCancelRequestMutation,
  useGetUserNetworkListQuery,
} from 'src/app/screens/profile/services/profileApi';
import Loader from '../Loader/Loader';
import { toast } from 'react-hot-toast';
import Avatar from '../Avatar/Avatar';

interface IRequestsContainer {
  title: string;
  type: any;
}

const RenderButtons = ({ type, id }: any) => {
  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const [acceptOrCancelRequest, { isLoading }] =
    useAcceptOrCancelRequestMutation();

  const handleSendRequest = async (action: string, type: string) => {
    const bodyData = {
      action: action,
      type: type,
      id: id,
    };

    const response = await acceptOrCancelRequest(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {type == 'follower' ? (
        <>
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            onClick={() => handleSendRequest('accept', 'follower')}
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
          >
            Confirm
          </Button>
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            onClick={() => handleSendRequest('cancel', 'follower')}
            style={{
              background: 'transparent',
              borderColor: border.dark,
              color: border.dark,
            }}
          >
            Delete Request
          </Button>
        </>
      ) : (
        <>
          <Button
            disabled={isLoading}
            variant="primary"
            type="button"
            className="float-end"
            onClick={() => handleSendRequest('cancel', 'connection')}
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            className="float-end"
            onClick={() => handleSendRequest('accept', 'connection')}
            style={{
              background: 'transparent',
              borderColor: border.dark,
              color: border.dark,
            }}
          >
            Confirm
          </Button>
        </>
      )}
    </>
  );
};

const RequestsContainer = ({ title, type }: IRequestsContainer) => {
  const { data: getUserNetworkList, isLoading } =
    useGetUserNetworkListQuery(type);

  const { data: userNetworkList = [] } = getUserNetworkList || {};

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{title}</h4>
          </div>
        </div>
        {!isLoading ? (
          userNetworkList?.length > 0 ? (
            userNetworkList?.map?.((data: any) => {
              const {
                follower_name,
                follower_profile,
                follower_username,
                id,
                followee_id,
                follower_id,
                sharedFriendsUsernames,
                total_friends,
              } = data;
              return (
                <Card.Body key={id}>
                  <ul className="request-list list-inline m-0 p-0">
                    <li className="d-flex align-items-center  justify-content-between flex-wrap">
                      <div className="user-img img-fluid flex-shrink-0">
                        <Avatar
                          pfp={follower_profile}
                          size="40"
                          name={follower_name || follower_username}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6>{follower_name || follower_username}</h6>
                        <p className="mb-0">{total_friends} friends</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2 mt-md-0">
                        <RenderButtons type={type} id={id} />
                      </div>
                    </li>
                  </ul>
                </Card.Body>
              );
            })
          ) : (
            <p className="p-2">No record found</p>
          )
        ) : (
          <Loader />
        )}
      </Card>
    </>
  );
};

export default RequestsContainer;
