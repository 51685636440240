import { api } from 'src/app/services';

export const sharedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIndustries: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/sports/industries/get`,
          method: 'GET',
        };
      },
    }),
    getRoles: builder.query({
      query: (industryId) => {
        return {
          url: `/api/user/dropdown/roles/against/sport/industry/${industryId}/get`,
          method: 'GET',
        };
      },
    }),
    getCategories: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/sports/categories/get`,
          method: 'GET',
        };
      },
    }),
    getNames: builder.query({
      query: (categoryId) => {
        return {
          url: `/api/user/dropdown/sport/names/against/sport/category/${categoryId}/get`,
          method: 'GET',
        };
      },
    }),
    getSportTypes: builder.query({
      query: (sportNameId) => {
        return {
          url: `/api/user/dropdown/sub/categories/against/sport/name/${sportNameId}/get`,
          method: 'GET',
        };
      },
    }),
    getBusinessSizes: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/business/size/get`,
          method: 'GET',
        };
      },
    }),
    getEducationLevel: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/education-level`,
          method: 'GET',
        };
      },
    }),
    getEmploymentTypes: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/employment-types`,
          method: 'GET',
        };
      },
    }),
    getUsers: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/all-users`,
          method: 'GET',
        };
      },
    }),
    getItemCategories: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/get/item-categories`,
          method: 'GET',
        };
      },
    }),
    getSportNames: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/all/sport-list`,
          method: 'GET',
        };
      },
    }),
    getCurrencies: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/currency/list`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetIndustriesQuery,
  useLazyGetRolesQuery,
  useGetRolesQuery,
  useGetCategoriesQuery,
  useLazyGetNamesQuery,
  useGetNamesQuery,
  useLazyGetSportTypesQuery,
  useGetSportTypesQuery,
  useGetBusinessSizesQuery,
  useGetEducationLevelQuery,
  useGetEmploymentTypesQuery,
  useGetUsersQuery,
  useGetItemCategoriesQuery,
  useGetSportNamesQuery,
  useGetCurrenciesQuery,
} = sharedApi;
