import React from 'react'
import { Button, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

interface IJobsHeader {
    activeLink: string,
}

const JobsHeader = ({
    activeLink
}: IJobsHeader) => {

    const history
        = useNavigate()

    const {
        theme,
    } = useSelector((state: any) =>
        state.styles
    )

    const {
        palette: {
            background,
            button,
            border,
        }
    } = theme

    return (
        <>
            <div className='d-flex justify-content-between align-items-end flex-wrap my-3'>
                <nav className="tab-bottom-bordered"
                    style={{
                        borderBottom: 0
                    }}
                >
                    <Nav
                        variant="tabs"
                        className="mb-0 rounded-top border-0"
                        activeKey={activeLink}
                        style={{
                            background: 'transparent',
                        }}
                        onSelect={(selectedKey) => history(`${selectedKey}`)}
                    >
                        <Nav.Link
                            eventKey="/jobs"
                            style={{
                                color: background.dark,
                                backgroundColor: 'transparent'
                            }}
                        >Find a job</Nav.Link>
                        <Nav.Link
                            eventKey="/post-job"
                            style={{
                                color: background.dark,
                                backgroundColor: 'transparent'
                            }}
                        >Post a job</Nav.Link>
                    </Nav>
                </nav>
                <Link to="/job-history">Jobs History</Link>
            </div>
        </>
    )
}

export default JobsHeader