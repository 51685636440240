import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { capitlizeFirstLetter } from 'src/app/config/helper';
import { baseUrl } from 'src/app/config/types/base-url';
import AboutGroupCard from '../shared/components/AboutGroupCard';
import Loader from '../shared/components/Loader';
import ProfilePageHeader from '../shared/components/ProfilePageHeader';
import GroupDetailHeader from './components/GroupDetailHeader/GroupDetailHeader';
import { useGetGroupQuery } from './services/groupsApi';

const AboutGroup = () => {
  const { id } = useParams();

  const { data, isLoading }: any = useGetGroupQuery({ groupId: id });

  const {
    background_image,
    group_member_count,
    group_type,
    name,
    profile_image,
    user_id,
    id: groupId,
    group_user_status,
  } = data?.data || {};

  return (
    <AuthGuard>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader
                image={baseUrl + '/' + background_image}
                title="Groups"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <GroupDetailHeader
                groupUserStatus={group_user_status}
                id={groupId}
                name={name}
                visibility={capitlizeFirstLetter(group_type)}
                members={group_member_count}
                image={baseUrl + '/' + profile_image}
                ownerId={user_id}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <AboutGroupCard {...data?.data} />
            </Col>
          </Row>
        </div>
      )}
    </AuthGuard>
  );
};

export default AboutGroup;
