type NoDataType = {
  name: string;
};

function NoData({ name }: NoDataType) {
  return (
    <div
      style={{
        marginLeft: '5px',
        marginRight: '5px',
        marginBottom: '5px',
      }}
    >
      <p>No {name} found.</p>
    </div>
  );
}

export default NoData;
