import { api } from 'src/app/services';

export const feedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addPost: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/add`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Posts', 'GroupPosts'],
    }),
    getPosts: builder.query({
      query: () => {
        return {
          url: `/api/user/post/get`,
          method: 'GET',
        };
      },
      providesTags: ['Posts'],
    }),
    updatePost: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/update`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Posts', 'PostDetails', 'GroupPosts'],
    }),
    getPostDetails: builder.query({
      query: (id) => {
        return {
          url: `/api/user/post/edit?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['PostDetails'],
    }),
    postLikeAndUnlike: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/like/unlike`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Posts'],
    }),
    addComment: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/add/comment`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Posts', 'GroupPosts'],
    }),
    deletePost: builder.mutation({
      query: (id) => {
        return {
          url: `/api/user/post/delete?post_id=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Posts', 'GroupPosts'],
    }),
    reportUser: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/report/add`,
          method: 'POST',
          body,
        };
      },
    }),
    getGroupPosts: builder.query({
      query: ({ groupId }) => {
        return {
          url: `/api/user/group/post/get/${groupId}`,
          method: 'GET',
        };
      },
      providesTags: ['GroupPosts'],
    }),
    getSinglePostDetail: builder.query({
      query: (id) => {
        return {
          url: `/api/user/post/single/post-details?id=${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Posts'],
    }),
    shareProfile: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/profile/share`,
          method: 'POST',
          body,
        };
      },
    }),
    sharePost: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/post/repost`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Posts'],
    }),
    shouldFollow: builder.query({
      query: () => {
        return {
          url: `/api/user/post/should/follow`,
          method: 'GET',
        };
      },
      providesTags: ['ShouldFollow'],
    }),
    globalSearch: builder.query({
      query: (search) => {
        return {
          url: `/api/user/global-search?query=${search}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetPostsQuery,
  useAddPostMutation,
  useUpdatePostMutation,
  useAddCommentMutation,
  useDeletePostMutation,
  useReportUserMutation,
  useGetGroupPostsQuery,
  useGetSinglePostDetailQuery,
  useGetPostDetailsQuery,
  usePostLikeAndUnlikeMutation,
  useShareProfileMutation,
  useSharePostMutation,
  useShouldFollowQuery,
  useGlobalSearchQuery,
} = feedApi;
