import { Col, Row } from 'react-bootstrap'
import { marketPlaceItemsData } from 'src/app/mock/marketPlace'
import marketPlaceImg from "../../assets/images/marketplace.png"
import CompanyDetailCard from '../shared/components/CompanyDetailCard/CompanyDetailCard'
import MarketPlaceHeader from '../shared/components/MarketPlaceHeader/MarketPlaceHeader'
import MarketPlaceItems from '../shared/components/MarketPlaceItems/MarketPlaceItems'
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader'
import ItemDetailCard from '../shared/components/ItemDetailCard/ItemDetailCard'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const CompanyItemDetail = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    <Row>
                        <Col md={12} xl={10}>
                            <ProfilePageHeader
                                title="Market Place"
                                image={marketPlaceImg}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row
                                className='mt-3'
                            >
                                <Col md={3}>
                                    <CompanyDetailCard />
                                </Col>
                                <Col md={7}>
                                    <ItemDetailCard
                                        edit={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default CompanyItemDetail