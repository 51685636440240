import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import user1 from '../../../assets/images/03.jpg';
import { useGetPersonalInformationQuery } from '../../profile/services/profileApi';
import { useSelector } from 'react-redux';

const UserSidebarDetails = () => {
  const [show1, setShow1] = useState('');

  const { user } = useSelector((state: any) => state.auth);

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { profile_image, roles, full_name, address } =
    personalInformation || {};

  const ChatSidebarClose = () => {
    document.getElementsByClassName('scroller')[0].classList.remove('show');
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="chat-profile me-3">
          <img
            loading="lazy"
            src={profile_image || user1}
            alt="chat-user"
            className="avatar-60"
            onClick={() => setShow1('true')}
          />
        </div>
        <div className="chat-caption">
          <h5 className="mb-0">{full_name}</h5>
          <p className="m-0">{roles?.title}</p>
        </div>
      </div>
      <div
        id="user-detail-popup"
        className={`scroller ${show1 === 'true' ? 'show' : ''}`}
      >
        <div className="user-profile">
          <Button
            type="submit"
            onClick={ChatSidebarClose}
            variant=" close-popup p-3"
          >
            <i
              className="material-symbols-outlined md-18"
              onClick={() => setShow1('false')}
            >
              close
            </i>
          </Button>
          <div className="user text-center mb-4">
            <Link className="avatar m-0" to="">
              <img
                loading="lazy"
                src={profile_image || user1}
                alt="avatar"
                className="avatar-100"
              />
            </Link>
            <div className="user-name mt-4">
              <h4 className="text-center">{full_name}</h4>
            </div>
            <div className="user-desc">
              <p className="text-center">{roles?.title}</p>
            </div>
          </div>
          <hr />
          <div className="user-detail text-left mt-4 ps-4 pe-4">
            <h5 className="mt-4 mb-4">About</h5>
            <p>{address}</p>
            <h5 className="mt-3 mb-3">Status</h5>
            <ul className="user-status p-0">
              <li className="mb-1">
                <i className="ri-checkbox-blank-circle-fill text-success pe-1"></i>
                <span>
                  {user?.active_status == '0' ? 'Inactive' : 'active'}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSidebarDetails;
