import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { baseUrl } from 'src/app/config/types/base-url';
import image05 from '../../../../assets/images/job-icon-2.png';
import {
  useGetAdvertisementQuery,
  useViewAdvertisementMutation,
} from './services/advertisementApi';

const Advertisement = () => {
  const [displayAdd, setDisplayAdd] = useState(false);

  const { data: getAdvertisement } = useGetAdvertisementQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: advertisementData } = getAdvertisement || {};
  const { id, external_link, post_id, title, attachments } =
    advertisementData || {};

  const [viewAdvertisement] = useViewAdvertisementMutation();

  const handleViewAdvertisement = () => {
    viewAdvertisement(id);
    setDisplayAdd(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayAdd(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {displayAdd &&
        advertisementData &&
        Object.keys(advertisementData)?.length != 0 && (
          <Card
            style={{
              width: '350px',
              position: 'fixed',
              right: '15px',
              top: '100px',
              zIndex: '9999999999',
            }}
          >
            <Card.Body>
              <div>
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                    alignItems: 'flex-start',
                  }}
                >
                  <img
                    src={
                      `${baseUrl}/storage/${attachments?.[0]?.path}` || image05
                    }
                    alt="Image"
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                    }}
                  />
                  <span
                    className="material-symbols-outlined"
                    style={{
                      transform: 'rotate(-45deg)',
                      position: 'absolute',
                      top: '15px',
                      right: '15px',
                      cursor: 'pointer',
                    }}
                    onClick={handleViewAdvertisement}
                  >
                    add
                  </span>
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: 'bold',
                        maxWidth: '100px',
                      }}
                    >
                      {title}
                    </p>
                    {external_link && (
                      <a href={external_link} target="_bank">
                        Visit website
                      </a>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    background: '#558BF7',
                    padding: '4px 12px',
                    display: 'inline',
                    color: '#FFFFFF',
                    position: 'absolute',
                    right: '15px',
                    bottom: '15px',
                  }}
                >
                  Ad
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
    </>
  );
};

export default Advertisement;
