import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import useDebounce from 'src/app/hooks/useDebounce';
import { useGlobalSearchQuery } from 'src/app/screens/feed/services/feedApi';
import Avatar from '../../Avatar/Avatar';
import '../header.css';
import cls from 'classnames';

const GlobalSearch = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { theme, mode } = useSelector((state: any) => state.styles);

  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { textInput, card },
  } = theme;

  const { search, handleSearch } = useDebounce();

  const { data: getGlobalSearch, isLoading } = useGlobalSearchQuery(search, {
    skip: !search,
  });
  const { data: globalSearch } = getGlobalSearch || {};

  const handleRedirection = (path: any) => {
    const e = {
      target: {
        value: '',
      },
    };
    path !== null && history(path);
    handleSearch(e);
  };

  useEffect(() => {
    if (search?.length > 0) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'initial';
  }, [search]);

  return (
    <>
      <div className="iq-search-bar device-search  position-relative">
        <form
          action="#"
          className="searchbox"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalFullscreenSm"
        >
          <Link className="search-link d-none d-lg-block" to="/">
            <span className="material-symbols-outlined text-dark">search</span>
          </Link>
          <Form.Control
            type="text"
            className="text search-input form-control"
            placeholder="Search here..."
            style={{
              background: textInput.background,
            }}
            onChange={handleSearch}
          />
        </form>

        <OutsideClickHandler
          onOutsideClick={() => {
            handleRedirection(null);
          }}
        >
          {(globalSearch?.[0]?.companies?.length > 0 ||
            globalSearch?.[0]?.items?.length > 0 ||
            globalSearch?.[0]?.users?.length > 0) &&
            search?.length > 0 && (
              <div
                className={cls(
                  'modal-dialogs',
                  'modal-fullscreen-lg-down',
                  'm-0',
                  'position-absolute'
                )}
                style={{
                  background: card.background,
                  width: '100%',
                  minHeight: '200px',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  zIndex: '999999999',
                }}
              >
                {globalSearch?.[0]?.users?.length > 0 &&
                  globalSearch?.[0]?.users?.map((data: any) => {
                    const { id, name, username, personal_information } = data;
                    return (
                      <div
                        className={cls(
                          'd-flex',
                          'align-items-center',
                          'border-bottom',
                          'search-hover',
                          'py-2',
                          'px-3'
                        )}
                        key={id}
                        onClick={() => handleRedirection(`/user-profile/${id}`)}
                      >
                        <div className="flex-shrink-0">
                          <Avatar
                            pfp={personal_information?.imagepath}
                            size="50"
                            name={name || username}
                          />
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <Link to="/" className="h5">
                            {name || username}
                          </Link>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                          <Link to="/" className="d-flex align-items-center">
                            <small>User</small>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                {globalSearch?.[0]?.companies?.length > 0 &&
                  globalSearch?.[0]?.companies?.map((data: any) => {
                    const { id, user_id, company_name, logopath } = data;
                    return (
                      <div
                        className={cls(
                          'd-flex',
                          'align-items-center',
                          'border-bottom',
                          'search-hover',
                          'py-2',
                          'px-3'
                        )}
                        key={id}
                        onClick={() =>
                          handleRedirection(`/company-items/${user_id}`)
                        }
                      >
                        <div className="flex-shrink-0">
                          <Avatar
                            pfp={logopath}
                            size="50"
                            name={company_name}
                          />
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <Link to="/" className="h5">
                            {company_name}
                          </Link>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                          <Link to="/" className="d-flex align-items-center">
                            <small>Company</small>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                {globalSearch?.[0]?.items?.length > 0 &&
                  globalSearch?.[0]?.items?.map((data: any) => {
                    const { id, title, attachments } = data;
                    return (
                      <div
                        className={cls(
                          'd-flex',
                          'align-items-center',
                          'border-bottom',
                          'search-hover',
                          'py-2',
                          'px-3'
                        )}
                        key={id}
                        onClick={() => handleRedirection(`/item-detail/${id}`)}
                      >
                        <div className="flex-shrink-0">
                          <Avatar
                            pfp={baseUrl + '/storage/' + attachments?.[0]?.path}
                            size="50"
                            name={title}
                          />
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <Link to="/" className="h5">
                            {title}
                          </Link>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                          <Link to="/" className="d-flex align-items-center">
                            <small>Item</small>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                {globalSearch?.[0]?.groups?.length > 0 &&
                  globalSearch?.[0]?.groups?.map((data: any) => {
                    const { id, name, profile_image } = data;
                    return (
                      <div
                        className={cls(
                          'd-flex',
                          'align-items-center',
                          'border-bottom',
                          'search-hover',
                          'py-2',
                          'px-3'
                        )}
                        key={id}
                        onClick={() =>
                          handleRedirection(`/groups/group-detail/${id}`)
                        }
                      >
                        <div className="flex-shrink-0">
                          <Avatar
                            pfp={baseUrl + '/' + profile_image}
                            size="50"
                            name={name}
                          />
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <Link to="/" className="h5">
                            {name}
                          </Link>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                          <Link to="/" className="d-flex align-items-center">
                            <small>Group</small>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default GlobalSearch;
