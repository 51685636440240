import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetUserNetworkQuery } from 'src/app/screens/profile/services/profileApi';
import NoData from '../NoData/NoData';
import Loader from '../Loader/Loader';

interface IFollowersCard {
  followers?: {
    image: string;
    name: string;
    link: string;
  }[];
  lg?: any;
  md?: any;
  xs?: any;
}

const FollowersCard = ({
  followers,
  lg = 3,
  md = 2,
  xs = 3,
}: IFollowersCard) => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { user } = useSelector((state: any) => state.auth);

  const { data: getUserNetwork = {}, isLoading } = useGetUserNetworkQuery(
    {
      user_id: id ? id : user?.uid,
      type: 'follower',
    },
    {
      skip: id ? !id : !user?.uid,
    }
  );

  const { data: userNetwork } = getUserNetwork || {};

  const shouldSlice =
    !pathname.includes('profile/followers') &&
    !pathname.includes('user-profile/followers');

  const renderUserNetwork = () => {
    return userNetwork
      ?.slice(0, shouldSlice ? 8 : undefined)
      ?.map?.((data: any) => {
        const {
          id,
          follower_profile,
          follower_name,
          follower_id,
          follower_username,
        } = data;
        return (
          <Col
            lg={lg}
            md={md}
            xs={xs}
            style={{
              marginBottom: '10px',
            }}
            key={id}
          >
            <div>
              <Link to={`/user-profile/${follower_id}`}>
                <img
                  loading="lazy"
                  src={follower_profile}
                  alt="gallary"
                  className="img-fluid"
                  style={{
                    width: '100%',
                  }}
                />
              </Link>
              <h6 className="mt-2 text-center">
                {follower_name || follower_username}
              </h6>
            </div>
          </Col>
        );
      });
  };

  const renderViewLink = () => {
    return (
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">Followers</h4>
        {!pathname.includes('profile/followers') &&
          !pathname?.includes('user-profile/followers') && (
            <Link
              to={!id ? '/profile/followers' : `/user-profile/followers/${id}`}
            >
              View
            </Link>
          )}
      </div>
    );
  };

  return (
    <>
      <Card>
        {renderViewLink()}
        <Card.Body>
          <Row>
            {!isLoading ? (
              userNetwork?.length > 0 ? (
                renderUserNetwork()
              ) : (
                <NoData name="followers" />
              )
            ) : (
              <Loader />
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default FollowersCard;
