import moment from 'moment';
import { lazy, Suspense, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SendRequestEnum } from 'src/app/config/constants/enums';
import { webBaseUrl } from 'src/app/config/types/base-url';
import useModalDialog from 'src/app/hooks/useModalDialog';
import {
  useDeletePostMutation,
  useGetGroupPostsQuery,
  useGetPostsQuery,
  useSharePostMutation,
} from 'src/app/screens/feed/services/feedApi';
import {
  useCancelRequestMutation,
  useGetUserPostsQuery,
  useSendRequestMutation,
} from 'src/app/screens/profile/services/profileApi';
import ReportUserModal from '../Modals/ReportUserModal';
import ShareProfileModal from '../Modals/ShareProfileModal';
import MoreOptions from '../MoreOptions/MoreOptions';
import PostContent from './PostContent';
import PostFooter from './PostFooter';
import PostHeader from './PostHeader';

const PostModal = lazy(() => import('../Modals/PostModal'));

type PostType = {
  home?: any;
  profile?: any;
  groupId?: number;
  isAdvertisement?: boolean;
};

const ShowMoreOptions = ({
  screenType,
  userId,
  loggedInUserId,
  postId,
  handleEditClick,
  handleReport,
  handleShare,
  handleSharePost,
  postType,
  isConnection,
  isFollow,
}: any) => {
  const history = useNavigate();

  const { user } = useSelector((state: any) => state.auth);

  const [deletePost, { isLoading }] = useDeletePostMutation();
  const [sendRequest, { isLoading: sendingRequest }] = useSendRequestMutation();
  const [cancelRequest, { isLoading: cancellingRequest }] =
    useCancelRequestMutation();

  const handleViewProfile = (id: any) => {
    user?.uid == id ? history('/profile') : history(`/user-profile/${id}`);
  };

  const handleCopyLink = (id: any, pid: any) => {
    navigator.clipboard.writeText(
      `${webBaseUrl}/user-profile/${id}/post-detail/${pid}`
    );
    toast.success('Link has been copied.');
  };

  const handleSendRequest = async (id: any, type: string) => {
    const bodyData = {
      followee_id: id,
      type: type == 'disconnect' ? 'connection' : type,
    };

    const response =
      type == 'follow' || type == 'connection'
        ? await sendRequest(bodyData)
        : await cancelRequest(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  const handleDeletePost = async (id: any) => {
    const response = await deletePost(id);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {screenType == 'home' && (
        <MoreOptions>
          <>
            {userId == loggedInUserId &&
              (postType == 'new' || postType == 'repost') && (
                <>
                  <Dropdown.Item className=" p-3" href="">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => handleDeletePost(postId)}
                    >
                      <span className="material-symbols-outlined">delete</span>
                      <div className="data ms-2">
                        <h6>Delete Post</h6>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className=" p-3" href="">
                    <div
                      className="d-flex align-items-center"
                      onClick={() => handleEditClick(postId)}
                    >
                      <span className="material-symbols-outlined">edit</span>
                      <div className="data ms-2">
                        <h6>Edit</h6>
                      </div>
                    </div>
                  </Dropdown.Item>
                </>
              )}
            <Dropdown.Item
              className=" p-3"
              href=""
              onClick={() => handleCopyLink(userId, postId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">content_copy</span>
                <div className="data ms-2">
                  <h6>Copy post link</h6>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=" p-3"
              href="#"
              onClick={() => handleViewProfile(userId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">badge</span>
                <div className="data ms-2">
                  <h6>View profile</h6>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=" p-3"
              href="#"
              onClick={() => handleShare(userId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">share</span>
                <div className="data ms-2">
                  <h6>Share profile</h6>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=" p-3"
              href="#"
              onClick={() => handleSharePost(postId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">post</span>
                <div className="data ms-2">
                  <h6>Repost</h6>
                </div>
              </div>
            </Dropdown.Item>
            {userId != loggedInUserId && (
              <>
                <Dropdown.Item
                  className=" p-3"
                  href="#"
                  onClick={() => {
                    const type =
                      isFollow == SendRequestEnum?.Accepted
                        ? 'follower'
                        : 'follow';
                    handleSendRequest(userId, type);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="material-symbols-outlined">
                      leak_remove
                    </span>
                    <div className="data ms-2">
                      <h6>
                        {isFollow == SendRequestEnum?.Accepted
                          ? 'UnFollow'
                          : isFollow == SendRequestEnum?.Pending
                          ? 'Follow Pending'
                          : 'Follow'}
                      </h6>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className=" p-3"
                  href="#"
                  onClick={() => {
                    const type =
                      isConnection == SendRequestEnum?.Accepted
                        ? 'disconnect'
                        : 'connection';
                    handleSendRequest(userId, type);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="material-symbols-outlined">stream</span>
                    <div className="data ms-2">
                      <h6>
                        {isConnection == SendRequestEnum?.Accepted
                          ? 'Disconnect'
                          : isConnection == SendRequestEnum?.Pending
                          ? 'Connect Pending'
                          : 'Connect'}
                      </h6>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className=" p-3"
                  href="#"
                  onClick={() => handleReport(postId)}
                >
                  <div className="d-flex align-items-center">
                    <span className="material-symbols-outlined">report</span>
                    <div className="data ms-2">
                      <h6>Report</h6>
                    </div>
                  </div>
                </Dropdown.Item>
              </>
            )}
          </>
        </MoreOptions>
      )}
      {screenType == 'profile' && loggedInUserId == userId && (
        <MoreOptions>
          <>
            <Dropdown.Item
              className=" p-3"
              href=""
              disabled={isLoading}
              onClick={() => handleDeletePost(postId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">delete</span>
                <div className="data ms-2">
                  <h6>Delete Post</h6>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item className=" p-3" href="">
              <div
                className="d-flex align-items-center"
                onClick={() => handleEditClick(postId)}
              >
                <span className="material-symbols-outlined">edit</span>
                <div className="data ms-2">
                  <h6>Edit</h6>
                </div>
              </div>
            </Dropdown.Item>
          </>
        </MoreOptions>
      )}
      {screenType == 'group' && (
        <MoreOptions>
          <>
            {userId == loggedInUserId && postType == 'new' && (
              <>
                <Dropdown.Item className=" p-3" href="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => handleDeletePost(postId)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                    <div className="data ms-2">
                      <h6>Delete Post</h6>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className=" p-3" href="">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => handleEditClick(postId)}
                  >
                    <span className="material-symbols-outlined">edit</span>
                    <div className="data ms-2">
                      <h6>Edit</h6>
                    </div>
                  </div>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item
              className=" p-3"
              href=""
              onClick={() => handleCopyLink(userId, postId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">content_copy</span>
                <div className="data ms-2">
                  <h6>Copy post link</h6>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=" p-3"
              href="#"
              onClick={() => handleReport(postId)}
            >
              <div className="d-flex align-items-center">
                <span className="material-symbols-outlined">report</span>
                <div className="data ms-2">
                  <h6>Report</h6>
                </div>
              </div>
            </Dropdown.Item>
          </>
        </MoreOptions>
      )}
    </>
  );
};

const Post = ({ groupId, home, profile, isAdvertisement }: PostType) => {
  const { id } = useParams();

  const { isOpen, open, close } = useModalDialog();
  const {
    isOpen: reportIsOpen,
    open: reportOpen,
    close: reportClose,
  } = useModalDialog();
  const {
    isOpen: shareIsOpen,
    open: shareOpen,
    close: shareClose,
  } = useModalDialog();

  const { user } = useSelector((state: any) => state.auth);

  const screenType = groupId ? 'group' : home ? 'home' : 'profile';

  const [editPostId, setEditPostId] = useState('');
  const [postId, setPostId] = useState('');
  const [userId, setUserId] = useState('');

  const { data: homePosts, isLoading: gettingHomePosts } = useGetPostsQuery(
    {},
    {
      skip: !home,
    }
  );
  const { data: profilePosts, isLoading: gettingProfielPosts } =
    useGetUserPostsQuery(id ? id : user?.uid, {
      skip: !profile,
    });
  const { data: groupPosts, isLoading: gettingGroupPosts } =
    useGetGroupPostsQuery({ groupId }, { skip: !groupId });

  const posts = homePosts || profilePosts || groupPosts;
  const isLoading =
    gettingHomePosts || gettingProfielPosts || gettingGroupPosts;

  const [sharePost, { isLoading: sharingPost }] = useSharePostMutation();

  const handleEditClick = (id: any) => {
    open();
    setEditPostId(id);
  };

  const handleReport = (id: any, userId: any) => {
    reportOpen();
    setPostId(id);
    setUserId(userId);
  };

  const handleShare = (userId: any) => {
    shareOpen();
    setUserId(userId);
  };

  const handleSharePost = async (postId: any) => {
    const bodyData = {
      parent_id: postId,
    };
    const response = await sharePost(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {!isLoading ? (
        posts?.data?.length > 0 ? (
          posts?.data?.map?.((data: any) => {
            const {
              id,
              user_id,
              parent_id,
              user_profile,
              fullname,
              post,
              attachments,
              total_comments,
              is_comments_allowed,
              created_at,
              total_likes,
              comments,
              is_liked,
              username,
              post_type,
              repost,
              is_connection,
              is_follow,
              is_advertisement,
              is_verified,
              group_id,
              group_name,
              is_edit,
            } = data;
            return (
              <Card key={id}>
                <Card.Body>
                  <div className="post-item">
                    <PostHeader
                      image={user_profile}
                      userId={user_id}
                      isPostEdit={is_edit}
                      name={fullname}
                      username={username}
                      isVerified={is_verified}
                      isAdvertisement={
                        is_advertisement == 'advertisement' ? true : false
                      }
                      date={moment(created_at).format('DD MMM')}
                      time={moment.utc(created_at).local().fromNow()}
                      loggedInUserPost={false}
                      postType={post_type}
                      repost={repost}
                      groupId={group_id}
                      groupName={group_name}
                      createdAt={created_at}
                    >
                      <ShowMoreOptions
                        screenType={screenType}
                        userId={user_id}
                        loggedInUserId={user?.uid}
                        postId={id}
                        postType={post_type}
                        handleEditClick={() => handleEditClick(id)}
                        handleReport={() => handleReport(id, user_id)}
                        handleShare={() => handleShare(user_id)}
                        handleSharePost={() =>
                          handleSharePost(parent_id ? parent_id : id)
                        }
                        isConnection={is_connection}
                        isFollow={is_follow}
                      />
                    </PostHeader>
                    <PostContent
                      postType={post_type}
                      repost={repost}
                      postData={data}
                      data={{
                        message: post,
                        image: attachments?.length == 1 && attachments[0],
                        multipleImages: attachments?.length > 1 && attachments,
                      }}
                    />
                    {is_advertisement != 'advertisement' && (
                      <PostFooter
                        postId={id}
                        sharePostId={parent_id ? parent_id : id}
                        isLiked={is_liked}
                        isCommentsAllowed={
                          is_comments_allowed === '1' ? false : true
                        }
                        totalLikes={total_likes}
                        totalComments={total_comments}
                        comments={comments}
                        image={user_profile}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <p>No record found</p>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Suspense fallback={<p>Loading...</p>}>
        <PostModal
          open={isOpen}
          close={close}
          type="edit"
          postId={editPostId}
        />
      </Suspense>

      <ReportUserModal
        open={reportIsOpen}
        close={reportClose}
        postId={postId}
        userId={userId}
      />

      <ShareProfileModal
        open={shareIsOpen}
        close={shareClose}
        userId={userId}
      />
    </>
  );
};

export default Post;
