import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AuthGuard from 'src/app/config/auth/AuthGuard'
import AddTeamForm from '../shared/components/Forms/AddTeamForm'
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader'
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar'

const ProfileAddTeam = () => {

    const history = useNavigate()

    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    {/* <Container
style={{
border: '0px solid red',
}}
> */}
                    {/* //todo: Get content from template and design acc to XD */}
                    <Row>
                        <Col md={10}>
                            <ProfileHeader
                                loggedInUserProfile={true}
                                myProfile={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <ProfileSidebar activeLink='profile-teams' />
                        </Col>
                        <Col md={8}>
                            <AddTeamForm />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default ProfileAddTeam