import team1 from "../assets/images/t1.jpg"
import team2 from "../assets/images/t2.jpg"
import team3 from "../assets/images/t3.jpg"
import team4 from "../assets/images/t4.jpg"
import team5 from "../assets/images/t5.jpg"
import team6 from "../assets/images/t6.jpg"

export const teamsData = [
    {
        image: team1,
        name: 'Alpha Team',
        link: '#',
    },
    {
        image: team2,
        name: 'Cricket Team',
        link: '#',
    },
    {
        image: team3,
        name: 'Basketball',
        link: '#',
    },
    {
        image: team4,
        name: 'Champions',
        link: '#',
    },
    {
        image: team5,
        name: 'Squard',
        link: '#',
    },
    {
        image: team6,
        name: 'Lead Alpha',
        link: '#',
    },
]