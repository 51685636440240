import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { objToParams } from 'src/app/config/helper';
import { baseUrl } from 'src/app/config/types/base-url';
import groupBgImage from '../../assets/images/profile-bg.jpg';
import Advertisement from '../shared/components/advertisement/Advertisement';
import Loader from '../shared/components/Loader';
import NoData from '../shared/components/NoData';
import ProfilePageHeader from '../shared/components/ProfilePageHeader';
import ScrollContainer from '../shared/components/ScrollContainer';
import GroupCard from './components/GroupCard';
import GroupsFilter from './components/GroupsFilter';
import GroupsHeader from './components/GroupsHeader';
import { useGetGroupsQuery } from './services/groupsApi';

const Groups = () => {
  const { userId, requested } = useParams();

  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState(0);
  const [sportNameId, setSportNameId] = useState(0);
  const [industryId, setIndustryId] = useState(0);
  const [groupName, setGroupName] = useState('');

  console.log('page===>', page);

  const { data, isFetching } = useGetGroupsQuery({
    params: objToParams({
      sports_category_id: categoryId,
      sports_name_id: sportNameId,
      sports_industry_id: industryId,
      page,
    }),
    userId,
    requested,
  });

  let dataToRender = data?.data;

  if (!(dataToRender?.length > 0)) {
    dataToRender = data?.data?.data;
  }

  const handleLoadMore = () => {
    setPage((prev) => {
      if (prev < data?.data?.last_page) return prev + 1;
      return prev;
    });
  };

  const renderGroups = () => {
    return dataToRender
      ?.filter((data: any) =>
        data?.name?.toLowerCase()?.includes(groupName?.toLowerCase())
      )
      ?.map?.((item: any) => {
        const {
          id,
          background_image,
          profile_image,
          name,
          description,
          post_count,
          group_member_count,
          user_id,
          group_type,
          group_member,
          group_user_status,
        } = item?.group || item || {};

        return (
          <Col lg={4} xl={3} className="mb-4" key={id}>
            <GroupCard
              id={id}
              groupBgImage={baseUrl + '/' + background_image}
              groupImage={baseUrl + '/' + profile_image}
              name={name}
              description={description}
              posts={post_count}
              members={group_member_count}
              groupMembers={group_member}
              ownerId={user_id}
              groupType={group_type}
              groupUserStatus={group_user_status || item?.group_user_status}
            />
          </Col>
        );
      });
  };

  useEffect(() => {
    setPage(1);
  }, [categoryId, sportNameId, industryId]);

  return (
    <AuthGuard>
      <>
        <ScrollContainer
          loading={isFetching && page != 1}
          loadMore={handleLoadMore}
        >
          <Row
            style={{
              border: '0px solid red',
            }}
          >
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Groups" image={groupBgImage} />
            </Col>
          </Row>
          <Row
            style={{
              border: '0px solid red',
            }}
          >
            <Col md={12} xl={10}>
              <GroupsHeader setPage={setPage} loading={isFetching} />
            </Col>
          </Row>
          {!userId && (
            <Row>
              <Col md={12} xl={10}>
                <GroupsFilter
                  categoryId={categoryId}
                  setCategoryId={setCategoryId}
                  setSportNameId={setSportNameId}
                  setIndustryId={setIndustryId}
                  setGroupName={setGroupName}
                />
              </Col>
            </Row>
          )}
          {page == 1 && isFetching ? (
            <Loader />
          ) : (
            <Row
              style={{
                border: '0px solid red',
              }}
            >
              <Col md={12} xl={10}>
                <Row>
                  {dataToRender?.length > 0 ? (
                    renderGroups()
                  ) : (
                    <NoData name="Group" />
                  )}
                </Row>
              </Col>
            </Row>
          )}
        </ScrollContainer>
        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default Groups;
