import moment from 'moment';
import { Image } from 'react-bootstrap';
import { baseUrl } from 'src/app/config/types/base-url';
import useModalDialog from 'src/app/hooks/useModalDialog';
import ImageSliderModal from '../ImageSliderModal/ImageSliderModal';
import PostHeader from './PostHeader';
import ReactPlayer from 'react-player';

interface IPostContent {
  data: any;
  postType?: any;
  repost?: any;
  postData?: any;
}

const renderImage = (image: any) => {
  return image?.fileExtension !== 'mp4' && image?.fileExtension !== 'mov' ? (
    <Image
      loading="lazy"
      src={`${baseUrl}/storage/${image?.path}`}
      alt="post"
      className="img-fluid w-100"
    />
  ) : (
    <video
      controls
      style={{
        width: '100%',
      }}
    >
      <source src={baseUrl + '/storage/' + image?.path} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const renderMessage = (message: string) => {
  return <p>{message}</p>;
};

const renderMultipleImages = (multipleImages: any) => {
  const { isOpen, open, close } = useModalDialog();
  return (
    <>
      <div
        className=""
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        }}
      >
        {multipleImages?.slice(0, 2)?.map?.((data: any, index: any) => (
          <div className="row-span-1 row-span-md-1" key={index}>
            {index === 1 ? (
              <div style={{ position: 'relative' }}>
                {data?.fileExtension !== 'mp4' &&
                data?.fileExtension !== 'mov' ? (
                  <img
                    loading="lazy"
                    src={`${baseUrl}/storage/${data?.path}`}
                    alt="post"
                    className="img-fluid w-100"
                    style={{
                      aspectRatio: '1/1',
                    }}
                  />
                ) : (
                  <video
                    controls
                    style={{
                      width: '100%',
                    }}
                  >
                    <source
                      src={baseUrl + '/storage/' + data?.path}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}

                {multipleImages?.length > 2 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#fff',
                      fontSize: '3rem',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                    onClick={open}
                  >
                    +{multipleImages?.length - 2}
                  </div>
                )}
              </div>
            ) : (
              index < 1 && (
                <>
                  {data?.fileExtension !== 'mp4' &&
                  data?.fileExtension !== 'mov' ? (
                    <img
                      loading="lazy"
                      src={`${baseUrl}/storage/${data?.path}`}
                      alt="post"
                      className="img-fluid w-100"
                      style={{
                        aspectRatio: '1/1',
                      }}
                    />
                  ) : (
                    <video
                      controls
                      style={{
                        width: '100%',
                      }}
                    >
                      <source
                        src={baseUrl + '/storage/' + data?.path}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )
            )}
          </div>
        ))}
      </div>
      <ImageSliderModal open={isOpen} close={close} images={multipleImages} />
    </>
  );
};

const PostContent = ({ data, postType, repost, postData }: IPostContent) => {
  return (
    <>
      <div className="user-post">
        {data?.message && renderMessage(data?.message)}
      </div>
      <div className="user-post">{data?.image && renderImage(data?.image)}</div>
      <div className="user-post">
        {data?.multipleImages && renderMultipleImages(data?.multipleImages)}
      </div>
      {postType == 'repost' && (
        <div
          style={{
            border: '1px solid #404040',
            padding: '24px',
            borderRadius: '8px',
          }}
        >
          <PostHeader
            // TODO: WILL REMOVE IT LATER
            // image={
            //   repost?.group_id
            //     ? `${baseUrl}/${repost?.group_image}`
            //     : repost?.user_profile
            // }
            image={repost?.user_profile}
            userId={repost?.user_id}
            // TODO: WILL REMOVE IT LATER
            // name={repost?.group_id ? repost?.group_name : repost?.fullname}
            name={repost?.fullname}
            username={repost?.username}
            postType="shared"
            isVerified={repost?.is_verified}
            isPostEdit={repost?.is_edit}
            isAdvertisement={false}
            date={moment(repost?.created_at).format('DD MMM')}
            time={moment.utc(repost?.created_at).local().fromNow()}
            loggedInUserPost={false}
            groupId={repost?.group_id}
            groupName={repost?.group_name}
          />
          <PostContent
            data={{
              message: repost?.post,
              image:
                repost?.attachments?.length == 1 && repost?.attachments?.[0],
              multipleImages:
                repost?.attachments?.length > 1 && repost?.attachments,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PostContent;
