import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';
import TeamCard from '../shared/components/TeamCard/TeamCard';

const UserTeams = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <TeamCard profile loggedInUserProfile={false} />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default UserTeams;
