import { useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSrc, objToFormData } from 'src/app/config/helper';
import Loader from '../../../shared/components/Loader';
import ProfilePageHeader from '../../../shared/components/ProfilePageHeader';
import {
  useGetCategoriesQuery,
  useGetIndustriesQuery,
  useGetNamesQuery,
} from '../../../shared/services/sharedApi';
import createGroupSchema from '../../schema/createGroup.schema';
import {
  useAddGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from '../../services/groupsApi';

const CreateGroupForm = () => {
  const defaultValues = {
    profile_image: null,
    background_image: null,
    name: '',
    sports_industry_id: 0,
    sports_category_id: 0,
    sports_name_id: 0,
    location: '',
    description: '',
    rules: '',
    group_type: '',
  };

  const { id }: any = useParams();

  const groupId = parseInt(id);

  let navigate = useNavigate();

  const ref = useRef<any>([]);

  const [addGroup, { isLoading }] = useAddGroupMutation();
  const [updateGroup, { isLoading: editingGroup }] = useUpdateGroupMutation();

  const { data: group, isLoading: gettingGroup } = useGetGroupQuery(
    {
      groupId: groupId,
    },
    { skip: !groupId }
  );

  const { data: industries, isLoading: gettingIndustries } =
    useGetIndustriesQuery({});
  const { data: categories, isLoading: gettingCategories } =
    useGetCategoriesQuery({});

  const categoryList = categories?.data?.map?.(({ uid, title }: any) => ({
    id: uid,
    name: title,
  }));
  const industryList = industries?.data?.map?.(({ uid, title }: any) => ({
    id: uid,
    name: title,
  }));

  const {
    theme: {
      palette: { button, border },
    },
  }: any = useSelector((state: any) => state.styles);
  const { user }: any = useSelector((state: any) => state.auth);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  }: any = useForm({
    defaultValues,
    resolver: createGroupSchema,
  });

  const watchSportsCategoryId = watch('sports_category_id');
  const watchProfileImage: any = watch('profile_image');
  const watchBackgroundImage: any = watch('background_image');

  const { data: sportNames, isLoading: gettingSportNames } = useGetNamesQuery(
    watchSportsCategoryId,
    { skip: !watchSportsCategoryId }
  );

  const sportNameList = sportNames?.data?.map?.(({ uid, title }: any) => ({
    id: uid,
    name: title,
  }));

  const handleChangePfp = () => {
    ref.current[0]?.click();
  };
  const handleChangeBackground = () => {
    ref.current[1]?.click();
  };
  const handleSave = handleSubmit(async (data: any) => {
    let response;

    const formData = objToFormData({
      ...data,
      group_id: groupId,
    });

    if (groupId)
      response = await updateGroup({
        id: groupId,
        body: formData,
      });
    else
      response = await addGroup({
        body: formData,
      });

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.errors?.email || error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      navigate(`/groups/${user?.uid}`);
    }
  });

  useEffect(() => {
    if (group) {
      const { ...groupData } = group?.data;

      reset({
        ...groupData,
      });
    }
  }, [group]);

  useEffect(() => {
    if (errors?.background_image)
      toast.error(errors?.background_image.message, {
        id: 'background_image',
      });
    if (errors?.profile_image)
      toast.error(errors?.profile_image.message, {
        id: 'profile_image',
      });
  }, [errors]);

  return (
    <>
      <Row>
        <Col md={12} xl={10}>
          <ProfilePageHeader
            title="Groups"
            image={getSrc(watchBackgroundImage)}
            pfp={getSrc(watchProfileImage)}
            changePfp={handleChangePfp}
            changeBackground={handleChangeBackground}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          xl={10}
          style={{
            marginTop: '16px',
          }}
        >
          <Card>
            <div className="card-header d-flex justify-content-between">
              <div
                className="header-title"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h4
                  className="card-title"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Create Group
                </h4>
                <p
                  style={{
                    color: '#FF0000',
                  }}
                  className="mb-0"
                >
                  * indicates Required
                </p>
              </div>
            </div>
            <Card.Body>
              <div className="form-card text-start">
                {isLoading ||
                gettingGroup ||
                gettingIndustries ||
                gettingCategories ? (
                  <Loader />
                ) : (
                  <Row>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Group Name: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register('name')}
                          placeholder="Group Name"
                        />
                        <p className="text-danger">{errors?.name?.message}</p>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Sports Industry:{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select {...register('sports_industry_id')}>
                          {industryList?.map?.((data: any) => {
                            const { id, name } = data;
                            return <option value={id}>{name}</option>;
                          })}
                        </Form.Select>
                        <p className="text-danger">
                          {errors?.sports_industry_id?.message}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Sports Category:{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          {...register('sports_category_id')}
                          aria-label="Default select example"
                        >
                          {categoryList?.map?.((data: any) => {
                            const { id, name } = data;
                            return <option value={id}>{name}</option>;
                          })}
                        </Form.Select>
                        <p className="text-danger">
                          {errors?.sports_category_id?.message}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Sports Name: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          {...register('sports_name_id')}
                          aria-label="Default select example"
                        >
                          {sportNameList?.map?.((data: any) => {
                            const { id, name } = data;
                            return <option value={id}>{name}</option>;
                          })}
                        </Form.Select>
                        <p className="text-danger">
                          {errors?.sports_name_id?.message}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Location: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          {...register('location')}
                          type="text"
                          name="location"
                          placeholder="Location"
                        />
                        <p className="text-danger">
                          {errors?.location?.message}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          Description: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description"
                          {...register('description')}
                        />
                        <p className="text-danger">
                          {errors?.description?.message}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          Rules: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          {...register('rules')}
                          as="textarea"
                          rows={3}
                          placeholder="Rules"
                        />
                        <p className="text-danger">{errors?.rules?.message}</p>
                      </Form.Group>
                    </Col>
                    <Form.Label className="mt-4">
                      Group Type: <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md="12">
                      <Form.Check
                        type="radio"
                        {...register('group_type')}
                        value="public"
                        label={
                          <>
                            <div>Public</div>
                            <div>
                              Anyone on Sportconn platform will be able to see
                              all post, create post, and add comments within
                              this group.
                            </div>
                          </>
                        }
                      />
                    </Col>
                    <Col md="12" className="mt-2">
                      <Form.Check
                        type="radio"
                        {...register('group_type')}
                        value="private"
                        label={
                          <>
                            <div>Private</div>
                            <div>Only members can see posts in the group.</div>
                          </>
                        }
                      />
                      <p className="text-danger">
                        {errors?.group_type?.message}
                      </p>
                    </Col>
                    <input
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      ref={(el) => (ref.current[0] = el)}
                      type="file"
                      name="profile_image"
                      onChange={() =>
                        setValue('profile_image', ref.current[0].files[0])
                      }
                    />
                    <input
                      accept="image/png, image/jpeg"
                      style={{ display: 'none' }}
                      ref={(el) => (ref.current[1] = el)}
                      type="file"
                      name="background_image"
                      onChange={() =>
                        setValue('background_image', ref.current[1].files[0])
                      }
                    />
                  </Row>
                )}
                <Button
                  disabled={
                    isLoading ||
                    editingGroup ||
                    gettingGroup ||
                    gettingIndustries ||
                    gettingCategories ||
                    gettingSportNames
                  }
                  variant="primary"
                  className="float-end"
                  onClick={handleSave}
                  style={{
                    background: button.background,
                    borderColor: border.dark,
                  }}
                >
                  {groupId ? 'Update' : 'Create'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateGroupForm;
