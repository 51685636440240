import { Card, Col, Image, Row } from 'react-bootstrap';
import {
  useGetEducationQuery,
  useGetPersonalInformationQuery,
  useGetWorkExperienceQuery,
} from 'src/app/screens/profile/services/profileApi';

const Resume = () => {
  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation || {};
  const { data: getEducation, isLoading: gettingEducation } =
    useGetEducationQuery({});
  const { data: education = [] } = getEducation || {};
  const { data: getWorkExperience, isLoading: gettingWorkExperience } =
    useGetWorkExperienceQuery({});
  const { data: workExperience = [] } = getWorkExperience || {};

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Profile</h4>
          </div>
        </div>
        <Card.Body>
          <h4 className="fw-bold mb-3 mt-5">Personal Details</h4>
          <div className="d-flex justidy-content-center flex-column align-items-center mt-3">
            <Image
              src={personalInformation?.profile_image}
              alt="Profile image"
              className="rounded-circle"
              style={{
                width: '120px',
              }}
            />
            <h4 className="fw-bold mt-3">{personalInformation?.full_name}</h4>
            <p className="mb-5">{personalInformation?.roles?.title}</p>
          </div>
          <Row className="mb-2">
            <h4 className="fw-bold mt-4 mb-3">Personal Details</h4>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">User Id:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.user_id || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Profile Url:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.profile_url || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Email ID:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.email_id || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Username:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.username || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Full Name:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.full_name || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Gender:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.gender || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Date of birth:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.date_of_birth || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Place of birth:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.place_of_birth || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Nationality:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.nationality || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Headline:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.headline || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Contact Type:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.contact_type || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Contact Number:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.contact_number || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <hr />

          <h4 className="fw-bold mb-3 mt-5">Work Experience</h4>
          {workExperience?.map?.((data: any) => {
            const {
              job_title,
              niche_industry,
              sport_type,
              employment_type,
              sport_name,
              location,
              sport_category,
              company,
            } = data;
            return (
              <div style={{ marginBottom: '16px' }}>
                <Row className="mb-2">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Title/Role:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{job_title || 'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Niche/Industry:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">
                          {niche_industry?.title || 'N/A'}
                        </h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Sports Type:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{sport_type?.title || 'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Employment Type:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{employment_type?.title}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Sports Name:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{sport_name?.title || 'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Location:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{location || 'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Sports Categories:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">
                          {sport_category?.title || 'N/A'}
                        </h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Company Name:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{company || 'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Education</h4>
          {education?.map?.((data: any) => {
            const {
              education_level,
              college_university,
              field_of_study,
              start_date,
              end_date,
            } = data;
            return (
              <div style={{ marginBottom: '16px' }}>
                <Row className="mb-2">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Education Level:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{education_level?.title}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">College/University:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{college_university}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Field of Study:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{field_of_study}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Start Date:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{start_date}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">End Date (Or Expected):</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{end_date}</h5>
                      </div>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <div className="col-6">
                        <h5 className="fw-bold">Grade:</h5>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">{'N/A'}</h5>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Sporting Career</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Sport Played:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.sport_played || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Position & Specialization:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.position_specialization || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Teams or Clubs Played for:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.teams_clubs_played_for || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">
                    Important Milestone & Achievements:
                  </h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.important_milestone_achievements ||
                      'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Career Statics (if applicable):</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.career_statics || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Accomplishments</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Major Award & Honors:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.major_award_honors || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Records held (if any):</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.records_held || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Notable Performances:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.notable_performances || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Physical Attributes</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Height:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.height || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Weight</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.weight || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Strength & Weakness:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.strength_weakness || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Profession</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Niche/Industry:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.niche_industry?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Roles:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.roles?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Sports Categories:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.sports_categories?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Sports Name:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.sport_name?.title || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Current Status</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Current Team Or Affiliation:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.current_team_affiliation || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Injury Status (if relevant):</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.injury_status || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Upcoming events or competitions:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.upcoming_events_competitions || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Address Details</h4>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Address:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.address || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">City:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">{personalInformation?.city || 'N/A'}</h5>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">Zip Code:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.zip_code || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <div className="col-6">
                  <h5 className="fw-bold">State:</h5>
                </div>
                <div className="col-6">
                  <h5 className="mb-0">
                    {personalInformation?.state || 'N/A'}
                  </h5>
                </div>
              </Row>
            </Col>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Personal Life</h4>
          <Row className="mb-2">
            <div className="col-4">
              <h5 className="fw-bold">Hobbies & Interests Outside Sports:</h5>
            </div>
            <div className="col-6">
              <h5 className="mb-0">
                {personalInformation?.hobbies_interests_outside_sports || 'N/A'}
              </h5>
            </div>
          </Row>
          <Row className="mb-5">
            <div className="col-4">
              <h5 className="fw-bold">Family information (optional):</h5>
            </div>
            <div className="col-6">
              <h5 className="mb-0">
                {personalInformation?.family_information || 'N/A'}
              </h5>
            </div>
          </Row>

          <hr />

          <h4 className="fw-bold mb-3 mt-5">Quotes & Interviews</h4>
          <Row className="mb-2">
            <div className="col-4">
              <h5 className="fw-bold">
                Any Noteworthy Statements Or Interviews :
              </h5>
            </div>
            <div className="col-7">
              <h5 className="mb-0">
                {personalInformation?.any_noteworthy_statements_interviews ||
                  'N/A'}
              </h5>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Resume;
