import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    confirm_password_validator,
    password_validator
} from "src/app/config/types/validators"
import {
    object
} from "yup"

const setNewPasswordSchema = object().shape({
    password: password_validator,
    password_confirmation: confirm_password_validator,
})

export default yupResolver(setNewPasswordSchema)