import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ApplePay from './../../../../assets/images/apple-pay.png';
import ApplePayWhite from './../../../../assets/images/apple-pay-white.png';
import Paypal from './../../../../assets/images/paypal.png';
import Stripe from './../../../../assets/images/stripe.png';
import { useForm } from 'react-hook-form';
import {
  useCreateAdvertisementMutation,
  useGetClientSecretMutation,
} from '../advertisement/services/advertisementApi';
import { toast } from 'react-hot-toast';
import { objToFormData } from 'src/app/config/helper';
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from '@paypal/react-paypal-js';
import actions from '../../store/setting/actions';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { stripePublishableKey } from 'src/app/config/types/keys';

let stripePromise = loadStripe(stripePublishableKey);
interface IPaymentMethodModal {
  open: boolean;
  close: () => void;
  audienceData: any;
}

const CheckoutForm = ({ data, paymentType }: any) => {
  const history = useNavigate();

  const stripe: any = useStripe();
  const elements: any = useElements();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const { theme, mode } = useSelector((state: any) => state.styles);
  const {
    palette: { background, button, border },
  } = theme;

  const [createAdvertisement, { isLoading }] = useCreateAdvertisementMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    setLoading(false);

    if (error) {
      setError(error.message);
      setSucceeded(false);
    } else {
      setError(null);
      setSucceeded(true);
      const bodyData = {
        ...data,
        token: token?.id,
      };

      const formData = objToFormData(bodyData);

      const response = await createAdvertisement(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        close();
        history('/');
      }
    }
  };

  const cardElementStyle = {
    base: {
      fontSize: '16px',
      fontFamily: 'Arial, sans-serif',
      color: 'white',
      border: '1px solid grey',
    },
  };

  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  return (
    <form onSubmit={handleSubmit} className="mt-3">
      {paymentType == 'stripe' && (
        <>
          <CardElement
            options={{
              style: cardElementStyle,
            }}
          />
          <Button
            variant="primary"
            type="submit"
            disabled={!stripe || loading}
            className="float-end mt-2"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
          >
            Pay
          </Button>
        </>
      )}
      {error && <div>{error}</div>}
      {succeeded && <div>Payment successful!</div>}
      {paymentRequest && paymentType == 'applePay' && (
        <div className="mt-3">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}
    </form>
  );
};

const PaymentMethodModal = ({
  open,
  close,
  audienceData,
}: IPaymentMethodModal) => {
  const defaultValues = {
    transaction_id: '',
    card_number: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
  };

  const initialOptions = {
    clientId:
      'AQR6uj_WQc5eTBKu9slXNbbZhfzuymlKkJVUxNYieA6WL6QNGBKz0dVOvxLUdZa2fd2uAjOsw8d8nEdx',
    currency: 'USD',
    intent: 'capture',
  };

  const history = useNavigate();

  const { theme, mode } = useSelector((state: any) => state.styles);

  const [paymentType, setPaymentType] = useState('stripe');

  const {
    palette: { background, button, border },
  } = theme;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [createAdvertisement, { isLoading }] = useCreateAdvertisementMutation();

  const options: any = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
  };

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: '100',
          },
        },
      ],
    });
  };

  const handleCreateAdvertisement = handleSubmit(async (data: any) => {
    const bodyData = {
      payment_type: paymentType,
      ...audienceData,
      ...data,
    };
    const formData = objToFormData(bodyData);

    const response = await createAdvertisement(formData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      close();
      history('/');
    }
  });

  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then((details: any) => {
      setValue('transaction_id', details?.id);
      handleCreateAdvertisement();
    });
  };

  return (
    <>
      <Modal centered show={open} onHide={close}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            Payment Method
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                marginTop: '8px',
              }}
            >
              <Form.Check
                type="radio"
                value="paypal"
                onChange={(e) => setPaymentType(e.target.value)}
                name="condition"
              />
              <img src={Paypal} alt="PayPal" width={80} />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                marginTop: '8px',
              }}
            >
              <Form.Check
                type="radio"
                value="applePay"
                onChange={(e) => setPaymentType(e.target.value)}
                name="condition"
              />
              <img
                src={mode == 'dark' ? ApplePayWhite : ApplePay}
                alt="Apple Pay"
                width={40}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                marginTop: '8px',
              }}
            >
              <Form.Check
                type="radio"
                value="stripe"
                onChange={(e) => setPaymentType(e.target.value)}
                name="condition"
                defaultChecked={true}
              />
              <img src={Stripe} alt="Credit Card" width={60} />
            </div>
          </Row>
          {paymentType == 'paypal' && (
            <div className="mt-5">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  style={{
                    layout: 'horizontal',
                    color: 'blue',
                    tagline: false,
                    label: 'pay',
                  }}
                  fundingSource={FUNDING.PAYPAL}
                  createOrder={(data: any, actions: any) =>
                    createOrder(data, actions)
                  }
                  onApprove={(data: any, actions: any) =>
                    onApprove(data, actions)
                  }
                />
              </PayPalScriptProvider>
            </div>
          )}
          {(paymentType == 'stripe' || paymentType == 'applePay') && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                data={{
                  payment_type: paymentType,
                  ...audienceData,
                }}
                paymentType={paymentType}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentMethodModal;
