import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetEducationLevelQuery } from '../../services/sharedApi';
import { useAddEducationMutation } from 'src/app/screens/profile/services/profileApi';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { setProfileCompletion } from 'src/app/screens/auth/store/authSlice';

const AddEducationForm = () => {
  const defaultValues = {
    education_level_id: '',
    college_university: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    grade: '',
  };

  const history = useNavigate();

  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);

  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border },
  } = theme;

  const { data: getEducationLevel } = useGetEducationLevelQuery({});

  const [addEducation, { isLoading }] = useAddEducationMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchEducationLevel = watch('education_level_id');

  const emptyInputFields = () => {
    setValue('education_level_id', '');
    setValue('college_university', '');
    setValue('field_of_study', '');
    setValue('start_date', '');
    setValue('end_date', '');
    setValue('grade', '');
  };

  const handleAddEducation = handleSubmit(async (data: any) => {
    if (watchEducationLevel != '') {
      const response = await addEducation(data);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        if (user?.profile_completion?.educationInfo == 0) {
          dispatch(
            setProfileCompletion({
              ...user,
              profile_completion: {
                ...user?.profile_completion,
                educationInfo: 1,
              },
            })
          );
          user?.profile_completion?.workExperience == 0
            ? history('/profile/add-workplace-experience')
            : user?.profile_completion?.teamsInfo == 0
            ? history('/profile/profile-add-teams')
            : history('/');
        }
        emptyInputFields();
      }
    } else {
      toast.error('Add atleast education level to complete the step');
    }
  });

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Education</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <Col md="5">
                <h5 className="fw-bold">Education Level:</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Select
                    aria-label="Default select example"
                    {...register('education_level_id')}
                  >
                    <option>Select</option>
                    {getEducationLevel?.data?.map?.((data: any) => {
                      const { uid, title } = data;
                      return <option value={uid}>{title}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <h5 className="fw-bold">College/University:</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    placeholder="College/University"
                    {...register('college_university')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <h5 className="fw-bold">Field of Study:</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    placeholder="Field of Study"
                    {...register('field_of_study')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <h5 className="fw-bold">Start Date:</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    {...register('start_date')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <h5 className="fw-bold">End Date (Or Expected):</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Control
                    type="date"
                    placeholder="End Date (Or Expected)"
                    {...register('end_date')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <h5 className="fw-bold">Grade:</h5>
              </Col>
              <Col md="7">
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    placeholder="Grade"
                    {...register('grade')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
              onClick={handleAddEducation}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddEducationForm;
