import { useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserNetworkQuery } from 'src/app/screens/profile/services/profileApi';
import {
  setChatType,
  setOpenChat,
  setReceiverId,
} from '../../store/sharedSlice';
import Avatar from '../Avatar/Avatar';
import NoData from '../NoData/NoData';

const RightSidebar = () => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState('');

  const { theme } = useSelector((state: any) => state.styles);
  const { openChat } = useSelector((state: any) => state.shared);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background },
  } = theme;

  const { data: getUserNetwork = {}, isLoading } = useGetUserNetworkQuery(
    {
      user_id: user?.uid,
      type: 'connection',
    },
    {
      skip: !user?.uid,
    }
  );

  const { data: userNetwork } = getUserNetwork || {};

  const minirightsidebar = () => {
    document &&
      document
        ?.getElementById('rightSidebar')
        ?.classList?.toggle('right-sidebar');
    document.body.classList.toggle('right-sidebar-close');
  };

  const handleOpenChat = (id: any) => {
    dispatch(setOpenChat(true));
    dispatch(setReceiverId(id));
    dispatch(setChatType('general'));
  };

  return (
    <>
      <div className="right-sidebar-mini" id="rightSidebar"
      >
        <div className="right-sidebar-panel p-0">
          <Card className="shadow-none">
            <Card.Body className="p-0">
              <div className="media-height p-3" data-scrollbar="init">
                <div
                  className="iq-search-bar device-search position-relative"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search"
                      style={{
                        borderRight: 0,
                      }}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{
                        backgroundColor: 'transparent',
                        borderLeft: '0',
                      }}
                    >
                      <span className="material-symbols-outlined">search</span>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                {userNetwork?.length > 0 ? (
                  userNetwork
                    ?.filter((item: any) => {
                      if (!searchInput) {
                        return true;
                      } else {
                        return (
                          item?.follower_name
                            ?.toLowerCase()
                            ?.includes(searchInput) ||
                          item?.follower_username
                            ?.toLowerCase()
                            ?.includes(searchInput)
                        );
                      }
                    })
                    ?.map(
                      ({
                        follower_profile,
                        follower_name,
                        onlineStatus,
                        id,
                        follower_id,
                        follower_username,
                        active_status,
                      }: any) => {
                        return (
                          <div
                            key={id}
                            className="d-flex align-items-center mb-4"
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => handleOpenChat(follower_id)}
                          >
                            <div
                              className={
                                active_status == '1'
                                  ? `iq-profile-avatar status-online`
                                  : ''
                              }
                            >
                              <Avatar
                                pfp={follower_profile}
                                size="50"
                                name={follower_name || follower_username}
                              />
                            </div>
                            <div className="ms-3">
                              <h6 className="mb-0">
                                {follower_name || follower_username}
                              </h6>
                              <p className="mb-0">{onlineStatus}</p>
                            </div>
                          </div>
                        );
                      }
                    )
                ) : (
                  <NoData name="connections" />
                )}
              </div>
              <div
                className="right-sidebar-toggle text-white mt-3 d-flex"
                style={{
                  background: background.dark,
                }}
                onClick={minirightsidebar}
              >
                <span className="material-symbols-outlined">chat</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
