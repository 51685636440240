import React, { useState } from 'react';

interface IPostComment {
  totalComments?: string;
}

const PostComment = ({ totalComments }: IPostComment) => {
  return (
    <>
      <div
        className="d-flex gap-2 align-items-center"
        style={{
          cursor: 'pointer',
        }}
      >
        <span className="material-symbols-outlined">chat</span>
        <p className="mb-0">
          {totalComments}{' '}
          <span className="custom-post-footer-text">Comments</span>
        </p>
      </div>
    </>
  );
};

export default PostComment;
