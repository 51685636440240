import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    string_required_validator
} from "src/app/config/types/validators"
import {
    object
} from "yup"

import * as yup from 'yup';

const postAdvertisementFormSchema = object().shape({
    post: string_required_validator,
    external_link: yup.string().url('External link must be a valid URL').nullable()
})

export default yupResolver(postAdvertisementFormSchema)