import { yupResolver } from '@hookform/resolvers/yup';
import {
  email_validator,
  string_required_validator,
} from 'src/app/config/types/validators';
import { object } from 'yup';

const postJobFormSchema = object().shape({
  sport_industry_id: string_required_validator,
  role_id: string_required_validator,
  sport_category_id: string_required_validator,
  sport_name_id: string_required_validator,
  job_type_id: string_required_validator,
  job_category_id: string_required_validator,
  email: email_validator,
  title: string_required_validator,
  company: string_required_validator,
});

export default yupResolver(postJobFormSchema);
