import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader'
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar'
import WorkContainer from '../shared/components/WorkContainer/WorkContainer'
import WorkCard from '../shared/components/WorkCard/WorkCard'
import { useNavigate } from 'react-router-dom'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const WorkExperience = () => {

    const history = useNavigate()

    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    <Row>
                        <Col md={10}>
                            <ProfileHeader
                                loggedInUserProfile={true}
                                myProfile={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <ProfileSidebar activeLink='work-experience' />
                        </Col>
                        <Col md={8}>
                            <WorkContainer
                                title="Work Experience"
                                work={true}
                            >
                                <WorkCard
                                    redirectTo="/profile/workplace-detail"
                                    workExperience={true}
                                />
                            </WorkContainer>
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default WorkExperience