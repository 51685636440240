import {
    Col,
    Row
} from 'react-bootstrap'
import { followSuggestionsData } from 'src/app/mock/followSuggestions'
import image01 from "../../assets/images/01.jpg"
import FollowSuggestion from '../shared/components/FollowSuggestion'
import PostAdvertisementForm from '../shared/components/Forms/PostAdvertisementForm'
import MiniProfileCard from '../shared/components/MiniProfileCard'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const PostAdvertisement = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                            <MiniProfileCard />
                            <FollowSuggestion
                                users={followSuggestionsData}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <PostAdvertisementForm />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default PostAdvertisement