import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useModalDialog from 'src/app/hooks/useModalDialog';
import PaymentMethodModal from './PaymentMethodModal';
import { useForm } from 'react-hook-form';
import { useAdvertisementPlansQuery } from '../advertisement/services/advertisementApi';

const prices = ['$200', '$400', '$900', '$1500', '$4500'];

interface IAdvertisementAudienceModal {
  open: boolean;
  close: () => void;
  data: any;
}

const AdvertisementAudienceModal = ({
  open,
  close,
  data,
}: IAdvertisementAudienceModal) => {
  const defaultValues = {
    target_audience: '',
    price: '',
  };

  const history = useNavigate();
  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const [targetAudience, setTargetAudience] = useState('');
  const [price, setPrice] = useState('');
  const [audienceData, setAudienceData] = useState({});

  const {
    isOpen,
    open: paymentMethodOpen,
    close: paymentMethodClose,
  } = useModalDialog();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { data: getAdvertisementPlans } = useAdvertisementPlansQuery({});
  const { data: advertisementPlans } = getAdvertisementPlans || {};

  const handleChangeTargetAudience = (e: any) => {
    setTargetAudience(e.target.value);
    const audiencePrice = advertisementPlans?.filter(
      (data: any) => data?.id == e.target.value
    )[0]?.price;
    setPrice(audiencePrice);
    setValue('target_audience', e.target.value);
    setValue('price', audiencePrice);
  };

  const handlePostAdvertisementData = handleSubmit(async (data: any) => {
    setAudienceData(data);
    paymentMethodOpen();
    close();
  });

  return (
    <>
      <Modal centered show={open} onHide={close}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            Audience
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form.Group className="form-group">
                <Form.Label>Target Audience</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={targetAudience}
                  onChange={(e) => handleChangeTargetAudience(e)}
                >
                  <option>Select</option>
                  {advertisementPlans?.map?.((data: any) => {
                    const { id, target_audience } = data;
                    return (
                      <option key={id} value={id}>
                        {target_audience}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group className="form-group">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Price"
                  disabled
                  value={price}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            type="button"
            className="float-end"
            style={{
              background: button.background,
              borderColor: border.dark,
            }}
            onClick={handlePostAdvertisementData}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>

      <PaymentMethodModal
        open={isOpen}
        close={paymentMethodClose}
        audienceData={{ ...audienceData, ...data }}
      />
    </>
  );
};

export default AdvertisementAudienceModal;
