import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetPersonalInformationQuery,
  useUpdatePersonalInformationMutation,
} from 'src/app/screens/profile/services/profileApi';
import {
  useGetBusinessSizesQuery,
  useGetCategoriesQuery,
  useGetIndustriesQuery,
  useLazyGetNamesQuery,
  useLazyGetRolesQuery,
  useLazyGetSportTypesQuery,
} from '../../services/sharedApi';
import personalInformationFormSchema from './schema/personalInformationForm.schema';
import { toast } from 'react-hot-toast';
import { setProfileCompletion } from 'src/app/screens/auth/store/authSlice';
import {
  convertNullToEmptyString,
  getFilePreviewURL,
} from 'src/app/config/helper';
import { setBusinessDetails, setScrollToBottom } from '../../store/sharedSlice';

const PersonalInformationForm = () => {
  const defaultValues = {
    user_id: '',
    username: '',
    profile_url: '',
    email_id: '',
    full_name: '',
    gender: '',
    date_of_birth: '',
    place_of_birth: '',
    is_business_exist: 0,
    headline: '',
    nationality: '',
    contact_type: '',
    contact_number: '',
    favorite_player_Team: '',
    brief_background_biography: '',
    early_life_upbringing: '',
    education: '',
    sport_played: '',
    position_specialization: '',
    teams_clubs_played_for: '',
    important_milestone_achievements: '',
    career_statics: '',
    major_award_honors: '',
    records_held: '',
    notable_performances: '',
    current_team_affiliation: '',
    injury_status: '',
    upcoming_events_competitions: '',
    height: '',
    weight: '',
    strength_weakness: '',
    hobbies_interests_outside_sports: '',
    family_information: '',
    any_noteworthy_statements_interviews: '',
    address: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
    niche_industry: '',
    roles: '',
    sports_categories: '',
    sport_name: '',
    sports_name_sub_category_id: '',
    company_name: '',
    website: '',
    sports_industry: '',
    business_size: '',
    description: '',
    tag_line: '',
    verify_authorized_representative: '',
    profile_image: {},
    team_photos_logos_videos: '',
    logo: {},
  };

  const history = useNavigate();
  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);
  const { businessDetails, scrollToBottom } = useSelector(
    (state: any) => state.shared
  );

  const {
    palette: { background, button, border, textInput },
  } = theme;

  const [industryNiche, setIndustryNiche] = useState('');
  const [roles, setRoles] = useState([]);
  const [category, setCategory] = useState('');
  const [names, setNames] = useState([]);
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  // const [businessDetails, setBusinessDetails] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [selectedLogo, setSelectedLogo] = useState('');
  const [sportTypes, setSportTypes] = useState([]);
  const [sportType, setSportType] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: personalInformationFormSchema,
  });

  const watchProfileImage = watch('profile_image');

  const watchLogo = watch('logo');

  const { data: allIndustries } = useGetIndustriesQuery({});

  const [getRoles] = useLazyGetRolesQuery();

  const { data: allCategories } = useGetCategoriesQuery({});

  const { data: allBusinessSizes } = useGetBusinessSizesQuery({});

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const [getNames] = useLazyGetNamesQuery();

  const [getSportTypes] = useLazyGetSportTypesQuery();

  const [updatePersonalInformation, { isLoading }] =
    useUpdatePersonalInformationMutation();

  const checkBuinessDetailAvailability = (data: any) => {
    if (
      data?.company_name ||
      data?.website ||
      data?.sports_industry ||
      data?.business_size ||
      data?.description ||
      data?.tag_line ||
      data?.verify_authorized_representative
    )
      return true;
    else return false;
  };

  const populateData = async () => {
    reset({
      ...convertNullToEmptyString(personalInformation),
      niche_industry: personalInformation?.niche_industry?.id,
      roles: personalInformation?.roles?.id,
      sports_categories: personalInformation?.sports_categories?.id,
      sport_name: personalInformation?.sport_name?.id,
      sports_name_sub_category_id:
        personalInformation?.sport_name_subcategory?.id,
      sports_industry: personalInformation?.sports_industry?.id,
      business_size: personalInformation?.business_size?.id,
    });

    if (checkBuinessDetailAvailability(personalInformation)) {
      dispatch(setBusinessDetails(true));
    }

    setIndustryNiche(personalInformation?.niche_industry?.title);
    setCategory(personalInformation?.sports_categories?.title);
    setRole(personalInformation?.roles?.title);
    setName(personalInformation?.sport_name?.title);
    setSportType(personalInformation?.sport_name_subcategory?.title);
    setSelectedProfileImage(personalInformation?.profile_image);
    setSelectedLogo(personalInformation?.logo);

    if (personalInformation?.niche_industry?.id) {
      const roles: any = await getRoles(
        personalInformation?.niche_industry?.id
      );
      setRoles(roles?.data?.data);
    }

    if (personalInformation?.sports_categories?.id) {
      const names = await getNames(personalInformation?.sports_categories?.id);
      setNames(names?.data?.data);
    }

    if (personalInformation?.sport_name?.id) {
      const types = await getSportTypes(personalInformation?.sport_name?.id);
      setSportTypes(types?.data?.data);
    }
  };

  const handleSavePersonalInformation = handleSubmit(async (data: any) => {
    const verifyAuthorizedRepresentative: any =
      data?.verify_authorized_representative == true ? 1 : 0;

    const modifiedProfileImage =
      typeof data?.profile_image == 'string'
        ? data?.profile_image?.split('/')[5]
        : data?.profile_image?.[0];

    const modifiedLogo =
      typeof data?.logo == 'string'
        ? data?.logo?.split('/')[5]
        : data?.logo?.[0];

    const formData = new FormData();

    formData.append('user_id', data?.user_id);
    formData.append('username', data?.username);
    formData.append('profile_url', data?.profile_url);
    formData.append('email_id', data?.email_id);
    formData.append('full_name', data?.full_name);
    formData.append('gender', data?.gender);
    formData.append('date_of_birth', data?.date_of_birth);
    formData.append('place_of_birth', data?.place_of_birth);
    formData.append('headline', data?.headline);
    formData.append('nationality', data?.nationality);
    formData.append('contact_type', data?.contact_type);
    formData.append('contact_number', data?.contact_number);
    formData.append('favorite_player_Team', data?.favorite_player_Team);
    formData.append(
      'brief_background_biography',
      data?.brief_background_biography
    );
    formData.append('early_life_upbringing', data?.early_life_upbringing);
    formData.append('education', data?.education);
    formData.append('sport_played', data?.sport_played);
    formData.append('position_specialization', data?.position_specialization);
    formData.append('teams_clubs_played_for', data?.teams_clubs_played_for);
    formData.append(
      'important_milestone_achievements',
      data?.important_milestone_achievements
    );
    formData.append('career_statics', data?.career_statics);
    formData.append('major_award_honors', data?.major_award_honors);
    formData.append('records_held', data?.records_held);
    formData.append('notable_performances', data?.notable_performances);
    formData.append('current_team_affiliation', data?.current_team_affiliation);
    formData.append('injury_status', data?.injury_status);
    formData.append(
      'upcoming_events_competitions',
      data?.upcoming_events_competitions
    );
    formData.append('height', data?.height);
    formData.append('weight', data?.weight);
    formData.append('strength_weakness', data?.strength_weakness);
    formData.append(
      'hobbies_interests_outside_sports',
      data?.hobbies_interests_outside_sports
    );
    formData.append('family_information', data?.family_information);
    formData.append(
      'any_noteworthy_statements_interviews',
      data?.any_noteworthy_statements_interviews
    );
    formData.append('address', data?.address);
    formData.append('street', data?.street);
    formData.append('city', data?.city);
    formData.append('state', data?.state);
    formData.append('zip_code', data?.zip_code);
    formData.append('niche_industry', data?.niche_industry);
    formData.append('roles', data?.roles);
    formData.append('sports_categories', data?.sports_categories);
    formData.append('sport_name', data?.sport_name);
    formData.append(
      'is_business_exist',
      checkBuinessDetailAvailability(data) ? '1' : '0'
    );

    data?.sports_name_sub_category_id &&
      formData.append(
        'sports_name_sub_category_id',
        data?.sports_name_sub_category_id
      );

    if (
      data?.company_name !== null &&
      data?.company_name &&
      data?.company_name !== undefined
    )
      formData.append('company_name', data?.company_name);

    if (
      data?.website !== null &&
      data?.website !== '' &&
      data?.website !== undefined
    )
      formData.append('website', data?.website);

    if (
      data?.sports_industry !== null &&
      data?.sports_industry !== undefined &&
      data?.sports_industry !== ''
    )
      formData.append('sports_industry', data?.sports_industry);

    if (
      data?.business_size !== null &&
      data?.business_size !== undefined &&
      data?.business_size !== ''
    )
      formData.append('business_size', data?.business_size);

    if (
      data?.description !== null &&
      data?.description !== '' &&
      data?.description !== undefined
    )
      formData.append('description', data?.description);

    if (
      data?.tag_line !== null &&
      data?.tag_line !== '' &&
      data?.tag_line !== undefined
    )
      formData.append('tag_line', data?.tag_line);

    if (
      data?.verify_authorized_representative !== null &&
      data?.verify_authorized_representative !== '' &&
      data?.verify_authorized_representative !== undefined
    )
      formData.append(
        'verify_authorized_representative',
        verifyAuthorizedRepresentative
      );

    formData.append('profile_image', modifiedProfileImage);
    formData.append('team_photos_logos_videos', data?.team_photos_logos_videos);

    if (data?.logo !== null && data?.logo !== '' && data?.logo !== undefined)
      formData.append('logo', modifiedLogo);

    const response = await updatePersonalInformation(formData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
      dispatch(setBusinessDetails(false));
      dispatch(setScrollToBottom(false));
      if (user?.profile_completion?.personalInfo == 0) {
        dispatch(
          setProfileCompletion({
            ...user,
            profile_completion: {
              ...user?.profile_completion,
              personalInfo: 1,
            },
          })
        );
        user?.profile_completion?.educationInfo == 0
          ? history('/profile/add-education')
          : user?.profile_completion?.workExperience == 0
          ? history('/profile/add-workplace-experience')
          : user?.profile_completion?.teamsInfo == 0
          ? history('/profile/profile-add-teams')
          : history('/');
      }
    }
  });

  useEffect(() => {
    personalInformation && populateData();
  }, [personalInformation]);

  useEffect(() => {
    watchProfileImage && setSelectedProfileImage(watchProfileImage[0]);
  }, [watchProfileImage]);

  useEffect(() => {
    watchLogo && setSelectedLogo(watchLogo[0]);
  }, [watchLogo]);

  useEffect(() => {
    scrollToBottom && window.scrollTo(0, document.body.scrollHeight);
  }, [scrollToBottom]);

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Personal Information</h4>
          </div>
        </div>
        <Card.Body>
          <div className="form-card text-start">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    User Id
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="User Id"
                    {...register('user_id')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Profile Url
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Profile Url"
                    {...register('profile_url')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Email ID
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="email"
                    placeholder="Email ID"
                    {...register('email_id')}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    User Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User Name"
                    {...register('username')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.username?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Full Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    {...register('full_name')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.full_name?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('gender')}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Date of Birth
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date of Birth"
                    {...register('date_of_birth')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.date_of_birth?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Place of Birth
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Place of Birth"
                    {...register('place_of_birth')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.place_of_birth?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Nationality
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nationality"
                    {...register('nationality')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.nationality?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Headline
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Headline"
                    {...register('headline')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.headline?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    {...register('contact_type')}
                  >
                    <option value="home">Home</option>
                    <option value="mobile">Mobile</option>
                    <option value="work">Work</option>
                  </Form.Select>
                </Form.Group>
                <p className="text-danger">{errors?.contact_type?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Contact Number
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact Number"
                    {...register('contact_number')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.contact_number?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>Favorite Player/Team</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Favorite Player/Team"
                    {...register('favorite_player_Team')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Biographical Information</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Brief Background & Biography</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Brief Background & Biography"
                    {...register('brief_background_biography')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Early Life & Upbringing</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Early Life & Upbringing"
                    {...register('early_life_upbringing')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Education</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Education"
                    {...register('education')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Sporting Career</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Sport Played</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sport Played"
                    {...register('sport_played')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Position & Specialization</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Position & Specialization"
                    {...register('position_specialization')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Teams or Clubs Played for</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teams or Clubs Played for"
                    {...register('teams_clubs_played_for')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Important Milestone & Achievements</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Important Milestone & Achievements"
                    {...register('important_milestone_achievements')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Career Statics (if applicable)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Career Statics (if applicable)"
                    {...register('career_statics')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Accomplishments</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Major Award & Honors</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Major Award & Honors"
                    {...register('major_award_honors')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Records held (if any)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Records held (if any)"
                    {...register('records_held')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Notable Performances</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Notable Performances"
                    {...register('notable_performances')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Current Status</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Current Team Or Affiliation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current Team Or Affiliation"
                    {...register('current_team_affiliation')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Injury Status (if relevant)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Injury Status (if relevant)"
                    {...register('injury_status')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Upcoming events or competitions</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Upcoming events or competitions"
                    {...register('upcoming_events_competitions')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Physical Attributes</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Height
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Height"
                    {...register('height')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.height?.message}</p>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Weight
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Weight"
                    {...register('weight')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.weight?.message}</p>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Strength & Weakness</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Strength & Weakness"
                    {...register('strength_weakness')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Personal Life</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Hobbies & Interests Outside Sports</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hobbies & Interests Outside Sports"
                    {...register('hobbies_interests_outside_sports')}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>Family information (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Family information (optional)"
                    {...register('family_information')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Quotes & Interviews</h4>

              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Any Noteworthy Statements Or Interviews
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Any Noteworthy Statements Or Interviews"
                    {...register('any_noteworthy_statements_interviews')}
                  />
                </Form.Group>
              </Col>

              <h4 className="fw-bold mb-3 mt-5">Address Details</h4>

              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Address
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    {...register('address')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.address?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    City
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    {...register('city')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.city?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    State
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    {...register('state')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.state?.message}</p>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label>
                    Zip Code
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Zip Code"
                    {...register('zip_code')}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.zip_code?.message}</p>
              </Col>

              <h4 className="fw-bold mb-2">Photo & Videos</h4>
              <Col md="12">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Team photos, logos, and videos</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    {...register('profile_image')}
                  />
                </Form.Group>
              </Col>

              {typeof watchProfileImage == 'string' && (
                <img
                  src={watchProfileImage}
                  alt="Profile Image"
                  style={{
                    width: '100px',
                  }}
                />
              )}
              {typeof watchProfileImage !== 'string' &&
                selectedProfileImage && (
                  <img
                    src={getFilePreviewURL(watchProfileImage[0])}
                    alt="Profile Image"
                    style={{
                      width: '100px',
                    }}
                  />
                )}

              <h4 className="fw-bold mb-2">Profession</h4>
              <Col md="12">
                <Form.Group className="form-group">
                  <Form.Label>
                    Niche/Industry
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Niche/Industry"
                    value={industryNiche}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.niche_industry?.message}</p>
              </Col>
              <Col md="12">
                <div
                  style={{
                    background: background.tag,
                    padding: '16px',
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allIndustries?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return (
                      <p
                        key={uid}
                        style={{
                          border: `1px dashed ${textInput.border}`,
                          padding: '2px 8px',
                          display: 'inline-block',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          background:
                            industryNiche === title
                              ? background.blue
                              : 'transparent',
                          color:
                            industryNiche === title
                              ? background.light
                              : background.dark,
                        }}
                        onClick={async () => {
                          setIndustryNiche(title);
                          const roles: any = await getRoles(uid);
                          setRoles(roles?.data?.data);
                          setValue('niche_industry', uid);
                        }}
                      >
                        {title}
                      </p>
                    );
                  })}
                </div>
              </Col>

              <Col md="12" className="mt-2">
                <Form.Group className="form-group">
                  <Form.Label>
                    Roles
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Roles" value={role} />
                </Form.Group>
                <p className="text-danger">{errors?.roles?.message}</p>
              </Col>
              <Col md="12">
                {roles?.length > 0 && (
                  <div
                    style={{
                      background: background.tag,
                      padding: '16px',
                      display: 'flex',
                      gap: '8px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {roles?.map?.((data: any) => {
                      const { uid, title } = data;
                      return (
                        <p
                          key={uid}
                          style={{
                            border: `1px dashed ${textInput.border}`,
                            padding: '2px 8px',
                            display: 'inline-block',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            background:
                              role === title ? background.blue : 'transparent',
                            color:
                              role === title
                                ? background.light
                                : background.dark,
                          }}
                          onClick={() => {
                            setRole(title);
                            setValue('roles', uid);
                          }}
                        >
                          {title}
                        </p>
                      );
                    })}
                  </div>
                )}
              </Col>

              <Col md="12" className="mt-2">
                <Form.Group className="form-group">
                  <Form.Label>
                    Sports Categories
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sports Categories"
                    value={category}
                  />
                </Form.Group>
                <p className="text-danger">
                  {errors?.sports_categories?.message}
                </p>
              </Col>
              <Col md="12">
                <div
                  style={{
                    background: background.tag,
                    padding: '16px',
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}
                >
                  {allCategories?.data?.map?.((data: any) => {
                    const { uid, title } = data;
                    return (
                      <p
                        key={uid}
                        style={{
                          border: `1px dashed ${textInput.border}`,
                          padding: '2px 8px',
                          display: 'inline-block',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          background:
                            category === title
                              ? background.blue
                              : 'transparent',
                          color:
                            category === title
                              ? background.light
                              : background.dark,
                        }}
                        onClick={async () => {
                          setCategory(title);
                          const names = await getNames(uid);
                          setNames(names?.data?.data);
                          setValue('sports_categories', uid);
                        }}
                      >
                        {title}
                      </p>
                    );
                  })}
                </div>
              </Col>

              <Col md="12" className="mt-2">
                <Form.Group className="form-group">
                  <Form.Label>Sport Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sport Name"
                    value={name}
                  />
                </Form.Group>
                <p className="text-danger">{errors?.sport_name?.message}</p>
              </Col>
              <Col md="12">
                {names?.length > 0 && (
                  <div
                    style={{
                      background: background.tag,
                      padding: '16px',
                      display: 'flex',
                      gap: '8px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {names?.map?.((data: any) => {
                      const { uid, title } = data;
                      return (
                        <p
                          key={uid}
                          style={{
                            border: `1px dashed ${textInput.border}`,
                            padding: '2px 8px',
                            display: 'inline-block',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            background:
                              name === title ? background.blue : 'transparent',
                            color:
                              name === title
                                ? background.light
                                : background.dark,
                          }}
                          onClick={async () => {
                            setName(title);
                            setValue('sport_name', uid);
                            const sportTypes = await getSportTypes(uid);
                            setSportTypes(sportTypes?.data?.data);
                          }}
                        >
                          {title}
                        </p>
                      );
                    })}
                  </div>
                )}
              </Col>
              {sportTypes?.length > 0 && (
                <>
                  <Col md="12" className="mt-2">
                    <Form.Group className="form-group">
                      <Form.Label>Sport Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sport Name"
                        value={sportType}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12" className="mb-3">
                    {sportTypes?.length > 0 && (
                      <div
                        style={{
                          background: background.tag,
                          padding: '16px',
                          display: 'flex',
                          gap: '8px',
                          flexWrap: 'wrap',
                        }}
                      >
                        {sportTypes?.map?.((data: any) => {
                          const { uid, title } = data;
                          return (
                            <p
                              key={uid}
                              style={{
                                border: `1px dashed ${textInput.border}`,
                                padding: '2px 8px',
                                display: 'inline-block',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                background:
                                  sportType === title
                                    ? background.blue
                                    : 'transparent',
                                color:
                                  sportType === title
                                    ? background.light
                                    : background.dark,
                              }}
                              onClick={async () => {
                                setSportType(title);
                                setValue('sports_name_sub_category_id', uid);
                              }}
                            >
                              {title}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </Col>
                </>
              )}
              {!businessDetails && (
                <Col md={12}>
                  <Button
                    variant="link"
                    className="d-flex gap-2 align-items-center"
                    onClick={() => dispatch(setBusinessDetails(true))}
                  >
                    <span className="material-symbols-outlined">
                      add_circle
                    </span>
                    Add Business Details
                  </Button>
                </Col>
              )}

              {businessDetails && (
                <>
                  <h4 className="fw-bold mb-2 mt-4">Business Details</h4>
                  <Col md="6">
                    <Form.Group className="form-group">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        {...register('company_name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group">
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Website"
                        {...register('website')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group">
                      <Form.Label>Sports Industry</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register('sports_industry')}
                      >
                        {allIndustries?.data?.map?.((data: any) => {
                          const { uid, title } = data;
                          return <option value={uid}>{title}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="form-group">
                      <Form.Label>Business Size</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register('business_size')}
                      >
                        {allBusinessSizes?.data?.map?.((data: any) => {
                          const { uid, title } = data;
                          return <option value={uid}>{title}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        {...register('description')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <Form.Label>Tag Line</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Tag Line"
                        {...register('tag_line')}
                      />
                    </Form.Group>
                  </Col>

                  <p>
                    Use your tagline to briefly describe what your organization
                    does. This can be changed later
                  </p>
                  <p>
                    <b>Logo: </b>300 x 300px recommended, .JPEG,.JPG and .PNG
                    supported
                  </p>
                  <Col md="12">
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        {...register('logo')}
                      />
                    </Form.Group>
                  </Col>

                  {typeof watchLogo == 'string' && watchLogo !== 'null' && (
                    <img
                      src={watchLogo}
                      alt="Profile Image"
                      style={{
                        width: '100px',
                      }}
                    />
                  )}
                  {typeof watchLogo !== 'string' && selectedLogo && (
                    <img
                      src={getFilePreviewURL(watchLogo[0])}
                      alt="Profile Image"
                      style={{
                        width: '100px',
                      }}
                    />
                  )}
                  <Col md="12">
                    <Form.Check
                      type="checkbox"
                      label="I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages."
                      {...register('verify_authorized_representative')}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
              }}
              onClick={handleSavePersonalInformation}
            >
              {user?.profile_completion?.personalInfo == 0 ? 'Save' : 'Update'}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default PersonalInformationForm;
