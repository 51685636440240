import { Col, Row } from 'react-bootstrap'
import AuthGuard from 'src/app/config/auth/AuthGuard'
import PersonalInformationForm from '../shared/components/Forms/PersonalInformationForm'
import ProfileHeader from '../shared/components/ProfileHeader'
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar'

const EditPersonalInformation = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    {/* <Container
    style={{
        border: '0px solid red',
    }}
> */}
                    {/* //todo: Get content from template and design acc to XD */}
                    <Row>
                        <Col md={10}>
                            <ProfileHeader
                                loggedInUserProfile={true}
                                myProfile={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <ProfileSidebar activeLink='personal-information' />
                        </Col>
                        <Col md={8}>
                            <PersonalInformationForm />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default EditPersonalInformation