import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProfileSetup } from '../../store/sharedSlice';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import moment from 'moment';

const ProfileIntroCard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const handleProfileDetails = () => {
    history('/profile/personal-information');
    dispatch(setProfileSetup(1));
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Intro</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex gap-1 align-items-center">
            <span className="material-symbols-outlined">schedule</span>
            <span>
              Joined{" "}
              {moment(personalInformation?.join_date).format('MMMM Do YYYY')}
            </span>
          </div>
          <div className="mt-4">
            <div className="d-grid">
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={handleProfileDetails}
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                Profile Details
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileIntroCard;
