import { useState } from "react"

const useModalDialog = () => {
    const [isOpen, setIsOpen] = useState(false)

    return {
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }
}

export default useModalDialog