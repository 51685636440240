export const stylesDarkTheme
    = {
    palette: {
        background: {
            dark: '#50B5FF',
            light: '#E8E8E8',
            blue: '#283645',
            search: '#151F27',
            tag: '#1A1A1A',
            icon: '#E8E8E8'
        },
        button: {
            background: '#50B5FF',
            info: '#007BFF',
            success: '#28A745',
            join: '#00BC4E',
            cancelRequest: '#FF0000',
            requested: '#00A5D3',
            other: '#3f37c9',
            admin: '#FF8800',
        },
        border: {
            dark: '#50B5FF',
            divider: '#F3F3F3',
        },
        textInput: {
            border: '#CED4DA',
            background: '#151F27'
        },
        card: {
            background: '#0F0F0F',
            mainBackground: '#19191A',
        },
        chat: {
            sender: '#50B5FF',
            receiver: '#ffffff',
        }
    }
}