import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JobApplicationForm from '../Forms/JobApplicationForm';
import HorizontalJobCard from '../HorizontalJobCard/HorizontalJobCard';

const JobApplicationCard = () => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { border },
  } = theme;

  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Job Application</h4>
          </div>
        </div>
        <Card.Body>
          <Row>
            <Col
              md={12}
              style={{
                marginBottom: '10px',
              }}
            >
              <HorizontalJobCard />
            </Col>
          </Row>
          <Row className="mt-5">
            <JobApplicationForm />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default JobApplicationCard;
