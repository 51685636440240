import { Card, Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { followersData } from 'src/app/mock/followers';
import { photosData } from 'src/app/mock/photos';
import { teamsData } from 'src/app/mock/teams';
import FollowersCard from '../shared/components/FollowersCard';
import PhotoCard from '../shared/components/PhotoCard';
import Post from '../shared/components/Post';
import ProfileAboutCard from '../shared/components/ProfileAboutCard';
import ProfileHeader from '../shared/components/ProfileHeader';
import TeamCard from '../shared/components/TeamCard';
import Advertisement from '../shared/components/advertisement/Advertisement';
import { useGetSinglePostDetailQuery } from '../feed/services/feedApi';
import { useParams } from 'react-router-dom';
import PostHeader from '../shared/components/Post/PostHeader';
import moment from 'moment';
import PostContent from '../shared/components/Post/PostContent';
import PostFooter from '../shared/components/Post/PostFooter';

const PostDetail = () => {
  const { postId } = useParams();

  const { data: getSinglePostDetail } = useGetSinglePostDetailQuery(postId, {
    skip: !postId,
  });
  const { data: singlePostDetail } = getSinglePostDetail || {};
  const {
    id,
    user_id,
    parent_id,
    user_profile,
    fullname,
    post,
    attachments,
    total_comments,
    is_comments_allowed,
    created_at,
    total_likes,
    comments,
    is_liked,
    username,
    post_type,
    repost,
    is_advertisement,
    is_verified,
  } = singlePostDetail || {};

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={false} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={7}>
              <Card key={id}>
                <Card.Body>
                  <div className="post-item">
                    <PostHeader
                      image={user_profile}
                      userId={user_id}
                      name={fullname}
                      username={username}
                      isVerified={is_verified}
                      isAdvertisement={
                        is_advertisement == 'advertisement' ? true : false
                      }
                      date={moment(created_at).format('DD MMM')}
                      time={moment.utc(created_at).local().fromNow()}
                      loggedInUserPost={false}
                      postType={post_type}
                      repost={repost}
                    />
                    <PostContent
                      postType={post_type}
                      repost={repost}
                      postData={singlePostDetail}
                      data={{
                        message: post,
                        image: attachments?.length == 1 && attachments[0],
                        multipleImages: attachments?.length > 1 && attachments,
                      }}
                    />
                    <PostFooter
                      postId={id}
                      sharePostId={parent_id ? parent_id : id}
                      isLiked={is_liked}
                      isCommentsAllowed={
                        is_comments_allowed === '1' ? false : true
                      }
                      totalLikes={total_likes}
                      totalComments={total_comments}
                      comments={comments}
                      image={user_profile}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={3}>
              <ProfileAboutCard />
              <PhotoCard
                photos={photosData}
                loggedInUserProfile={false}
                redirectTo={`/user-profile/photos/${user_id}}`}
              />
              <FollowersCard followers={followersData} />
              <TeamCard
                teams={teamsData}
                loggedInUserProfile={false}
                profile
                redirectTo="/team-details"
              />
            </Col>
          </Row>
        </div>
        <Advertisement />
      </>
    </AuthGuard>
  );
};

export default PostDetail;
