import { Button, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useJobDetailsQuery } from 'src/app/screens/jobs/services/jobsAPI';
import image1 from '../../../../assets/images/job-icon-2.png';

interface IHorizontalJobCard {
  applyNow?: boolean;
}

const HorizontalJobCard = ({ applyNow = false }: IHorizontalJobCard) => {
  const { id } = useParams();

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);
  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border, textInput },
  } = theme;

  const { data: jobDetails } = useJobDetailsQuery(id);
  const data = jobDetails?.data;

  return (
    <>
      <div
        style={{
          border: `1px solid ${border.divider}`,
          padding: '16px',
          borderRadius: '8px',
        }}
      >
        <div className="d-flex flex-wrap align-items-start gap-3">
          <Image
            src={data?.company_logo || image1}
            alt="Image"
            className="img-fluid"
            style={{
              width: '80px',
              height: '80px',
              objectFit: 'cover',
            }}
          />
          <div
            className="d-flex justify-content-between align-items-start flex-wrap"
            style={{
              flex: 'auto',
            }}
          >
            <div>
              <h4 className="fw-bold">{data?.title}</h4>
              <h6>{data?.company}</h6>
              <h6 className="d-flex gap-1 align-items-center">
                <span className="material-symbols-outlined">location_on</span>
                {data?.location}
              </h6>
              <div className="d-flex gap-2 mt-2 mb-2">
                <div
                  style={{
                    padding: '1px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    background: '#ECF5FF',
                    color: background.blue,
                  }}
                >
                  {data?.job_category?.title}
                </div>
                <div
                  style={{
                    padding: '1px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    background: '#FFE7A5',
                    color: background.blue,
                  }}
                >
                  {data?.job_type?.title}
                </div>
              </div>
            </div>
            {user?.uid !== data?.user_id && applyNow && (
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={() =>
                  history(`/jobs/job-details/job-application/${id}`)
                }
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                Apply Now
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HorizontalJobCard;
