import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useShouldFollowQuery } from 'src/app/screens/feed/services/feedApi';
import { useSendRequestMutation } from 'src/app/screens/profile/services/profileApi';
import Avatar from '../Avatar/Avatar';
import { truncateText } from 'src/app/config/helper';

const FollowUserCard = () => {
  const history = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const { data: getShouldFollow } = useShouldFollowQuery({});
  const [sendRequest, { isLoading }] = useSendRequestMutation();
  const { data: shouldFollow } = getShouldFollow || {};

  const handleSendRequest = async (userId: any) => {
    const bodyData = {
      followee_id: userId,
      type: 'follow',
    };

    const response = await sendRequest(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      {(showAll ? shouldFollow : shouldFollow?.slice(0, 5))?.map?.(
        (data: any) => {
          const { id, username, name, personal_information } = data;
          return (
            <li className="d-flex mb-3 align-items-center flex-wrap" key={id}>
              {personal_information?.imagepath && (
                <Avatar
                  pfp={personal_information?.imagepath}
                  size="40"
                  name={username}
                />
              )}
              <div
                className="ms-2 flex-grow-1"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => history(`/user-profile/${id}`)}
              >
                <h6 className="d-flex align-items-center">
                  {truncateText(name, 10)}
                </h6>
                <small>{truncateText(username, 15)}</small>
              </div>
              <Button
                disabled={isLoading}
                variant="primary"
                type="button"
                className="float-end"
                onClick={() => handleSendRequest(id)}
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                Follow
              </Button>
            </li>
          );
        }
      )}
    </>
  );
};

export default FollowUserCard;
