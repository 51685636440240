import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

interface IJobHistoryHeader {
  activeLink: string;
}

const JobHistoryHeader = ({ activeLink }: IJobHistoryHeader) => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  return (
    <>
      <div className="d-flex align-items-end flex-wrap my-3">
        <nav
          className="tab-bottom-bordered"
          style={{
            borderBottom: 0,
          }}
        >
          <Nav
            variant="tabs"
            className="mb-0 rounded-top border-0"
            activeKey={activeLink}
            style={{
              background: 'transparent',
            }}
            onSelect={(selectedKey) => history(`${selectedKey}`)}
          >
            <Nav.Link
              eventKey="/job-history"
              style={{
                color: background.dark,
                backgroundColor: 'transparent',
              }}
            >
              Applied Jobs
            </Nav.Link>
            <Nav.Link
              eventKey="/posted-jobs"
              style={{
                color: background.dark,
                backgroundColor: 'transparent',
              }}
            >
              Posted Jobs
            </Nav.Link>
          </Nav>
        </nav>
      </div>
    </>
  );
};

export default JobHistoryHeader;
