import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

type PreviewImagesType = {
  images?: any;
  removeImage?: any;
  type?: any;
};

const PreviewImages = ({ images, removeImage, type }: PreviewImagesType) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 4,
          flexWrap: 'wrap',
          marginBottom: '16px',
        }}
      >
        {images?.map((url: any, index: any) => (
          <div
            key={index}
            style={{
              position: 'relative',
              marginRight: '8px',
              marginBottom: '8px',
              width: url?.includes('.mp4') && '50%',
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
          >
            {type == 'edit' ? (
              url?.includes('.mp4') ? (
                <video
                  controls
                  style={{
                    width: '100%',
                  }}
                >
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <Image
                  key={index}
                  src={url}
                  alt={`Preview ${index}`}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: url?.includes('file-1') ? 'contain' : 'cover',
                  }}
                />
              )
            ) : (
              <Image
                key={index}
                src={url}
                alt={`Preview ${index}`}
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: url?.includes('file-1') ? 'contain' : 'cover',
                }}
              />
            )}

            <div
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                width: '28px',
                height: '28px',
                borderRadius: '40px',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  color: 'white',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => removeImage(index)}
              >
                &#10006;
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PreviewImages;
