import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { marketPlaceItemsData } from 'src/app/mock/marketPlace';
import marketPlaceImg from '../../assets/images/marketplace.png';
import MarketPlaceItems from '../shared/components/MarketPlaceItems/MarketPlaceItems';
import ProfilePageHeader from '../shared/components/ProfilePageHeader/ProfilePageHeader';
import CompanyDetailCard from '../shared/components/CompanyDetailCard/CompanyDetailCard';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { useParams } from 'react-router-dom';
import { useGetUserMarketPlaceItemsQuery } from '../market-place/services/marketPlaceApi';
import NoData from '../shared/components/NoData/NoData';
import Loader from '../shared/components/Loader/Loader';

const CompanyItems = () => {
  const { id } = useParams();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const { data: getUserMarketPlaceItems, isLoading } =
    useGetUserMarketPlaceItemsQuery(id, { skip: !id });
  const { data: userMarketPlaceItems } = getUserMarketPlaceItems || {};

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Market Place" image={marketPlaceImg} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={3}>
              <CompanyDetailCard />
            </Col>
            <Col md={7}>
              {!isLoading ? (
                userMarketPlaceItems?.length > 0 ? (
                  <MarketPlaceItems data={userMarketPlaceItems} col={4} />
                ) : (
                  <NoData name="items" />
                )
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default CompanyItems;
