import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  useEditEducationMutation,
  useGetEducationDetailQuery,
} from 'src/app/screens/profile/services/profileApi';
import { useGetEducationLevelQuery } from '../../services/sharedApi';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Loader from '../Loader/Loader';

interface IEditEducationModal {
  open: boolean;
  editId: any;
  close: () => void;
}

const EditEducationModal = ({ open, close, editId }: IEditEducationModal) => {
  const defaultValues = {
    education_level_id: '',
    college_university: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    grade: '',
  };

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchEducationLevel = watch('education_level_id');
  const watchCollegeUniversity = watch('college_university');

  const { data: getEducationLevel } = useGetEducationLevelQuery({});

  const { data: getEducationDetail = {}, isLoading: gettingEducationDetails } =
    useGetEducationDetailQuery(editId, {
      skip: editId ? false : true,
    });

  const { data } = getEducationDetail;

  const [editEducation, { isLoading }] = useEditEducationMutation();

  const emptyInputFields = () => {
    reset({
      ...defaultValues,
    });
  };

  const populateData = () => {
    reset({
      ...data,
    });
  };

  const handleEditEducation = handleSubmit(async (data: any) => {
    if (watchEducationLevel != '' && watchCollegeUniversity != null) {
      const bodyData = {
        ...data,
        id: editId,
      };

      const response = await editEducation(bodyData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.errors?.email);

      if (respData) {
        toast.success(respData?.message);
        emptyInputFields();
        close();
      }
    } else {
      toast.error('Please add education level and college/universilty');
    }
  });

  const renderForm = () => {
    return (
      <Modal.Body>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>Bachelor's Degree</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register('education_level_id')}
              >
                <option>Select</option>
                {getEducationLevel?.data?.map?.((data: any) => {
                  const { uid, title } = data;
                  return <option value={uid}>{title}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>College/University</Form.Label>
              <Form.Control
                type="text"
                placeholder="College/University"
                {...register('college_university')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>Field of Study</Form.Label>
              <Form.Control
                type="text"
                placeholder="Field of Study"
                {...register('field_of_study')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                {...register('start_date')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>End Date (Or Expected)</Form.Label>
              <Form.Control
                type="date"
                placeholder="End Date (Or Expected)"
                {...register('end_date')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Form.Group className="form-group">
              <Form.Label>Grade</Form.Label>
              <Form.Control
                type="text"
                placeholder="Grade"
                {...register('grade')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          disabled={isLoading}
          variant="primary"
          type="button"
          className="float-end"
          style={{
            background: button.background,
            borderColor: border.dark,
          }}
          onClick={handleEditEducation}
        >
          Save
        </Button>
      </Modal.Body>
    );
  };

  useEffect(() => {
    populateData();
  }, [data]);

  return (
    <>
      <Modal centered show={open} onHide={close}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            Edit Education
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        {!gettingEducationDetails ? renderForm() : <Loader />}
      </Modal>
    </>
  );
};

export default EditEducationModal;
