import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import groupBgImage from '../../../../assets/images/profile-bg2.jpg';
import TeamCard from '../TeamCard/TeamCard';
import ProfilePageHeader from '../ProfilePageHeader/ProfilePageHeader';

const Teams = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={12} xl={10}>
              <ProfilePageHeader title="Teams" image={groupBgImage} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={10}>
              <TeamCard
                home
                showAll
                loggedInUserProfile={null}
                style={{
                  height: '300px',
                  objectFit: 'cover',
                }}
              />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default Teams;
