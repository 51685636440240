import { yupResolver } from '@hookform/resolvers/yup';
import {
  confirm_password_validator2,
  custom_message_file_required_validator,
  email_validator,
  label_required_validator,
  password_validator,
  policy_checkbox_validator,
  string_required_validator,
  username_validator,
} from 'src/app/config/types/validators';
import { object } from 'yup';

const signupSchema = object().shape({
  username: username_validator,
  email: email_validator,
  password: password_validator,
  password_confirmation: confirm_password_validator2,
  privacy_policy: policy_checkbox_validator('Privacy Policy'),
});

export default yupResolver(signupSchema);
