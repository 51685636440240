import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    string_required_validator
} from "src/app/config/types/validators"
import {
    object
} from "yup"

const supportFormSchema = object().shape({
    subject: string_required_validator,
    description: string_required_validator,
})

export default yupResolver(supportFormSchema)