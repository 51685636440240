import React from 'react'
import { Card, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import WorkCard from '../WorkCard/WorkCard'

interface IWorkContainer {
    title: string,
    education?: boolean,
    work?: boolean,
    children: React.ReactElement,
}

const WorkContainer = ({
    title,
    education,
    work,
    children,
}: IWorkContainer) => {
    return (
        <>
            <Card>
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4
                            className="card-title">{title}
                        </h4>
                    </div>
                    {
                        education === true &&
                        <div className="card-header-toolbar">
                            <Link to="/profile/add-education" className='d-flex align-items-center'>
                                <span className="material-symbols-outlined">
                                    add_circle
                                </span>
                                Add Education
                            </Link>
                        </div>
                    }
                    {
                        work === true &&
                        <div className="card-header-toolbar">
                            <Link to="/profile/add-workplace-experience" className='d-flex align-items-center'>
                                <span className="material-symbols-outlined">
                                    add_circle
                                </span>
                                Add Workplace
                            </Link>
                        </div>
                    }
                </div>
                <Card.Body>
                    <Row>
                       {children}
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default WorkContainer