import { createSlice, current } from '@reduxjs/toolkit';
import { authApi } from '../services/authApi';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    visitedRoutes: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setProfileCompletion: (state, action) => {
      state.user = action.payload;
    },
    setVisitedRoutes: (state: any, action) => {
      state.visitedRoutes = action.payload;
    },
    setLogout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.create.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        authApi.endpoints.googleLogin.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.data;
        }
      )
      .addMatcher(
        authApi.endpoints.logout.matchFulfilled,
        (state, { payload }) => {
          state.user = null;
        }
      );
  },
});

export const { setUser, setProfileCompletion, setVisitedRoutes, setLogout } =
  authSlice.actions;

export default authSlice.reducer;
