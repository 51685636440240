import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    number_required_validator,
    string_required_validator
} from "src/app/config/types/validators"
import {
    object
} from "yup"

const personalInformationFormSchema = object().shape({
    username: string_required_validator,
    full_name: string_required_validator,
    date_of_birth: string_required_validator,
    place_of_birth: string_required_validator,
    headline: string_required_validator,
    contact_type: string_required_validator,
    nationality: string_required_validator,
    contact_number: string_required_validator,
    height: string_required_validator,
    weight: string_required_validator,
    address: string_required_validator,
    city: string_required_validator,
    state: string_required_validator,
    zip_code: string_required_validator,
    niche_industry: number_required_validator,
    roles: number_required_validator,
    sports_categories: number_required_validator,
})

export default yupResolver(personalInformationFormSchema)