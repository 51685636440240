import moment from 'moment';
import Pusher from 'pusher-js';
import { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { handleImageUpload } from 'src/app/config/helper';
import { webBaseUrl } from 'src/app/config/types/base-url';
import {
  useGetSingleUserChatQuery,
  useSendMessageMutation,
} from 'src/app/screens/chat/services/chatApi';
import {
  useGetPersonalInformationQuery,
  useGetUserDetailsQuery,
} from 'src/app/screens/profile/services/profileApi';
import user02 from '../../../../assets/images/04.jpg';
import { setOpenChat } from '../../store/sharedSlice';
import Avatar from '../Avatar/Avatar';
import ChatMessage from '../ChatMessage/ChatMessage';
import PreviewImages from '../PreviewImages/PreviewImages';
import OutsideClickHandler from 'react-outside-click-handler';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

const ChatBox = () => {
  const defaultValues = {
    message: '',
    sender_id: '',
    receiver_id: '',
    attachments: [],
  };

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { theme } = useSelector((state: any) => state.styles);
  const { chatType, receiverId, productDetail } = useSelector(
    (state: any) => state.shared
  );
  const { user } = useSelector((state: any) => state.auth);

  const defaultMessages = [
    `Is ${productDetail?.title} still available?`,
    'Thank you!',
    `Hi! I'm interested in buying ${productDetail?.title} item`,
  ];

  const {
    palette: { background, button, border, textInput, card },
  } = theme;

  const [hideBody, setHideBody] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<any>([]);
  const fileInputRef = useRef<any>(null);
  const filePdfRef = useRef<any>(null);
  const [userMessages, setUserMessages] = useState<any>(null);
  const [emoji, setEmoji] = useState(false);
  const inputRef = useRef<any>(null);

  const { data: getUserDetails = {}, isLoading } = useGetUserDetailsQuery(
    receiverId,
    { skip: !receiverId }
  );
  const { data: userDetails = {} } = getUserDetails;
  const { personal_info } = userDetails || {};

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const [sendMessage, { isLoading: sendingMessage }] = useSendMessageMutation();
  const {
    data: getSingleUserChat,
    isLoading: gettingUserChat,
    refetch: refetchMessages,
  } = useGetSingleUserChatQuery(
    {
      authId: user?.uid,
      id: receiverId,
    },
    {
      skip: !receiverId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: userChat } = getSingleUserChat || {};

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchMessage = watch('message');

  const watchAttachments = watch('attachments');

  const handleHideBody = () => {
    setHideBody(!hideBody);
  };

  const handleCloseChat = () => {
    dispatch(setOpenChat(false));
  };

  const handleFileSelection = (reference: any) => {
    reference == 'fileInputRef'
      ? fileInputRef.current.click()
      : filePdfRef.current.click();
  };

  const removeImage = (indexToRemove: number) => {
    const updatedPreviewUrls = [...uploadedImages];
    updatedPreviewUrls.splice(indexToRemove, 1);
    let fileArray = Array.from(watchAttachments);
    const removedAttachment: any = fileArray[indexToRemove];
    const filterAttachments: any = fileArray?.filter(
      (data: any, index) => index !== indexToRemove
    );
    setValue('attachments', filterAttachments);
    setUploadedImages(updatedPreviewUrls);
  };

  const addEmoji = (e: any) => {
    let sym = e.unified.split('-');
    let codesArray: any = [];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);

    const cursor = inputRef.current.selectionStart;

    const newValue =
      watchMessage.slice(0, cursor) + emoji + watchMessage.slice(cursor);

    setValue('message', newValue);
  };

  const handleSendMessage = handleSubmit((data) => {
    if (data?.message != '' || data?.attachments?.length > 0) {
      const formData = new FormData();

      formData?.append('message', data?.message);
      formData?.append('sender_id', user?.uid);
      formData?.append('receiver_id', receiverId);
      formData?.append(
        'channel_id',
        userChat?.channel_name ? userChat?.id : null
      );
      data?.attachments?.map((data: any) =>
        formData?.append('attachments[]', data)
      );
      sendMessage(formData);
      reset({ ...defaultValues });
      setUploadedImages([]);
    } else {
      toast.error('Please write a message');
    }
  });

  useEffect(() => {
    if (userChat) {
      var pusher = new Pusher('14d3035a4ea699478de1', {
        cluster: 'ap2',
      });
      const channelName = userChat?.channel_name;
      const channel = pusher.subscribe(channelName);
      channel.bind('MessageSent', (data: any) => {
        setUserMessages((prevUserMessages: any) => ({
          ...prevUserMessages,
          chat: [...prevUserMessages?.chat, data?.message?.data?.message],
        }));
      });
    }
  }, [userChat?.channel_name]);

  useEffect(() => {
    setUserMessages(userChat);
  }, [userChat]);

  return (
    <>
      {!isLoading && !gettingUserChat && (
        <div
          className="chat"
          style={{
            background: card.background,
            position: 'sticky',
            bottom: 0,
            left: '63%',
            minWidth: '300px',
            maxWidth: '350px',
            boxShadow: '-10px -8px 20px -5px rgba(0,0,0,.15)',
            zIndex: '9999999999',
          }}
        >
          <div
            className="chat-header d-flex justify-content-between align-items-center px-2 py-3"
            style={{
              boxShadow: '0 8px 5px -5px rgba(0,0,0,.25)',
            }}
          >
            <div className="d-flex gap-3 align-items-center">
              <Avatar
                pfp={personal_info?.profile_image}
                size="40"
                name={personal_info?.full_name || personal_info?.username}
              />
              <p className="fw-bold mb-0">
                {personal_info?.full_name || personal_info?.username}
              </p>
            </div>
            <div className="d-flex gap-2">
              <span
                className="material-symbols-outlined"
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleHideBody}
              >
                expand_less
              </span>
              <span
                className="material-symbols-outlined"
                style={{
                  cursor: 'pointer',
                }}
                onClick={handleCloseChat}
              >
                close
              </span>
            </div>
          </div>
          {!hideBody && (
            <>
              <div className="chat-bodys invert-scroll">
                {userMessages?.chat?.length > 0 ? (
                  userMessages?.chat
                    ?.slice()
                    ?.reverse()
                    ?.map?.((data: any, index: any) => {
                      const {
                        channel_id,
                        file,
                        id,
                        message,
                        receiver_id,
                        sender_id,
                        created_at,
                        attachments,
                      } = data;

                      const image =
                        sender_id == user?.uid
                          ? personalInformation?.profile_image
                          : personal_info?.profile_image;
                      const name =
                        sender_id == user?.uid
                          ? personalInformation?.full_name ||
                            personalInformation?.username
                          : personal_info?.full_name || personal_info?.username;

                      const currentDate =
                        moment(created_at).format('MMMM Do YYYY');
                      const nextDate =
                        index < userMessages?.chat?.length - 1
                          ? moment(
                              userMessages?.chat?.slice()?.reverse()?.[
                                index + 1
                              ].created_at
                            ).format('MMMM Do YYYY')
                          : '';

                      const showDate = currentDate != nextDate;

                      return (
                        <>
                          <ChatMessage
                            image={user02}
                            sender={sender_id == user?.uid ? true : false}
                            data={{ message, created_at, image, name }}
                            attachments={attachments}
                          />
                          {showDate && (
                            <div
                              key={`date-${created_at}`}
                              className="p-4 text-center"
                            >
                              {currentDate} -{' '}
                              {moment(created_at).format('dddd')}
                            </div>
                          )}
                        </>
                      );
                    })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                    }}
                  >
                    <h3>No message found</h3>
                  </div>
                )}
              </div>
              {chatType == 'marketplace' && (
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    flexWrap: 'wrap',
                    padding: '16px',
                  }}
                >
                  {defaultMessages?.map?.((data: any, index: any) => (
                    <div
                      style={{
                        padding: '4px 8px',
                        borderRadius: '6px',
                        background: textInput.background,
                        width: 'max-content',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        index != 1
                          ? setValue(
                              'message',
                              `${data}\n${webBaseUrl}/item-detail/${productDetail?.id}`
                            )
                          : setValue('message', data)
                      }
                    >
                      {data}
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  borderTop: '0.5px solid #68686853',
                }}
              ></div>
              <div className="chat-messaging">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write a message..."
                    // {...register('message')}
                    ref={inputRef}
                    value={watchMessage}
                    onChange={(e) => setValue('message', e.target.value)}
                    style={{
                      resize: 'none',
                      border: 'none',
                      background: card.background,
                    }}
                  />
                </Form.Group>
              </div>
              <div
                style={{
                  borderTop: '0.5px solid #68686853',
                }}
              />
              <div className="px-2">
                <PreviewImages
                  images={uploadedImages}
                  removeImage={removeImage}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center px-2 pb-2">
                <div className="d-flex align-items-center">
                  <span
                    className="material-symbols-outlined"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleFileSelection('fileInputRef')}
                  >
                    image
                  </span>
                  <input
                    type="file"
                    id="imageUpload"
                    ref={fileInputRef}
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      handleImageUpload(
                        e,
                        setValue,
                        'attachments',
                        setUploadedImages,
                        watchAttachments
                      )
                    }
                  />
                  <span
                    className="material-symbols-outlined"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleFileSelection('filePdfRef')}
                  >
                    link
                  </span>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setEmoji(false);
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '8px 0',
                      }}
                    >
                      <span
                        className="material-symbols-outlined  me-1"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => setEmoji(!emoji)}
                      >
                        sentiment_satisfied
                      </span>
                      {emoji && (
                        <div
                          style={{
                            zIndex: 999999,
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                          }}
                        >
                          <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            perLine={6}
                          />
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                  <input
                    type="file"
                    id="imageUpload"
                    ref={filePdfRef}
                    accept="application/pdf"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      handleImageUpload(
                        e,
                        setValue,
                        'attachments',
                        setUploadedImages,
                        watchAttachments
                      )
                    }
                  />
                </div>
                <Button
                  variant="primary"
                  type="button"
                  className="float-end"
                  onClick={handleSendMessage}
                  style={{
                    background: button.background,
                    borderColor: border.dark,
                  }}
                >
                  Send
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ChatBox;
