import AboutGroup from 'src/app/screens/about-group';
import AccountSettings from 'src/app/screens/account-settings/AccountSettings';
import AddEducation from 'src/app/screens/add-education/AddEducation';
import AddItem from 'src/app/screens/add-item/AddItem';
import AddTeam from 'src/app/screens/add-team/AddTeam';
import AddWorkplaceExperience from 'src/app/screens/add-workplace-experience/AddWorkplaceExperience';
import Chat from 'src/app/screens/chat/Chat';
import CompanyItemDetail from 'src/app/screens/company-item-detail/CompanyItemDetail';
import CompanyItems from 'src/app/screens/company-items/CompanyItems';
import Connections from 'src/app/screens/connections/Connections';
import EditItemDetail from 'src/app/screens/edit-item-detail/EditItemDetail';
import EditPersonalInformation from 'src/app/screens/edit-personal-information/EditPersonalInformation';
import EducationDetail from 'src/app/screens/education-detail/EducationDetail';
import Education from 'src/app/screens/education/Education';
import Feed from 'src/app/screens/feed/Feed';
import FollowRequests from 'src/app/screens/follow-requests/FollowRequests';
import Followers from 'src/app/screens/followers/Followers';
import Following from 'src/app/screens/following/Following';
import {
  CreateGroup,
  GroupDetail,
  GroupRequests,
  Groups,
} from 'src/app/screens/groups';
import GroupMembers from 'src/app/screens/groups/GroupMembers';
import ItemDetail from 'src/app/screens/item-detail/ItemDetail';
import JobApplication from 'src/app/screens/job-application/JobApplication';
import JobDetails from 'src/app/screens/job-details/JobDetails';
import JobHistory from 'src/app/screens/job-history/JobHistory';
import Jobs from 'src/app/screens/jobs/Jobs';
import MarketPlace from 'src/app/screens/market-place/MarketPlace';
import MyItems from 'src/app/screens/my-items/MyItems';
import MyProfileTeamsDetail from 'src/app/screens/my-profile-teams-detail/MyProfileTeamsDetail';
import MyProfileTeams from 'src/app/screens/my-profile-teams/MyProfileTeams';
import PendingRequests from 'src/app/screens/pending-requests/PendingRequests';
import PersonalInformation from 'src/app/screens/personal-information/PersonalInformation';
import PostAdvertisement from 'src/app/screens/post-advertisement/PostAdvertisement';
import PostDetail from 'src/app/screens/post-detail/PostDetail';
import PostJob from 'src/app/screens/post-job/PostJob';
import PostedJobs from 'src/app/screens/posted-jobs/PostedJobs';
import ProfileAddTeam from 'src/app/screens/profile-add-team/ProfileAddTeam';
import ProfilePhotos from 'src/app/screens/profile-photos/ProfilePhotos';
import ProfileTeamDetail from 'src/app/screens/profile-team-detail/ProfileTeamDetail';
import ProfileTeams from 'src/app/screens/profile-teams/ProfileTeams';
import Profile from 'src/app/screens/profile/Profile';
import Teams from 'src/app/screens/shared/components/Teams/Teams';
import Support from 'src/app/screens/support/Support';
import TeamDetails from 'src/app/screens/team-details/TeamDetails';
import UserAbout from 'src/app/screens/user-about';
import UserFollowers from 'src/app/screens/user-followers/UserFollowers';
import UserPhotos from 'src/app/screens/user-photos';
import UserProfile from 'src/app/screens/user-profile/UserProfile';
import UserTeam from 'src/app/screens/user-team';
import UserTeamDetail from 'src/app/screens/user-team-detail';
import UserTeams from 'src/app/screens/user-teams/UserTeams';
import WorkExperience from 'src/app/screens/work-experience/WorkExperience';
import WorkplaceDetail from 'src/app/screens/workplace-detail/WorkplaceDetail';

export const DefaultRouter = [
  {
    path: '/',
    element: <Feed />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/user-profile/:id',
    element: <UserProfile />,
  },
  {
    path: '/user-profile/:id/post-detail/:postId',
    element: <PostDetail />,
  },
  {
    path: '/post-advertisement',
    element: <PostAdvertisement />,
  },
  {
    path: '/chat',
    element: <Chat />,
  },
  {
    path: '/teams',
    element: <Teams />,
  },
  {
    path: '/market-place',
    element: <MarketPlace />,
  },
  {
    path: '/company-items/:id',
    element: <CompanyItems />,
  },
  {
    path: '/item-detail/:id',
    element: <ItemDetail />,
  },
  {
    path: '/user-profile/about/:id',
    element: <UserAbout />,
  },
  {
    path: '/add-item',
    element: <AddItem />,
  },
  {
    path: '/company-item-detail/:itemId',
    element: <CompanyItemDetail />,
  },
  {
    path: '/edit-item-detail/:itemId',
    element: <EditItemDetail />,
  },
  {
    path: '/my-items',
    element: <MyItems />,
  },
  {
    path: '/user-profile/photos/:id',
    element: <UserPhotos />,
  },
  {
    path: '/user-profile/followers/:id',
    element: <UserFollowers />,
  },
  {
    path: '/user-profile/teams/:id',
    element: <UserTeams />,
  },
  {
    path: '/user-profile/teams',
    element: <UserTeam />,
  },
  {
    path: '/user-profile/team-details/:id/:teamId',
    element: <UserTeamDetail />,
  },
  {
    path: '/groups',
    element: <Groups />,
  },
  {
    path: '/groups/:userId',
    element: <Groups />,
  },
  {
    path: '/groups/:userId/:requested',
    element: <Groups />,
  },
  {
    path: '/groups/group-detail/:id',
    element: <GroupDetail />,
  },
  {
    path: '/groups/group-requests/:id',
    element: <GroupRequests />,
  },
  {
    path: '/groups/group-members/:id',
    element: <GroupMembers />,
  },
  {
    path: '/groups/about-group/:id',
    element: <AboutGroup />,
  },
  {
    path: '/create-group/:id',
    element: <CreateGroup />,
  },
  {
    path: '/jobs',
    element: <Jobs />,
  },
  {
    path: '/jobs/:sport_industry_id/:role_id/:sport_category_id/:sport_name_id/:sports_name_sub_category_id/:location',
    element: <Jobs />,
  },
  {
    path: '/jobs/job-details/:id',
    element: <JobDetails />,
  },
  {
    path: '/jobs/job-details/job-application/:id',
    element: <JobApplication />,
  },
  {
    path: '/post-job',
    element: <PostJob />,
  },
  {
    path: '/job-history',
    element: <JobHistory />,
  },
  {
    path: '/posted-jobs',
    element: <PostedJobs />,
  },
  {
    path: '/profile/followers',
    element: <Followers />,
  },
  {
    path: '/profile/followings',
    element: <Following />,
  },
  {
    path: '/profile/connections',
    element: <Connections />,
  },
  {
    path: '/profile/follow-requests',
    element: <FollowRequests />,
  },
  {
    path: '/profile/pending-requests',
    element: <PendingRequests />,
  },
  {
    path: '/profile/photos',
    element: <ProfilePhotos />,
  },
  {
    path: '/profile/teams',
    element: <ProfileTeams />,
  },
  {
    path: '/profile/team-details',
    element: <ProfileTeamDetail />,
  },
  {
    path: '/profile/add-team',
    element: <AddTeam />,
  },
  {
    path: '/profile/personal-information',
    element: <PersonalInformation />,
  },
  {
    path: '/profile/edit-personal-information',
    element: <EditPersonalInformation />,
  },
  {
    path: '/profile/education',
    element: <Education />,
  },
  {
    path: '/profile/education-detail/:id',
    element: <EducationDetail />,
  },
  {
    path: '/profile/add-education',
    element: <AddEducation />,
  },
  {
    path: '/profile/work-experience',
    element: <WorkExperience />,
  },
  {
    path: '/profile/workplace-detail/:id',
    element: <WorkplaceDetail />,
  },
  {
    path: '/profile/profile-teams',
    element: <MyProfileTeams />,
  },
  {
    path: '/profile/profile-teams-detail/:teamId',
    element: <MyProfileTeamsDetail />,
  },
  {
    path: '/team-details/:teamId',
    element: <TeamDetails />,
  },
  {
    path: '/profile/profile-add-teams',
    element: <ProfileAddTeam />,
  },
  {
    path: '/profile/profile-edit-teams/:id',
    element: <ProfileAddTeam />,
  },
  {
    path: '/profile/account-settings',
    element: <AccountSettings />,
  },
  {
    path: '/profile/support',
    element: <Support />,
  },
  {
    path: '/profile/add-workplace-experience',
    element: <AddWorkplaceExperience />,
  },
  {
    path: 'dashboard/app/profile',
    element: <UserProfile />,
  },
];
