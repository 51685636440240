import { Button, Card, Image } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cls from 'classnames';
import {
  useCancelRequestMutation,
  useGetPersonalInformationQuery,
  useGetUserDetailsQuery,
  useSendRequestMutation,
} from 'src/app/screens/profile/services/profileApi';
import {
  setChatType,
  setOpenChat,
  setReceiverId,
} from '../../store/sharedSlice';
import Avatar from '../Avatar';
import VerifiedIcon from '../../../../assets/images/check-circle.png';

interface IProfileHeader {
  loggedInUserProfile: boolean;
  activeLink?: string;
  myProfile?: boolean;
  changeBackground?: any;
}

const ProfileHeader = ({
  loggedInUserProfile,
  activeLink,
  myProfile = false,
  changeBackground,
}: IProfileHeader) => {
  const dispatch = useDispatch();

  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const { user } = useSelector((state: any) => state.auth);

  const {
    palette: { background, button, border, textInput },
  } = theme;

  const { id } = useParams();

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {},
    {
      skip: id ? true : false,
    }
  );

  const { data: getUserDetails = {}, isFetching } = useGetUserDetailsQuery(id, {
    skip: !id,
  });

  const data =
    getPersonalInformation?.data || getUserDetails?.data?.personal_info;

  const followStatus = getUserDetails?.data;

  const [sendRequest, { isLoading }] = useSendRequestMutation();
  const [cancelRequest, { isLoading: cancellingRequest }] =
    useCancelRequestMutation();

  const handleOpenChat = () => {
    dispatch(setOpenChat(true));
    dispatch(setReceiverId(id));
    dispatch(setChatType('general'));
  };

  const handleSendRequest = async (type: any) => {
    const bodyData = {
      followee_id: id,
      type: type,
    };

    const response =
      (followStatus?.follow_status == 'no' && type == 'follow') ||
      (followStatus?.connection_status == 'no' && type == 'connection')
        ? await sendRequest(bodyData)
        : await cancelRequest(bodyData);

    const { error, data: respData }: any = response || {};

    if (error) toast.error(error?.data?.message);

    if (respData) {
      toast.success(respData?.message);
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="profile-page p-0">
          <div className="profile-header">
            <div className="position-relative">
              <Image
                loading="lazy"
                src={data?.background_image}
                alt="profile-bg"
                className="rounded img-fluid custom-image-height"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
            {changeBackground && (
              <span
                onClick={changeBackground}
                style={{
                  border: '0px solid red',
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  background: '#0008',
                  borderRadius: '50%',
                  padding: '5px',
                  cursor: 'pointer',
                }}
                className="material-symbols-outlined"
              >
                edit
              </span>
            )}
            <div
              className="user-detail text-center"
              style={{
                marginBottom: '-16px',
              }}
            >
              <div className="profile-img custom-avatar-dimension">
                {data?.profile_image && <Avatar pfp={data?.profile_image} />}
              </div>
              <div className="profile-detail">
                <div
                  className={cls(
                    'd-flex',
                    'align-items-center',
                    'justify-content-center',
                    'gap-1'
                  )}
                >
                  <h4 className="">{data?.full_name || data?.username}</h4>
                  {data?.is_verified && (
                    <img
                      src={VerifiedIcon}
                      alt="Verified User"
                      width={20}
                      className="ms-1 mb-1"
                    />
                  )}
                </div>
                <p>{data?.roles?.title}</p>
              </div>
            </div>
            <div className="profile-info p-3 d-flex align-items-center justify-content-end position-relative">
              {loggedInUserProfile ? (
                !myProfile ? (
                  <div className="social-info">
                    <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                      <li className="text-center ps-3">
                        <Link to="/profile">
                          <h6
                            className={`${
                              activeLink === 'profile' ? 'fw-bold' : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'profile' && background.dark,
                            }}
                          >
                            Posts
                          </h6>
                          <h6
                            className={`mb-0 ${
                              activeLink === 'profile' ? 'fw-bold' : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'profile' && background.dark,
                            }}
                          >
                            {data?.total_posts}
                          </h6>
                        </Link>
                      </li>
                      <li className="text-center ps-3">
                        <Link to="/profile/followers">
                          <h6
                            className={`${
                              activeLink === 'followers'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'followers' && background.dark,
                            }}
                          >
                            Followers
                          </h6>
                          <h6
                            className={`mb-0 ${
                              activeLink === 'followers'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'followers' && background.dark,
                            }}
                          >
                            {data?.total_followers}
                          </h6>
                        </Link>
                      </li>
                      <li className="text-center ps-3">
                        <Link to="/profile/followings">
                          <h6
                            className={`${
                              activeLink === 'following'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'following' && background.dark,
                            }}
                          >
                            Following
                          </h6>
                          <h6
                            className={`mb-0 ${
                              activeLink === 'following'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'following' && background.dark,
                            }}
                          >
                            {data?.total_folowings}
                          </h6>
                        </Link>
                      </li>
                      <li className="text-center ps-3">
                        <Link to="/profile/connections">
                          <h6
                            className={`${
                              activeLink === 'connections'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'connections' && background.dark,
                            }}
                          >
                            Connections
                          </h6>
                          <h6
                            className={`mb-0 ${
                              activeLink === 'connections'
                                ? 'fw-bold'
                                : 'fw-normal'
                            }`}
                            style={{
                              color:
                                activeLink === 'connections' && background.dark,
                            }}
                          >
                            {data?.total_connections}
                          </h6>
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div
                    style={{
                      height: '45px',
                    }}
                  ></div>
                )
              ) : (
                <div className="d-flex gap-2 flex-wrap">
                  <Button
                    variant="primary"
                    type="button"
                    className="float-end"
                    onClick={handleOpenChat}
                    style={{
                      background: button.background,
                      borderColor: border.dark,
                    }}
                  >
                    Message
                  </Button>
                  <Button
                    disabled={isLoading || cancellingRequest}
                    variant="primary"
                    type="button"
                    className="float-end"
                    onClick={() =>
                      handleSendRequest(
                        followStatus?.follow_status == 'no'
                          ? 'follow'
                          : 'follower'
                      )
                    }
                    style={{
                      background: button.background,
                      borderColor: border.dark,
                    }}
                  >
                    {followStatus?.follow_status == 'no'
                      ? 'Follow'
                      : followStatus?.follow_status == 'yes'
                      ? 'Following'
                      : 'Cancel Follow Request'}
                  </Button>
                  <Button
                    disabled={isLoading || cancellingRequest}
                    variant="primary"
                    type="button"
                    className="float-end"
                    onClick={() => handleSendRequest('connection')}
                    style={{
                      background: button.background,
                      borderColor: border.dark,
                    }}
                  >
                    {followStatus?.connection_status == 'no'
                      ? 'Connect'
                      : followStatus?.connection_status == 'yes'
                      ? 'Disconnect'
                      : 'Cancel Connection Request'}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileHeader;
