import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar';
import TeamDetailCard from '../shared/components/TeamDetailCard/TeamDetailCard';

const MyProfileTeamsDetail = () => {
  const history = useNavigate();

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={true} myProfile={true} />
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <ProfileSidebar activeLink="profile-teams" />
            </Col>
            <Col md={8}>
              <TeamDetailCard name="Alpha Team" icon={true} />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default MyProfileTeamsDetail;
