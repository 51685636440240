import Spinner from 'react-bootstrap/Spinner';

interface LoaderProps {
  size?: 'sm' | undefined;
  sx?: any;
}

function Loader({ size, sx }: LoaderProps) {
  return (
    <div
      style={{
        border: '0px solid red',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        ...sx,
      }}
    >
      <Spinner size={size} />
    </div>
  );
}

export default Loader;
