import { api } from 'src/app/services';

export const advertisementApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAdvertisement: builder.mutation({
      query: (body) => {
        return {
          url: `/api/user/advertisement/create`,
          method: 'POST',
          body,
        };
      },
    }),
    advertisementPlans: builder.query({
      query: () => {
        return {
          url: `/api/user/dropdown/advertisement/plans`,
          method: 'GET',
        };
      },
    }),
    getAdvertisement: builder.query({
      query: () => {
        return {
          url: `/api/user/advertisement/swipe-advertisement`,
          method: 'GET',
        };
      },
    }),
    viewAdvertisement: builder.mutation({
      query: (advertisementId) => {
        return {
          url: `/api/user/advertisement/view-advertisement?id=${advertisementId}`,
          method: 'GET',
        };
      },
    }),
    getClientSecret: builder.mutation({
      query: (price) => {
        return {
          url: `/api/user/advertisement/stripe-intent?price=${price}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateAdvertisementMutation,
  useAdvertisementPlansQuery,
  useGetAdvertisementQuery,
  useViewAdvertisementMutation,
  useGetClientSecretMutation,
} = advertisementApi;
