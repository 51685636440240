import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GroupMiniInfoCard = () => {
  const history = useNavigate();

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Groups</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <div
            onClick={() => history('/')}
            style={{
              cursor: 'pointer',
            }}
            className="d-flex gap-1 align-items-center mb-1"
          >
            <span className="material-symbols-outlined">view_timeline</span>
            <span>Your home feed</span>
          </div>
          <div
            onClick={() => history('/groups')}
            style={{
              cursor: 'pointer',
              marginTop: 10,
            }}
            className="d-flex gap-1 align-items-center  mb-1"
          >
            <span className="material-symbols-outlined">
              location_searching
            </span>
            <span>Home discover</span>
          </div>
          <div className="mt-2">
            <div className="d-grid">
              <Button
                variant="primary"
                type="button"
                className="float-end"
                onClick={() => history('/create-group/0')}
                style={{
                  background: button.background,
                  borderColor: border.dark,
                }}
              >
                Create New Group
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GroupMiniInfoCard;
