import image01 from "../assets/images/01.jpg"
import image02 from "../assets/images/02.jpg"
import image03 from "../assets/images/03.jpg"
import image04 from "../assets/images/04.jpg"

export const followSuggestionsData = [
    {
        image: image01,
        name: 'Anna Sthesia',
        username: '@annasthesia'
    },
    {
        image: image02,
        name: 'Michael Smith',
        username: '@michaelSmith'
    },
    {
        image: image03,
        name: 'Sarah Johnson',
        username: '@sarahJOhn'
    },
    {
        image: image04,
        name: 'Christopher Anderson',
        username: '@ChrisAnderson'
    },
]