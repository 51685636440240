import {
    yupResolver
} from "@hookform/resolvers/yup"
import {
    email_validator1
} from "src/app/config/types/validators"
import {
    object
} from "yup"

const forgotPasswordSchema = object().shape({
    email: email_validator1,
})

export default yupResolver(forgotPasswordSchema)