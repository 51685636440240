import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ProfileHeader from '../shared/components/ProfileHeader'
import TeamDetailCard from '../shared/components/TeamDetailCard'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const ProfileTeamDetail = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    {/* <Container
    style={{
        border: '0px solid red',
    }}
> */}
                    {/* //todo: Get content from template and design acc to XD */}
                    <Row>
                        <Col md={10}>
                            <ProfileHeader loggedInUserProfile={true} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <TeamDetailCard
                                name="Alpha Team"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default ProfileTeamDetail