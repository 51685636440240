import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';
import ProfileSidebar from '../shared/components/ProfileSidebar/ProfileSidebar';
import WorkCard from '../shared/components/WorkCard/WorkCard';
import WorkContainer from '../shared/components/WorkContainer/WorkContainer';

const Education = () => {
  const history = useNavigate();

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={true} myProfile={true} />
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <ProfileSidebar activeLink="education" />
            </Col>
            <Col md={8}>
              <WorkContainer title="Education" education={true}>
                <WorkCard
                  redirectTo="/profile/education-detail"
                  education={true}
                />
              </WorkContainer>
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default Education;
