import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetWorkExperienceDetailQuery } from 'src/app/screens/profile/services/profileApi';
import Spinner from 'react-bootstrap/Spinner';

const WorkplaceDetailCard = () => {
  const { id } = useParams();

  const { data: getWorkExperienceDetail = {}, isLoading } =
    useGetWorkExperienceDetailQuery(id);

  const { data = {} } = getWorkExperienceDetail;

  const {
    job_title,
    location,
    employment_type,
    niche_industry,
    company,
    sport_category,
    sport_name,
    sport_type,
  } = data || {};

  return (
    <>
      {!isLoading ? (
        <Card>
          <div className="card-header d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Work Experience</h4>
            </div>
          </div>
          <Card.Body>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Title/Role:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{job_title}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Location:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{location}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Employment Type:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{employment_type?.title}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Niche/Industry:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{niche_industry?.title}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Company Name:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{company}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Sports Categories:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{sport_category?.title}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Sports Name:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{sport_name?.title}</h5>
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <div className="col-6">
                    <h5 className="fw-bold">Sports Type:</h5>
                  </div>
                  <div className="col-6">
                    <h5 className="mb-0">{sport_type?.title}</h5>
                  </div>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default WorkplaceDetailCard;
