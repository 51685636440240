import cls from 'classnames';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import ChatBox from '../../shared/components/ChatBox/ChatBox';
import Header from '../../shared/components/HeaderStyle/header';
import RightSidebar from '../../shared/components/RightSidebar';
import './../../../../global.css';

const Default = () => {
  const { openChat } = useSelector((state) => state.shared);

  const { pathname } = useLocation();

  return (
    <div
      style={{
        border: '0px solid red',
      }}
    >
      <Header />
      <div
        className={cls('main-content')}
        style={{
          border: '0px solid red',
        }}
      >
        <Outlet />
        {openChat && <ChatBox />}
      </div>
      {!pathname.includes('chat') && <RightSidebar />}
    </div>
  );
};

export default Default;
