import { Col, Row } from 'react-bootstrap'
import SupportForm from '../shared/components/Forms/SupportForm'
import ProfileHeader from '../shared/components/ProfileHeader'
import AuthGuard from 'src/app/config/auth/AuthGuard'

const Support = () => {
    return (
        <AuthGuard>
            <>
                <div
                    id="content-page"
                    className="content-page"
                >
                    <Row>
                        <Col md={10}>
                            <ProfileHeader
                                loggedInUserProfile={true}
                                myProfile={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <SupportForm />
                        </Col>
                    </Row>
                </div>
            </>
        </AuthGuard>
    )
}

export default Support