import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { followSuggestionsData } from 'src/app/mock/followSuggestions';
import { teamsData } from 'src/app/mock/teams';
import CreatePost from '../shared/components/CreatePost';
import FollowSuggestion from '../shared/components/FollowSuggestion';
import MiniProfileCard from '../shared/components/MiniProfileCard';
import Post from '../shared/components/Post';
import TeamCard from '../shared/components/TeamCard';
import { useSelector } from 'react-redux';
import { useGetPersonalInformationQuery } from '../profile/services/profileApi';

const Feed = () => {
  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { full_name, username } = personalInformation || {};

  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col sm={12} md={12} lg={4}>
              <MiniProfileCard />
              <FollowSuggestion users={followSuggestionsData} />
              <TeamCard
                home
                teams={teamsData}
                loggedInUserProfile={true}
                redirectTo="team-details"
                style={{
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <CreatePost
                placeholder={`What's your game plan, ${full_name || username}`}
              />
              <Post home />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default Feed;
