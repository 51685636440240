import { Col, Row } from 'react-bootstrap';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import ProfileHeader from '../shared/components/ProfileHeader/ProfileHeader';
import TeamDetailCard from '../shared/components/TeamDetailCard/TeamDetailCard';

const TeamDetails = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <Row>
            <Col md={10}>
              <ProfileHeader loggedInUserProfile={true} myProfile={true} />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <TeamDetailCard name="Alpha Team" />
            </Col>
          </Row>
        </div>
      </>
    </AuthGuard>
  );
};

export default TeamDetails;
