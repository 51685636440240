import AuthGuard from 'src/app/config/auth/AuthGuard';
import CreateGroupForm from './components/CreateGroupForm/CreateGroupForm';

const CreateGroup = () => {
  return (
    <AuthGuard>
      <>
        <div id="content-page" className="content-page">
          <CreateGroupForm />
        </div>
      </>
    </AuthGuard>
  );
};

export default CreateGroup;
