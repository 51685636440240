import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { baseUrl } from 'src/app/config/types/base-url';
import {
  useAddPostMutation,
  useGetPostDetailsQuery,
  useUpdatePostMutation,
} from 'src/app/screens/feed/services/feedApi';
import { useGetPersonalInformationQuery } from 'src/app/screens/profile/services/profileApi';
import photoVideoImage from '../../../../assets/images/photo-video.png';
import Avatar from '../Avatar/Avatar';
import PreviewImages from '../PreviewImages/PreviewImages';
import cls from 'classnames';

type PostModalType = {
  open: boolean;
  close: () => void;
  type: 'add' | 'edit';
  postId?: any;
  groupId?: number;
};

const PostModal = ({
  groupId,
  open,
  close,
  type = 'add',
  postId,
}: PostModalType) => {
  const defaultValues = {
    post: '',
    is_comments_allowed: '0',
    attachments: [],
    delete_attachment: [],
  };

  const { theme } = useSelector((state: any) => state.styles);

  const {
    palette: { background, button, border },
  } = theme;

  const [previewUrls, setPreviewUrls] = useState<any>([]);
  const [prevAttachments, setPrevAttachments] = useState([]);
  const fileInputRef = useRef<any>(null);
  const [postAttachments, setPostAttachments] = useState<any>([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  }: any = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchPost = watch('post');
  const watchAttachments = watch('attachments');

  const { data: getPersonalInformation = {} } = useGetPersonalInformationQuery(
    {}
  );

  const { data: personalInformation = {} } = getPersonalInformation;

  const { data: getPostDetails = {}, isFetching: gettingPostDetails } =
    useGetPostDetailsQuery(postId, {
      skip: !postId,
    });

  const { data: postDetails = {} } = getPostDetails;

  const [addPost, { isLoading: addingPost }] = useAddPostMutation();
  const [updatePost, { isLoading: updatingPost }] = useUpdatePostMutation();

  const isLoading = addingPost || updatingPost;

  const handleFileSelection = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    setValue('attachments', [
      ...Array.from(watchAttachments),
      ...Array.from(e.target.files),
    ]);

    const files = Array.from(e.target.files);
    const urls: any = files.map((file: any) => {
      if (file.type.startsWith('image/')) {
        return URL.createObjectURL(file);
      } else if (file.type.startsWith('video/')) {
        return `${baseUrl}/assets/img/default_logo.png`;
      }
      return null;
    });

    setPreviewUrls((prevUrls: any) => [...prevUrls, ...urls]);
  };

  const emptyFields = () => {
    setValue('post', '');
    setValue('is_comments_allowed', '0');
    setValue('attachments', []);
    setPreviewUrls([]);
  };

  const populateFields = () => {
    reset({
      ...postDetails,
    });
    setValue(
      'is_comments_allowed',
      postDetails?.is_comments_allowed == '0' ? false : true
    );
    setPrevAttachments(postDetails?.attachments);

    const existingImageUrls = postDetails?.attachments?.map(
      (attachment: any) => {
        return baseUrl + '/storage/' + attachment.path;
      }
    );
    setPreviewUrls(existingImageUrls);
  };

  const removeImage = (indexToRemove: number) => {
    const updatedPreviewUrls = [...previewUrls];
    updatedPreviewUrls.splice(indexToRemove, 1);
    let fileArray = Array.from(watchAttachments);
    const removedAttachment: any = fileArray[indexToRemove];
    setValue('delete_attachment', [removedAttachment?.id]);
    const filterAttachments = fileArray?.filter(
      (data: any, index) => index !== indexToRemove
    );
    setValue('attachments', filterAttachments);
    setPreviewUrls(updatedPreviewUrls);
    const removedImages = postDetails?.attachments
      ?.filter(
        (obj: any) =>
          !filterAttachments?.some((item: any) => item?.id === obj?.id)
      )
      ?.map?.((obj: any) => obj?.id);
    setValue('delete_attachment', removedImages);
  };

  const handleAddPost = handleSubmit(async (data: any) => {
    if (watchPost != '' || watchAttachments?.length > 0) {
      const formData = new FormData();

      postId && formData.append('id', postId);
      formData.append('post', data?.post);
      data?.delete_attachment?.forEach((data: any) =>
        formData?.append('delete_attachment[]', data)
      );
      formData.append(
        'is_comments_allowed',
        data?.is_comments_allowed ? '1' : '0'
      );
      Object?.keys(data?.attachments)?.forEach((key: any) =>
        formData?.append('attachments[]', data?.attachments[key])
      );

      if (groupId) formData.append('group_id', groupId.toString());

      const response =
        type === 'add' ? await addPost(formData) : await updatePost(formData);

      const { error, data: respData }: any = response || {};

      if (error) toast.error(error?.data?.message);

      if (respData) {
        toast.success(respData?.message);
        emptyFields();
        close();
      }
    } else {
      toast.error('Please add text or images to create post');
    }
  });

  useEffect(() => {
    if (postId) populateFields();
  }, [postDetails]);

  return (
    <>
      <Modal centered show={open} onHide={close}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="modal-title" id="post-modalLabel">
            {postId ? 'Edit Post' : 'Create Post'}
          </h5>
          <Link className="lh-1" to="#" onClick={close}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        {type === 'add' || (type === 'edit' && !gettingPostDetails) ? (
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 8,
                marginBottom: '16px',
              }}
            >
              <Avatar
                pfp={personalInformation?.profile_image}
                size="50"
                name={personalInformation?.username}
                style={{
                  margin: 0,
                }}
              />
              <div>
                <span>
                  {personalInformation?.full_name ||
                    personalInformation?.username}
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span className="material-symbols-outlined">forum</span>
                    <span className="ms-2">Allow comments</span>
                  </div>
                  <div>
                    <Form.Check
                      type="switch"
                      {...register('is_comments_allowed')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col md="12">
                <Form.Group className="form-group mb-0">
                  <Form.Control
                    as="textarea"
                    placeholder="Post Title"
                    {...register('post')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 4,
                marginTop: previewUrls?.length > 0 ? '16px' : 0,
              }}
            >
              <PreviewImages
                images={previewUrls}
                removeImage={removeImage}
                type={type}
              />
            </div>

            <div className="divider"></div>

            <div
              className={cls(
                'bg-soft-primarys',
                'rounded',
                'p-2',
                'pointer',
                'd-flex',
                'align-items-center',
                'me-3',
                'mb-md-0',
                'mb-4m',
                'mt-3'
              )}
              style={{
                background: background.search,
                width: 'max-content',
                cursor: 'pointer',
                fontSize: '12px',
              }}
              onClick={handleFileSelection}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
              />
              <Image
                loading="lazy"
                src={photoVideoImage}
                alt="icon"
                className="img-fluid me-1"
                style={{
                  width: '20px',
                }}
              />
              Photo/Video
            </div>
            <Button
              disabled={isLoading}
              variant="primary"
              type="button"
              className="float-end"
              style={{
                background: button.background,
                borderColor: border.dark,
                marginTop: '32px',
              }}
              onClick={handleAddPost}
            >
              {!postId ? 'Create Post' : 'Update'}
            </Button>
          </Modal.Body>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px 0',
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </Modal>
    </>
  );
};

export default PostModal;
