import { useGoogleLogin } from '@react-oauth/google';
import cls from 'classnames';
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthGuard from 'src/app/config/auth/AuthGuard';
import { slideOne, slideThree, slideTwo } from 'src/app/config/constants/texts';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import cycling from '../../../assets/images/cycling.png';
import gmail from '../../assets/images/gmail.png';
import { enterEmail } from '../../config/constants';
import TextInput from '../shared/components/TextInput';
import loginSchema from './schema/login.schema';
import { useGoogleLoginMutation, useLoginMutation } from './services/authApi';

const SignIn = () => {
  const defaultValues = {
    email: '',
    password: '',
    terms_and_conditions: false,
  };

  const history = useNavigate();

  const { theme } = useSelector((state) => state.styles);

  const {
    palette: { button, border },
  } = theme;

  const [terms_and_conditions, setTermsAndConditions] = useState(false);

  const [login, { isLoading }] = useLoginMutation();
  const [googleLogin, { isLoading: loggingInUser }] = useGoogleLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: loginSchema,
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await fetch(
        'https://www.googleapis.com/oauth2/v2/userinfo',
        {
          headers: {
            Authorization: `Bearer ${tokenResponse?.access_token}`,
          },
        }
      ).then((response) => response.json());
      if (userInfo) {
        const data = {
          google_id: userInfo?.id,
          email: userInfo?.email,
          name: userInfo?.name,
        };
        const response = await googleLogin(data);

        const { error, data: respData } = response || {};

        if (error)
          toast.error(
            error?.data?.errors?.AccountBlock || error?.data?.message
          );

        if (respData) {
          toast.success(respData?.message);
          history('/');
        }
      }
    },
  });

  const handleLogin = handleSubmit(async (data) => {
    if (data?.terms_and_conditions) {
      const response = await login(data);

      const { error, data: respData } = response || {};

      if (error)
        toast.error(error?.data?.errors?.AccountBlock || error?.data?.message);

      if (respData) {
        history('/');
      }
    }
  });

  return (
    <AuthGuard>
      <>
        <section className={cls('sign-in-page')}>
          <div
            id="container-inside"
            style={{
              border: '0px solid red',
            }}
          >
            <div
              id="circle-small"
              style={{
                background: '#5B5B5C',
              }}
            />
            <div
              id="circle-medium"
              style={{
                background: '#444444',
              }}
            />
            <div
              id="circle-large"
              style={{
                background: '#2D2D2D',
              }}
            />
            <div
              id="circle-xlarge"
              style={{
                background: '#1F1E1F',
              }}
            />
            <div
              id="circle-xxlarge"
              style={{
                background: '#151515',
              }}
            />
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              <Col
                md="6"
                className="text-center "
                style={{
                  border: '0px solid red',
                }}
              >
                <div
                  className="sign-in-detail text-white"
                  style={{
                    border: '0px solid red',
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Link className="sign-in-logo" to="#">
                      <Logo height="73px" width="" />
                    </Link>
                    <h4 className="mb-3">Your Sports Network</h4>
                    <div
                      className="sign-slider"
                      style={{
                        border: '0px solid red',
                      }}
                    >
                      <div className="sign-slider overflow-hidden ">
                        <Swiper
                          spaceBetween={30}
                          centeredSlides={true}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          className="list-inline m-0 p-0 "
                        >
                          <SwiperSlide>
                            <Image
                              src={cycling}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Find new friends
                            </h4>
                            <p>{slideOne}</p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={cycling}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Connect with the world
                            </h4>
                            <p>{slideTwo}</p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={cycling}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Create new events
                            </h4>
                            <p>{slideThree}</p>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md="6"
                className="bg-white pt-5 pt-5 pb-lg-0 pb-5"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="sign-in-from"
                  style={{
                    border: '0px solid red',
                    width: '100%',
                  }}
                >
                  <h1 className="mb-0">Sign In</h1>
                  <p>{enterEmail}</p>
                  <Form className="mt-4" onSubmit={handleLogin}>
                    <TextInput
                      name="email"
                      placeholder="Email"
                      register={register('email')}
                    />
                    <p className="text-danger">{errors?.email?.message}</p>
                    <TextInput
                      name="password"
                      placeholder="New Password"
                      register={register('password')}
                    />
                    <p className="text-danger">{errors?.password?.message}</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Link
                        to="/auth/forgot-password"
                        style={{
                          marginBottom: 10,
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        {...register('terms_and_conditions')}
                      />
                      <p className="mb-0">
                        I agreed to the{' '}
                        <Link to="/auth/terms-and-conditions">
                          Terms and Conditions.
                        </Link>
                      </p>
                    </div>
                    <p className="text-danger">
                      {errors?.terms_and_conditions?.message}
                    </p>
                    <div className="d-inline-block w-100">
                      <Button
                        variant="primary"
                        disabled={isLoading}
                        type="submit"
                        className="float-end"
                        style={{
                          background: button.background,
                          borderColor: border.dark,
                        }}
                      >
                        Sign In
                      </Button>
                    </div>
                    <div
                      className="sign-info"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: '8px',
                        borderTop: `1px solid ${border.divider}`,
                      }}
                    >
                      <span
                        className={cls(
                          'line-height-2',
                          'd-inline-block',
                          'dark-color'
                        )}
                        style={{
                          marginTop: '3px',
                        }}
                      >
                        Don't have an account?
                        <Link
                          to="/auth/sign-up"
                          style={{
                            marginLeft: 10,
                            cursor: 'pointer',
                          }}
                        >
                          Sign Up
                        </Link>
                      </span>
                      <Image
                        src={gmail}
                        className="img-fluid mb-4"
                        alt="logo"
                        style={{
                          height: '22px',
                          maxWidth: '22px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleGoogleLogin()}
                      />
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </AuthGuard>
  );
};

export default SignIn;
