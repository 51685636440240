import { Card, Row } from 'react-bootstrap';
import JobCard from '../JobCard/JobCard';

const PostedJobsCard = () => {
  return (
    <>
      <Card>
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Posted Jobs</h4>
          </div>
        </div>
        <Card.Body>
          <Row>
            <JobCard jobHistory={true} postedJobs />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PostedJobsCard;
